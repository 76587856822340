import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import api from "../../../services/axios-config.service"
import D from "../../../store/localStorage/dispatcher"
import Selector from "../../LocalElements/Selector/reactSelect/Selector"

const SelectCompany = ({ companyId, setCompanyId }) => {
  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector(state => state.headerReducer.role)
  const token = useSelector(state => state.authReducer.authData.token)

  const [data, setData] = useState(null)
  const isClearable = true
  const isSearchable = false

  const options = data
    ? data.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = (selectedOption) => {
    setCompanyId(selectedOption ? selectedOption.value : "")
  }

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        if ( userRole === "super" ) {
          const apiUrl = `${process.env.REACT_APP_API_URL}/api/companies/list`
          const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          }
          const response = await api.get(apiUrl, { headers })
          const companyData = response.data.data
          setData(companyData)
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchUsersData()
  }, [token, userRole])

  return (
    <React.Fragment>
      <Selector
        placeholder={"Выберите компанию"}
        options={options}
        onChange={getValue}
        value={options.find((option) => option.value === companyId)}
        isClearable={isClearable}
        isSearchable={isSearchable}
        noOptionsMessage={() => "Нет доступных компаний"}
      />
    </React.Fragment>
  )
}

export default SelectCompany
