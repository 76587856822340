import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  dataEtalon: [],
  selectedEtalon: null,
  metaEtalon: null | undefined,
  isLoading: true,
  searchQuery: "",

  createData: {
    companyId: "",
    idMeasurement: "",
    methodId: "",
    etalonId: "",
    typeKits: "",
    manufacturerNumber: "",
    regNumber: "",
    signKits: "",
    link: "",
    dateFrom: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    dateTo: "",
    verificationPlace: "",
  },
};

export const etalonReducer = createSlice({
  name: "etalonReducer",
  initialState,
  reducers: {
    setDataEtalon: (state, action) => {
      state.dataEtalon = action.payload;
    },
    setMetaEtalon: (state, action) => {
      state.metaEtalon = action.payload;
    },

    changeEtalon: (state, action) => {
      state.createData[action.payload.value] = action.payload.label;
    },
    resetCreateData: (state) => {
      state.createData = initialState.createData;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setDataEtalon,
  setMetaEtalon,
  changeEtalon,
  resetCreateData,
  setSearchQuery,
  setIsLoading,
} = etalonReducer.actions;
export default etalonReducer.reducer;
