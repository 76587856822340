import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";

import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import Selector from "../../LocalElements/Selector/reactSelect/Selector";
import Input from "../../LocalElements/Input/InputMUI";
import DatePicker from "../../LocalElements/DatePicker/DatePicker";
import SelectCompany from "../Select/SelectCompany";

import D from "../../../store/localStorage/dispatcher";
import R from "../../../services/request.service";

import {
  changeTickets,
  resetCreateTickets,
} from "../../../store/slices/views/journals";
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/comps/snackbar";
import { refreshTickets } from "../../../store/slices/controlers/updater";

const AddJournalTickets = () => {
  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole");
  const userRole = useSelector((state) => state.headerReducer.role);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isToggle = useSelector((state) => state.toggleReducer.isToggle);
  const [validationErrors, setValidationErrors] = useState({});
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);

  const [data, setData] = useState(null);
  const isClearable = true;
  const isSearchable = false;

  const [companyId, setCompanyId] = useState("");

  const meterNumber = useSelector(
    (state) => state.journalReducer.createTickets.meterNumber
  );

  const handleMeterNumberChange = (param) => {
    dispatch(changeTickets({ value: "meterNumber", label: param }));
  };

  const customerName = useSelector(
    (state) => state.journalReducer.createTickets.customerName
  );

  const handleCustomerNameChange = (param) => {
    dispatch(changeTickets({ value: "customerName", label: param }));
  };

  const nameSi = useSelector(
    (state) => state.journalReducer.createTickets.nameSi
  );

  const handleNameSiChange = (param) => {
    dispatch(changeTickets({ value: "nameSi", label: param }));
  };

  const verificationDate = useSelector(
    (state) => state.journalReducer.createTickets.verificationDate
  );

  const handleVerificationDateChange = (date) => {
    if (date) {
      dispatch(
        changeTickets({
          value: "verificationDate",
          label: date.format("YYYY-MM-DD HH:mm:ss"),
        })
      );
    }
  };

  const meterFactoryNumber = useSelector(
    (state) => state.journalReducer.createTickets.meterFactoryNumber
  );

  const handleMeterFactoryNumberChange = (param) => {
    dispatch(changeTickets({ value: "meterFactoryNumber", label: param }));
  };

  const arshinName = useSelector(
    (state) => state.journalReducer.createTickets.arshinName
  );

  const arshinId = useSelector(
    (state) => state.journalReducer.createTickets.arshinId
  );

  const handleArshinIdChange = (param) => {
    dispatch(changeTickets({ value: "arshinId", label: param }));
  };

  const protocolId = useSelector(
    (state) => state.journalReducer.createTickets.protocolId
  );

  const handleProtocolIdChange = (param) => {
    dispatch(changeTickets({ value: "protocolId", label: param }));
  };

  const desc = useSelector((state) => state.journalReducer.createTickets.desc);

  const handleDescChange = (param) => {
    dispatch(changeTickets({ value: "desc", label: param }));
  };

  const options = data
    ? data.map((data) => ({
        value: `${data.surname} ${data.name} ${data.patr}`,
        label: `${data.surname} ${data.name} ${data.patr}`,
      }))
    : [];

  const getValue = (selectedOption) => {
    dispatch(
      changeTickets({
        value: "arshinName",
        label: selectedOption ? selectedOption.value : null,
      })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await R.getUserList(`?company_id=${companyId}`);
      setData(data.data);
    };

    fetchData();
  }, [companyId]);

  const navigateToProtocols = () => {
    navigate("/metriva/journals");
  };

  const handleSave = async () => {
    setIsDisabledBtn(true);
    const data = {
      meter_number: meterNumber,
      customer_arshin_name: customerName,
      name_si: nameSi,
      meter_factory_number: meterFactoryNumber,
      verifier_arshin_name: arshinName,
      verification_date: verificationDate,
      arshin_id: arshinId,
      protocol_id: protocolId,
      desc: desc,
    };

    if (userRole === "super") {
      data.company_id = companyId;
    }

    const { status, data: ticketsAddData } = await R.addTickets(data);

    if (status === 201) {
      dispatch(setMessage("Запись успешно создана"));
      dispatch(setType("success"));
      dispatch(setOpenAlert(true));
      dispatch(resetCreateTickets());
      dispatch(refreshTickets());
      navigate("/metriva/journals");
    } else {
      if (ticketsAddData.errors) {
        const errors = ticketsAddData.errors;
        setValidationErrors(errors);
      }
      dispatch(setType("error"));
      dispatch(setOpenAlert(true));
      dispatch(setMessage("Запись не добавлена - ошибка валидации"));
      setIsDisabledBtn(false);
    }
  };

  return (
    <main className={isToggle ? "main_open" : "main"}>
      <div className="path_to_back" onClick={navigateToProtocols}>
        <i className="fas fa-arrow-left icon_back" style={{ width: '30px', height: '30px', marginRight: '6px' }} />
        <p>Все журналы</p>
      </div>

      <div className="path_to_edit">
        <h4>Журнал "Заявок"</h4>
      </div>
      <div className="box">
        {userRole === "super" && (
          <div className="width32Percent" style={{ marginBottom: "15px" }}>
            <h2 style={{ marginBottom: "15px" }}>Компания</h2>
            <SelectCompany companyId={companyId} setCompanyId={setCompanyId} />
            {validationErrors["company_id"] && (
              <div className="error">{validationErrors["company_id"]}</div>
            )}
          </div>
        )}

        <h2 style={{ marginBottom: "20px" }}>Информация о журнале</h2>
        <div className="flexContainerWithGap" style={{ marginBottom: "25px" }}>
          <div className="width32Percent">
            <Input
              label={"№ протокола поверки"}
              type={"text"}
              value={protocolId || ""}
              actions={{
                change: handleProtocolIdChange,
              }}
            />

            {validationErrors["protocol_id"] && (
              <div className="error">{validationErrors["protocol_id"]}</div>
            )}
          </div>

          <div className="width32Percent">
            <Input
              label={"№ Госреестра"}
              type={"text"}
              placeholder={"Пример: '26382-07'"}
              value={meterNumber || ""}
              actions={{
                change: handleMeterNumberChange,
              }}
            />
            {validationErrors["meter_number"] && (
              <div className="error">{validationErrors["meter_number"]}</div>
            )}
          </div>

          <div className="width32Percent">
            <Input
              label={"Заводской номер"}
              type={"text"}
              placeholder={"Пример: '10171 - 52'"}
              value={meterFactoryNumber || ""}
              actions={{
                change: handleMeterFactoryNumberChange,
              }}
            />
            {validationErrors["meter_factory_number"] && (
              <div className="error">
                {validationErrors["meter_factory_number"]}
              </div>
            )}
          </div>
        </div>

        <div className="flexContainerWithGap" style={{ marginBottom: "25px" }}>
          <div className="width32Percent">
            <Input
              label={"Наименование"}
              type={"text"}
              placeholder={"Пример: 'Счетчики холодной воды крыльчатые, VLF-R'"}
              value={nameSi || ""}
              actions={{
                change: handleNameSiChange,
              }}
            />
            {validationErrors["name_si"] && (
              <div className="error">{validationErrors["name_si"]}</div>
            )}
          </div>

          <div className="width32Percent">
            <Input
              label={"Владелец СИ"}
              type={"text"}
              placeholder={"Пример: 'Физ. лицо'"}
              value={customerName || ""}
              actions={{
                change: handleCustomerNameChange,
              }}
            />
            {validationErrors["customer_arshin_name"] && (
              <div className="error">
                {validationErrors["customer_arshin_name"]}
              </div>
            )}
          </div>

          <div className="width32Percent">
            <Input
              label={"№ свидетельства / извещения"}
              type={"text"}
              placeholder={"Пример: '123456789'"}
              value={arshinId || ""}
              actions={{
                change: handleArshinIdChange,
              }}
            />

            {validationErrors["arshin_id"] && (
              <div className="error">{validationErrors["arshin_id"]}</div>
            )}
          </div>
        </div>

        <Input
          label={"Примечание"}
          type={"text"}
          value={desc || ""}
          actions={{
            change: handleDescChange,
          }}
        />
        {validationErrors["desc"] && (
          <div className="error">{validationErrors["desc"]}</div>
        )}

        <div className="flexContainerWithGap" style={{ marginTop: "15px" }}>
          <div className="width32Percent">
            <p
              style={{
                marginBottom: "9px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Поверитель{" "}
              {!arshinName ? (
                <ErrorIcon
                  sx={{
                    color: "#FFD43B",
                    marginLeft: "8px",
                    marginTop: "-2px",
                  }}
                />
              ) : (
                <CheckCircleIcon
                  sx={{
                    color: "#63E6BE",
                    marginLeft: "8px",
                    marginTop: "-2px",
                  }}
                />
              )}
            </p>
            <Selector
              placeholder={"Выберите мастера-поверителя"}
              options={options}
              value={options.find((option) => option.value === arshinName)}
              onChange={getValue}
              isClearable={isClearable}
              isSearchable={isSearchable}
              noOptionsMessage={() => "Нет доступных мастеров"}
            />
            {validationErrors["verifier_arshin_name"] && (
              <div className="error">
                {validationErrors["verifier_arshin_name"]}
              </div>
            )}
          </div>

          <div className="width32Percent">
            <p
              style={{
                marginBottom: "9px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Дата поверки{" "}
              {!verificationDate ? (
                <ErrorIcon
                  sx={{
                    color: "#FFD43B",
                    marginLeft: "8px",
                    marginTop: "-2px",
                  }}
                />
              ) : (
                <CheckCircleIcon
                  sx={{
                    color: "#63E6BE",
                    marginLeft: "8px",
                    marginTop: "-2px",
                  }}
                />
              )}
            </p>
            <DatePicker
              selectedDate={verificationDate}
              dateChange={handleVerificationDateChange}
            />

            {validationErrors["verification_date"] && (
              <div className="error">
                {validationErrors["verification_date"]}
              </div>
            )}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "10px" }}>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={isDisabledBtn}
          sx={{ mt: 1, mr: 1 }}
          style={{
            textTransform: "none",
            fontSize: "15px",
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: "11px 20px 11px",
            margin: 0,
            borderRadius: "12px",
            width: "100%",
          }}
        >
          Сохранить запись
        </Button>
      </div>
    </main>
  );
};

export default AddJournalTickets;
