import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  user: "",
  userRole: "",
  authData: {
    login: "",
    pass: "",
    token: "",
  }
}

export const authReducer = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    setUser: (state, action) => {},
    setUserRole: (state, action) => {
      state.userRole = action.payload
    },
    setAuthLogin: (state, action) => {
      state.authData.login = action.payload
    },
    setAuthPass: (state, action) => {
      state.authData.pass = action.payload
    },
    setAuthToken: (state, action) => {
      state.authData.token = action.payload
    },
    clearAuthData: (state, action) => {
      state.authData = {
        login: "",
        pass: "",
        token: "",
      }
    },
    removeUser: (state) => {
      state.user = ''
    },
    removeUserRole: (state) => {
      state.userRole = ''
    },
    removeToken: (state) => {
      state.authData.token = ''
    }
  }
})

export const { 
  setUser, 
  setUserRole,
  setAuthLogin,
  setAuthPass,
  setAuthToken,
  clearAuthData,
  removeUser, 
  removeUserRole,
  removeToken
} = authReducer.actions
export default authReducer.reducer