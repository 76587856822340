import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  open: false
}

export const rightContainerReducer = createSlice({
  name: 'rightContainerReducer',
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload
    }
  }
})

export const { setOpen } = rightContainerReducer.actions
export default rightContainerReducer.reducer