import React, { useState, useEffect } from "react"
import api from "../../services/axios-config.service"
import Selector from "../LocalElements/Selector/reactSelect/Selector"
import { useDispatch, useSelector } from "react-redux"

import { changeData } from "../../store/slices/views/users"

const SelectedRegions = ({ validationErrors }) => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.authReducer.authData.token)
  const [data, setData] = useState(null)
  const isMulti = true
  const isSearchable = true

  const regionIds = useSelector(
    (state) => state.usersReducer.createData.regionIds
  )

  const options = data
    ? data.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = (selectedOptions) => {
    const selectedIds = selectedOptions.map((options) => options.value)
    dispatch(changeData({ value: "regionIds", label: selectedIds }))
  }

  useEffect(() => {
    ( async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/companies/create`
        const headers = {
          Authorization: `Bearer ${token}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        }
        const response = await api.get(apiUrl, { headers })
        const regionData = response.data.regions
        setData(regionData)
      } catch (error) {
        console.error(error)
      }
    })()
  }, [ token ])

  return (
    <div 
      style={{ 
        marginBottom: "0px",
        display: "flex",
        flexFlow: "column", 
      }}
    >
      <span style={{ marginBottom: "9px", display: "block" }}>Регион пользователя</span>
      <Selector
        placeholder={"Выберите регионы из списка"}
        options={options}
        value={options.filter((option) => regionIds.includes(option.value))}
        onChange={getValue}
        isMulti={isMulti}
        isSearchable={isSearchable}
        noOptionsMessage={() => "Нет доступных регионов"}
      />

      { validationErrors["region_ids"] && (
        <div className="error">{validationErrors["region_ids"]}</div>
      )}
    </div>
  )
}

export default SelectedRegions
