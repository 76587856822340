import React from "react"

import MuiAccordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import { styled } from "@mui/material/styles"

import plusIcon from "../../../img/plus.svg"

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: "10px",
  borderRadius: "6px",
  padding: "14px 8px",
  "&::before": {
    display: "none",
  },
}))

const AccordionComponent = (props) => {
  const {
    title = "",
    data = [],
    dataType = "default",
    backgroundColor = null,
    border = null,
    titleSize = null,
  } = props

  return (
    <React.Fragment>
      <Accordion
        style={{
          backgroundColor: backgroundColor ?? "#F8F9FC",
          border: border ?? "none",
          boxShadow: "none",
          borderRadius: "12px",
        }}
      >
        <AccordionSummary
          expandIcon={null}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{
            fontSize: titleSize ?? "15px",
            fontWeight: 600,
            paddingTop: "0px",
          }}
        >
          <img
            alt={""}
            src={plusIcon}
            style={{
              marginRight: "15px",
              width: "22px",
              marginTop: "-1px",
            }}
          />
          { title }
        </AccordionSummary>
        <AccordionDetails>
          { dataType === "default" && <React.Fragment>
            { data.map((item, index) => {
              return (
                <div
                  className="show_protocol_meter"
                  style={{
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    backgroundColor: index % 2 === 0 ? "white" : "#F8F9FC",
                    height: "60px",
                    padding: "8px 16px 11px",
                    borderRadius: "6px",
                  }}
                >
                  {item.map(({ fontWeight, textAlign, width, title }) => {
                    return (
                      <span
                        style={{
                          fontWeight,
                          textAlign,
                          width,
                          fontSize: "15px",
                          lineHeight: "22px",
                        }}
                      >
                        {title}
                      </span>
                    )
                  })}
                </div>
              )
            })}
          </React.Fragment> }
          { dataType === "custom" && <React.Fragment>
            { data }
          </React.Fragment> }
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  )
}

export default AccordionComponent
