import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";

import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import Checkbox from "@mui/material/Checkbox";

import Input from "../../LocalElements/Input//InputMUI";
import SelectCompany from "../Select/SelectCompany";
import DatePicker from "../../LocalElements/DatePicker/DatePicker";
import D from "../../../store/localStorage/dispatcher";
import R from "../../../services/request.service";

import { changeKits, resetCreateKits } from "../../../store/slices/views/journals";
import { refreshVerifications } from "../../../store/slices/controlers/updater";
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/comps/snackbar";

const AddJournalVerification = () => {
  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole");
  const userRole = useSelector((state) => state.headerReducer.role);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isToggle = useSelector((state) => state.toggleReducer.isToggle);
  const [validationErrors, setValidationErrors] = useState({});
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);

  const [companyId, setCompanyId] = useState(null);

  const meterNameSi = useSelector(
    (state) => state.journalReducer.createKits.meterNameSi
  );

  const handleMeterNameSiChange = (param) => {
    dispatch(changeKits({ value: "meterNameSi", label: param }));
  };

  const periodicity = useSelector(
    (state) => state.journalReducer.createKits.periodicity
  );

  const handlePeriodicitySiChange = (param) => {
    dispatch(changeKits({ value: "periodicity", label: param }));
  };

  const verificationAddress = useSelector(
    (state) => state.journalReducer.createKits.verificationAddress
  );

  const handleVerificationPlaceChange = (param) => {
    dispatch(changeKits({ value: "verificationAddress", label: param }));
  };

  const verificationDate = useSelector(
    (state) => state.journalReducer.createKits.verificationDate
  );

  const nextVerificationDate = useSelector(
    (state) => state.journalReducer.createKits.nextVerificationDate
  );

  const handleVerificationDateChange = (date) => {
    if (date) {
      dispatch(
        changeKits({
          value: "verificationDate",
          label: date.format("YYYY-MM-DD HH:mm:ss"),
        })
      );
    }
  };

  const handleNextVerificationDateChange = (date) => {
    if (date) {
      dispatch(
        changeKits({
          value: "nextVerificationDate",
          label: date.format("YYYY-MM-DD HH:mm:ss"),
        })
      );
    }
  };

  const verificationMark = useSelector(
    (state) => state.journalReducer.createKits.verificationMark
  );

  const handleVerificationMarkChange = (event) => {
    dispatch(
      changeKits({
        value: "verificationMark",
        label: event.target.checked,
      })
    );
  };

  const navigateToProtocols = () => {
    navigate("/metriva/journals");
  };

  const handleSave = async () => {
    setIsDisabledBtn(true);
    const data = {
      kit_fullname: meterNameSi,
      periodicity: periodicity,
      verified_at: verificationDate,
      expired_at: nextVerificationDate,
      verification_place: verificationAddress,
      verification_mark: verificationMark,
    };

    if (userRole === "super") {
      data.company_id = companyId;
    }

    const { status, data: verificationAddData } = await R.addVerifications(
      data
    );
    if (status === 201) {
      dispatch(setMessage("Запись успешно создана"));
      dispatch(setType("success"));
      dispatch(setOpenAlert(true));
      dispatch(resetCreateKits());
      dispatch(refreshVerifications());
      navigate("/metriva/journals");
    } else {
      if (verificationAddData.errors) {
        const errors = verificationAddData.errors;
        setValidationErrors(errors);
      }
      dispatch(setType("error"));
      dispatch(setOpenAlert(true));
      dispatch(setMessage("Запись не добавлена - ошибка валидации"));
      setIsDisabledBtn(false);
    }
  };

  return (
    <main className={isToggle ? "main_open" : "main"}>
      <div className="path_to_back" onClick={navigateToProtocols}>
        <i className="fas fa-arrow-left icon_back" style={{ width: '30px', height: '30px', marginRight: '6px' }} />
        <p>Все журналы</p>
      </div>

      <div className="path_to_edit">
        <h4>Журнал "График поверки"</h4>
      </div>
      <div className="box">
        {userRole === "super" && (
          <div className="width32Percent" style={{ marginBottom: "15px" }}>
            <h2 style={{ marginBottom: "15px" }}>Компания</h2>
            <SelectCompany companyId={companyId} setCompanyId={setCompanyId} />
            {validationErrors["company_id"] && (
              <div className="error">{validationErrors["company_id"]}</div>
            )}
          </div>
        )}

        <h2 style={{ marginBottom: "25px" }}>Информация о журнале</h2>

        <div className="flexContainerWithGap" style={{ marginBottom: "20px" }}>
          <div className="width32Percent">
            <Input
              label={"Наименование СИ, Тип СИ, Зав. №"}
              type={"text"}
              value={meterNameSi || ""}
              actions={{
                change: handleMeterNameSiChange,
              }}
            />
            {validationErrors["kit_fullname"] && (
              <div className="error">{validationErrors["kit_fullname"]}</div>
            )}
          </div>

          <div className="width32Percent">
            <Input
              label={"Место проведения последней поверки"}
              type={"text"}
              placeholder={"Введите место проведения последней поверки"}
              value={verificationAddress || ""}
              actions={{
                change: handleVerificationPlaceChange,
              }}
            />

            {validationErrors["verification_place"] && (
              <div className="error">
                {validationErrors["verification_place"]}
              </div>
            )}
          </div>
        </div>

        <div className="flexContainerWithGap" style={{ marginBottom: "15px" }}>
          <div className="width32Percent">
            <p
              style={{
                marginBottom: "9px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Дата последней поверки{" "}
              {!verificationDate ? (
                <ErrorIcon
                  sx={{
                    color: "#FFD43B",
                    marginLeft: "8px",
                    marginTop: "-2px",
                  }}
                />
              ) : (
                <CheckCircleIcon
                  sx={{
                    color: "#63E6BE",
                    marginLeft: "8px",
                    marginTop: "-2px",
                  }}
                />
              )}
            </p>
            <DatePicker
              selectedDate={verificationDate}
              dateChange={handleVerificationDateChange}
            />

            {validationErrors["verified_at"] && (
              <div className="error">{validationErrors["verified_at"]}</div>
            )}
          </div>

          <div className="width32Percent">
            <p
              style={{
                marginBottom: "9px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Дата окончания поверки{" "}
              {!nextVerificationDate ? (
                <ErrorIcon
                  sx={{
                    color: "#FFD43B",
                    marginLeft: "8px",
                    marginTop: "-2px",
                  }}
                />
              ) : (
                <CheckCircleIcon
                  sx={{
                    color: "#63E6BE",
                    marginLeft: "8px",
                    marginTop: "-2px",
                  }}
                />
              )}
            </p>
            <DatePicker
              selectedDate={nextVerificationDate}
              dateChange={handleNextVerificationDateChange}
            />

            {validationErrors["expired_at"] && (
              <div className="error">{validationErrors["expired_at"]}</div>
            )}
          </div>

          <div className="width32Percent">
            <p
              style={{
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Периодичность поверки (месяцы)
            </p>
            <Input
              type={"text"}
              value={periodicity || ""}
              actions={{
                change: handlePeriodicitySiChange,
              }}
            />
            {validationErrors["periodicity"] && (
              <div className="error">{validationErrors["periodicity"]}</div>
            )}
          </div>
        </div>

        <div
          style={{
            marginTop: "15px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <p>Отметка о проведении поверки :</p>
          <Checkbox
            checked={verificationMark}
            onChange={handleVerificationMarkChange}
          />
        </div>
      </div>

      <div style={{ marginTop: "10px" }}>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={isDisabledBtn}
          sx={{ mt: 1, mr: 1 }}
          style={{
            textTransform: "none",
            fontSize: "15px",
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: "11px 20px 11px",
            margin: 0,
            borderRadius: "12px",
            width: "100%",
          }}
        >
          Сохранить запись
        </Button>
      </div>
    </main>
  );
};

export default AddJournalVerification;
