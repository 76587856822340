import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  companiesPing: false,
  casesPing: false,
  verificationsPing: false,
  ticketsPing: false,
  conditionsPing: false,
  complectsPing: false,
  etalonsPing: false,
  metersPing: false,
  usersPing: false,
  protocolsPing: false,
  headerPing: false,
}

const updaterReducer = createSlice({
  name: "updaterReducer",
  initialState,
  reducers: {
    refreshCompanies: (state, action) => {
      state.companiesPing = !state.companiesPing
    },
    refreshCases: (state, action) => {
      state.casesPing = !state.casesPing
    },
    refreshVerifications: (state, action) => {
      state.verificationsPing = !state.verificationsPing
    },
    refreshTickets: (state, action) => {
      state.ticketsPing = !state.ticketsPing
    },
    refreshConditions: (state, action) => {
      state.conditionsPing = !state.conditionsPing
    },
    refreshComplects: (state, action) => {
      state.complectsPing = !state.complectsPing
    },
    refreshEtalons: (state, action) => {
      state.etalonsPing = !state.etalonsPing
    },
    refreshMeters: (state, action) => {
      state.metersPing = !state.metersPing
    },
    refreshUsers: (state, action) => {
      state.usersPing = !state.usersPing
    },
    refreshProtocols: (state, action) => {
      state.protocolsPing = !state.protocolsPing
    },
    refreshHeader: (state, action) => {
      state.headerPing = !state.headerPing
    },
  },
})

export const {
  refreshCompanies,
  refreshCases,
  refreshVerifications,
  refreshTickets,
  refreshConditions,
  refreshComplects,
  refreshEtalons,
  refreshMeters,
  refreshUsers,
  refreshProtocols,
  refreshHeader,
} = updaterReducer.actions

export default updaterReducer.reducer
