// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Routes, Route } from "react-router-dom"
import { setAuthToken, removeToken } from "./store/slices/controlers/auth"

import Login from "./pages/Auth"
import Home from "./pages/Home"
import Updater from "./pages/Updater"
import { AxiosInterceptor } from "./services/axios-interceptor.service"
import SocketConnector from "./services/socket-connector.service"
import ProtectedRoute from "./pages/ProtectedRoute"

import Cookies from "js-cookie"
import "./index.scss"
import "./index.layout.scss"
import "./index.mobile.scss"

function App() {
  const dispatch = useDispatch()
  const [ firstUpdate, setFirstUpdate ] = useState(false)

  useEffect(() => {
    const TOKEN = Cookies.get("accessToken")
    
    TOKEN && console.log(TOKEN)

    !!TOKEN && dispatch(setAuthToken(TOKEN))
    !!!TOKEN && dispatch(removeToken())

    setTimeout(() => {
      setFirstUpdate(true)
    }, 2000)
  }, [])

  return (
    <AxiosInterceptor>
      <SocketConnector />
      <div className="App">
        { firstUpdate && <Updater /> }
        <Routes>
          <Route
            path="/"
            element={<Login />}
          />
          <Route
            path="/metriva/*"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </AxiosInterceptor>
  )
}

export default App
