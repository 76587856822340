import dayjs from "dayjs";
export const createGasMeasurement = {
  customerName: "Физическое лицо",
  customerNameType: "Физическое лицо",
  customerAddress: "",
  customerFullName: "",
  customerPhoneNumber: "",

  meterId: "",
  selectedMeter: null,
  selectedMod: null,

  meterFifNumber: "",
  meterNameSi: "",
  meterNumber: "",
  meterYear: "",

  diameter: "",
  meterLocation: "",

  typeSize: "",
  q_min: "",
  q_t: "",
  q_max: "",
  q_min_limit: "",
  q_max_limit: "",
  q_min_pressure_loss: "",
  q_max_pressure_loss: "",

  isMechanicalAccepted: null,
  isTightnessAccepted: null,
  isTotalAccepted: false,
  isMarkPassport: false,
  isMarkSi: false,

  verificationDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
  nextVerificationDate: "",
  agreementNumber: "",

  indications: [],
  conditions: [],
  indicationPass: [],
  permissiblePass: true,

  verification_flow_1: "",
  etalon_volume_1: "",
  device_volume_1: "",
  correction_1: "",
};
