import { createSlice } from "@reduxjs/toolkit"
import { createMethodology } from "../../store.interface"

const initialState = createMethodology

const mi15922015Reducer = createSlice({
  name: "mi15922015Reducer",
  initialState,
  reducers: {
    changeProperty: (state, action) => {
      state[action.payload.value] = action.payload.label
    },
    setDefaultState: (state) => {
      Object.assign(state, initialState)
    },
  },
})

export const { changeProperty, setDefaultState } = mi15922015Reducer.actions
export default mi15922015Reducer.reducer
