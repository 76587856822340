// --
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import api from "../../../services/axios-config.service"

import Table from "../../LocalElements/Table/Table"
import D from "../../../store/localStorage/dispatcher"
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
} from "../../../store/slices/comps/modal"

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/comps/snackbar"
import { Skeleton } from "@mui/material"

const Tab = ({ onDelete }) => {
  const token = useSelector((state) => state.authReducer.authData.token)

  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector((state) => state.headerReducer.role)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const idMeasurement = parseInt(D.get("measurement_id"), 10)
  const data = useSelector((state) => state.meterReducer.metersList)

  const handleShowClick = (param) => {
    navigate(`/metriva/meters/${param.row.id}`)
  }
  const handleEditClick = (meterId) => {
    navigate(`/metriva/meters/${meterId}/edit_gas`)
  }

  const handleDelete = async (meterId) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/meters/${idMeasurement}/${meterId}`
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      }
      const response = await api.delete(apiUrl, { headers })
      if (response.data && response.data.message) {
        dispatch(setMessage(response.data.message))
      }
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))

      dispatch(setOpen(false))
      onDelete()
    } catch (error) {
      console.error(error)
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Ошибка на сервере."))
    }
  }

  let colData = [
    {
      field: "numberRegistry",
      headerName: "№ Госреестра (Поиск)",
      width: 180,
    },
    {
      field: "number",
      headerName: "№ Госреестра (Аршин)",
      width: 180,
    },
    {
      field: "nameSi",
      headerName: "Наименование",
      width: 200,
    },
    {
      field: "manufacturerName",
      headerName: "Завод производитель",
      width: 300,
    },
  ]

  if (userRole === "super") {
    colData.splice(4, 0, {
      field: "actions",
      type: "actions",
      headerName: "Действия",
      width: 130,
      getActions: (item) => {
        const localItem = item

        return [
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              gap: "14px",
            }}
          >
            {/* <span
              onClick={() => handleEditClick(localItem.id)}
              style={{
                width: "5%",
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-around",
              }}
            >
              <span
                className="fas fa-pencil-alt icon"
                style={{
                  fontSize: "15px",
                }}
              />
            </span> */}
            <span
              onClick={() => {
                dispatch(setOpen(true))
                dispatch(setTitle("Вы действительно хотите удалить прибор?"))
                dispatch(
                  setChildren("Отменить действие будет уже невозможно.")
                )
                dispatch(setConfirmAction(() => handleDelete(localItem.id)))
              }}
              style={{
                width: "5%",
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-around",
              }}
            >
              <span
                className="fas fa-trash icon"
                style={{
                  fontSize: "15px",
                }}
              />
            </span>
          </div>,
        ]
      },
    })
  }

  return (
    <React.Fragment>
      {(!data || data.length === 0) && (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              flexFlow: "column",
            }}
          >
            <Skeleton
              sx={{ backgroundColor: "#fff" }}
              animation="wave"
              width={"100%"}
              height={66}
            />
            <Skeleton
              sx={{ marginTop: "-16px" }}
              animation="wave"
              width={"100%"}
              height={66}
            />
            <Skeleton
              sx={{ backgroundColor: "#fff", marginTop: "-16px" }}
              animation="wave"
              width={"100%"}
              height={66}
            />
            <Skeleton
              sx={{ marginTop: "-16px" }}
              animation="wave"
              width={"100%"}
              height={66}
            />
            <Skeleton
              sx={{ backgroundColor: "#fff", marginTop: "-16px" }}
              animation="wave"
              width={"100%"}
              height={66}
            />
            <Skeleton
              sx={{ marginTop: "-16px" }}
              animation="wave"
              width={"100%"}
              height={66}
            />
          </div>
        </React.Fragment>
      )}
      {data && data.length > 0 && (
        <Table
          css={{
            marginTop: "12px",
            backgroundColor: "#ffffff",
            border: "1px solid #d7ddea",
            "& .MuiDataGrid-cell:hover": {
              cursor: "pointer",
            },
          }}
          colData={colData}
          rowData={data.map((item) => ({
            id: item.id,
            numberRegistry: item.number_registry,
            number: item.number,
            nameSi: item.name_si,
            manufacturerName: item.manufacturer.name,
            mpiText: item.mpi_text,
          }))}
          rowCallback={handleShowClick}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      )}
    </React.Fragment>
  )
}

export default Tab
