import React from "react";
import { Skeleton } from "@mui/material";

const SkeletonProtocol = () => {
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexFlow: "column",
        }}
      >
        <Skeleton
          sx={{ backgroundColor: "#fff", borderRadius: "12px" }}
          animation="wave"
          width={"100%"}
          height={120}
        />
        <Skeleton
          sx={{
            marginTop: "-50px",
            borderRadius: "12px",
            backgroundColor: "#fff",
          }}
          animation="wave"
          width={"100%"}
          height={200}
        />
        <Skeleton
          sx={{
            backgroundColor: "#fff",
            marginTop: "-65px",
            borderRadius: "12px",
          }}
          animation="wave"
          width={"100%"}
          height={200}
        />
        <Skeleton
          sx={{
            marginTop: "-85px",
            borderRadius: "12px",
            backgroundColor: "#fff",
          }}
          animation="wave"
          width={"100%"}
          height={300}
        />
        <Skeleton
          sx={{
            backgroundColor: "#fff",
            marginTop: "-82px",
            borderRadius: "12px",
          }}
          animation="wave"
          width={"100%"}
          height={200}
        />
        <Skeleton
          sx={{
            backgroundColor: "#fff",
            marginTop: "-62px",
            borderRadius: "12px",
          }}
          animation="wave"
          width={"100%"}
          height={200}
        />
        <Skeleton
          sx={{
            backgroundColor: "#fff",
            marginTop: "-130px",
            borderRadius: "12px",
          }}
          animation="wave"
          width={"100%"}
          height={500}
        />
        <Skeleton
          sx={{
            backgroundColor: "#fff",
            marginTop: "-185px",
            borderRadius: "12px",
          }}
          animation="wave"
          width={"100%"}
          height={500}
        />
        <Skeleton
          sx={{
            backgroundColor: "#fff",
            marginTop: "-100px",
            borderRadius: "12px",
          }}
          animation="wave"
          width={"100%"}
          height={120}
        />
        <Skeleton
          sx={{
            backgroundColor: "#fff",
            marginTop: "-100px",
            borderRadius: "12px",
          }}
          animation="wave"
          width={"100%"}
          height={200}
        />
      </div>
    </React.Fragment>
  );
};

export default SkeletonProtocol;
