import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const Accepted = (props) => {
  const { title, description, value, onChange, label } = props;

  return (
    <React.Fragment>
      <h2 style={{ marginBottom: "10px" }}>{title}</h2>
      <p style={{ marginBottom: "15px", lineHeight: "24px" }}>{description}</p>
      <h4 style={{ marginBottom: "10px" }}>{label}</h4>
      <RadioGroup
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        style={{
          flexDirection: "row",
          fontFamily: "'Wix Madefor Display', sans-serif",
          color: "#123532",
          fontSize: "16px",
          fontWeight: "400",
        }}
        value={value}
        onChange={onChange}
      >
        <FormControlLabel
          value={true}
          control={
            <Radio
              style={{
                color: "#0084e2",
                "&.MuiChecked": {
                  color: "#0084e2",
                },
              }}
            />
          }
          label="Соответствует"
          sx={{
            "& .MuiTypography-root": {
              fontFamily: "'Wix Madefor Display', sans-serif !important",
              fontSize: "15px !important",
              fontWeight: 400,
              color: "#132532",
            },
          }}
        />
        <FormControlLabel
          value={false}
          control={
            <Radio
              style={{
                color: "#ff5963",
                "&.MuiChecked": { color: "#ff5963" },
              }}
            />
          }
          label="Не соответствует"
          sx={{
            "& .MuiTypography-root": {
              fontFamily: "'Wix Madefor Display', sans-serif !important",
              fontSize: "15px !important",
              fontWeight: 400,
              color: "#132532",
            },
          }}
        />
      </RadioGroup>
    </React.Fragment>
  );
};

export default Accepted;
