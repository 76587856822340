// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Collapse from "@mui/material/Collapse"

import Method from "../../components/Methodologies/views/Method/Method"
import TypeMethod from "../../components/Methodologies/views/TypeMethod/TypeMethod"

import WaterMi15922015 from "../../components/Methodologies/Water/Mi1592-2015/AddProtocol"
import GasGost8324 from "../../components/Methodologies/Gas/Gost8324-2002/AddProtocol"
import WaterGost815683 from "../../components/Methodologies/Water/Gost8-156-83/AddProtocol"

import {
  resetCase,
  resetMeasurementId,
  resetCompanyId,
  resetMethodId,
} from "../../store/slices/views/protocols"
import { setName } from "../../store/slices/controlers/pageName"

import useWindowDimensions from "../../hooks/useWindowDimensions"

import css from "../../components/Methodologies/styles.css"
const { BoxContainer } = css

const NewProtocol = () => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)
  const caseId = useSelector((state) => state.protocolReducer.case.caseId)
  const pointId = useSelector((state) => state.protocolReducer.case.pointId)

  const step = useSelector((state) => state.protocolReducer.stepNumber)

  useEffect(() => { dispatch(setName("Новый протокол")) }, []) 
  useEffect(() => {
    return () => {
      dispatch(resetCase())
      dispatch(resetMeasurementId())
      dispatch(resetCompanyId())
      dispatch(resetMethodId())
    }
  }, [])

  return (
    <main className={isToggle ? "main_open" : "main"}>
      { step === 0 && <Method /> }
      { step === 0 && <Collapse in={!!caseId}>
        <BoxContainer margin={"12px 0px 0px"}>
          <TypeMethod />
        </BoxContainer>
      </Collapse> }

      { pointId === null && (
        <React.Fragment>
          <BoxContainer margin={"12px 0px 0px"}>
            <div className="page_null">
              <h4
                style={ width > 1050 ? {
                  fontSize: "20.8px",
                  letterSpacing: "0.4px",
                  color: "#132532",
                  fontWeight: "400",
                } : {
                  fontSize: "17px",
                  letterSpacing: "0.4px",
                  color: "#132532",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                Выберите базовые параметры, чтобы продолжить создание протокола
              </h4>
            </div>
          </BoxContainer>
        </React.Fragment>
      )}

      {/* {( pointId === 1 || pointId === 3 || pointId === null ) && <WaterGost815683 /> } */}
      
      {( pointId === 1 || pointId === 3 ) && <WaterMi15922015 /> }
      {( pointId === 4 || pointId === 5 ) && <WaterGost815683 /> }
      { pointId === 2 && <GasGost8324 /> }
    </main>
  )
}

export default NewProtocol
