import { IQdata, IQdataOptical } from "./interfaces"

export const twoColumnsTable = (title: string, columns: string[]): string => {
  return `
    <section
      style="
        display: flex;
        flex-flow: column;
        position: relative;
        width: 100%;
        border: 1px solid rgba(217, 219, 228, 0.6);
        border-bottom: none;
        margin-top: 20px;
        margin-bottom: 20px;
        overflow: hidden;
      "
    >
      <div
        style="
          display: flex;
          flex-flow: row;
          align-items: center;
          position: relative;
          height: 44px;
          width: 100%;
          border-bottom: 1px solid rgba(217, 219, 228, 0.6);
          background-color: #e5f3fc80;
        "
      >
        <h5 style="width: 100%; text-align: center; font-weight: 500;">${ title }</h5>
      </div>
      <div
        style="
          display: flex;
          flex-flow: row;
          position: relative;
          width: 100%;
        "
      >
        <div 
          style="
            display: flex;
            flex-flow: column;
            align-items: center;
            width: 50%;
          "
        >
          ${ columns[0] }
        </div>
        <div 
          style="
            display: flex;
            flex-flow: column;
            align-items: center;
            width: 50%;
          "
        >
          ${ columns[1] }
        </div>
      </div>
    </section>
  `
}

export const threeColumnsTable = (columns: string[]): string => {
  return `
    <section
      style="
        display: flex;
        flex-flow: column;
        position: relative;
        width: 70%;
        border: 1px solid rgba(217, 219, 228, 0.6);
        border-bottom: none;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
      "
    >
      <div
        style="
          display: flex;
          flex-flow: row;
          position: relative;
          width: 100%;
        "
      >
        <div 
          style="
            display: flex;
            flex-flow: column;
            align-items: center;
            width: 50%;
          "
        >
          ${ columns[0] }
        </div>
        <div 
          style="
            display: flex;
            flex-flow: column;
            align-items: center;
            width: 50%;
          "
        >
          ${ columns[1] }
        </div>
        <div 
          style="
            display: flex;
            flex-flow: column;
            align-items: center;
            width: 50%;
          "
        >
          ${ columns[2] }
        </div>
      </div>
    </section>
  `
}

export const noOpticalTable = (params: IQdata): string => {
  return `
    <section
      style="
        display: flex;
        flex-flow: column;
        position: relative;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
      "
    >
      <span 
        style="
          width: 100%; 
          font-style: italic; 
          font-size: 14px;
          margin-bottom: 10px;
        "
      >Таблица 1 - Результаты проведенных измерений</span>
      <div
        style="
          display: flex;
          flex-flow: row wrap;
          position: relative;
          width: 100%;
          gap: 10px;
          text-align: center;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            width: calc(50% - 5px);
            min-height: 30px;
            border: 1px solid rgba(217, 219, 228, 0.6);
          "
        >
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <span 
              style="
                display: block; 
                width: 70%; 
                border-right: 1px solid rgba(217, 219, 228, 0.6); 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >Qmin</span>
            <span 
              style="
                display: block; 
                width: 30%; 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >${ params.qdata[0] } м³/ч</span>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >№</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Qi м³/ч</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                  padding-top: 0px;
                "
              >Начало изм, м³</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                  padding-top: 0px;
                "
              >Конец изм, м³</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Vиi, м³</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Vyi, м³</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >δуi, %</span>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >1</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.qi[0] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.start[0] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.finish[0] }</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vui[0] }</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vyi[0] }</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.byi[0] }</span>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >2</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.qi[1] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.start[1] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.finish[1] }</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vui[1] }</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vyi[1] }</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.byi[1] }</span>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >3</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.qi[2] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.start[2] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.finish[2] }</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vui[2] }</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vyi[2] }</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.byi[2] }</span>
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            width: calc(50% - 5px);
            min-height: 30px;
            border: 1px solid rgba(217, 219, 228, 0.6);
          "
        >
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <span 
              style="
                display: block; 
                width: 70%; 
                border-right: 1px solid rgba(217, 219, 228, 0.6); 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >1.1 * Qt</span>
            <span 
              style="
                display: block; 
                width: 30%; 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >${ params.qdata[1] } м³/ч</span>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >№</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Qi м³/ч</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                  padding-top: 0px;
                "
              >Начало изм, м³</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                  padding-top: 0px;
                "
              >Конец изм, м³</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Vиi, м³</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Vyi, м³</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 33px;
                "
              >δуi, %</span>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >4</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.qi[3] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.start[3] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.finish[3] }</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vui[3] }</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vyi[3] }</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.byi[3] }</span>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >5</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.qi[4] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.start[4] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.finish[4] }</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vui[4] }</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vyi[4] }</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.byi[4] }</span>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >6</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.qi[5] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.start[5] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.finish[5] }</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vui[5] }</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vyi[5] }</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.byi[5] }</span>
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            width: calc(50% - 5px);
            min-height: 30px;
            border: 1px solid rgba(217, 219, 228, 0.6);
          "
        >
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <span 
              style="
                display: block; 
                width: 70%; 
                border-right: 1px solid rgba(217, 219, 228, 0.6); 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >Qmax</span>
            <span 
              style="
                display: block; 
                width: 30%; 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >${ params.qdata[2] } м³/ч</span>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >№</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Qi м³/ч</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                  padding-top: 0px;
                "
              >Начало изм, м³</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                  padding-top: 0px;
                "
              >Конец изм, м³</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Vиi, м³</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Vyi, м³</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >δуi, %</span>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >7</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.qi[6] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.start[6] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.finish[6] }</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vui[6] }</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vyi[6] }</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.byi[6] }</span>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >8</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.qi[7] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.start[7] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.finish[7] }</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vui[7] }</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vyi[7] }</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.byi[7] }</span>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >9</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.qi[8] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.start[8] }</span>
              <span 
                style="
                  display: block; 
                  width: 25%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.finish[8] }</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vui[8] }</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vyi[8] }</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.byi[8] }</span>
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            width: calc(50% - 5px);
            min-height: 30px;
          "
        >
          <div
            style="
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: flex-start;
              width: 50%;
              gap: 8px;
              padding-left: 10px;
            "
          >
            <span style="font-size: 14px;">Vиi - объем измеренный счетчиком, м³</span>
            <span style="font-size: 14px;">Vyi - объем воды по эталону, м³</span>
            <span style="font-size: 14px;">δуi - относит. погрешность счетчика, %</span>
          </div>
          <div
            style="
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: flex-start;
              width: 50%;
              gap: 8px;
              padding-left: 10px;
            "
          >
            <span style="font-size: 14px;">Qmin - наименьший расход воды, м³/ч</span>
            <span style="font-size: 14px;">Qt - переходный расход воды, м³/ч</span>
            <span style="font-size: 14px;">Qmax - наибольший расход воды, м³/ч</span>
          </div>
        </div>
      </div>
    </section>
  `
}

export const opticalTable = (params: IQdataOptical): string => {
  return `
    <section
      style="
        display: flex;
        flex-flow: column;
        position: relative;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
      "
    >
      <span 
        style="
          width: 100%; 
          font-style: italic; 
          font-size: 14px;
          margin-bottom: 10px;
        "
      >Таблица 1 - Результаты проведенных измерений</span>
      <div
        style="
          display: flex;
          flex-flow: row wrap;
          position: relative;
          width: 100%;
          gap: 10px;
          text-align: center;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            width: calc(50% - 5px);
            min-height: 30px;
            border: 1px solid rgba(217, 219, 228, 0.6);
          "
        >
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <span 
              style="
                display: block; 
                width: 70%; 
                border-right: 1px solid rgba(217, 219, 228, 0.6); 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >Qmin</span>
            <span 
              style="
                display: block; 
                width: 30%; 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >${ params.qdata[0] } м³/ч</span>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <span 
              style="
                display: block; 
                width: 70%; 
                border-right: 1px solid rgba(217, 219, 228, 0.6); 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >Коэффициент преобразования</span>
            <span 
              style="
                display: block; 
                width: 30%; 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >${ params.koeff[0] }</span>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >№</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Qi м³/ч</span>
              <span 
                style="
                  display: block; 
                  width: 50%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 18px;
                  height: 55px;
                  Padding-top: 8.8px;
                "
              >Количество импульсов за время измерения, имп</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Vиi, м³</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Vyi, м³</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >δуi, %</span>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >1</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.qi[0] }</span>
              <span 
                style="
                  display: block; 
                  width: 50%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.impulse[0] }</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vui[0] }</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vyi[0] }</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.byi[0] }</span>
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            width: calc(50% - 5px);
            min-height: 30px;
            border: 1px solid rgba(217, 219, 228, 0.6);
          "
        >
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <span 
              style="
                display: block; 
                width: 70%; 
                border-right: 1px solid rgba(217, 219, 228, 0.6); 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >1.1 * Qt</span>
            <span 
              style="
                display: block; 
                width: 30%; 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >${ params.qdata[1] } м³/ч</span>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <span 
              style="
                display: block; 
                width: 70%; 
                border-right: 1px solid rgba(217, 219, 228, 0.6); 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >Коэффициент преобразования</span>
            <span 
              style="
                display: block; 
                width: 30%; 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >${ params.koeff[1] }</span>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >№</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Qi м³/ч</span>
              <span 
                style="
                  display: block; 
                  width: 50%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 18px;
                  height: 55px;
                  Padding-top: 8.8px;
                "
              >Количество импульсов за время измерения, имп</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Vиi, м³</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Vyi, м³</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 33px;
                "
              >δуi, %</span>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >2</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.qi[1] }</span>
              <span 
                style="
                  display: block; 
                  width: 50%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.impulse[1] }</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vui[1] }</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vyi[1] }</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.byi[1] }</span>
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            width: calc(50% - 5px);
            min-height: 30px;
            border: 1px solid rgba(217, 219, 228, 0.6);
          "
        >
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <span 
              style="
                display: block; 
                width: 70%; 
                border-right: 1px solid rgba(217, 219, 228, 0.6); 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >Qmax</span>
            <span 
              style="
                display: block; 
                width: 30%; 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >${ params.qdata[2] } м³/ч</span>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <span 
              style="
                display: block; 
                width: 70%; 
                border-right: 1px solid rgba(217, 219, 228, 0.6); 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >Коэффициент преобразования</span>
            <span 
              style="
                display: block; 
                width: 30%; 
                font-size: 14px;
                line-height: 33px;
                height: 33px;
              "
            >${ params.koeff[2] }</span>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >№</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Qi м³/ч</span>
              <span 
                style="
                  display: block; 
                  width: 50%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 18px;
                  height: 55px;
                  Padding-top: 8.8px;
                "
              >Количество импульсов за время измерения, имп</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Vиi, м³</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >Vyi, м³</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 55px;
                  height: 55px;
                "
              >δуi, %</span>
            </div>
          </div>
          <div
            style="
              display: flex;
              flex-flow: row;
              width: 100%;
            "
          >
            <div style="display: flex; flex-flow: row; width: 70%; border-right: 1px solid rgba(217, 219, 228, 0.6);">
              <span 
                style="
                  display: block; 
                  width: 10%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  background-color: #e5f3fc80; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >3</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.qi[2] }</span>
              <span 
                style="
                  display: block; 
                  width: 50%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.impulse[2] }</span>
              <span 
                style="
                  display: block; 
                  width: 20%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vui[2] }</span>
            </div>
            <div style="display: flex; flex-flow: row; width: 30%;">
              <span 
                style="
                  display: block; 
                  width: 60%; 
                  border-right: 1px solid rgba(217, 219, 228, 0.6); 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.vyi[2] }</span>
              <span 
                style="
                  display: block; 
                  width: 40%; 
                  font-size: 14px;
                  line-height: 33px;
                  height: 33px;
                "
              >${ params.byi[2] }</span>
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            width: calc(50% - 5px);
            min-height: 30px;
          "
        >
          <div
            style="
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: flex-start;
              width: 50%;
              gap: 8px;
              padding-left: 10px;
            "
          >
            <span style="font-size: 14px;">Vиi - объем измеренный счетчиком, м³</span>
            <span style="font-size: 14px;">Vyi - объем воды по эталону, м³</span>
            <span style="font-size: 14px;">δуi - относит. погрешность счетчика, %</span>
          </div>
          <div
            style="
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: flex-start;
              width: 50%;
              gap: 8px;
              padding-left: 10px;
            "
          >
            <span style="font-size: 14px;">Qmin - наименьший расход воды, м³/ч</span>
            <span style="font-size: 14px;">Qt - переходный расход воды, м³/ч</span>
            <span style="font-size: 14px;">Qmax - наибольший расход воды, м³/ч</span>
          </div>
        </div>
      </div>
    </section>
  `
}