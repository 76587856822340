// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setState } from "../../components/Methodologies/UniversalShow/state";

import ViewProtocolComponent from "../../components/Methodologies/UniversalShow/ViewProtocol";
import R from "../../services/request.service";
import PdfDownloadButton from "../../services/pdf-protocols.service";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import EditIcon from "@mui/icons-material/Edit";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

const Show = () => {
  const { protocol_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [status, setStatus] = useState("");

  const isToggle = useSelector((state) => state.toggleReducer.isToggle);

  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);

  const actions = [
    {
      icon: <EditIcon />,
      name: "Редактировать протокол",
      callback: () => navigate(`/metriva/protocols/${protocol_id}/edit`),
      disabled: status === "sent",
    },
    {
      icon: <ContentPasteIcon />,
      name: "Копировать протокол",
      callback: () => navigate(`/metriva/protocols/${protocol_id}/copy`),
    },
    {
      icon: (
        <PdfDownloadButton protocolId={protocol_id} viewType="viewProtocol" />
      ),
      name: "Скачать PDF",
      callback: () => {},
    },
  ];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    (async () => {
      const { data } = await R.getProtocol(protocol_id);

      dispatch(setState(data.data));
      setStatus(data.data.status);
      setLoading(false);
    })();
  }, []);

  return (
    <main className={isToggle ? "main_open" : "main"}>
      {!!!loading && <ViewProtocolComponent />}
      <SpeedDial
        ariaLabel="SpeedDial controlled open example"
        sx={{ position: "absolute", bottom: 30, right: 40 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.callback}
            disabled={action.disabled}
          />
        ))}
      </SpeedDial>
    </main>
  );
};

export default Show;
