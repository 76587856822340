// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import api from "../../../services/axios-config.service"
import Selector from "../../LocalElements/Selector/reactSelect/Selector"
import D from "../../../store/localStorage/dispatcher"

import { setMagazineId } from "../../../store/slices/views/journals"

const SelectorMagazine = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const isClearable = true
  const isSearchable = true

  const magazineId = useSelector((state) => state.journalReducer.magazineId)
  const token = useSelector(state => state.authReducer.authData.token)

  const options = data
    ? data
      .filter(data => data.id === 1)
      .map(data => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = (selectedOption) => {
    const newMagazineId = selectedOption ? selectedOption.value : ""
    dispatch(setMagazineId(newMagazineId))
    D.set("selectedMagazineId", newMagazineId)
  }

  useEffect(() => {
    const savedMagazineId = D.get("selectedMagazineId")
    if ( savedMagazineId ) {
      dispatch(setMagazineId(parseInt(savedMagazineId, 10)))
    }

    const fetchMagazine = async () => {

      // ----------------------------------------------------------------
      // переписать в конструктор запросов
      // ----------------------------------------------------------------
      
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/journals/list`
        const headers = {
          Authorization: `Bearer ${token}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        }
        const response = await api.get(apiUrl, { headers })
        const magazines = response.data.journals

        setData(magazines)
      } catch (error) {
        console.error(error)
      }
    }

    fetchMagazine()
  }, [])

  return (
    <React.Fragment>
      <Selector
        placeholder={"Выберите вид журнала"}
        isClearable={isClearable}
        isSearchable={isSearchable}
        options={options}
        onChange={getValue}
        value={options.find((option) => option.value === magazineId)}
        label={{
          text: "Вид журнала",
          topBg: "#f8f9fc",
          bottomBg: "white"
        }}
      />
    </React.Fragment>
  )
}

export default SelectorMagazine
