import React from "react"

import { useSelector, useDispatch } from "react-redux"
import Tooltip from "@mui/material/Tooltip"

import Input from "../LocalElements/Input/InputMUI"
import { changeData } from "../../store/slices/views/cases"

const InnerDataCase = ({ validationErrors }) => {
  const dispatch = useDispatch()

  const innerName = useSelector(
    (state) => state.casesReducer.createData.innerName
  )
  const handleInnerNameChange = (param) => {
    dispatch(changeData({ value: "innerName", label: param }))
  }

  const arshinName = useSelector(
    (state) => state.casesReducer.createData.arshinName
  )

  const handleArshinNameChange = (param) => {
    const value = param.replace(/[^\D]/g, "")
    dispatch(changeData({ value: "arshinName", label: value }))
  }

  const snils = useSelector((state) => state.casesReducer.createData.snils)

  const handleSnilsChange = (param) => {
    let formattedValue = param
      .replace(/\D/g, "")
      .slice(0, 11)
      .replace(/(\d{3})(?=\d{3})/g, "$1-")
      .replace(/(\d{2})$/, " $1")
    dispatch(changeData({ value: "snils", label: formattedValue }))
  }

  return (
    <div className="flexContainerWithGap">
      <div className="width32Percent" style={{ marginBottom: "20px" }}>
        <Input
          label={"Внутренний номер СП"}
          type={"text"}
          placeholder={"Формат записи: 08"}
          value={innerName}
          actions={{
            change: handleInnerNameChange,
          }}
        />
        {validationErrors["inner_name"] && (
          <div className="error">*{validationErrors["inner_name"]}</div>
        )}
      </div>

      <div className="width32Percent" style={{ marginBottom: "20px" }}>
        <Input
          label={
            <div>
              Поверитель
              <Tooltip
                title="Информация необходима для передачи данных в АРШИН"
                placement="top"
                style={{ marginLeft: "7px" }}
              >
                <i className="fas fa-question-circle question" />
              </Tooltip>
            </div>
          }
          type={"text"}
          placeholder={"Введите ФИО мастера-поверителя"}
          value={arshinName}
          actions={{
            change: handleArshinNameChange,
          }}
        />

        {validationErrors["arshin_name"] && (
          <div className="error">*{validationErrors["arshin_name"]}</div>
        )}
      </div>

      <div className="width32Percent" style={{ marginBottom: "20px" }}>
        <Input
          label={
            <div>
              Снилс
              <Tooltip
                title="Информация необходима для передачи данных в Росаккредитацию (ФСА)"
                placement="top"
                style={{ marginLeft: "7px" }}
              >
                <i className="fas fa-question-circle question" />
              </Tooltip>
            </div>
          }
          type={"text"}
          placeholder={"XXX-XXX-XXX XX"}
          value={snils}
          actions={{
            change: handleSnilsChange,
          }}
        />
        {validationErrors["snils"] && (
          <div className="error">*{validationErrors["snils"]}</div>
        )}
      </div>
    </div>
  )
}

export default InnerDataCase
