import D from "../store/localStorage/dispatcher"
import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { refreshProtocols } from '../store/slices/controlers/updater'

import echo from './echo-config.service'

const SocketConnector = () => {
  let companyId = D.get("companyId")
  const dispatch = useDispatch()

  useEffect(() => {

    const protocolCompanyPrivatChannel = echo.private('protocol_store_by_company_' + companyId)
    protocolCompanyPrivatChannel.listen('ProtocolStoreEvent',
      event => {
        false && console.log(event)
        dispatch(refreshProtocols())
      }
    )

    const protocolSuperPrivatChannel = echo.private('protocol_store_super')
    protocolSuperPrivatChannel.listen('ProtocolStoreEvent',
      event => {
        false && console.log(event)
        dispatch(refreshProtocols())
      }
    )

  }, [companyId, dispatch])

  return (
    <span
      style={{
        display: 'block',
        width: 0,
        height: 0
      }}
    />
  )

}

export default SocketConnector