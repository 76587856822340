import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const SearchIcon = (props) => (
  <SvgIcon
    {...props}
    width="22"
    height="23"
    viewBox="0 0 22 23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 2.28235C5.66751 2.28235 1.75 6.19986 1.75 11.0323C1.75 15.8648 5.66751 19.7823 10.5 19.7823C15.3325 19.7823 19.25 15.8648 19.25 11.0323C19.25 6.19986 15.3325 2.28235 10.5 2.28235ZM0.25 11.0323C0.25 5.37143 4.83908 0.782349 10.5 0.782349C16.1609 0.782349 20.75 5.37143 20.75 11.0323C20.75 13.5929 19.8111 15.9341 18.2589 17.7306L21.5303 21.002C21.8232 21.2949 21.8232 21.7698 21.5303 22.0627C21.2374 22.3556 20.7626 22.3556 20.4697 22.0627L17.1982 18.7912C15.4017 20.3435 13.0605 21.2823 10.5 21.2823C4.83908 21.2823 0.25 16.6933 0.25 11.0323Z"
      fill="rgb(0, 132, 226)"
    />
  </SvgIcon>
);

export default SearchIcon;
