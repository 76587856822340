import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: {}
}

const showProtocolReducer = createSlice({
  name: 'showProtocolReducer',
  initialState,
  reducers: {
    setState: (state, action) => {
      state.data = action.payload
    },
    clearState: state => {
      state = initialState
    }
  }
})

export const { setState, clearState } = showProtocolReducer.actions
export default showProtocolReducer.reducer