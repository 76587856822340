import React from "react"

const MetersData = ({
  validationErrors,
  numberRegistry,
  setNumberRegistry,
  number,
  setNumber,
  manufacturerName,
  setManufacturerName,
}) => {
  return (
    <div className="mechanical_characteristics_box">
      <div className="mechanical_characteristics">
        <p>№ в реестре ФИФ</p>
        <input
          className="input"
          placeholder="Введите  № в реестре ФИФ"
          value={numberRegistry}
          onChange={(e) => setNumberRegistry(e.target.value)}
        />
        { validationErrors["number_registry"] && (
          <div className="error">{validationErrors["number_registry"]}</div>
        )}
      </div>

      <div className="mechanical_characteristics">
        <p>№ ФИФ в Аршин</p>
        <input
          className="input"
          placeholder="01234-02"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
        />
        { validationErrors["number"] && (
          <div className="error">{validationErrors["number"]}</div>
        )}
      </div>

      <div className="mechanical_characteristics">
        <p>Наименование СИ</p>
        <input
          className="input"
          placeholder="Наименование СИ"
          value={manufacturerName}
          onChange={(e) => setManufacturerName(e.target.value)}
        />
        { validationErrors["manufacturer.name"] && (
          <div className="error">{validationErrors["manufacturer.name"]}</div>
        )}
      </div>
    </div>
  )
}

export default MetersData
