// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import D from "../../store/localStorage/dispatcher";
import R from "../../services/request.service";

import Input from "../../components/LocalElements/Input/InputMUI";
import DatePicker from "../../components/LocalElements/DatePicker/DatePicker";
import SelectCompany from "../../components/Etalon/SelectCompany";
import Button from "@mui/material/Button";

import { changeEtalon, resetCreateData } from "../../store/slices/views/etalon";
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../store/slices/comps/snackbar";
import { refreshEtalons } from "../../store/slices/controlers/updater";

import { setShow } from "../../store/slices/comps/feedback";
import Feedback from "../../components/LocalElements/Feedback/Feedback";

const EditEtalon = () => {
  const { etalon_id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole");
  const userRole = useSelector((state) => state.headerReducer.role);
  const show = useSelector((state) => state.feedbackReducer.show);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isToggle = useSelector((state) => state.toggleReducer.isToggle);
  const [validationErrors, setValidationErrors] = useState({});
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);

  const [data, setData] = useState([]);

  const companyId = useSelector(
    (state) => state.etalonReducer.createData.companyId
  );
  const typeKits = useSelector(
    (state) => state.etalonReducer.createData.typeKits
  );
  const manufacturerNumber = useSelector(
    (state) => state.etalonReducer.createData.manufacturerNumber
  );
  const regNumber = useSelector(
    (state) => state.etalonReducer.createData.regNumber
  );
  const signKits = useSelector(
    (state) => state.etalonReducer.createData.signKits
  );
  const verificationPlace = useSelector(
    (state) => state.etalonReducer.createData.verificationPlace
  );
  const link = useSelector((state) => state.etalonReducer.createData.link);
  const dateFrom = useSelector(
    (state) => state.etalonReducer.createData.dateFrom
  );

  const dateTo = useSelector((state) => state.etalonReducer.createData.dateTo);

  const handleTypeKitsChange = (param) => {
    dispatch(changeEtalon({ value: "typeKits", label: param }));
  };

  const handleManufacturerNumberChange = (param) => {
    dispatch(changeEtalon({ value: "manufacturerNumber", label: param }));
  };

  const handleRegNumberChange = (param) => {
    dispatch(changeEtalon({ value: "regNumber", label: param }));
  };

  const handleSignKitsChange = (param) => {
    dispatch(changeEtalon({ value: "signKits", label: param }));
  };

  const handleVerificationPlaceChange = (param) => {
    dispatch(changeEtalon({ value: "verificationPlace", label: param }));
  };

  const handleLinkChange = (param) => {
    dispatch(changeEtalon({ value: "link", label: param }));
  };

  const handleDateChange = (date) => {
    if (date) {
      dispatch(
        changeEtalon({
          value: "dateFrom",
          label: date.format("YYYY-MM-DD HH:mm:ss"),
        })
      );
    }
  };

  const handleNextDateChange = (date) => {
    if (date) {
      dispatch(
        changeEtalon({
          value: "dateTo",
          label: date.format("YYYY-MM-DD HH:mm:ss"),
        })
      );
    }
  };

  const fetchData = async () => {
    dispatch(setShow(true));
    try {
      const { data: etalonItemData } = await R.getEtalonItem(etalon_id);
      const data = etalonItemData.data;

      setData(data);

      dispatch(
        changeEtalon({
          value: "companyId",
          label: data.company.id,
        })
      );
      dispatch(
        changeEtalon({
          value: "typeKits",
          label: data.device_sign,
        })
      );
      dispatch(
        changeEtalon({
          value: "manufacturerNumber",
          label: data.device_manufacturer_number,
        })
      );
      dispatch(
        changeEtalon({
          value: "regNumber",
          label: data.reg_number,
        })
      );
      dispatch(
        changeEtalon({
          value: "signKits",
          label: data.device_description,
        })
      );
      dispatch(
        changeEtalon({
          value: "verificationPlace",
          label: data.verification_place,
        })
      );
      dispatch(
        changeEtalon({
          value: "link",
          label: data.device_link,
        })
      );
      dispatch(
        changeEtalon({
          value: "dateFrom",
          label: data.verified_at,
        })
      );
      dispatch(
        changeEtalon({
          value: "dateTo",
          label: data.expired_at,
        })
      );
    } catch (error) {
      false && console.log(error);
    }
    dispatch(setShow(false));
  };

  useEffect(() => {
    fetchData();
  }, [etalon_id, dispatch]);

  const handleSave = async () => {
    setIsDisabledBtn(true);

    const data = {
      device_description: signKits,
      device_manufacturer_number: manufacturerNumber,
      reg_number: regNumber,
      device_sign: typeKits,
      device_link: link,
      expired_at: dateTo,
      verified_at: dateFrom,
      verification_place: verificationPlace,
    };

    if (userRole === "super") {
      data.company_id = companyId;
    }

    const { status, data: etalonAddData } = await R.editEtalon(etalon_id, data);

    if (status === 200) {
      dispatch(setMessage("Эталон успешно отредактирован"));
      dispatch(setType("success"));
      dispatch(setOpenAlert(true));
      dispatch(refreshEtalons());
      navigate("/metriva/etalon");
    } else if (status === 422) {
      if (etalonAddData.errors) {
        const errors = etalonAddData.errors;
        setValidationErrors(errors);
      }
      dispatch(setType("error"));
      dispatch(setOpenAlert(true));
      dispatch(setMessage("Эталон не отредактирован - ошибка валидации"));
      setIsDisabledBtn(false);
    } else {
      if (etalonAddData.errors) {
        const errors = etalonAddData.errors;
        setValidationErrors(errors);
      }
      dispatch(setMessage("Ошибка обращения к api"));
      dispatch(setType("error"));
      dispatch(setOpenAlert(true));
      setIsDisabledBtn(false);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetCreateData());
    };
  }, []);

  const navigateToEtalon = () => {
    navigate("/metriva/etalon");
  };

  return (
    <main className={isToggle ? "main_open" : "main"}>
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <div className="path_to_back" onClick={navigateToEtalon}>
            <i
              className="fas fa-arrow-left icon_back"
              style={{ width: "30px", height: "30px", marginRight: "6px" }}
            />
            <p>Все эталоны</p>
          </div>

          <div className="path_to_edit">
            <h2>{data?.device?.name}</h2>
          </div>

          <div className="box" style={{ marginTop: "0" }}>
            {userRole === "super" && (
              <div className="width32Percent" style={{ marginBottom: "15px" }}>
                <h2 style={{ marginBottom: "15px" }}>Компания</h2>
                <SelectCompany />
                {validationErrors["company_id"] && (
                  <div className="error">{validationErrors["company_id"]}</div>
                )}
              </div>
            )}

            <h2 style={{ marginBottom: "20px" }}>Описание эталона</h2>
            <div
              className="flexContainerWithGap"
              style={{ marginBottom: "30px" }}
            >
              <div className="width32Percent">
                <Input
                  label={"Тип эталона"}
                  type={"text"}
                  value={typeKits || ""}
                  actions={{
                    change: handleTypeKitsChange,
                  }}
                />
                {validationErrors["device_sign"] && (
                  <div className="error">{validationErrors["device_sign"]}</div>
                )}
              </div>

              <div className="width32Percent">
                <Input
                  label={"Заводской номер"}
                  type={"text"}
                  value={manufacturerNumber || ""}
                  actions={{
                    change: handleManufacturerNumberChange,
                  }}
                />
                {validationErrors["device_manufacturer_number"] && (
                  <div className="error">
                    {validationErrors["device_manufacturer_number"]}
                  </div>
                )}
              </div>

              <div className="width32Percent">
                <Input
                  label={"Регистрационный номер"}
                  type={"text"}
                  value={regNumber || ""}
                  actions={{
                    change: handleRegNumberChange,
                  }}
                />
                {validationErrors["reg_number"] && (
                  <div className="error">{validationErrors["reg_number"]}</div>
                )}
              </div>
            </div>

            <div style={{ marginBottom: "30px" }}>
              <Input
                label={"Свидетельство о поверке"}
                type={"text"}
                value={signKits || ""}
                actions={{
                  change: handleSignKitsChange,
                }}
              />
              {validationErrors["device_description"] && (
                <div className="error">
                  {validationErrors["device_description"]}
                </div>
              )}
            </div>

            <div style={{ marginBottom: "30px" }}>
              <Input
                label={"Место проведения поверки"}
                type={"text"}
                placeholder={"Введите место проведения поверки"}
                value={verificationPlace || ""}
                actions={{
                  change: handleVerificationPlaceChange,
                }}
              />

              {validationErrors["verification_place"] && (
                <div className="error">
                  {validationErrors["verification_place"]}
                </div>
              )}
            </div>

            <div style={{ marginBottom: "20px" }}>
              <Input
                label={"Ссылка"}
                type={"text"}
                placeholder={"https://"}
                value={link || ""}
                actions={{
                  change: handleLinkChange,
                }}
              />

              {validationErrors["device_link"] && (
                <div className="error">{validationErrors["device_link"]}</div>
              )}
            </div>

            <div className="flexContainerWithGap">
              <div className="width32Percent">
                <p
                  style={{
                    marginBottom: "9px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Дата поверки{" "}
                  {!dateFrom ? (
                    <ErrorIcon
                      sx={{
                        color: "#FFD43B",
                        marginLeft: "8px",
                        marginTop: "-2px",
                      }}
                    />
                  ) : (
                    <CheckCircleIcon
                      sx={{
                        color: "#63E6BE",
                        marginLeft: "8px",
                        marginTop: "-2px",
                      }}
                    />
                  )}
                </p>
                <DatePicker
                  selectedDate={dateFrom}
                  dateChange={handleDateChange}
                />
              </div>

              <div className="width32Percent">
                <p
                  style={{
                    marginBottom: "9px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Поверка действительна до{" "}
                  {!dateTo ? (
                    <ErrorIcon
                      sx={{
                        color: "#FFD43B",
                        marginLeft: "8px",
                        marginTop: "-2px",
                      }}
                    />
                  ) : (
                    <CheckCircleIcon
                      sx={{
                        color: "#63E6BE",
                        marginLeft: "8px",
                        marginTop: "-2px",
                      }}
                    />
                  )}
                </p>
                <DatePicker
                  selectedDate={dateTo}
                  dateChange={handleNextDateChange}
                />
              </div>
            </div>
          </div>

          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isDisabledBtn}
              sx={{ mt: 1, mr: 1 }}
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: "11px 20px 11px",
                margin: 0,
                borderRadius: "12px",
                width: "100%",
              }}
            >
              Редактировать эталон
            </Button>
          </div>
        </React.Fragment>
      )}
    </main>
  );
};

export default EditEtalon;
