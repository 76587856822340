import React, { useState } from "react"
import { useDispatch } from "react-redux"
import ImageUploading from "react-images-uploading"
import Button from "@mui/material/Button"

import ImageViewer from "../Modal/ImageViewer"
import Input from "../Input/InputMUI"
import { settingsImageViewer } from "../../../store/slices/comps/modal"
import useWindowDimensions from "../../../hooks/useWindowDimensions"

const ImageUpload = (props) => {
  const { showTitle = true, showInput = true, count = 10, callback } = props

  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const [images, setImages] = useState([])
  const maxNumber = count

  const onChange = (imageList) => {
    setImages(imageList)
    callback(imageList)
  }

  const handleImageClick = (image) => {
    dispatch(settingsImageViewer({ value: "currentImage", label: image }))
    dispatch(settingsImageViewer({ value: "open", label: true }))
  }

  return (
    <React.Fragment>
      { showTitle && <h2 style={{ marginBottom: "10px" }}>Дополнительная информация</h2> }
      { showInput && <div style={{ width: "100%", marginTop: "20px" }}>
        <Input
          type={"text"}
          placeholder={
            "Здесь вы можете написать дополнительную информацию о проведенной поверке"
          }
          label={"Сопроводительный комментарий (необязательно)"}
          value={""}
          actions={{
            change: () => {},
          }}
        />
      </div> }

      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        maxFileSize={5242880}
        imgExtension={[".png", ".jpg", ".jpeg", ".bmp", ".tiff", ".gif"]}
        dataURLKey="base64"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
        }) => (
          <div className="upload__image-wrapper">
            <div
              className="btn_container"
              style={{
                gap: "16px",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              <div
                className="image-upload-container"
                style={
                  width > 1050
                    ? { marginBottom: "0px" }
                    : { height: "220px", marginBottom: "0px" }
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    alignItems: "center",
                    padding: width > 1050 ? "0px" : "0px 14px",
                  }}
                >
                  <h4
                    style={{
                      display: "block",
                      marginTop: "40px",
                      textAlign: width > 1050 ? "left" : "center",
                    }}
                  >
                    Чтобы загрузить изображения, перетащите их сюда или нажмите
                    Загрузить
                  </h4>
                  <span
                    style={{
                      display: "block",
                      color: "gray",
                      marginTop: "14px",
                      opacity: 0.8,
                      lineHeight: "24px",
                      textAlign: width > 1050 ? "left" : "center",
                    }}
                  >
                    Не более {count} изображений размером до 10 МБ
                  </span>
                </div>
                <Button
                  variant="outlined"
                  onClick={() => {
                    onImageUpload()
                  }}
                  sx={{ mt: 1, mr: 1 }}
                  style={{
                    position: "absolute",
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: "11px 10px",
                    borderRadius: "18px",
                    width: "100%",
                    height: "100%",
                    zIndex: 2,
                    opacity: 0,
                    margin: 0,
                    top: 0,
                    left: 0,
                  }}
                >
                  Добавить изображение
                </Button>
              </div>

              { images && images.length >= 2 && (
                <Button
                  variant="contained"
                  onClick={onImageRemoveAll}
                  style={{
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: "11px 10px",
                    margin: 0,
                    marginBottom: "14px",
                    borderRadius: "12px",
                    width: "calc(20% - 6px)",
                    lineHeight: "22px",
                  }}
                >
                  Удалить все изображения
                </Button>
              )}
            </div>
            { imageList.length > 0 && (
              <div className="image-container">
                { imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img
                      src={image["base64"]}
                      alt="Фото протокола"
                      className="img_uploading"
                      onClick={() => handleImageClick(image)}
                    />
                    <div className="image-item__btn-wrapper">
                      <Button
                        variant="outlined"
                        onClick={() => onImageUpdate(index)}
                        style={{
                          textTransform: "none",
                          fontSize: "15px",
                          fontFamily: '"Wix Madefor Display", sans-serif',
                          padding: "8px ",
                          margin: 0,
                          borderRadius: "12px",
                          width: "calc(50% - 7px)",
                        }}
                      >
                        Изменить
                      </Button>

                      <Button
                        variant="contained"
                        onClick={() => onImageRemove(index)}
                        style={{
                          textTransform: "none",
                          fontSize: "15px",
                          fontFamily: '"Wix Madefor Display", sans-serif',
                          padding: "8px",
                          margin: 0,
                          borderRadius: "12px",
                          width: "calc(50% - 7px)",
                        }}
                      >
                        Удалить
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </ImageUploading>
      <ImageViewer />
    </React.Fragment>
  )
}

export default ImageUpload
