/* eslint-disable no-template-curly-in-string */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import { useDispatch, useSelector } from "react-redux"
import { changeProperty } from "../components/Methodologies/Water/Mi1592-2015/state"

const useGenerateProtocolData = () => {
  const dispatch = useDispatch()

  const q_min = useSelector((state) => state.mi15922015Reducer.q_min)
  const q_t = +useSelector((state) => state.mi15922015Reducer.q_t) * 1.1
  const q_max = useSelector((state) => state.mi15922015Reducer.q_actual)

  const q_min_limit = useSelector(
    (state) => state.mi15922015Reducer.q_min_limit
  )
  const q_max_limit = useSelector(
    (state) => state.mi15922015Reducer.q_max_limit
  )

  const diameter = useSelector((state) => state.mi15922015Reducer.diameter)
  const start_1 = useSelector((state) => state.mi15922015Reducer.start_1)

  // ----------------------------------------------------------------
  // локальные данные внутри хука
  // ----------------------------------------------------------------

  let localQ_i_1 = null
  let localQ_i_2 = null
  let localQ_i_3 = null

  let localQ_i_4 = null
  let localQ_i_5 = null
  let localQ_i_6 = null

  let localQ_i_7 = null
  let localQ_i_8 = null
  let localQ_i_9 = null

  // ----------------------------------------------------------------
  // локальные данные внутри хука
  // ----------------------------------------------------------------

  const getRandom = (min, max) => {
    return Math.floor(Math.random() * (max + 1 - min)) + min
  }

  const generateQactual = () => {
    const getQActualValue = (min, max) => getRandom(min, max) / 10

    let value
    switch (Number(diameter)) {
      case 10:
        value = getQActualValue(4, 8)
        break
      case 15:
        value = getQActualValue(4, 9)
        break
      case 20:
        value = getQActualValue(4, 10)
        break
      default:
        return
    }

    dispatch(
      changeProperty({
        value: "q_actual",
        label: value,
      })
    )
  }

  const generateValuesQ = () => {
    console.group("генерация расхода во время измерения")

    if (q_min) {
      localQ_i_1 = +(
        +q_min +
        ((getRandom(0, 1) * getRandom(1, 3)) / 1000) * +q_min
      ).toFixed(4)

      false && console.log(
        `Q_i_1 = ${+q_min} + getRandom(0, 1) * getRandom(1, 3) / 1000 * ${+q_min} = ${localQ_i_1}`
      )

      dispatch(
        changeProperty({
          value: "q_i_1",
          label: localQ_i_1.toFixed(4),
        })
      )

      localQ_i_2 = +(
        +q_min +
        ((getRandom(0, 1) * getRandom(1, 3)) / 1000) * +q_min
      ).toFixed(4)

      false && console.log(
        `Q_i_2 = ${+q_min} + getRandom(0, 1) * getRandom(1, 3) / 1000 * ${+q_min} = ${localQ_i_2}`
      )

      dispatch(
        changeProperty({
          value: "q_i_2",
          label: localQ_i_2.toFixed(4),
        })
      )

      localQ_i_3 = +(
        +q_min +
        ((getRandom(0, 1) * getRandom(1, 3)) / 1000) * +q_min
      ).toFixed(4)

      false && console.log(
        `Q_i_3 = ${+q_min} + getRandom(0, 1) * getRandom(1, 3) / 1000 * ${+q_min} = ${localQ_i_3}`
      )

      dispatch(
        changeProperty({
          value: "q_i_3",
          label: localQ_i_3.toFixed(4),
        })
      )
    }

    if (q_t) {
      localQ_i_4 = +(
        +q_t +
        ((getRandom(-1, 1) * getRandom(1, 1)) / 1000) * +q_t
      ).toFixed(4)

      false && console.log(
        `Q_i_4 = ${+q_t} + getRandom(-1, 1) * getRandom(1, 1) / 1000 * ${+q_t} = ${localQ_i_4}`
      )

      dispatch(
        changeProperty({
          value: "q_i_4",
          label: localQ_i_4.toFixed(4),
        })
      )

      localQ_i_5 = +(
        +q_t +
        ((getRandom(-1, 1) * getRandom(1, 1)) / 1000) * +q_t
      ).toFixed(4)

      false && console.log(
        `Q_i_5 = ${+q_t} + getRandom(-1, 1) * getRandom(1, 1) / 1000 * ${+q_t} = ${localQ_i_5}`
      )

      dispatch(
        changeProperty({
          value: "q_i_5",
          label: localQ_i_5.toFixed(4),
        })
      )

      localQ_i_6 = +(
        +q_t +
        ((getRandom(-1, 1) * getRandom(1, 1)) / 1000) * +q_t
      ).toFixed(4)

      false && console.log(
        `Q_i_6 = ${+q_t} + getRandom(-1, 1) * getRandom(1, 1) / 1000 * ${+q_t} = ${localQ_i_6}`
      )

      dispatch(
        changeProperty({
          value: "q_i_6",
          label: localQ_i_6.toFixed(4),
        })
      )
    }

    if (q_max) {
      localQ_i_7 = +(+q_max - (getRandom(1, 2) / 1000) * +q_max).toFixed(4)

      false && console.log(
        `Q_i_7 = ${+q_max} - getRandom(1, 2) / 1000 * ${+q_max} = ${localQ_i_7}`
      )

      dispatch(
        changeProperty({
          value: "q_i_7",
          label: localQ_i_7.toFixed(4),
        })
      )

      localQ_i_8 = +(+q_max - (getRandom(1, 2) / 1000) * +q_max).toFixed(4)

      false && console.log(
        `Q_i_8 = ${+q_max} - getRandom(1, 2) / 1000 * ${+q_max} = ${localQ_i_8}`
      )

      dispatch(
        changeProperty({
          value: "q_i_8",
          label: localQ_i_8.toFixed(4),
        })
      )

      localQ_i_9 = +(+q_max - (getRandom(1, 2) / 1000) * +q_max).toFixed(4)

      false && console.log(
        `Q_i_9 = ${+q_max} - getRandom(1, 2) / 1000 * ${+q_max} = ${localQ_i_9}`
      )

      dispatch(
        changeProperty({
          value: "q_i_9",
          label: localQ_i_9.toFixed(4),
        })
      )
    }

    console.groupEnd()
  }

  const generateValuesV = (vи1, vи2, vи3, vи4, vи5, vи6, vи7, vи8, vи9) => {
    if ((start_1 || start_1 === 0) && q_min_limit) {
      console.group("генерация значений погрешности")

      let vyi1Fault =
        (getRandom(0, q_min_limit - 1) + getRandom(1, 99) / 100) *
        (getRandom(0, 1) * 2 - 1)

      false && console.log(
        `vyi1Fault = ( getRandom(0, ${
          q_min_limit - 1
        }) + getRandom(1, 99) / 100 ) * ( getRandom(0, 1) * 2 - 1 ) = ${vyi1Fault}
      `
      )

      let vyi2Fault =
        (getRandom(0, q_min_limit - 1) + getRandom(1, 99) / 100) *
        (getRandom(0, 1) * 2 - 1)

      false && console.log(
        `vyi2Fault = ( getRandom(0, ${
          q_min_limit - 1
        }) + getRandom(1, 99) / 100 ) * ( getRandom(0, 1) * 2 - 1 ) = ${vyi2Fault}
      `
      )

      let vyi3Fault =
        (getRandom(0, q_min_limit - 1) + getRandom(1, 99) / 100) *
        (getRandom(0, 1) * 2 - 1)

      false && console.log(
        `vyi13Fault = ( getRandom(0, ${
          q_min_limit - 1
        }) + getRandom(1, 99) / 100 ) * ( getRandom(0, 1) * 2 - 1 ) = ${vyi3Fault}
      `
      )

      let vyi4Fault =
        (getRandom(0, q_max_limit - 1) + getRandom(1, 99) / 100) *
        (getRandom(0, 1) * 2 - 1)

      false && console.log(
        `vyi4Fault = ( getRandom(0, ${
          q_max_limit - 1
        }) + getRandom(1, 99) / 100 ) * ( getRandom(0, 1) * 2 - 1 ) = ${vyi4Fault}
      `
      )

      let vyi5Fault =
        (getRandom(0, q_max_limit - 1) + getRandom(1, 99) / 100) *
        (getRandom(0, 1) * 2 - 1)

      false && console.log(
        `vyi5Fault = ( getRandom(0, ${
          q_max_limit - 1
        }) + getRandom(1, 99) / 100 ) * ( getRandom(0, 1) * 2 - 1 ) = ${vyi5Fault}
      `
      )

      let vyi6Fault =
        (getRandom(0, q_max_limit - 1) + getRandom(1, 99) / 100) *
        (getRandom(0, 1) * 2 - 1)

      false && console.log(
        `vyi6Fault = ( getRandom(0, ${
          q_max_limit - 1
        }) + getRandom(1, 99) / 100 ) * ( getRandom(0, 1) * 2 - 1 ) = ${vyi6Fault}
      `
      )

      let vyi7Fault =
        (getRandom(0, q_max_limit - 1) + getRandom(1, 99) / 100) *
        (getRandom(0, 1) * 2 - 1)

      false && console.log(
        `vyi7Fault = ( getRandom(0, ${
          q_max_limit - 1
        }) + getRandom(1, 99) / 100 ) * ( getRandom(0, 1) * 2 - 1 ) = ${vyi7Fault}
      `
      )

      let vyi8Fault =
        (getRandom(0, q_max_limit - 1) + getRandom(1, 99) / 100) *
        (getRandom(0, 1) * 2 - 1)

      false && console.log(
        `vyi8Fault = ( getRandom(0, ${
          q_max_limit - 1
        }) + getRandom(1, 99) / 100 ) * ( getRandom(0, 1) * 2 - 1 ) = ${vyi8Fault}
      `
      )

      let vyi9Fault =
        (getRandom(0, q_max_limit - 1) + getRandom(1, 99) / 100) *
        (getRandom(0, 1) * 2 - 1)

      false && console.log(
        `vyi9Fault = ( getRandom(0, ${
          q_max_limit - 1
        }) + getRandom(1, 99) / 100 ) * ( getRandom(0, 1) * 2 - 1 ) = ${vyi9Fault}
      `
      )

      console.groupEnd()

      dispatch(
        changeProperty({
          value: "vyi1Fault",
          label: vyi1Fault,
        })
      )
      dispatch(
        changeProperty({
          value: "vyi2Fault",
          label: vyi2Fault,
        })
      )
      dispatch(
        changeProperty({
          value: "vyi3Fault",
          label: vyi3Fault,
        })
      )
      dispatch(
        changeProperty({
          value: "vyi4Fault",
          label: vyi4Fault,
        })
      )
      dispatch(
        changeProperty({
          value: "vyi5Fault",
          label: vyi5Fault,
        })
      )
      dispatch(
        changeProperty({
          value: "vyi6Fault",
          label: vyi6Fault,
        })
      )
      dispatch(
        changeProperty({
          value: "vyi7Fault",
          label: vyi7Fault,
        })
      )
      dispatch(
        changeProperty({
          value: "vyi8Fault",
          label: vyi8Fault,
        })
      )
      dispatch(
        changeProperty({
          value: "vyi9Fault",
          label: vyi9Fault,
        })
      )

      // ----------------------------------------------------------------
      // ----------------------------------------------------------------

      console.group("генерация значений объема по эталону")

      let vyi1 = ((100 * vи1) / (100 + vyi1Fault)).toFixed(6)

      false && console.log(`vyi1 = ( 100 * ${vи1} ) / ( 100 + ${vyi1Fault} ) = ${vyi1}`)

      let vyi2 = ((100 * vи2) / (100 + vyi2Fault)).toFixed(6)

      false && console.log(`vyi2 = ( 100 * ${vи2} ) / ( 100 + ${vyi2Fault} ) = ${vyi2}`)

      let vyi3 = ((100 * vи3) / (100 + vyi3Fault)).toFixed(6)

      false && console.log(`vyi3 = ( 100 * ${vи3} ) / ( 100 + ${vyi3Fault} ) = ${vyi3}`)

      let vyi4 = ((100 * vи4) / (100 + vyi4Fault)).toFixed(6)

      false && console.log(`vyi4 = ( 100 * ${vи4} ) / ( 100 + ${vyi4Fault} ) = ${vyi4}`)

      let vyi5 = ((100 * vи5) / (100 + vyi5Fault)).toFixed(6)

      false && console.log(`vyi5 = ( 100 * ${vи5} ) / ( 100 + ${vyi5Fault} ) = ${vyi5}`)

      let vyi6 = ((100 * vи6) / (100 + vyi6Fault)).toFixed(6)

      false && console.log(`vyi6 = ( 100 * ${vи6} ) / ( 100 + ${vyi6Fault} ) = ${vyi6}`)

      let vyi7 = ((100 * vи7) / (100 + vyi7Fault)).toFixed(6)

      false && console.log(`vyi7 = ( 100 * ${vи7} ) / ( 100 + ${vyi7Fault} ) = ${vyi7}`)

      let vyi8 = ((100 * vи8) / (100 + vyi8Fault)).toFixed(6)

      false && console.log(`vyi8 = ( 100 * ${vи8} ) / ( 100 + ${vyi8Fault} ) = ${vyi8}`)

      let vyi9 = ((100 * vи9) / (100 + vyi9Fault)).toFixed(6)

      false && console.log(`vyi9 = ( 100 * ${vи9} ) / ( 100 + ${vyi9Fault} ) = ${vyi9}`)

      dispatch(
        changeProperty({
          value: "v_y_i_1",
          label: +vyi1,
        })
      )
      dispatch(
        changeProperty({
          value: "v_y_i_2",
          label: +vyi2,
        })
      )
      dispatch(
        changeProperty({
          value: "v_y_i_3",
          label: +vyi3,
        })
      )
      dispatch(
        changeProperty({
          value: "v_y_i_4",
          label: +vyi4,
        })
      )
      dispatch(
        changeProperty({
          value: "v_y_i_5",
          label: +vyi5,
        })
      )
      dispatch(
        changeProperty({
          value: "v_y_i_6",
          label: +vyi6,
        })
      )
      dispatch(
        changeProperty({
          value: "v_y_i_7",
          label: +vyi7,
        })
      )
      dispatch(
        changeProperty({
          value: "v_y_i_8",
          label: +vyi8,
        })
      )
      dispatch(
        changeProperty({
          value: "v_y_i_9",
          label: +vyi9,
        })
      )
    }
  }

  const generateValuesStartFinish = () => {
    generateValuesQ()
    generateValuesV(
      +((Math.round(((localQ_i_1 * 1000) / 3600) * 720) + 2) / 1000).toFixed(4),
      +((Math.round(((localQ_i_2 * 1000) / 3600) * 720) + 2) / 1000).toFixed(4),
      +((Math.round(((localQ_i_3 * 1000) / 3600) * 720) + 2) / 1000).toFixed(4),
      +((Math.round(((localQ_i_4 * 1000) / 3600) * 360) + 2) / 1000).toFixed(4),
      +((Math.round(((localQ_i_5 * 1000) / 3600) * 360) + 2) / 1000).toFixed(4),
      +((Math.round(((localQ_i_6 * 1000) / 3600) * 360) + 2) / 1000).toFixed(4),
      +((Math.round(((localQ_i_7 * 1000) / 3600) * 120) + 5) / 1000).toFixed(4),
      +((Math.round(((localQ_i_8 * 1000) / 3600) * 120) + 5) / 1000).toFixed(4),
      +((Math.round(((localQ_i_9 * 1000) / 3600) * 120) + 5) / 1000).toFixed(4)
    )

    if ((start_1 || start_1 === 0) && q_min) {
      console.group("генерация объема по показаниям счетчика")

      let localFinish_1 = Number(
        (
          +start_1 +
          +(
            (Math.round(((localQ_i_1 * 1000) / 3600) * 720) + 2) /
            1000
          ).toFixed(4)
        ).toFixed(3)
      )

      false && console.log(
        `Vи1 = (( ${localQ_i_1} * 1000 / 3600 * 720 ) + 2 ) / 1000 = ${+(
          (Math.round(((localQ_i_1 * 1000) / 3600) * 720) + 2) /
          1000
        ).toFixed(4)}
      `
      )

      dispatch(
        changeProperty({
          value: "finish_1",
          label: localFinish_1.toFixed(3),
        })
      )

      let localStart_2 = Number(
        (localFinish_1 + getRandom(1, 4) / 1000).toFixed(3)
      )

      dispatch(
        changeProperty({
          value: "start_2",
          label: localStart_2.toFixed(3),
        })
      )

      let localFinish_2 = Number(
        (
          +localStart_2 +
          +(
            (Math.round(((localQ_i_2 * 1000) / 3600) * 720) + 2) /
            1000
          ).toFixed(4)
        ).toFixed(3)
      )

      false && console.log(
        `Vи2 = (( ${localQ_i_2} * 1000 / 3600 * 720 ) + 2 ) / 1000 = ${+(
          (Math.round(((localQ_i_2 * 1000) / 3600) * 720) + 2) /
          1000
        ).toFixed(4)}
      `
      )

      dispatch(
        changeProperty({
          value: "finish_2",
          label: localFinish_2.toFixed(3),
        })
      )

      let localStart_3 = Number(
        (localFinish_2 + getRandom(1, 4) / 1000).toFixed(3)
      )

      dispatch(
        changeProperty({
          value: "start_3",
          label: localStart_3.toFixed(3),
        })
      )

      let localFinish_3 = Number(
        (
          +localStart_3 +
          +(
            (Math.round(((localQ_i_3 * 1000) / 3600) * 720) + 2) /
            1000
          ).toFixed(4)
        ).toFixed(3)
      )

      false && console.log(
        `Vи3 = (( ${localQ_i_3} * 1000 / 3600 * 720 ) + 2 ) / 1000 = ${+(
          (Math.round(((localQ_i_3 * 1000) / 3600) * 720) + 2) /
          1000
        ).toFixed(4)}
      `
      )

      dispatch(
        changeProperty({
          value: "finish_3",
          label: localFinish_3.toFixed(3),
        })
      )

      let localStart_4 = Number(
        (localFinish_3 + getRandom(1, 4) / 1000).toFixed(3)
      )

      dispatch(
        changeProperty({
          value: "start_4",
          label: localStart_4.toFixed(3),
        })
      )

      let localFinish_4 = Number(
        (
          +localStart_4 +
          +(
            (Math.round(((localQ_i_4 * 1000) / 3600) * 360) + 2) /
            1000
          ).toFixed(4)
        ).toFixed(3)
      )

      false && console.log(
        `Vи4 = (( ${localQ_i_4} * 1000 / 3600 * 360 ) + 2 ) / 1000 = ${+(
          (Math.round(((localQ_i_4 * 1000) / 3600) * 360) + 2) /
          1000
        ).toFixed(4)}
      `
      )

      dispatch(
        changeProperty({
          value: "finish_4",
          label: localFinish_4.toFixed(3),
        })
      )

      let localStart_5 = Number(
        (localFinish_4 + getRandom(1, 4) / 1000).toFixed(3)
      )

      dispatch(
        changeProperty({
          value: "start_5",
          label: localStart_5.toFixed(3),
        })
      )

      let localFinish_5 = Number(
        (
          +localStart_5 +
          +(
            (Math.round(((localQ_i_5 * 1000) / 3600) * 360) + 2) /
            1000
          ).toFixed(4)
        ).toFixed(3)
      )

      false && console.log(
        `Vи5 = (( ${localQ_i_5} * 1000 / 3600 * 360 ) + 2 ) / 1000 = ${+(
          (Math.round(((localQ_i_5 * 1000) / 3600) * 360) + 2) /
          1000
        ).toFixed(4)}
      `
      )

      dispatch(
        changeProperty({
          value: "finish_5",
          label: localFinish_5.toFixed(3),
        })
      )

      let localStart_6 = Number(
        (localFinish_5 + getRandom(1, 4) / 1000).toFixed(3)
      )

      dispatch(
        changeProperty({
          value: "start_6",
          label: localStart_6.toFixed(3),
        })
      )

      let localFinish_6 = Number(
        (
          +localStart_6 +
          +(
            (Math.round(((localQ_i_6 * 1000) / 3600) * 360) + 2) /
            1000
          ).toFixed(4)
        ).toFixed(3)
      )

      false && console.log(
        `Vи6 = (( ${localQ_i_6} * 1000 / 3600 * 360 ) + 2 ) / 1000 = ${+(
          (Math.round(((localQ_i_6 * 1000) / 3600) * 360) + 2) /
          1000
        ).toFixed(4)}
      `
      )

      dispatch(
        changeProperty({
          value: "finish_6",
          label: localFinish_6.toFixed(3),
        })
      )

      let localStart_7 = Number(
        (localFinish_6 + getRandom(1, 4) / 1000).toFixed(3)
      )

      dispatch(
        changeProperty({
          value: "start_7",
          label: localStart_7.toFixed(3),
        })
      )

      let localFinish_7 = Number(
        (
          +localStart_7 +
          +(
            (Math.round(((localQ_i_7 * 1000) / 3600) * 120) + 5) /
            1000
          ).toFixed(4)
        ).toFixed(3)
      )

      false && console.log(
        `Vи7 = (( ${localQ_i_7} * 1000 / 3600 * 120 ) + 5 ) / 1000 = ${+(
          (Math.round(((localQ_i_7 * 1000) / 3600) * 120) + 5) /
          1000
        ).toFixed(4)}
      `
      )

      dispatch(
        changeProperty({
          value: "finish_7",
          label: localFinish_7.toFixed(3),
        })
      )

      let localStart_8 = Number(
        (localFinish_7 + getRandom(1, 4) / 1000).toFixed(3)
      )

      dispatch(
        changeProperty({
          value: "start_8",
          label: localStart_8.toFixed(3),
        })
      )

      let localFinish_8 = Number(
        (
          +localStart_8 +
          +(
            (Math.round(((localQ_i_8 * 1000) / 3600) * 120) + 5) /
            1000
          ).toFixed(4)
        ).toFixed(3)
      )

      false && console.log(
        `Vи8 = (( ${localQ_i_8} * 1000 / 3600 * 120 ) + 5 ) / 1000 = ${+(
          (Math.round(((localQ_i_8 * 1000) / 3600) * 120) + 5) /
          1000
        ).toFixed(4)}
      `
      )

      dispatch(
        changeProperty({
          value: "finish_8",
          label: localFinish_8.toFixed(3),
        })
      )

      let localStart_9 = Number(
        (localFinish_8 + getRandom(1, 4) / 1000).toFixed(3)
      )

      dispatch(
        changeProperty({
          value: "start_9",
          label: localStart_9.toFixed(3),
        })
      )

      let localFinish_9 = Number(
        (
          +localStart_9 +
          +(
            (Math.round(((localQ_i_7 * 1000) / 3600) * 120) + 5) /
            1000
          ).toFixed(4)
        ).toFixed(3)
      )

      false && console.log(
        `Vи9 = (( ${localQ_i_9} * 1000 / 3600 * 120 ) + 5 ) / 1000 = ${+(
          (Math.round(((localQ_i_9 * 1000) / 3600) * 120) + 5) /
          1000
        ).toFixed(4)}
      `
      )

      dispatch(
        changeProperty({
          value: "finish_9",
          label: localFinish_9.toFixed(3),
        })
      )

      console.groupEnd()
      console.group("генерация показаний на начало и конец")

      false && console.log(`показания на начало 1 - ${start_1}`)
      false && console.log(
        `показания на конец 1 - ${start_1} + (((( ${localQ_i_1} * 1000 / 3600 * 720 ) + 2 ) / 1000).toFixed(4)) = ${localFinish_1}`
      )

      false && console.log(
        `показания на начало 2 - ${localFinish_1} + getRandom(1, 4) / 1000 = ${localStart_2}`
      )
      false && console.log(
        `показания на конец 2 - ${localStart_2} + (((( ${localQ_i_2} * 1000 / 3600 * 720 ) + 2 ) / 1000).toFixed(4)) = ${localFinish_2}`
      )

      false && console.log(
        `показания на начало 3 - ${localFinish_2} + getRandom(1, 4) / 1000 = ${localStart_3}`
      )
      false && console.log(
        `показания на конец 3 - ${localStart_3} + (((( ${localQ_i_3} * 1000 / 3600 * 720 ) + 2 ) / 1000).toFixed(4)) = ${localFinish_3}`
      )

      false && console.log(
        `показания на начало 4 - ${localFinish_3} + getRandom(1, 4) = ${localStart_4}`
      )
      false && console.log(
        `показания на конец 4 - ${localStart_4} + (((( ${localQ_i_4} * 1000 / 3600 * 360 ) + 2 ) / 1000).toFixed(4)) = ${localFinish_4}`
      )

      false && console.log(
        `показания на начало 5 - ${localFinish_4} + getRandom(1, 4) / 1000 = ${localStart_5}`
      )
      false && console.log(
        `показания на конец 5 - ${localStart_5} + (((( ${localQ_i_5} * 1000 / 3600 * 360 ) + 2 ) / 1000).toFixed(4)) = ${localFinish_5}`
      )

      false && console.log(
        `показания на начало 6 - ${localFinish_5} + getRandom(1, 4) / 1000 = ${localStart_6}`
      )
      false && console.log(
        `показания на конец 6 - ${localStart_6} + (((( ${localQ_i_6} * 1000 / 3600 * 360 ) + 2 ) / 1000).toFixed(4)) = ${localFinish_6}`
      )

      false && console.log(
        `показания на начало 7 - ${localFinish_6} + getRandom(1, 4) / 1000 = ${localStart_7}`
      )
      false && console.log(
        `показания на конец 7 - ${localStart_7} + (((( ${localQ_i_7} * 1000 / 3600 * 120 ) + 5 ) / 1000).toFixed(4)) = ${localFinish_7}`
      )

      false && console.log(
        `показания на начало 8 - ${localFinish_7} + getRandom(1, 4) / 1000 = ${localStart_8}`
      )
      false && console.log(
        `показания на конец 8 - ${localStart_8} + (((( ${localQ_i_8} * 1000 / 3600 * 120 ) + 5 ) / 1000).toFixed(4)) = ${localFinish_8}`
      )

      false && console.log(
        `показания на начало 9 - ${localFinish_8} + getRandom(1, 4) / 1000 = ${localStart_9}`
      )
      false && console.log(
        `показания на конец 9 - ${localStart_9} + (((( ${localQ_i_9} * 1000 / 3600 * 120 ) + 5 ) / 1000).toFixed(4)) = ${localFinish_9}`
      )

      console.groupEnd()
    }
  }

  const defaultValuesStartFinish = () => {
    dispatch(
      changeProperty({
        value: "finish_1",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "start_2",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "finish_2",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "start_3",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "finish_3",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "start_4",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "finish_4",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "start_5",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "finish_5",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "start_6",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "finish_6",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "start_7",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "finish_7",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "start_8",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "finish_8",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "start_9",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "finish_9",
        label: null,
      })
    )

    // ----------------------------------------------------------------
    // ----------------------------------------------------------------

    dispatch(
      changeProperty({
        value: "q_i_1",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "q_i_2",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "q_i_3",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "q_i_4",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "q_i_5",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "q_i_6",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "q_i_7",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "q_i_8",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "q_i_9",
        label: null,
      })
    )

    // ----------------------------------------------------------------
    // ----------------------------------------------------------------

    dispatch(
      changeProperty({
        value: "v_y_i_1",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "v_y_i_2",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "v_y_i_3",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "v_y_i_4",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "v_y_i_5",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "v_y_i_6",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "v_y_i_7",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "v_y_i_8",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "v_y_i_9",
        label: null,
      })
    )

    // ----------------------------------------------------------------
    // ----------------------------------------------------------------

    dispatch(
      changeProperty({
        value: "vyi1Fault",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "vyi2Fault",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "vyi3Fault",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "vyi4Fault",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "vyi5Fault",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "vyi6Fault",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "vyi7Fault",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "vyi8Fault",
        label: null,
      })
    )
    dispatch(
      changeProperty({
        value: "vyi9Fault",
        label: null,
      })
    )
  }

  return {
    generateValuesStartFinish,
    defaultValuesStartFinish,
    generateValuesQ,
    generateValuesV,
    generateQactual,
  }
}

export default useGenerateProtocolData
