// ----------------------------------------------------------------
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import R from "../../services/request.service"
import Feedback from "../../components/LocalElements/Feedback/Feedback"
import { setShow } from "../../store/slices/comps/feedback"

import useWindowDimensions from "../../hooks/useWindowDimensions"

const ShowUsers = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userRole = localStorage.getItem("userRole")

  const { users_id } = useParams()
  const { width } = useWindowDimensions()

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)
  const [userData, setUserData] = useState([])

  const show = useSelector((state) => state.feedbackReducer.show)

  useEffect(() => {
    ( async () => {
      dispatch(setShow(true))
      try {
        const { data: usersData } = await R.getUsersItem(users_id)
        const data = usersData.data
        setUserData(data)
      } catch (error) {
        console.error(error)
      }
      dispatch(setShow(false))
    })()
  }, [ users_id, dispatch ])

  const navigateToProtocols = () => {
    navigate("/metriva/users")
  }

  return (
    <main className={isToggle ? "main_open" : "main"}>
      { width > 1050 && <React.Fragment>
        { show ? (
          <Feedback />
        ) : (
          <React.Fragment>
            <div className="path_to_back" onClick={navigateToProtocols}>
              <i
                className="fas fa-arrow-left icon_back"
                style={{ width: "30px", height: "30px", marginRight: "6px" }}
              />
              <p>Все пользователи</p>
            </div>

            { userRole != "brigadier" && (
              <div
                className="path_to_edit"
                style={{ justifyContent: "space-between" }}
              >
                <h4>
                  {userData?.surname} {userData?.name} {userData?.patr}
                </h4>
                <span
                  className="fas fa-pencil-alt icon_back"
                  onClick={() => navigate(`/metriva/users/${users_id}/edit`)}
                ></span>
              </div>
            )}

            <div className="box">
              <h2 style={{ marginBottom: "15px" }}>Личные данные</h2>

              <div
                className="flexContainerWithGap"
                style={{ marginBottom: "15px" }}
              >
                <p>Логин :</p>
                <h4>{userData?.login}</h4>
              </div>

              <div
                className="flexContainerWithGap"
                style={{ marginBottom: "15px" }}
              >
                <p>Email :</p>
                <h4>{userData?.email}</h4>
              </div>

              <div className="flexContainerWithGap">
                <p>Регион :</p>
                <div className="flexContainerWithGap">
                  { userData?.regions?.map((item) => (
                    <h4 key={item.id}>{item.name}</h4>
                  ))}
                </div>
              </div>
            </div>

            <div className="box">
              <h2 style={{ marginBottom: "15px" }}>Рабочие данные</h2>

              <div
                className="flexContainerWithGap"
                style={{ marginBottom: "15px" }}
              >
                <p>Должность :</p>
                <h4>
                  { userData?.role === "brigadier"
                    ? "Руководитель"
                    : userData?.role === "metrologist"
                    ? "Мастер поверитель"
                    : userData?.role === "admin"
                    ? "Администратор"
                    : userData?.role === "super"
                    ? "Супер-админ"
                    : "нет данных"}
                </h4>
              </div>

              {( userData?.role === "brigadier" || userData?.role === "metrologist" ) && (
                <div
                  className="flexContainerWithGap"
                  style={{ marginBottom: "15px" }}
                >
                  <p>
                    { userData?.role === "brigadier"
                      ? "Сотрудники :"
                      : "Руководитель :"}
                  </p>
                  <h4>
                    { userData?.role === "brigadier"
                      ? userData?.metrologists
                          .map((metrologist) => metrologist.surname)
                          .join(", ")
                      : `${userData?.brigadier?.surname} ${userData?.brigadier?.name}`}
                  </h4>
                </div>
              )}

              {( userData?.role === "brigadier" || userData?.role === "metrologist" ) && (
                <div className="flexContainerWithGap">
                  <p>Комплекты СП :</p>

                  <div className="flexContainerWithGap">
                    { userData?.cases?.map((item) => (
                      <h4 key={item.id}>{item.inner_name}</h4>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </React.Fragment> }
      { width <= 1050 && 
        <span 
          style={{ 
            color: 'gray',
            width: '100%' ,
            textAlign: 'center',
            display: 'block',
            lineHeight: '23px',
            marginTop: '30px'
          }}
        >
          Воспользуйтесь версией для ПК чтобы просмотреть страницу
        </span> 
      }
    </main>
  )
}

export default ShowUsers
