// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-useless-escape */
// ----------------------------------------------------------------
/* eslint-disable no-undef */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Checkbox from "@mui/material/Checkbox"
import Button from "@mui/material/Button"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import DatePicker from "../../LocalElements/DatePicker/DatePicker"
import Input from "../../LocalElements/Input/InputMUI"
import SelectComplect from "../Select/SelectComplect"
import SelectCompany from "../Select/SelectCompany"

import D from "../../../store/localStorage/dispatcher"
import R from "../../../services/request.service"
import Feedback from "../../LocalElements/Feedback/Feedback"
import { setShow } from "../../../store/slices/comps/feedback"

import {
  changeComplect,
  resetCreateComplect,
} from "../../../store/slices/views/journals"
import { refreshComplects } from "../../../store/slices/controlers/updater"
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/comps/snackbar"

const EditJournal = () => {
  const { journal_id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector((state) => state.headerReducer.role)

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)
  const show = useSelector((state) => state.feedbackReducer.show)
  const [validationErrors, setValidationErrors] = useState({})
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const [companyId, setCompanyId] = useState(null)

  const casesId = useSelector((state) => state.journalReducer.createComplect.casesId)
  const issuer = useSelector((state) => state.journalReducer.createComplect.issuer)

  const handleIssuerChange = (param) => {
    const onlyRussianLettersAndSpacesAndPunctuation = param.replace(
      /[^а-яА-Я\s,.\-]/g,
      ""
    )
    const words = onlyRussianLettersAndSpacesAndPunctuation
      .split(/\s+/)
      .slice(0, 5)
    const formattedName = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ")

    dispatch(changeComplect({ value: "issuer", label: formattedName }))
  }

  const receiver = useSelector((state) => state.journalReducer.createComplect.receiver)

  const handleReceiverChange = (param) => {
    const onlyRussianLettersAndSpacesAndPunctuation = param.replace(
      /[^а-яА-Я\s,.\-]/g,
      ""
    )
    const words = onlyRussianLettersAndSpacesAndPunctuation
      .split(/\s+/)
      .slice(0, 5)
    const formattedName = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ")

    dispatch(changeComplect({ value: "receiver", label: formattedName }))
  }

  const dateJournal = useSelector((state) => state.journalReducer.createComplect.dateJournal)

  const handleDateChange = (date) => {
    if (date) {
      dispatch(
        changeComplect({
          value: "dateJournal",
          label: date.format("YYYY-MM-DD HH:mm:ss"),
        })
      )
    }
  }

  const isSueMark = useSelector((state) => state.journalReducer.createComplect.isSueMark)
  const isReturnMark = useSelector((state) => state.journalReducer.createComplect.isReturnMark)

  const handleIssueMarkChange = (event) => {
    dispatch(
      changeComplect({
        value: "isSueMark",
        label: event.target.checked,
      })
    )
  }

  const handleReturnMarkChange = (event) => {
    dispatch(
      changeComplect({
        value: "isReturnMark",
        label: event.target.checked,
      })
    )
  }

  const navigateToProtocols = () => {
    navigate("/metriva/journals")
  }

  useEffect(() => {
    (async () => {
      dispatch(setShow(true))
      try {
        const { data: complectItemData } = await R.getComplectItem(journal_id)
        const data = complectItemData.data

        setCompanyId(data.company.id)

        dispatch(
          changeComplect({
            value: "casesId",
            label: data.case.id,
          })
        )
        dispatch(
          changeComplect({
            value: "issuer",
            label: data.issuer_name,
          })
        )
        dispatch(
          changeComplect({
            value: "receiver",
            label: data.receiver_name,
          })
        )
        dispatch(
          changeComplect({
            value: "dateJournal",
            label: data.issue_at,
          })
        )
        dispatch(
          changeComplect({
            value: "isSueMark",
            label: data.issue_mark,
          })
        )
        dispatch(
          changeComplect({
            value: "isReturnMark",
            label: data.return_mark,
          })
        )
      } catch (error) {
        console.error(error)
      }
      dispatch(setShow(false))
    })()
  }, [journal_id, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetCreateComplect())
    }
  }, [])

  const handleSave = async () => {
    setIsDisabledBtn(true)
    const data = {
      case_id: casesId,
      issue_at: dateJournal,
      issue_mark: isSueMark,
      return_mark: isReturnMark,
      issuer_name: issuer,
      receiver_name: receiver,
    }

    if (userRole === "super") {
      data.company_id = companyId
    }

    const { status, data: complectAddData } = await R.editComplect(
      journal_id,
      data
    )

    if (status === 200) {
      dispatch(setMessage("Запись успешно отредактирована"))
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(refreshComplects())
      navigate("/metriva/journals")
    } else {
      if (complectAddData.errors) {
        const errors = complectAddData.errors
        setValidationErrors(errors)
      }
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Запись не отредактирована - ошибка валидации"))
      setIsDisabledBtn(false)
    }
  }

  return (
    <main className={isToggle ? "main_open" : "main"}>
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <div className="path_to_back" onClick={navigateToProtocols}>
            <i
              className="fas fa-arrow-left icon_back"
              style={{ width: "30px", height: "30px", marginRight: "6px" }}
            />
            <p>Все журналы</p>
          </div>

          <div className="path_to_edit">
            <h4>Журнал выдачи средств поверки</h4>
          </div>

          <div className="box">
            {userRole === "super" && (
              <div className="width32Percent" style={{ marginBottom: "15px" }}>
                <h2 style={{ marginBottom: "15px" }}>Компания</h2>
                <SelectCompany
                  companyId={companyId}
                  setCompanyId={setCompanyId}
                />
                {validationErrors["company_id"] && (
                  <div className="error">{validationErrors["company_id"]}</div>
                )}
              </div>
            )}

            <h2 style={{ marginBottom: "15px" }}>Информация о журнале</h2>
            <div
              className="flexContainerWithGap"
              style={{ marginBottom: "15px" }}
            >
              <div className="width32Percent">
                <SelectComplect
                  companyId={companyId}
                  validationErrors={validationErrors}
                />
              </div>

              <div className="width32Percent">
                <Input
                  label={"Выдавший (должность, Ф.И.О.)"}
                  type={"text"}
                  placeholder={"Введите должность и ФИО выдавшего"}
                  value={issuer || ""}
                  actions={{
                    change: handleIssuerChange,
                  }}
                />
                {!issuer && validationErrors["issuer_name"] && (
                  <div className="error">{validationErrors["issuer_name"]}</div>
                )}
              </div>

              <div className="width32Percent">
                <Input
                  label={"Принявший (должность, Ф.И.О.)"}
                  type={"text"}
                  placeholder={"Введите должность и ФИО принявшего"}
                  value={receiver || ""}
                  actions={{
                    change: handleReceiverChange,
                  }}
                />
                {!receiver && validationErrors["receiver_name"] && (
                  <div className="error">
                    {validationErrors["receiver_name"]}
                  </div>
                )}
              </div>
            </div>

            <div
              className="flexContainerWithGap"
              style={{ marginBottom: "15px" }}
            >
              <div className="width32Percent">
                <p
                  style={{
                    marginBottom: "9px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Дата выдачи{" "}
                  {!dateJournal ? (
                    <ErrorIcon
                      sx={{
                        color: "#FFD43B",
                        marginLeft: "8px",
                        marginTop: "-2px",
                      }}
                    />
                  ) : (
                    <CheckCircleIcon
                      sx={{
                        color: "#63E6BE",
                        marginLeft: "8px",
                        marginTop: "-2px",
                      }}
                    />
                  )}
                </p>
                <DatePicker
                  selectedDate={dateJournal}
                  dateChange={handleDateChange}
                />
              </div>
            </div>

            <div className="flexContainerWithGap">
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <p>Отметка выдачи :</p>
                <Checkbox
                  checked={isSueMark}
                  onChange={handleIssueMarkChange}
                />
              </div>

              { isSueMark === true && (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <p>Отметка возврата :</p>
                  <Checkbox
                    checked={isReturnMark}
                    onChange={handleReturnMarkChange}
                  />
                </div>
              )}
            </div>
          </div>

          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isDisabledBtn}
              sx={{ mt: 1, mr: 1 }}
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: "11px 20px 11px",
                margin: 0,
                borderRadius: "12px",
                width: "100%",
              }}
            >
              Редактировать запись
            </Button>
          </div>
        </React.Fragment>
      )}
    </main>
  )
}

export default EditJournal
