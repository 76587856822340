import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import Selector from "../LocalElements/Selector/reactSelect/Selector"
import { changeData } from "../../store/slices/views/cases"
import R from "../../services/request.service"

const Methods = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState(null)

  const measurementId = useSelector((state) => state.casesReducer.createData.measurementId)
  const methodId = useSelector((state) => state.casesReducer.createData.methodId)

  const options = data
    ? data.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = (selectedOption) => {
    if (selectedOption) {
      dispatch(
        changeData({
          value: "methodId",
          label: selectedOption.value,
        })
      )

      dispatch(
        changeData({
          value: "typeMethodId",
          label: "",
        })
      )

      dispatch(
        changeData({
          value: "methodPoints",
          label: [],
        })
      )
      dispatch(changeData({ value: "deviceIds", label: [] }))
    } else {
      dispatch(
        changeData({
          value: "methodId",
          label: "",
        })
      )

      dispatch(
        changeData({
          value: "typeMethodId",
          label: "",
        })
      )

      dispatch(
        changeData({
          value: "methodPoints",
          label: [],
        })
      )
      dispatch(changeData({ value: "deviceIds", label: [] }))
    }
  }

  useEffect(() => {
    if (measurementId) {
      (async () => {
        const { data: listMethod } = await R.getMethodItem(
          `?measurement_id=${measurementId}`
        )
        const method = listMethod.data
        setData(method)
      })()
    }
  }, [measurementId])

  return (
    <React.Fragment>
      <p
        style={{
          marginBottom: "9px",
          display: "flex",
          alignItems: "center",
        }}
      >
        Методика поверки{" "}
        {!methodId ? (
          <ErrorIcon
            sx={{
              color: "#FFD43B",
              marginLeft: "8px",
              marginTop: "-2px",
            }}
          />
        ) : (
          <CheckCircleIcon
            sx={{ color: "#63E6BE", marginLeft: "8px", marginTop: "-2px" }}
          />
        )}
      </p>
      <Selector
        placeholder={"Выберите методику поверки из списка"}
        isClearable={true}
        isSearchable={true}
        options={options}
        onChange={getValue}
        noOptionsMessage={() => "Выберите область измерения"}
        value={
          methodId ? options.find((option) => option.value === methodId) : null
        }
      />
    </React.Fragment>
  )
}

export default Methods
