import axios from "./axios-config.service"
import Cookies from "js-cookie"
class Request {
  #HEADERS_JSON = {
    Authorization: `Bearer ${Cookies.get("accessToken")}`,
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  }

  // ----------------------------------------------------------------
  // свойства с заголовками
  // ----------------------------------------------------------------
  #authUrl = `${process.env.REACT_APP_API_URL}/api/auth/login`
  // ----------------------------------------------------------------
  // авторизация
  // ----------------------------------------------------------------
  #addressUrl = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`
  // ----------------------------------------------------------------
  // получение списка адресов из dadata
  // ----------------------------------------------------------------
  #tariffsUrl = `${process.env.REACT_APP_API_URL}/api/tariffs`
  #subscriptionUrl = `${process.env.REACT_APP_API_URL}/api/subscriptions`
  #tariffsUrlExtra = `${process.env.REACT_APP_API_URL}/api/tariffs/services_rate`
  // ----------------------------------------------------------------
  // список тарифов из lanbilling
  // ----------------------------------------------------------------
  #billingUrlPostPayment = `${process.env.REACT_APP_API_URL}/api/billing`
  #billingUrlPrePayment = `${process.env.REACT_APP_API_URL}/api/billing/prepay`
  // ----------------------------------------------------------------
  // информация по биллингу
  // ----------------------------------------------------------------
  #companiesUrl = `${process.env.REACT_APP_API_URL}/api/companies`
  #companiesStatusUrl = (companyId) =>
    `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/activate`
  #companiesUrlList = `${process.env.REACT_APP_API_URL}/api/companies/list`
  #companiesUrlItem = (companyId) =>
    `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/edit`
  #companiesUrlEdit = (companyId) =>
    `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/update`
  // ----------------------------------------------------------------
  // получение списка компаний - с полной и компактной информацией
  // ----------------------------------------------------------------
  #casesUrl = `${process.env.REACT_APP_API_URL}/api/cases`
  #casesUrlItem = `${process.env.REACT_APP_API_URL}/api/cases/list`
  #casesUrlEdit = (caseId) =>
    `${process.env.REACT_APP_API_URL}/api/cases/${caseId}`
  #casesUrlPut = (caseId) =>
    `${process.env.REACT_APP_API_URL}/api/cases/${caseId}`

  #measurementUrlItem = `${process.env.REACT_APP_API_URL}/api/measurements/list`
  #methodUrlItem = `${process.env.REACT_APP_API_URL}/api/methods/list`
  #typeUrlItem = `${process.env.REACT_APP_API_URL}/api/verification-type/list`
  #pointUrlItem = `${process.env.REACT_APP_API_URL}/api/points/list`
  // ----------------------------------------------------------------
  #metersUrlList = `${process.env.REACT_APP_API_URL}/api/meters/list`
  #metersUrl = `${process.env.REACT_APP_API_URL}/api/meters`
  #metersUrlWithParams = (measurementId, searchQuery) => `
    ${process.env.REACT_APP_API_URL}/api/meters?measurement_id=${measurementId}&search=${searchQuery}
  `
  // ----------------------------------------------------------------
  #verificationsUrl = `${process.env.REACT_APP_API_URL}/api/journals/verifications-shedule`
  #verificationsUrlEdit = (journalId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/verifications-shedule/${journalId}`
  #verificationsUrlItem = (journalId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/verifications-shedule/${journalId}/edit`
  // ----------------------------------------------------------------
  #ticketsUrl = `${process.env.REACT_APP_API_URL}/api/journals/tickets`
  #ticketsUrlEdit = (journalId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/tickets/${journalId}`
  #ticketsUrlItem = (journalId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/tickets/${journalId}/edit`
  // ----------------------------------------------------------------
  #conditionsUrl = `${process.env.REACT_APP_API_URL}/api/journals/conditions-of-cases`
  #conditionsUrlEdit = (journalId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/conditions-of-cases/${journalId}`
  #conditionsUrlItem = (journalId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/conditions-of-cases/${journalId}/edit`
  // ----------------------------------------------------------------
  #complectUrl = `${process.env.REACT_APP_API_URL}/api/journals/issue-cases`
  #complectUrlEdit = (journalId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/issue-cases/${journalId}`
  #complectUrlItem = (journalId) =>
    `${process.env.REACT_APP_API_URL}/api/journals/issue-cases/${journalId}/edit`
  // ----------------------------------------------------------------
  #usersListUrl = `${process.env.REACT_APP_API_URL}/api/users/list`
  #userRegionsUrl = `${process.env.REACT_APP_API_URL}/api/protocols/create`
  #usersUrl = `${process.env.REACT_APP_API_URL}/api/users`
  #usersUrlItem = (userId) =>
    `${process.env.REACT_APP_API_URL}/api/users/${userId}`
  #usersTrustUrl = (userId) =>
    `${process.env.REACT_APP_API_URL}/api/users/${userId}/trust`
  #usersBanUrl = (userId) =>
    `${process.env.REACT_APP_API_URL}/api/users/${userId}/ban`
  // ----------------------------------------------------------------
  #etalonUrl = `${process.env.REACT_APP_API_URL}/api/kits`
  #etalonUrlList = `${process.env.REACT_APP_API_URL}/api/kits/list`
  #etalonUrlEdit = (etalonId) =>
    `${process.env.REACT_APP_API_URL}/api/kits/${etalonId}`
  #etalonUrlItem = (etalonId) =>
    `${process.env.REACT_APP_API_URL}/api/kits/${etalonId}`
  // ----------------------------------------------------------------
  #protocolsUrl = `${process.env.REACT_APP_API_URL}/api/protocols`
  #protocolsUrlEdit = (protocol_id) =>
    `${process.env.REACT_APP_API_URL}/api/protocols/${protocol_id}`
  #filesUrl = `${process.env.REACT_APP_API_URL}/api/files`
  #protocolsCheck = `${process.env.REACT_APP_API_URL}/api/protocols/sent`
  #protocolsArchive = `${process.env.REACT_APP_API_URL}/api/protocols/archive`
  #protocolsUnArchive = `${process.env.REACT_APP_API_URL}/api/protocols/unarchive`
  #protocolsPdf = `${process.env.REACT_APP_API_URL}/api/protocols/pdf`
  // ----------------------------------------------------------------
  #headerDataUrl = `${process.env.REACT_APP_API_URL}/api/profile/refresh`

  async auth(login, password, callback = () => {}) {
    const response = await axios
      .post(this.#authUrl, {
        login,
        password,
      })
      .catch((error) => {
        const { status, data } = error.response
        callback()

        return { status, data }
      })

    const { status, data } = response
    callback()

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async addresses(inputValue, locations) {
    const response = await axios
      .post(
        this.#addressUrl,
        locations
          ? {
              query: inputValue,
              count: 0,
              locations,
            }
          : {
              query: inputValue,
              count: 0,
            },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Token 4004411614eac159f915e4ae43989e1565b5ca1b`,
          },
        }
      )
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async subscription() {
    const response = await axios
      .get(this.#subscriptionUrl, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async subscriptionService(route) {
    const response = await axios
      .get(this.#subscriptionUrl + route, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async tariffs() {
    const response = await axios
      .get(this.#tariffsUrl, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async tariffSingle(route) {
    const response = await axios
      .get(this.#tariffsUrl + route, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async tariffChange(queryData, companyId) {
    const response = await axios
      .post(this.#tariffsUrl + "/" + companyId, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async tariffChangeActivate(queryData, companyId, param) {
    const response = await axios
      .patch(this.#companiesUrl + "/" + companyId + "/" + param, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getBillingPostPay(route) {
    const response = await axios
      .get(this.#billingUrlPostPayment + route, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getBillingPrePay(route) {
    const response = await axios
      .get(this.#billingUrlPrePayment + route, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getCompanies(param) {
    const response = await axios
      .get(this.#companiesUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async getCompaniesList() {
    const response = await axios
      .get(this.#companiesUrlList, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async addCompanies(queryData) {
    const response = await axios
      .post(this.#companiesUrl, queryData, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "multipart/form-data",
        },
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getCompaniesItem(companyId) {
    const response = await axios
      .get(this.#companiesUrlItem(companyId), {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async editCompanies(companyId, queryData) {
    const response = await axios
      .post(this.#companiesUrlEdit(companyId), queryData, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "multipart/form-data",
        },
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async changeStatus(companyId) {
    const response = await axios
      .patch(this.#companiesStatusUrl(companyId), {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getCases(param) {
    const response = await axios
      .get(this.#casesUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async getCasesItem(param) {
    const response = await axios
      .get(this.#casesUrlItem + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async getMeasurementItem() {
    const response = await axios
      .get(this.#measurementUrlItem, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async getMethodItem(param) {
    const response = await axios
      .get(this.#methodUrlItem + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async getTypeItem(param) {
    const response = await axios
      .get(this.#typeUrlItem + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async getPointItem(param) {
    const response = await axios
      .get(this.#pointUrlItem + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async getCasesShow(caseId) {
    const response = await axios
      .get(this.#casesUrlEdit(caseId), {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async addCases(queryData) {
    const response = await axios
      .post(this.#casesUrl, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async addCasesPut(caseId, queryData) {
    const response = await axios
      .put(this.#casesUrlPut(caseId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteCase(param) {
    const response = await axios
      .delete(this.#casesUrl + "/" + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getVerifications(param) {
    const response = await axios
      .get(this.#verificationsUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async addVerifications(queryData) {
    const response = await axios
      .post(this.#verificationsUrl, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getVerificationsItem(journalId) {
    const response = await axios
      .get(this.#verificationsUrlItem(journalId), {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async editVerifications(journalId, queryData) {
    const response = await axios
      .put(this.#verificationsUrlEdit(journalId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteVerifications(param) {
    const response = await axios
      .delete(this.#verificationsUrl + "/" + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getTickets(param) {
    const response = await axios
      .get(this.#ticketsUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async addTickets(queryData) {
    const response = await axios
      .post(this.#ticketsUrl, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getTicketsItem(journalId) {
    const response = await axios
      .get(this.#ticketsUrlItem(journalId), {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async editTickets(journalId, queryData) {
    const response = await axios
      .put(this.#ticketsUrlEdit(journalId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteTickets(param) {
    const response = await axios
      .delete(this.#ticketsUrl + "/" + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getConditions(param) {
    const response = await axios
      .get(this.#conditionsUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async addConditions(queryData) {
    const response = await axios
      .post(this.#conditionsUrl, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getConditionsItem(journalId) {
    const response = await axios
      .get(this.#conditionsUrlItem(journalId), {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async editConditions(journalId, queryData) {
    const response = await axios
      .put(this.#conditionsUrlEdit(journalId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteConditions(param) {
    const response = await axios
      .delete(this.#conditionsUrl + "/" + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getComplect(param) {
    const response = await axios
      .get(this.#complectUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async addComplect(queryData) {
    const response = await axios
      .post(this.#complectUrl, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getComplectItem(journalId) {
    const response = await axios
      .get(this.#complectUrlItem(journalId), {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async editComplect(journalId, queryData) {
    const response = await axios
      .put(this.#complectUrlEdit(journalId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteComplect(param) {
    const response = await axios
      .delete(this.#complectUrl + "/" + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getMetersList(param) {
    const response = await axios
      .get(this.#metersUrlList + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async getMetersListWithParams(measurementId, searchQuery) {
    const response = await axios
      .get(this.#metersUrlWithParams(measurementId, searchQuery), {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async getMeterItem(param, callback = () => {}) {
    const response = await axios
      .get(this.#metersUrl + "/" + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    response && callback()

    return { status, data }
  }

  async addMeter(measurementId, queryData) {
    const response = await axios
      .post(this.#metersUrl + "/" + measurementId, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async editMeter(measurementId, queryData, meterId) {
    const response = await axios
      .put(
        this.#metersUrl + "/" + measurementId + "/" + Number(meterId),
        queryData,
        {
          headers: this.#HEADERS_JSON,
        }
      )
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteMeters(measurementId, queryData) {
    const response = await axios
      .delete(
        this.#metersUrl + "/" + measurementId,
        { data: queryData },
        { headers: this.#HEADERS_JSON }
      )
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // возможно метод будет перенесен в статус deprecated
  // ----------------------------------------------------------------

  async getUserRegions() {
    const response = await axios
      .get(this.#userRegionsUrl, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getUsers(param) {
    const response = await axios
      .get(this.#usersUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async getUserList(param) {
    const response = await axios
      .get(this.#usersListUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async addUser(queryData) {
    const response = await axios
      .post(this.#usersUrl, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async addUserPut(userId, queryData) {
    const response = await axios
      .put(this.#usersUrlItem(userId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getUsersItem(userId) {
    const response = await axios
      .get(this.#usersUrlItem(userId), {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async changeUserTrust(userId, queryData) {
    const response = await axios
      .patch(this.#usersTrustUrl(userId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async changeUserBan(userId, queryData) {
    const response = await axios
      .patch(this.#usersBanUrl(userId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteUser(userId) {
    const response = await axios
      .delete(this.#usersUrlItem(userId), {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getEtalon(param) {
    const response = await axios
      .get(this.#etalonUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async getEtalonList(param) {
    const response = await axios
      .get(this.#etalonUrlList + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async getEtalonItem(etalonId) {
    const response = await axios
      .get(this.#etalonUrlItem(etalonId), {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async addEtalon(queryData) {
    const response = await axios
      .post(this.#etalonUrl, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async deleteEtalon(param) {
    const response = await axios
      .delete(this.#etalonUrl + "/" + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async editEtalon(etalonId, queryData) {
    const response = await axios
      .put(this.#etalonUrlEdit(etalonId), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getProtocol(param, callback = () => {}) {
    const response = await axios
      .get(this.#protocolsUrl + "/" + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    if (status !== "ошибка обращения к api") callback()

    return { status, data }
  }

  async editProtocol(protocol_id, queryData) {
    const response = await axios
      .put(this.#protocolsUrlEdit(protocol_id), queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async postProtocol(queryData) {
    const response = await axios
      .post(this.#protocolsUrl, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async getProtocolsList(param) {
    const response = await axios
      .get(this.#protocolsUrl + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }

  async deleteProtocol(param) {
    const response = await axios
      .delete(this.#protocolsUrl + "/" + param, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response

    return { status, data }
  }

  async addFiles(queryData) {
    const response = await axios
      .post(this.#filesUrl, queryData, {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "multipart/form-data",
        },
      })
      .catch((error) => {
        false && console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async protocolCheck(queryData) {
    const response = await axios
      .patch(this.#protocolsCheck, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async protocolArchive(queryData) {
    const response = await axios
      .patch(this.#protocolsArchive, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async protocolUnArchive(queryData) {
    const response = await axios
      .patch(this.#protocolsUnArchive, queryData, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.log(error)

        const { status, data } = error.response

        return { status, data }
      })

    const { status, data } = response

    return { status, data }
  }

  async protocolPdf(queryData) {
    try {
      const response = await axios.patch(this.#protocolsPdf, queryData, {
        headers: this.#HEADERS_JSON,
        responseType: "arraybuffer",
      })

      const { status, data } = response
      return { status, data }
    } catch (error) {
      console.log(error)
      const { status, data } = error.response || {
        status: 500,
        data: "Ошибка сервера",
      }
      return { status, data }
    }
  }

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  async getHeaderData() {
    const response = await axios
      .get(this.#headerDataUrl, {
        headers: this.#HEADERS_JSON,
      })
      .catch((error) => {
        console.error(error)
      })

    const { status, data } = response
      ? response
      : { status: "ошибка обращения к api", data: { data: null } }

    return { status, data }
  }
}

const R = new Request()
export default R
