import React from "react"

import NavBar from "../components/NavBar"
import SideBar from "../components/SideBar"
import Feedback from "../components/LocalElements/Feedback/Feedback"

import RoutesComponent from "../routes/RouteComponent"
import PositionedSnackbar from "../components/LocalElements/Snackbar/Snackbar"
import ModalComponent from "../components/LocalElements/Modal/Modal"
import FileViewerComponent from "../components/LocalElements/Modal/FileViewer"
import ImageViewerComponent from "../components/LocalElements/Modal/ImageViewer"

import ErrorBoundary from "../services/error-boundary.service"

const Home = () => {
  return (
    <React.Fragment>
      <div className="home">
        <div className="bar">
          <NavBar />
        </div>
        <div className="main_box" style={{ position: 'relative' }}>
          <Feedback />
          <SideBar />
          <ErrorBoundary key={new Date().getTime()}>
            <RoutesComponent />
          </ErrorBoundary>
          <PositionedSnackbar />
          <ModalComponent />
          <FileViewerComponent />
          <ImageViewerComponent />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home
