import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  dataVerifications: [],
  dataTickets: [],
  dataConditions: [],
  dataComplect: [],
  isLoadingVerifications: true,
  isLoadingTickets: true,
  isLoadingConditions: true,
  isLoadingComplect: true,
  selectedVerifications: null,
  metaVerifications: null | undefined,
  metaTickets: null | undefined,
  metaConditions: null | undefined,
  metaComplect: null | undefined,
  magazineId: "",
  dateFrom: "",
  dateUntil: "",
  expiredAt: "",
  createTickets: {
    meterNumber: "",
    customerName: "",
    nameSi: "",
    verificationDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    meterFactoryNumber: "",
    arshinName: "",
    arshinId: "",
    protocolId: "",
    desc: "",
  },
  createComplect: {
    casesId: null,
    issuer: null,
    receiver: null,
    isSueMark: false,
    isReturnMark: false,
    dateJournal: dayjs().format("YYYY-MM-DD HH:mm:ss"),
  },
  createConditions: {
    temp: (Math.floor(Math.random() * 9) + 21).toString(),
    humidity: (Math.floor(Math.random() * 7) + 43).toString(),
    pressure: (Math.floor(Math.random() * 3) + 99).toString(),
    conditionsDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
  },
  createKits: {
    meterNameSi: null,
    periodicity: "",
    verificationAddress: null,
    verificationMark: false,
    verificationDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    nextVerificationDate: "",
  },
};

export const journalReducer = createSlice({
  name: "journalReducer",
  initialState,
  reducers: {
    setDataVerifications: (state, action) => {
      state.dataVerifications = action.payload;
    },
    setMetaVerifications: (state, action) => {
      state.metaVerifications = action.payload;
    },
    setDataTickets: (state, action) => {
      state.dataTickets = action.payload;
    },
    setMetaTickets: (state, action) => {
      state.metaTickets = action.payload;
    },
    setDataConditions: (state, action) => {
      state.dataConditions = action.payload;
    },
    setMetaConditions: (state, action) => {
      state.metaConditions = action.payload;
    },
    setDataComplect: (state, action) => {
      state.dataComplect = action.payload;
    },
    setMetaComplect: (state, action) => {
      state.metaComplect = action.payload;
    },
    changeTickets: (state, action) => {
      state.createTickets[action.payload.value] = action.payload.label;
    },
    resetCreateTickets: (state) => {
      state.createTickets = initialState.createTickets;
    },
    changeComplect: (state, action) => {
      state.createComplect[action.payload.value] = action.payload.label;
    },
    resetCreateComplect: (state) => {
      state.createComplect = initialState.createComplect;
    },
    changeConditions: (state, action) => {
      state.createConditions[action.payload.value] = action.payload.label;
    },
    resetCreateConditions: (state) => {
      state.createConditions = initialState.createConditions;
    },
    changeKits: (state, action) => {
      state.createKits[action.payload.value] = action.payload.label;
    },
    resetCreateKits: (state) => {
      state.createKits = initialState.createKits;
    },
    setIsLoadingVerifications: (state, action) => {
      state.isLoadingVerifications = action.payload;
    },
    setIsLoadingTickets: (state, action) => {
      state.isLoadingTickets = action.payload;
    },
    setIsLoadingConditions: (state, action) => {
      state.isLoadingConditions = action.payload;
    },
    setIsLoadingComplect: (state, action) => {
      state.isLoadingComplect = action.payload;
    },
    setMagazineId: (state, action) => {
      state.magazineId = action.payload;
    },

    setDateFrom: (state, action) => {
      state.dateFrom = action.payload;
    },
    setDateUntil: (state, action) => {
      state.dateUntil = action.payload;
    },
  },
});

export const {
  changeTickets,
  changeComplect,
  changeConditions,
  changeKits,
  setDataVerifications,
  setMetaVerifications,
  setDataTickets,
  setMetaTickets,
  setDataConditions,
  setMetaConditions,
  setDataComplect,
  setMetaComplect,
  resetCreateKits,
  resetCreateConditions,
  resetCreateTickets,
  resetCreateComplect,
  setIsLoadingVerifications,
  setIsLoadingTickets,
  setIsLoadingConditions,
  setIsLoadingComplect,
  setMagazineId,
  setDateFrom,
  setDateUntil,
} = journalReducer.actions;
export default journalReducer.reducer;
