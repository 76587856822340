import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import { useSelector, useDispatch } from 'react-redux'
import { setOpen } from '../../../store/slices/comps/snackbar'

const PositionedSnackbar = () => {
  const dispatch = useDispatch()

  const open = useSelector(state => state.snackbarReducer.open)
  const vertical = useSelector(state => state.snackbarReducer.vertical)
  const horizontal = useSelector(state => state.snackbarReducer.horizontal)
  const message = useSelector(state => state.snackbarReducer.message)
  const type = useSelector(state => state.snackbarReducer.type)

  const handleClose = () => dispatch(setOpen(false))

  return (
    <Snackbar
      autoHideDuration={6000}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={type ?? "success"}
        variant="filled"
      >
        { message && message }
      </Alert>
    </Snackbar>
  )
}

export default PositionedSnackbar