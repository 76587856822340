import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyId: "",
};

export const companyIdReducer = createSlice({
  name: "companyIdReducer",
  initialState,
  reducers: {
    setCompanyId: (state, action) => {
      state.companyId = action.payload;
    },
  },
});

export const { setCompanyId } = companyIdReducer.actions;
export default companyIdReducer.reducer;
