import React from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"

import D from "../../../store/localStorage/dispatcher"
import R from "../../../services/request.service"
import Table from "../../LocalElements/Table/Table"
import Skeleton from "../../LocalElements/Skeleton/Skeleton"
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
} from "../../../store/slices/comps/modal"

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/comps/snackbar"
import { refreshComplects } from "../../../store/slices/controlers/updater"

const TableComplect = () => {
  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector((state) => state.headerReducer.role)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isLoading = useSelector(
    (state) => state.journalReducer.isLoadingComplect
  )
  const data = useSelector((state) => state.journalReducer.dataComplect)

  const handleEditClick = (param) => {
    navigate(`/metriva/journals/${param.row.id}/edit_complect`)
  }

  const handleDelete = async (journalId) => {
    const { status } = await R.deleteComplect(journalId)

    if (status === 200) {
      dispatch(refreshComplects())
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Запись удалена"))
      dispatch(setOpen(false))
    } else {
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Ошибка обращения к API."))
    }
  }

  let colData = [
    {
      field: "innerName",
      headerName: "№ комплекта СИ",
      width: 170,
    },
    {
      field: "issuerName",
      headerName: "Выдавший (должность, Ф.И.О.)",
      width: 350,
    },
    {
      field: "receiverName",
      headerName: "Принявший (должность, Ф.И.О.)",
      width: 350,
    },
    { field: "issueAt", headerName: "Дата выдачи", width: 150 },
    {
      field: "issueMark",
      headerName: "Отметка выдачи",
      width: 150,
      renderCell: (params) =>
        params.value ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexFlow: "row",
              justifyContent: "center",
              gap: "14px",
            }}
          >
            <i
              className="fas fa-check-circle"
              style={{ color: "#22c58b", fontSize: "19px" }}
            />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexFlow: "row",
              justifyContent: "center",
              gap: "14px",
            }}
          >
            <i
              className="fas fa-times-circle"
              style={{ color: "#ff5963", fontSize: "19px" }}
            />
          </div>
        ),
    },
    {
      field: "returnMark",
      headerName: "Отметка возврата",
      width: 190,
      renderCell: (params) =>
        params.value ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexFlow: "row",
              justifyContent: "center",
              gap: "14px",
            }}
          >
            <i
              className="fas fa-check-circle"
              style={{ color: "#22c58b", fontSize: "19px" }}
            />
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexFlow: "row",
              justifyContent: "center",
              gap: "14px",
            }}
          >
            <i
              className="fas fa-times-circle"
              style={{ color: "#ff5963", fontSize: "19px" }}
            />
          </div>
        ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Удалить",
      width: 130,
      getActions: (item) => {
        const localItem = item

        return [
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-around",
              gap: "14px",
            }}
          >
            <span
              onClick={() => {
                dispatch(setOpen(true))
                dispatch(setTitle("Вы действительно хотите удалить запись?"))
                dispatch(
                  setChildren("Отменить действие будет уже невозможно.")
                )
                dispatch(setConfirmAction(() => handleDelete(localItem.id)))
              }}
              style={{
                width: "5%",
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-around",
              }}
            >
              <span
                className="fas fa-trash icon"
                style={{
                  fontSize: "15px",
                }}
              />
            </span>
          </div>,
        ]
      },
    },
  ]

  if (userRole === "super") {
    colData.splice(6, 0, {
      field: "company",
      headerName: "Название компании",
      width: 220,
    })
  }

  return (
    <React.Fragment>
      { isLoading ? (
        <Skeleton />
      ) : (
        <Table
          css={{
            marginTop: "12px",
            backgroundColor: "#ffffff",
            border: "1px solid #d7ddea",
            "& .MuiDataGrid-cell:hover": {
              cursor: "pointer",
            },
          }}
          colData={colData}
          rowData={data.map((item) => ({
            id: item.id,
            innerName: item.case.inner_name,
            issuerName: item.issuer_name,
            receiverName: item.receiver_name,
            issueAt: moment(item.issue_at).format("YYYY-MM-DD"),
            issueMark: item.issue_mark,
            returnMark: item.return_mark,
            company: item.company?.name,
          }))}
          rowCallback={handleEditClick}
          hasCheckboxSelection={false}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      )}
    </React.Fragment>
  )
}

export default TableComplect
