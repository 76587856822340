import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  title: ""
}

const pageNameReducer = createSlice({
  name: "pageNameReducer",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.title = action.payload
    }
  }
})

export const { setName } = pageNameReducer.actions
export default pageNameReducer.reducer