import { twoColumnsTable, threeColumnsTable, noOpticalTable, opticalTable } from "./templates.ts"
import { IQdata, IQdataOptical } from "./interfaces.ts"
class LayoutBuilder {
  type: "gas" | "water"
  company: string
  number: string
  address: string

  #firstColumnDefaultData: Array<string> = [
    "Вид поверки",
    "Наименование, модификации и тип в соответствии с описанием",
    "Год изготовления",
    "Номер прибора в государственном реестре",
    "Заводской номер",
    "Заказчик",
    "Место проведения поверки",
    "Диаметр условного прохода, мм",
    "Минимальный расход по описанию типа, м³/ч",
    "Переходный расход по описанию типа, м³/ч",
    "Наибольший расход по описанию типа, м³/ч",
    "Пределы погрешности от Qmin до Qt, %",
    "Пределы погрешности от Qt до Qmax, %",
    "Наименование методики поверки",
  ]

  constructor(
    type: "gas" | "water",
    company: string,
    number: string,
    address: string
  ) {
    this.type = type
    this.company = company
    this.number = number
    this.address = address
  }

  renderCompanyName(): string {
    return this.company ? `
      <h4 style="width: 100%; text-align: center;">${this.company}</h4>
    ` : ''
  }
  renderCompanyNumber(): string {
    return this.company ? `
      <h4 style="width: 100%; text-align: center;">Уникальный номер записи об аккредитации в реестре аккредитованных лиц № ${this.number}</h4>
    ` : ''
  }
  renderCompanyAddress(): string {
    return this.company ? `
      <h4 style="width: 100%; text-align: center;">Адрес компании - ${this.address}</h4>
    ` : ''
  }

  renderContentTitle(param: string): string {
    return `
      <h5 style="width: 100%; text-align: left; font-weight: 500; margin-bottom: 4px; background-color: #e5f3fc80;">${ param }</h5>
    `
  }
  renderContentText(param: string): string {
    return `
      <span style="width: 90%; text-align: left; line-height: 22px; font-size: 14px; margin-bottom: 12px;">${ param }</span>
    `
  }

  renderTwoColumnsTable(
    title: string,
    column1: Array<string> | null,
    column2: Array<string>
  ): string {
    let layout: string = ''

    if ( column1 === null ) {
      const column1Layout = this.#firstColumnDefaultData
        .map((item: string): string => 
          `<span 
            style="
              border-bottom: 1px solid rgba(217, 219, 228, 0.6);
              border-right: 1px solid rgba(217, 219, 228, 0.6);
              width: 100%; 
              text-align: center;
              position: relative;
              line-height: 33px;
              height: 33px;
              font-size: 14px;
            "
          >${item}</span>`)
        .join("")
      const column2Layout = column2
        .map((item: string): string => 
          `<span 
            style="
              border-bottom: 1px solid rgba(217, 219, 228, 0.6); 
              width: 100%; 
              text-align: center;
              position: relative;
              line-height: 33px;
              height: 33px;
              font-size: 14px;
              font-weight: 500;
            "
          >${item}</span>`)
        .join("")

      layout = twoColumnsTable(title, [column1Layout, column2Layout])
    }

    return layout
  }

  renderThreeColumnsTable(
    column1: Array<string>,
    column2: Array<string>,
    column3: Array<string>
  ): string {
    let layout: string = ''

    const column1Layout = column1
      .map((item: string): string => 
        `<span 
          style="
            border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            border-right: 1px solid rgba(217, 219, 228, 0.6);
            width: 100%; 
            text-align: center;
            position: relative;
            line-height: 33px;
            height: 33px;
            font-size: 14px;
          "
        >${item}</span>`)
      .join("")
    const column2Layout = column2
      .map((item: string): string => 
        `<span 
          style="
            border-bottom: 1px solid rgba(217, 219, 228, 0.6);
            border-right: 1px solid rgba(217, 219, 228, 0.6);
            width: 100%; 
            text-align: center;
            position: relative;
            line-height: 33px;
            height: 33px;
            font-size: 14px;
            font-weight: 500;
          "
        >${item}</span>`)
      .join("")
    const column3Layout = column3
      .map((item: string): string => 
        `<span 
          style="
            border-bottom: 1px solid rgba(217, 219, 228, 0.6); 
            width: 100%; 
            text-align: center;
            position: relative;
            line-height: 33px;
            height: 33px;
            font-size: 14px;
            font-weight: 500;
          "
        >${item}</span>`)
      .join("")

    layout = threeColumnsTable([ column1Layout, column2Layout, column3Layout ])

    return layout
  }

  renderOpticalColumnsTable(params: IQdataOptical): string {
    let layout: string = opticalTable(params)
  
    return layout
  }

  renderNoOpticalColumnsTable(params: IQdata): string {
    let layout: string = noOpticalTable(params)

    return layout
  }
}

export default LayoutBuilder
