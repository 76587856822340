import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Button from "@mui/material/Button"

import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import Input from "../../LocalElements/Input/InputMUI"
import DatePicker from "../../LocalElements/DatePicker/DatePicker"
import D from "../../../store/localStorage/dispatcher"
import R from "../../../services/request.service"

import {
  changeConditions,
  resetCreateConditions,
} from "../../../store/slices/views/journals"
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/comps/snackbar"
import { refreshConditions } from "../../../store/slices/controlers/updater"

import SelectCompany from "../Select/SelectCompany"

const AddJournalConditions = () => {
  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector((state) => state.headerReducer.role)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)
  const [validationErrors, setValidationErrors] = useState({})
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const [companyId, setCompanyId] = useState(null)

  const temp = useSelector((state) => state.journalReducer.createConditions.temp)

  const handleTempChange = (param) => {
    dispatch(changeConditions({ value: "temp", label: param }))
  }

  const humidity = useSelector((state) => state.journalReducer.createConditions.humidity)

  const handleHumidityChange = (param) => {
    dispatch(changeConditions({ value: "humidity", label: param }))
  }

  const pressure = useSelector((state) => state.journalReducer.createConditions.pressure)

  const handlePressureChange = (param) => {
    dispatch(changeConditions({ value: "pressure", label: param }))
  }

  const conditionsDate = useSelector((state) => state.journalReducer.createConditions.conditionsDate)

  const handleConditionsDateChange = (date) => {
    if (date) {
      dispatch(
        changeConditions({
          value: "conditionsDate",
          label: date.format("YYYY-MM-DD HH:mm:ss"),
        })
      )
    }
  }

  const handleSave = async () => {
    setIsDisabledBtn(true)
    const data = {
      condition_date: conditionsDate,
      temperature_environment: temp,
      relative_humidity: humidity,
      atmospheric_pressure: pressure,
    }

    if (userRole === "super") {
      data.company_id = companyId
    }

    const { status, data: conditionsAddData } = await R.addConditions(data)

    if (status === 201) {
      dispatch(setMessage("Запись успешно создана"))
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(resetCreateConditions())
      dispatch(refreshConditions())
      navigate("/metriva/journals")
    } else {
      if (conditionsAddData.errors) {
        const errors = conditionsAddData.errors
        setValidationErrors(errors)
      }
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Запись не добавлена - ошибка валидации"))
      setIsDisabledBtn(false)
    }
  }

  const navigateToProtocols = () => {
    navigate("/metriva/journals")
  }

  return (
    <main className={isToggle ? "main_open" : "main"}>
      <div className="path_to_back" onClick={navigateToProtocols}>
        <i className="fas fa-arrow-left icon_back" style={{ width: '30px', height: '30px', marginRight: '6px' }} />
        <p>Все журналы</p>
      </div>

      <div className="path_to_edit">
        <h4>Журнал "Регистрации условий хранения средств поверки"</h4>
      </div>
      <div className="box">
        {userRole === "super" && (
          <div className="width32Percent" style={{ marginBottom: "15px" }}>
            <h2 style={{ marginBottom: "15px" }}>Компания</h2>
            <SelectCompany companyId={companyId} setCompanyId={setCompanyId} />
            {validationErrors["company_id"] && (
              <div className="error">{validationErrors["company_id"]}</div>
            )}
          </div>
        )}

        <h2 style={{ marginBottom: "20px" }}>Информация о журнале</h2>
        <div className="flexContainerWithGap" style={{ marginBottom: "15px" }}>
          <div className="width32Percent">
            <Input
              label={"Температура, ˚С"}
              type={"text"}
              placeholder={"20"}
              value={temp || ""}
              actions={{
                change: handleTempChange,
              }}
            />
            {validationErrors["atmospheric_pressure"] && (
              <div className="error">
                {validationErrors["atmospheric_pressure"]}
              </div>
            )}
          </div>

          <div className="width32Percent">
            <Input
              label={"Влажность, %"}
              type={"text"}
              placeholder={"50"}
              value={humidity || ""}
              actions={{
                change: handleHumidityChange,
              }}
            />
            {validationErrors["temperature_environment"] && (
              <div className="error">
                {validationErrors["temperature_environment"]}
              </div>
            )}
          </div>

          <div className="width32Percent">
            <Input
              label={"Давление, кПа"}
              type={"text"}
              placeholder={"90"}
              value={pressure || ""}
              actions={{
                change: handlePressureChange,
              }}
            />

            {validationErrors["relative_humidity"] && (
              <div className="error">
                {validationErrors["relative_humidity"]}
              </div>
            )}
          </div>
        </div>

        <div className="flexContainerWithGap">
          <div className="width32Percent">
            <p
              style={{
                marginBottom: "9px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Дата{" "}
              {!conditionsDate ? (
                <ErrorIcon
                  sx={{
                    color: "#FFD43B",
                    marginLeft: "8px",
                    marginTop: "-2px",
                  }}
                />
              ) : (
                <CheckCircleIcon
                  sx={{
                    color: "#63E6BE",
                    marginLeft: "8px",
                    marginTop: "-2px",
                  }}
                />
              )}
            </p>
            <DatePicker
              selectedDate={conditionsDate}
              dateChange={handleConditionsDateChange}
            />

            {validationErrors["condition_date"] && (
              <div className="error">{validationErrors["condition_date"]}</div>
            )}
          </div>
        </div>
      </div>

      <div style={{ marginTop: "10px" }}>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={isDisabledBtn}
          sx={{ mt: 1, mr: 1 }}
          style={{
            textTransform: "none",
            fontSize: "15px",
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: "11px 20px 11px",
            margin: 0,
            borderRadius: "12px",
            width: "100%",
          }}
        >
          Сохранить запись
        </Button>
      </div>
    </main>
  )
}

export default AddJournalConditions
