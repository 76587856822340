import React from "react";

const Input = ({
  type,
  pattern,
  className,
  placeholder,
  length,
  style,
  value,
  checked,
  actions,
  autoComplete,
  isDisabled = false
}) => {
  return (
    <input
      type={type}
      pattern={pattern}
      placeholder={placeholder}
      maxLength={length}
      style={style}
      autoComplete={autoComplete}
      value={value}
      checked={checked}
      className={className}
      disabled={isDisabled}
      onChange={(event) => {
        if (type === "checkbox") {
          actions["change"] && actions["change"](event.target.checked);
        } else {
          actions["change"] && actions["change"](event.target.value);
        }
      }}
      onKeyUp={(event) => {
        actions["keyup"] && actions["keyup"](event);
      }}
    />
  );
};

export default Input;
