import { createSlice } from "@reduxjs/toolkit"
import { createGasMeasurement } from "../../store.gasInterface"

const initialState = createGasMeasurement

const gost83242002Reducer = createSlice({
  name: "gost83242002Reducer",
  initialState,
  reducers: {
    changeProperty: (state, action) => {
      state[action.payload.value] = action.payload.label
    },
    setDefaultState: (state) => {
      state = initialState
    },
  },
})

export const { changeProperty, setDefaultState } = gost83242002Reducer.actions
export default gost83242002Reducer.reducer
