// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import EditPassword from "../pages/EditPassword";

import NewProtocol from "../pages/Protocols/NewProtocol";
import ProtocolsIndex from "../pages/Protocols/ProtocolsIndex";
import EditProtocol from "../pages/Protocols/EditProtocol";
import CopyProtocol from "../pages/Protocols/CopyProtocol";
import Show from "../pages/Protocols/Show";

import Users from "../pages/Users/Users";
import AddUsers from "../pages/Users/AddUsers";
import EditUsers from "../pages/Users/EditUsers";
import ShowUsers from "../pages/Users/ShowUsers";

import Meters from "../pages/Meters/Meters";
import AddMetersWater from "../pages/Meters/water/AddMeters";
import AddMetersGas from "../pages/Meters/gas/AddMetersGas";
import MeterShow from "../pages/Meters/MeterShow";
import EditMetersWater from "../pages/Meters/water/EditMeters";
import EditMetersGas from "../pages/Meters/gas/EditMetersGas";

import Cases from "../pages/Cases/Cases";
import AddCases from "../pages/Cases/AddCases";
import ShowCases from "../pages/Cases/ShowCases";
import EditCases from "../pages/Cases/EditCases";

import Company from "../pages/Сompanies/Сompanies";
import AddCompany from "../pages/Сompanies/AddСompanies";
import ShowCompany from "../pages/Сompanies/ShowСompanies";

import CompanyShow from "../pages/Company/CompanyShow";
import CompanyEdit from "../pages/Company/CompanyEdit";

import Magazines from "../pages/Magazines/MagazinesIndex";
import AddJournalComplect from "../components/Journals/JournalComplect/AddJournalComplect";
import EditJournalComplect from "../components/Journals/JournalComplect/EditJournalComplect";

import AddJournalVerification from "../components/Journals/JournalVerificationSchedule/AddJournalVerification";
import EditJournalVerification from "../components/Journals/JournalVerificationSchedule/EditJournalVerification";

import AddJournalConditions from "../components/Journals/JournalConditionsCases/AddJournalConditions";
import EditJournalConditions from "../components/Journals/JournalConditionsCases/EditJournalConditions";

import AddJournalTickets from "../components/Journals/JournalTickets/AddJournalTickets";
import EditJournalTickets from "../components/Journals/JournalTickets/EditJournalTickets";

import Etalon from "../pages/Etalon/IndexEtalon";
import AddEtalon from "../pages/Etalon/AddEtalon";
import EditEtalon from "../pages/Etalon/EditEtalon";

import NotForMobile from "../pages/NotForMobile";

import useWindowDimensions from "../hooks/useWindowDimensions";

const RoutesComponent = () => {
  const { width } = useWindowDimensions();

  const userRole = useSelector((state) => state.headerReducer.role);

  return (
    <Routes>
      <Route path="protocol" element={<NewProtocol />} />

      <Route
        path="protocols"
        element={width > 100 ? <ProtocolsIndex /> : <NotForMobile />}
      />
      <Route
        path="protocols/:protocol_id"
        element={width > 1050 ? <Show /> : <NotForMobile />}
      />
      <Route
        path="protocols/:protocol_id/copy"
        element={width > 100 ? <CopyProtocol /> : <NotForMobile />}
      />
      <Route
        path="protocols/:protocol_id/edit"
        element={width > 100 ? <EditProtocol /> : <NotForMobile />}
      />

      <Route
        path="users"
        element={width > 1050 ? <Users /> : <NotForMobile />}
      />
      <Route
        path="users/create"
        element={width > 1050 ? <AddUsers /> : <NotForMobile />}
      />
      <Route path="users/:users_id/edit" element={<EditUsers />} />
      <Route path="users/:users_id" element={<ShowUsers />} />

      <Route path="meters" element={<Meters />} />
      <Route path="meters/create_water_base" element={<AddMetersWater />} />
      <Route path="meters/create_gas_base" element={<AddMetersGas />} />
      <Route path="meters/:meters_id" element={<MeterShow />} />
      <Route
        path="meters/:meters_id/edit_water"
        element={<EditMetersWater />}
      />
      <Route path="meters/:meters_id/edit_gas" element={<EditMetersGas />} />

      <Route
        path="cases"
        element={width > 100 ? <Cases /> : <NotForMobile />}
      />
      <Route path="cases/create" element={<AddCases />} />
      <Route path="cases/:cases_id" element={<ShowCases />} />
      <Route path="cases/:cases_id/edit" element={<EditCases />} />

      <Route
        path="companies"
        element={width > 1050 ? <Company /> : <NotForMobile />}
      />
      <Route path="companies/create" element={<AddCompany />} />
      <Route path="companies/:company_id" element={<ShowCompany />} />

      <Route path="company" element={<CompanyShow />} />
      <Route path="company/:companyAdminId/edit" element={<CompanyEdit />} />

      <Route path="edit_password" element={<EditPassword />} />

      <Route
        path="journals"
        element={width > 1050 ? <Magazines /> : <NotForMobile />}
      />
      <Route
        path="journals/create_line_journal_complect"
        element={<AddJournalComplect />}
      />
      <Route
        path="journals/:journal_id/edit_complect"
        element={<EditJournalComplect />}
      />
      <Route
        path="journals/create_line_verification_schedule"
        element={<AddJournalVerification />}
      />
      <Route
        path="journals/:journal_id/edit_verification"
        element={<EditJournalVerification />}
      />

      <Route
        path="journals/create_line_conditions_case"
        element={<AddJournalConditions />}
      />
      <Route
        path="journals/:journal_id/edit_conditions"
        element={<EditJournalConditions />}
      />

      <Route
        path="journals/create_line_tickets"
        element={<AddJournalTickets />}
      />
      <Route
        path="journals/:journal_id/edit_tickets"
        element={<EditJournalTickets />}
      />

      <Route
        path="etalon"
        element={width > 1050 ? <Etalon /> : <NotForMobile />}
      />
      <Route path="etalon/create" element={<AddEtalon />} />
      <Route path="etalon/:etalon_id/edit" element={<EditEtalon />} />
    </Routes>
  );
};

export default RoutesComponent;
