// ----------------------------------------------------------------
/* eslint-disable jsx-a11y/anchor-has-content */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable react/jsx-no-target-blank */
// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import api from "../../../services/axios-config.service"

import Table from "../../LocalElements/Table/Table"
import TableMobile from "../../LocalElements/Table/TableMobile.tsx"

import Tooltip from "@mui/material/Tooltip"
import { Skeleton } from "@mui/material"
import D from "../../../store/localStorage/dispatcher"
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
} from "../../../store/slices/comps/modal.js"
import { setSelectedMeter, 
  setSelectedMeters,
  changeMeterWater, 
  clearMeterWaterMods, 
  changeMeterWaterOptions,
  clearMeterWaterOptions,
  changeMeterWaterInitial
} from "../../../store/slices/views/meters.js"
import { settingsFileViewer } from "../../../store/slices/comps/modal.js"

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/comps/snackbar.js"
import { setShow } from "../../../store/slices/comps/feedback.js"
import { setName } from "../../../store/slices/controlers/pageName.js"

import R from "../../../services/request.service.js"
import useWindowDimensions from "../../../hooks/useWindowDimensions.js"
import PdfDownloadButton from "../../../services/pdf-meters.service.js"

const Tab = ({ onDelete }) => {
  const token = useSelector((state) => state.authReducer.authData.token)
  const { width } = useWindowDimensions()

  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector((state) => state.headerReducer.role)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const data = useSelector((state) => state.meterReducer.metersList)

  const idMeasurement = useSelector((state) => state.meterReducer.measurement_id)
  const variants = useSelector((state) => state.meterReducer.selectedMeter.variants)
  const selectedVariant = useSelector((state) => state.meterReducer.selectedMeter.selectedModification)

  const methods = useSelector((state) => state.meterReducer.filterParams.selectedMethods)
  const customers = useSelector((state) => state.meterReducer.filterParams.selectedCustomers)
  const titles = useSelector((state) => state.meterReducer.filterParams.selectedTitles)
  const linkSI = useSelector((state) => state.meterReducer.filterParams.isLinkSI)
  const pdf = useSelector((state) => state.meterReducer.filterParams.isPDF)

  const handleShowClickReview = (param) => {
    navigate(`/metriva/meters/${param.row.id}`)
  }
  
  const handleShowClick = (param) => {
    dispatch(setShow(true))

    const go = () => {
      navigate(`/metriva/meters/${param.row.id}`)
      dispatch(setShow(false))
    }

    ( async () => {
      const { data } = await R.getMeterItem(
        `${idMeasurement}/${param.row.id}`,
        go
      )

      data.data &&
        dispatch(
          setSelectedMeter({
            value: "manufacturer",
            label: data.data.manufacturer.name,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "city",
            label: data.data.manufacturer_cities,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "country",
            label: data.data.manufacturer_countries,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "numberRegistry",
            label: data.data.fif_number,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "nameSi",
            label: data.data.name_si,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "typeSi",
            label: data.data.type_si,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "link",
            label: data.data.type_link,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "pdf",
            label: data.data.pdf,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "image",
            label: data.data.images 
              ? data.data.images 
              : [],
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "variants",
            label: data.data.modifications,
          })
        )
    })()
  }

  const handleShowClickMobile = (param) => {
    const go = () => {
      navigate(`/metriva/meters/${param}`)
    }

    ( async () => {
      const { data } = await R.getMeterItem(
        `${idMeasurement}/${param}`,
        go
      )

      data.data &&
        dispatch(
          setSelectedMeter({
            value: "manufacturer",
            label: data.data.manufacturer.name,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "city",
            label: data.data.manufacturer_cities,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "country",
            label: data.data.manufacturer_countries,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "numberRegistry",
            label: data.data.fif_number,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "nameSi",
            label: data.data.name_si,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "typeSi",
            label: data.data.type_si,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "link",
            label: data.data.type_link,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "pdf",
            label: data.data.pdf,
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "image",
            label: data.data.images 
              ? data.data.images 
              : [],
          })
        )
      data.data &&
        dispatch(
          setSelectedMeter({
            value: "variants",
            label: data.data.modifications,
          })
        )
    })()
  }

  const handleEditClick = (param) => {
    dispatch(setShow(true))

    const go = () => {
      navigate(`/metriva/meters/${param}/edit_water`)
      dispatch(setShow(false))
    }

    ( async () => {
      const { data } = await R.getMeterItem(
        `${idMeasurement}/${param}`,
        go
      )

      // ----------------------------------------------------------------
      // техдолг - объединить все в initial после обновления прода
      // ----------------------------------------------------------------

      data.data && dispatch(changeMeterWaterInitial({
        meterIndex: data.data.id,
        checkMethod: data.data.methods.map(m => m.id),
        numberRegistry: data.data.fif_number,
        number: "",
        nameSi: data.data.name_si,
        typeSi: data.data.type_si,
        country: data.data.manufacturer_countries,
        cityFactory: data.data.manufacturer_cities,
        manufacturerFactory: data.data.manufacturer.name,
        mpiText: "",
        hot: data.data.mpi_hot ? data.data.mpi_hot : "",
        cold: data.data.mpi_cold ? data.data.mpi_cold : "",
        diameter: [],
        qLimitMinCold: 
          data.data.q_min_limit_cold 
            ? data.data.q_min_limit_cold
            : "",
        qLimitMaxCold: 
          data.data.q_max_limit_cold 
            ? data.data.q_max_limit_cold
            : "",
        qLimitMinHot: 
          data.data.q_min_limit_hot 
            ? data.data.q_min_limit_hot
            : "",
        qLimitMaxHot: 
          data.data.q_max_limit_hot 
            ? data.data.q_max_limit_hot
            : "",
        fgisLink: data.data.type_link,
        pdf: null,
        imgs: data.data.images,
        options: data.data.additionals,
        mods: data.data.modifications        ,
        mod: {
          title: "",
          diameter: "",
          koeff: "",
          water: null,
          vPositionQmin: "",
          vPositionQt: "",
          vPositionQmax: "",
          hPositionQmin: "",
          hPositionQt: "",
          hPositionQmax: "",
          additionals: []
        }
      }))

    })()
  }

  const handleDelete = async (meterId) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/meters/${idMeasurement}/${meterId}`
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      }
      const response = await api.delete(apiUrl, { headers })
      if (response.data && response.data.message) {
        dispatch(setMessage(response.data.message))
      }
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))

      dispatch(setOpen(false))
      onDelete()
    } catch (error) {
      console.error(error)
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Ошибка на сервере."))
    }
  }

  let colData = width > 1050 ? [
    {
      field: "numberRegistry",
      headerName: "Номер в реестре ФИФ",
      width: 200
    },
    {
      field: "nameSi",
      headerName: "Наименование",
      width: 500,
    },
    {
      field: "manufacturerName",
      headerName: "Завод производитель",
      width: 600,
    },
    {
      field: "typeLink",
      headerName: "Ссылка на прибор",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <span 
            style={{ color: 'red' }}
            onClick={event => {
              event.stopPropagation()

              false && console.log(params.row)
            }}
          >
            <a 
              href={params.row.typeLink}
              target="_blank"
              style={{ color: "#0093FD" }}
            >
              Ссылка на ФГИС Аршин
            </a>
          </span>
        )
      }
    },
  ] : [
    {
      field: "numberRegistry",
      headerName: "Номер в реестре ФИФ",
      width: 200
    },
    {
      field: "nameSi",
      headerName: "Наименование",
      width: 500
    },
    {
      field: "manufacturerName",
      headerName: "Завод производитель",
      width: 600
    },
    {
      field: "typeLink",
      headerName: "Ссылка на прибор",
      width: 200,
      renderCell: (params) => {
        return (
          <span>
            <a 
              href={params.row.typeLink}
              target="_blank"
              style={{ color: "#0093FD" }}
            >
              Ссылка на ФГИС Аршин
            </a>
          </span>
        )
      }
    },
  ]

  if ( width > 1050 ) {
    colData.splice(8, 0, {
      field: "actions",
      type: "actions",
      headerName: "Действия",
      width: 230,
      getActions: (item) => {
        const localItem = item

        return [
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-between",
              gap: "22px",
            }}
          >
            {( userRole === "super" && width > 1050 ) && <Tooltip title="Редактировать" placement="top">
              <span
                onClick={() => handleEditClick(localItem.id)}
                style={{
                  width: "5%",
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-around",
                }}
              >
                <span
                  className="fas fa-pencil-alt icon"
                  style={{
                    fontSize: "15px",
                  }}
                />
              </span>
            </Tooltip> }
            { false && <span
              onClick={() => {
                dispatch(setOpen(true))
                dispatch(setTitle("Вы действительно хотите удалить прибор?"))
                dispatch(
                  setChildren("Отменить действие будет уже невозможно.")
                )
                dispatch(setConfirmAction(() => handleDelete(localItem.id)))
              }}
              style={{
                width: "5%",
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-around",
              }}
            >
              <span
                className="fas fa-trash icon"
                style={{
                  fontSize: "15px",
                }}
              />
            </span> }
            <Tooltip title="Скачать PDF с описанием типа" placement="top">
              <span
                style={{
                  width: "5%",
                  textAlign: "center",
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-around",
                }}
              >
                <PdfDownloadButton
                  measurementId={idMeasurement} 
                  meterId={localItem.id} 
                  title={localItem.row.numberRegistry}
                />
              </span>
            </Tooltip>
          </div>,
        ]
      },
    })
  }

  useEffect(() => { false && console.log(data) }, [ data ])
  useEffect(() => { dispatch(setName("База приборов")) }, []) 
  useEffect(() => { dispatch(settingsFileViewer({ value: "open", label: false })) }, [])

  return (
    <React.Fragment>
      {( !data || data.length === 0 ) && (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              flexFlow: "column",
            }}
          >
            <Skeleton
              sx={{ backgroundColor: "#fff" }}
              animation="wave"
              width={"100%"}
              height={66}
            />
            <Skeleton
              sx={{ marginTop: "-16px" }}
              animation="wave"
              width={"100%"}
              height={66}
            />
            <Skeleton
              sx={{ backgroundColor: "#fff", marginTop: "-16px" }}
              animation="wave"
              width={"100%"}
              height={66}
            />
            <Skeleton
              sx={{ marginTop: "-16px" }}
              animation="wave"
              width={"100%"}
              height={66}
            />
            <Skeleton
              sx={{ backgroundColor: "#fff", marginTop: "-16px" }}
              animation="wave"
              width={"100%"}
              height={66}
            />
            <Skeleton
              sx={{ marginTop: "-16px" }}
              animation="wave"
              width={"100%"}
              height={66}
            />
          </div>
        </React.Fragment>
      )}
      { data && data.length > 0 && (
        <React.Fragment>
          { width > 1050 ? <Table
            css={{
            marginTop: "12px",
            backgroundColor: "#ffffff",
            border: "1px solid #d7ddea",
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "1.2",
              wordWrap: "break-word",
            },
            "& .MuiDataGrid-cell:hover": {
              cursor: "default",
            },
            "& .MuiDataGrid-cell": {
              whiteSpace: "normal",
              wordWrap: "break-word",

              height: "auto",
              lineHeight: "1.5",
              padding: "8px",
              boxSizing: "border-box",
            },
          }}
            colData={colData}
            rowData={data
              .filter(rowData => rowData.manufacturer.name.indexOf(customers) !== -1)
              .filter(rowData => rowData.name_si.indexOf(titles) !== -1)
              .filter(rowData => {
                if ( methods.length === 0 ) return rowData
                if ( methods.length > 0 ) {
                  if ( rowData.methods
                    .map(method => method.name)
                    .some(method => methods.includes(method)) ) {
                      return rowData
                    } 
                }
              })
              .filter(rowData => {
                if ( linkSI === null ) return rowData
                if ( +linkSI === 0 ) return !!!rowData.type_link ? rowData : null
                if ( +linkSI === 1 ) return rowData.type_link ? rowData : null
              })
              .filter(rowData => {
                if ( pdf === null ) return rowData
                if ( +pdf === 0 ) return !!!rowData.pdf_id ? rowData : null
                if ( +pdf === 1 ) return rowData.pdf_id ? rowData : null
              })
              .map((item) => ({
              id: item.id,
              numberRegistry: item.fif_number,
              nameSi: item.name_si,
              manufacturerName: item.manufacturer.name,
              typeLink: item.type_link
            }))}
            hasCheckboxSelection={( width > 1050 && userRole === "super" ) ? true : false }
            rowCallback={handleShowClick}
            rowSelectionCallback={array => {
              dispatch(setSelectedMeters(array))
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          /> : <TableMobile 
            controlIconButton={"getPDF"}
            rowCallback={handleShowClickMobile}
            rowCallbackParams={""}
            data={data
              .filter(rowData => rowData.manufacturer.name.indexOf(customers) !== -1)
              .filter(rowData => rowData.name_si.indexOf(titles) !== -1)
              .filter(rowData => {
                if ( methods.length === 0 ) return rowData
                if ( methods.length > 0 ) {
                  if ( rowData.methods
                    .map(method => method.name)
                    .some(method => methods.includes(method)) ) {
                      return rowData
                    } 
                }
              })
              .filter(rowData => {
                if ( linkSI === null ) return rowData
                if ( +linkSI === 0 ) return !!!rowData.type_link ? rowData : null
                if ( +linkSI === 1 ) return rowData.type_link ? rowData : null
              })
              .filter(rowData => {
                if ( pdf === null ) return rowData
                if ( +pdf === 0 ) return !!!rowData.pdf_id ? rowData : null
                if ( +pdf === 1 ) return rowData.pdf_id ? rowData : null
              })
              .map(item => {
              return [
                [ "ID", item.id ],
                [ "args", [ item.pdf_id ] ],
                [ "№ СИ в госреестре", item.fif_number ],
                [ "Наименование", item.name_si ],
                [ "Изготовитель", item.manufacturer.name ],
                [ "Утвержденные типы СИ", 
                  <a 
                    href={item.type_link}
                    target="_blank"
                    style={{ color: 'rgb(0, 132, 226)' }}
                  >
                    Ссылка на ФГИС Аршин
                  </a> 
                ]
              ]
            })}
          /> }
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Tab
