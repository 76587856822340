// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { DataGrid, ruRU, GridOverlay } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TableMenu from "./TableMenu";

const Table = (props) => {
  const {
    colData,
    rowData,
    css = null,
    rowCallback = () => {},
    rowSelectionCallback = () => {},
    hasCheckboxSelection,
    hideFooter,
    getRowHeight,
    initialState,
    noRowsText = "Нет данных",
  } = props;

  const locale = createTheme(ruRU);

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    rowSelectionCallback(selectedRows);
  }, [selectedRows]);

  return (
    <ThemeProvider theme={locale}>
      <Box sx={{ minHeight: 155, width: "100%" }}>
        <DataGrid
          autoHeight={true}
          slots={{
            toolbar: TableMenu,
            noRowsOverlay: () => (
              <GridOverlay>
                <Box
                  sx={{
                    mt: 1,
                    color: "#132532",
                    fontSize: "20.8px",
                    textAlign: "center",
                    fontWeight: "400",
                    letterSpacing: "0.4px"
                  }}
                >
                  {noRowsText}
                </Box>
              </GridOverlay>
            ),
          }}
          rows={rowData && rowData}
          columns={colData && colData}
          initialState={initialState}
          pageSizeOptions={[10, 20, 30, 40, 50]}
          checkboxSelection={hasCheckboxSelection}
          rowSelectionModel={selectedRows}
          onRowSelectionModelChange={setSelectedRows}
          // ----------------------------------------------------------------
          // disableRowSelectionOnClick
          // ----------------------------------------------------------------
          disableColumnMenu
          onRowClick={rowCallback && rowCallback}
          hideFooter={hideFooter ? hideFooter : false}
          getRowHeight={getRowHeight}
          sx={
            css
              ? {
                  ...css,
                  width: "100%",
                  borderRadius: "12px",
                  padding: "10px",
                  color: "#132532",
                  fontFamily: "'Wix Madefor Display', sans-serif",
                  overflow: "hidden",
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "#F8F9FC",
                  },
                  "& .MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-columnHeader:focus": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-cell:focus-within": {
                    outline: "none",
                  },
                }
              : {
                  ...css,
                  width: "100%",
                  borderRadius: "12px",
                  padding: "10px",
                  color: "#132532",
                  fontFamily: "'Wix Madefor Display', sans-serif",
                  overflow: "hidden",
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "#F8F9FC",
                  },
                  "& .MuiDataGrid-cell:hover": {
                    cursor: "pointer",
                  },
                  "& .MuiDataGrid-cell:focus": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-columnHeader:focus": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-cell:focus-within": {
                    outline: "none",
                  },
                }
          }
        />
      </Box>
    </ThemeProvider>
  );
};

export default Table;
