// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"

import Selector from "../../../../LocalElements/Selector/reactSelect/Selector"
import Input from "../../../../LocalElements/Input/InputMUI"
import { changeProperty } from "../state"

import useWindowDimensions from "../../../../../hooks/useWindowDimensions"

import css from "../style.css"
const {
  DescriptionTypeBox,
  DescriptionTypeBoxLastElement,
  QLimitDescriptionBox,
  QLimitDescriptionBoxLastElement,
} = css

const DescriptionType = () => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const selectedMod = useSelector((state) => state.mi15922015Reducer.selectedMod)
  const defaultData = useSelector((state) => state.mi15922015Reducer.defaultData)

  const diameter = useSelector((state) => state.mi15922015Reducer.diameter)
  const meterType = useSelector((state) => state.mi15922015Reducer.meterType)
  const meterLocation = useSelector((state) => state.mi15922015Reducer.meterLocation)

  const q_min = useSelector((state) => state.mi15922015Reducer.q_min)
  const q_t = useSelector((state) => state.mi15922015Reducer.q_t)
  const q_max = useSelector((state) => state.mi15922015Reducer.q_max)
  const q_min_limit = useSelector((state) => state.mi15922015Reducer.q_min_limit)
  const q_max_limit = useSelector((state) => state.mi15922015Reducer.q_max_limit)

  const meterTypeData = [10, 15, 20]
  const options = meterTypeData
    ? meterTypeData.map((item) => ({
        value: item,
        label: item,
      }))
    : []

  const getValue = (selectedOption) => {
    dispatch(
      changeProperty({
        value: "diameter",
        label: selectedOption ? selectedOption.value : null,
      })
    )
  }

  const handleMeterTypeChange = (e) => {
    dispatch(changeProperty({ value: "meterType", label: e.target.value }))
  }

  const handleMeterLocationChange = (e) => {
    dispatch(changeProperty({ value: "meterLocation", label: e.target.value }))
  }

  const handleQMinChange = (param) => {
    dispatch(
      changeProperty({ value: "q_min", label: param.replace(",", ".") })
    )
  }

  const handleQtChange = (param) => {
    dispatch(changeProperty({ value: "q_t", label: param.replace(",", ".") }))
  }

  const handleQMaxChange = (param) => {
    dispatch(
      changeProperty({ value: "q_max", label: param.replace(",", ".") })
    )
  }

  const handleQMinLimitChange = (param) => {
    dispatch(
      changeProperty({ value: "q_min_limit", label: param.replace(",", ".") })
    )
  }

  const handleQMaxLimitChange = (param) => {
    dispatch(
      changeProperty({ value: "q_max_limit", label: param.replace(",", ".") })
    )
  }

  useEffect(() => {

    false && console.log(selectedMod)

    if (selectedMod !== null) {
      if (selectedMod && selectedMod.diameter) {
        dispatch(
          changeProperty({ value: "diameter", label: selectedMod.diameter })
        )
      }

      if (selectedMod && selectedMod.type === "hot") {
        dispatch(changeProperty({ value: "meterType", label: "hot" }))
      } else if (selectedMod && selectedMod.type === "cold") {
        dispatch(changeProperty({ value: "meterType", label: "cold" }))
      }

      if (
        selectedMod &&
        selectedMod.q_min_vertical &&
        meterLocation === "vertical"
      ) {
        const q_min = parseFloat(selectedMod.q_min_vertical).toFixed(2)
        const q_t = parseFloat(selectedMod.q_t_vertical).toFixed(2)
        const q_max = parseFloat(selectedMod.q_max_vertical).toFixed(2)
        dispatch(changeProperty({ value: "q_min", label: q_min }))
        dispatch(changeProperty({ value: "q_t", label: q_t }))
        dispatch(changeProperty({ value: "q_max", label: q_max }))
      }
      if (
        selectedMod &&
        selectedMod.q_min_horizontal &&
        meterLocation === "horizontal"
      ) {
        const q_min = parseFloat(selectedMod.q_min_horizontal).toFixed(2)
        const q_t = parseFloat(selectedMod.q_t_horizontal).toFixed(2)
        const q_max = parseFloat(selectedMod.q_max_horizontal).toFixed(2)
        dispatch(changeProperty({ value: "q_min", label: q_min }))
        dispatch(changeProperty({ value: "q_t", label: q_t }))
        dispatch(changeProperty({ value: "q_max", label: q_max }))
      }

      if (selectedMod && defaultData && meterType === "cold") {
        const q_min_limit = parseFloat(defaultData.q_min_limit_cold)
        const q_max_limit = parseFloat(defaultData.q_max_limit_cold)
        dispatch(changeProperty({ value: "q_min_limit", label: q_min_limit }))
        dispatch(changeProperty({ value: "q_max_limit", label: q_max_limit }))
      }

      if (selectedMod && defaultData && meterType === "hot") {
        const q_min_limit = parseFloat(defaultData.q_min_limit_hot)
        const q_max_limit = parseFloat(defaultData.q_max_limit_hot)
        dispatch(changeProperty({ value: "q_min_limit", label: q_min_limit }))
        dispatch(changeProperty({ value: "q_max_limit", label: q_max_limit }))
      }
    }
  }, [selectedMod, defaultData, meterLocation, meterType, dispatch])

  return (
    <React.Fragment>
      <h2
        style={{
          marginBottom: "14px",
          lineHeight: width > 1050 ? "24px" : "28px",
        }}
      >
        { width > 1050
          ? "Метрологические и технические характеристики по описанию типа"
          : "Метрологические и технические характеристики"}
      </h2>

      <div
        className="flexContainerWithGap"
        style={{ marginBottom: width > 1050 ? "25px" : "20px" }}
      >
        <DescriptionTypeBox margin={"0px"}>
          <p
            style={{
              marginBottom: "9px",
              display: "flex",
              alignItems: "center",
            }}
          >
            Диаметр условного прохода, мм{" "}
            { diameter ? (
              <CheckCircleIcon
                sx={{ color: "#63E6BE", marginLeft: "8px", marginTop: "0px" }}
              />
            ) : (
              <ErrorIcon
                sx={{
                  color: "#FFD43B",
                  marginLeft: "8px",
                  marginTop: "0px",
                }}
              />
            )}
          </p>

          { selectedMod ? (
            <p
              style={{
                height: "54px",
                border: "1px solid #d7ddea",
                padding: "14px 16px",
                borderRadius: "12px",
              }}
            >
              {diameter}
            </p>
          ) : (
            <Selector
              placeholder={"Выберите диаметр условного прохода"}
              options={options}
              onChange={getValue}
              value={
                diameter
                  ? options.find((option) => option.value === diameter)
                  : null
              }
              isClearable={true}
              isSearchable={true}
            />
          )}
        </DescriptionTypeBox>

        <div
          className="width32Percent"
          style={{ padding: width > 1050 ? "0 0 0 14px" : "0px" }}
        >
          <p
            style={{
              marginBottom: "9px",
              display: "flex",
              alignItems: "center",
              fontWeight: width > 1050 ? "normal" : 500,
            }}
          >
            Расположение прибора{" "}
            {!!meterLocation ? (
              <CheckCircleIcon
                sx={{ color: "#63E6BE", marginLeft: "8px", marginTop: "0px" }}
              />
            ) : (
              <ErrorIcon
                sx={{
                  color: "#FFD43B",
                  marginLeft: "8px",
                  marginTop: "0px",
                }}
              />
            )}
          </p>

          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={meterLocation}
            onChange={handleMeterLocationChange}
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: width > 1050 ? "14px" : "0px",
              marginBottom: "10px",
            }}
          >
            <FormControlLabel
              value="vertical"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "22px !important",
                    },
                  }}
                />
              }
              label="Вертикальное"
              sx={{
                "& .MuiTypography-root": {
                  fontFamily: "'Wix Madefor Display', sans-serif !important",
                  fontSize: "15px !important",
                  fontWeight: 400,
                  color: "#4f5960",
                },
              }}
            />
            <FormControlLabel
              value="horizontal"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "22px !important",
                    },
                  }}
                />
              }
              label="Горизонтальное"
              sx={{
                "& .MuiTypography-root": {
                  fontFamily: "'Wix Madefor Display', sans-serif !important",
                  fontSize: "15px !important",
                  fontWeight: 400,
                  color: "#4f5960",
                },
              }}
            />
          </RadioGroup>
        </div>

        <div
          className="width32Percent"
          style={{ padding: width > 1050 ? "0 0 0 14px" : "0px" }}
        >
          <p
            style={{
              marginBottom: "9px",
              display: "flex",
              alignItems: "center",
              fontWeight: width > 1050 ? "normal" : 500,
            }}
          >
            Измеряемая вода{" "}
            {!!meterType ? (
              <CheckCircleIcon
                sx={{ color: "#63E6BE", marginLeft: "8px", marginTop: "0px" }}
              />
            ) : (
              <ErrorIcon
                sx={{
                  color: "#FFD43B",
                  marginLeft: "8px",
                  marginTop: "0px",
                }}
              />
            )}
          </p>

          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={meterType}
            onChange={handleMeterTypeChange}
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: width > 1050 ? "14px" : "0px",
              marginBottom: "10px",
            }}
          >
            <FormControlLabel
              value="cold"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "22px !important",
                    },
                  }}
                />
              }
              label="Холодная"
              sx={{
                "& .MuiTypography-root": {
                  fontFamily: "'Wix Madefor Display', sans-serif !important",
                  fontSize: "15px !important",
                  fontWeight: 400,
                  color: "#4f5960",
                },
              }}
              disabled={selectedMod && selectedMod.type === "hot"}
            />
            <FormControlLabel
              value="hot"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "22px !important",
                    },
                  }}
                />
              }
              label="Горячая"
              sx={{
                "& .MuiTypography-root": {
                  fontFamily: "'Wix Madefor Display', sans-serif !important",
                  fontSize: "15px !important",
                  fontWeight: 400,
                  color: "#4f5960",
                },
              }}
              disabled={selectedMod && selectedMod.type === "cold"}
            />
          </RadioGroup>
        </div>
      </div>

      <div className="flexContainerWithGap" style={{ marginBottom: "25px" }}>
        <DescriptionTypeBox margin={"0px"}>
          <Input
            label={"Qmin (описание типа), м³/ч"}
            placeholder={"0.000"}
            value={q_min}
            actions={{
              change: handleQMinChange,
            }}
          />
        </DescriptionTypeBox>

        <DescriptionTypeBox margin={"0px"}>
          <Input
            label={"Qt (описание типа), м³/ч"}
            placeholder={"0.000"}
            value={q_t}
            actions={{
              change: handleQtChange,
            }}
          />
        </DescriptionTypeBox>

        <DescriptionTypeBoxLastElement margin={"0px"}>
          <Input
            label={"Qmax (описание типа), м³/ч"}
            placeholder={"0.000"}
            value={q_max}
            actions={{
              change: handleQMaxChange,
            }}
          />
        </DescriptionTypeBoxLastElement>
      </div>

      <div
        className="flexContainerWithGap"
        style={width > 1050 ? {} : { marginBottom: "0px" }}
      >
        <QLimitDescriptionBox>
          <Input
            label={"Предел допуск. погрешности от Qmin до Qt, %"}
            placeholder={"0"}
            value={q_min_limit}
            actions={{
              change: handleQMinLimitChange,
            }}
          />
        </QLimitDescriptionBox>

        <QLimitDescriptionBoxLastElement>
          <Input
            label={"Предел допуск. погрешности от Qt до Qmax, %"}
            placeholder={"0"}
            value={q_max_limit}
            actions={{
              change: handleQMaxLimitChange,
            }}
          />
        </QLimitDescriptionBoxLastElement>
      </div>
    </React.Fragment>
  )
}

export default DescriptionType
