// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import { useDispatch, useSelector } from "react-redux"
import { changeProperty } from "../components/Methodologies/Water/Mi1592-2015/state"

const useGeneratePDataOptical = () => {
  const dispatch = useDispatch()

  const q_min = useSelector((state) => state.mi15922015Reducer.q_min)
  const q_t = +useSelector((state) => state.mi15922015Reducer.q_t) * 1.1
  const q_max = useSelector((state) => state.mi15922015Reducer.q_actual)

  const q_min_limit = useSelector((state) => state.mi15922015Reducer.q_min_limit)
  const q_max_limit = useSelector((state) => state.mi15922015Reducer.q_max_limit)
  const conversion_factor = useSelector((state) => state.mi15922015Reducer.conversion_factor)

  const diameter = useSelector((state) => state.mi15922015Reducer.diameter)

  // ----------------------------------------------------------------
  // локальные данные внутри хука
  // ----------------------------------------------------------------

  let localQ_i_1 = null
  let localQ_i_2 = null
  let localQ_i_3 = null

  // ----------------------------------------------------------------
  // локальные данные внутри хука
  // ----------------------------------------------------------------

  const getRandom = (min, max) => {
    return Math.floor(Math.random() * (max + 1 - min)) + min
  }

  const generateQactual = () => {
    const getQActualValue = (min, max) => getRandom(min, max) / 10

    let value
    switch (Number(diameter)) {
      case 10:
        value = getQActualValue(4, 8)
        break
      case 15:
        value = getQActualValue(4, 9)
        break
      case 20:
        value = getQActualValue(4, 10)
        break
      default:
        return
    }

    dispatch(
      changeProperty({
        value: "q_actual",
        label: value,
      })
    )
  }

  const generateValuesQ = () => {
    console.group("генерация расхода во время измерения")

    if (q_min) {
      localQ_i_1 =
        +q_min + ((getRandom(0, 1) * getRandom(1, 3)) / 1000) * +q_min

      false && console.log(
        `Qmi1 = ${+q_min} + getRandom(0, 1) * getRandom(1, 3) / 1000 * ${+q_min} = ${localQ_i_1}`
      )

      dispatch(
        changeProperty({
          value: "q_i_1",
          label: localQ_i_1.toFixed(4),
        })
      )
    }

    if (q_t) {
      localQ_i_2 = +q_t + ((getRandom(-1, 1) * getRandom(1, 1)) / 1000) * +q_t

      false && console.log(
        `Qt2 = ${+q_t} + getRandom(-1, 1) * getRandom(1, 1) / 1000 * ${+q_t} = ${localQ_i_2}`
      )

      dispatch(
        changeProperty({
          value: "q_i_2",
          label: localQ_i_2.toFixed(4),
        })
      )
    }

    if (q_max) {
      localQ_i_3 = +q_max - (getRandom(1, 2) / 1000) * +q_max

      false && console.log(
        `Qmax3 = ${+q_max} - getRandom(1, 2) / 1000 * ${+q_max} = ${localQ_i_3}`
      )

      dispatch(
        changeProperty({
          value: "q_i_3",
          label: localQ_i_3.toFixed(4),
        })
      )
    }

    console.groupEnd()
  }

  const generateValuesV = (vи1, vи2, vи3) => {
    if (q_min_limit && q_max_limit) {
      console.group("генерация значений погрешности")

      let vyi1Fault =
        (getRandom(0, q_min_limit - 1) + getRandom(1, 99) / 100) *
        (getRandom(0, 1) * 2 - 1)

      false && console.log(
        `vyi1Fault = ( getRandom(0, ${
          q_min_limit - 1
        }) + getRandom(1, 99) / 100 ) * ( getRandom(0, 1) * 2 - 1 ) = ${vyi1Fault}
      `
      )

      let vyi4Fault =
        (getRandom(0, q_max_limit - 1) + getRandom(1, 99) / 100) *
        (getRandom(0, 1) * 2 - 1)

      false && console.log(
        `vyi4Fault = ( getRandom(0, ${
          q_max_limit - 1
        }) + getRandom(1, 99) / 100 ) * ( getRandom(0, 1) * 2 - 1 ) = ${vyi4Fault}
      `
      )

      let vyi7Fault =
        (getRandom(0, q_max_limit - 1) + getRandom(1, 99) / 100) *
        (getRandom(0, 1) * 2 - 1)

      false && console.log(
        `vyi7Fault = ( getRandom(0, ${
          q_max_limit - 1
        }) + getRandom(1, 99) / 100 ) * ( getRandom(0, 1) * 2 - 1 ) = ${vyi7Fault}
      `
      )

      console.groupEnd()

      dispatch(
        changeProperty({
          value: "vyi1Fault",
          label: vyi1Fault,
        })
      )
      dispatch(
        changeProperty({
          value: "vyi4Fault",
          label: vyi4Fault,
        })
      )
      dispatch(
        changeProperty({
          value: "vyi7Fault",
          label: vyi7Fault,
        })
      )

      // ----------------------------------------------------------------
      // ----------------------------------------------------------------

      console.group("генерация значений объема по эталону")

      let vyi1 = (100 * vи1) / (100 + vyi1Fault)

      false && console.log(`vyi1 = ( 100 * ${vи1} ) / ( 100 + ${vyi1Fault} ) = ${vyi1}`)

      let vyi4 = (100 * vи2) / (100 + vyi4Fault)

      false && console.log(`vyi4 = ( 100 * ${vи2} ) / ( 100 + ${vyi4Fault} ) = ${vyi4}`)

      let vyi7 = (100 * vи3) / (100 + vyi7Fault)

      false && console.log(`vyi7 = ( 100 * ${vи3} ) / ( 100 + ${vyi7Fault} ) = ${vyi7}`)

      console.groupEnd()

      dispatch(
        changeProperty({
          value: "v_y_i_1",
          label: vyi1.toFixed(6),
        })
      )
      dispatch(
        changeProperty({
          value: "v_y_i_2",
          label: vyi4.toFixed(6),
        })
      )
      dispatch(
        changeProperty({
          value: "v_y_i_3",
          label: vyi7.toFixed(6),
        })
      )
    }
  }

  const generateValuesStartEnd = () => {
    generateValuesQ()

    if ( q_min && q_t && q_max ) {
      let vи1 = (((localQ_i_1 * 1000) / 3600) * 720 + 2) / 1000
      let vи2 = (((localQ_i_2 * 1000) / 3600) * 360 + 2) / 1000
      let vи3 = (((localQ_i_3 * 1000) / 3600) * 120 + 5) / 1000

      let imp1 = vи1 / conversion_factor
      let imp2 = vи2 / conversion_factor
      let imp3 = vи3 / conversion_factor

      dispatch(
        changeProperty({
          value: "impulses_1",
          label: imp1.toFixed(0),
        })
      )
      dispatch(
        changeProperty({
          value: "impulses_2",
          label: imp2.toFixed(0),
        })
      )
      dispatch(
        changeProperty({
          value: "impulses_3",
          label: imp3.toFixed(0),
        })
      )

      false && generateQactual()
      generateValuesV(vи1, vи2, vи3)
    }
  }

  const defaultValues = () => {
    dispatch(
      changeProperty({
        value: "q_i_1",
        label: '',
      })
    )
    dispatch(
      changeProperty({
        value: "q_i_2",
        label: '',
      })
    )
    dispatch(
      changeProperty({
        value: "q_i_3",
        label: '',
      })
    )

    dispatch(
      changeProperty({
        value: "vyi1Fault",
        label: '',
      })
    )
    dispatch(
      changeProperty({
        value: "vyi4Fault",
        label: '',
      })
    )
    dispatch(
      changeProperty({
        value: "vyi7Fault",
        label: '',
      })
    )

    dispatch(
      changeProperty({
        value: "v_y_i_1",
        label: '',
      })
    )
    dispatch(
      changeProperty({
        value: "v_y_i_2",
        label: '',
      })
    )
    dispatch(
      changeProperty({
        value: "v_y_i_3",
        label: '',
      })
    )

    dispatch(
      changeProperty({
        value: "impulses_1",
        label: '',
      })
    )
    dispatch(
      changeProperty({
        value: "impulses_2",
        label: '',
      })
    )
    dispatch(
      changeProperty({
        value: "impulses_3",
        label: '',
      })
    )
  }

  return {
    generateValuesStartEnd,
    generateValuesQ,
    generateValuesV,
    generateQactual,
    defaultValues
  }
}

export default useGeneratePDataOptical
