// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import Button from "@mui/material/Button"
import DatePicker from "../../components/LocalElements/DatePicker/DatePicker"

import SelectJournal from "../../components/Journals/Select/SelectJournal"
import TableComplect from "../../components/Journals/JournalComplect/TableComplect"
import TableConditions from "../../components/Journals/JournalConditionsCases/Tab"
import TableTickets from "../../components/Journals/JournalTickets/Tab"
import TableVerificationSchedule from "../../components/Journals/JournalVerificationSchedule/Tab"
import ExcelCsv from "../../services/journal-csv-excel-download.service"
import BasicTabs from "../../components/LocalElements/TabsMenu/TabsMenu"

import D from "../../store/localStorage/dispatcher"

import FilterAltIcon from "@mui/icons-material/FilterAlt"

import { setOpen as setOpenRightContainer } from "../../store/slices/comps/rightContainer"
import { setName } from "../../store/slices/controlers/pageName"

import useWindowDimensions from "../../hooks/useWindowDimensions"
import SearchQuery from "../../components/LocalElements/Search/SearchQuery"

const MagazinesIndex = () => {
  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector((state) => state.headerReducer.role)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)
  const magazineId = useSelector((state) => state.journalReducer.magazineId)

  const handleJournalAdd = () => {
    if ( magazineId === 2 ) {
      false && navigate("/metriva/journals/create_line_journal_complect")
    } else if ( magazineId === 4 ) {
      false && navigate("/metriva/journals/create_line_verification_schedule")
    } else if ( magazineId === 1 ) {
      false && navigate("/metriva/journals/create_line_conditions_case")
    } else if ( magazineId === 3 ) {
      false && navigate("/metriva/journals/create_line_tickets")
    }
  }

  useEffect(() => { dispatch(setName("История журналов")) }, []) 

  return (
    <main className={isToggle ? "main_open" : "main"}>
      <div style={{ width: width > 1050 ? "605px" : "100%" }}>
        <SelectJournal />
      </div>
      {( userRole === "super" || userRole === "admin" ) && (
        <div style={{ margin: "10px 0 12px" }}>
          <BasicTabs
            viewVariant={0}
            callback={() => {}}
            tabs={[{ label: "Актуальная версия документа" }, { label: "Настройки" }, { label: "Архивные" }]}
          />
        </div>
      )}
      <div 
        className="flexContainerWithGap"
        style={ width > 1050 ? {
          flexWrap: 'wrap',
          width: '100%',
          gap: '12px'
        } : {
          flexWrap: 'wrap',
          width: '100%',
          gap: '12px',
          marginTop: "0px",
          marginBottom: "0px"
        }}
      >
        <div 
          style={ width > 1050 ? {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '10px'
          } : {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px'
          }}
        >
          { true && (
            <div style={{ width: width > 1050 ? "18%" : "100%" }}>
              <Button
                disabled={ !!!magazineId && false ? false : true }
                variant="contained"
                onClick={handleJournalAdd}
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "14px 20px",
                  margin: 0,
                  borderRadius: "12px",
                  width: "100%",
                  height: "54.4px"
                }}
              >
                Добавить запись
              </Button>
            </div>
          )}
          <div style={{ width: width > 1050 ? "82%" : "100%" }}>
            <SearchQuery
              searchQuery={""}
              setSearchQuery={() => {}}
            />
          </div>
          { false && <div
            style={{
              display: "flex",
              flexFlow: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              marginRight: "38px",
            }}
          >
            { magazineId && <ExcelCsv /> }
          </div> }
          { width > 1050 && <div style={{ width: width > 1050 ? "16%" : "100%", position: "relative" }}>
            <Button
              variant="outlined"
              onClick={() => {
                dispatch(setOpenRightContainer(true))
              }}
              startIcon={<FilterAltIcon />}
              style={{
                width: "100%",
                textTransform: "none",
                fontSize: "15px",
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: "0px 20px",
                borderRadius: "12px",
                height: "54.4px"
              }}
            >
              Фильтры
            </Button>
          </div> }
        </div>
      </div>

      { magazineId === "" && (
        <div className="box" style={{ marginTop: "12px" }}>
          <div className="page_null">
            <h4
              style={{
                fontSize: "20.8px",
                letterSpacing: "0.4px",
                color: "#132532",
                fontWeight: "400",
              }}
            >
              Выберите вид журнала из списка
            </h4>
          </div>
        </div>
      )}
      { magazineId === 1 && <TableConditions />}
      { magazineId === 2 && <TableComplect />}
      { magazineId === 3 && <TableTickets />}
      { magazineId === 4 && <TableVerificationSchedule />}
    </main>
  )
}

export default MagazinesIndex
