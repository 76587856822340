import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Input from "../../../../LocalElements/Input/InputMUI";
import { changeProperty } from "../state";

const IndicationsShort = () => {
  const dispatch = useDispatch();

  const q_min = useSelector((state) => state.gost83242002Reducer.q_min);
  const q_t = useSelector((state) => state.gost83242002Reducer.q_t);
  const q_max = useSelector((state) => state.gost83242002Reducer.q_max);
  const q_min_limit = useSelector(
    (state) => state.gost83242002Reducer.q_min_limit
  );
  const q_max_limit = useSelector(
    (state) => state.gost83242002Reducer.q_max_limit
  );

  const verification_flow_1 = useSelector(
    (state) => state.gost83242002Reducer.verification_flow_1
  );
  const etalon_volume_1 = useSelector(
    (state) => state.gost83242002Reducer.etalon_volume_1
  );
  const device_volume_1 = useSelector(
    (state) => state.gost83242002Reducer.device_volume_1
  );
  const correction_1 = useSelector(
    (state) => state.gost83242002Reducer.correction_1
  );

  const [verification_flow_2, setVerification_flow_2] = useState("");
  const [etalon_volume_2, setEtalon_volume_2] = useState("");
  const [device_volume_2, setDevice_volume_2] = useState("");
  const [correction_2, setCorrection_2] = useState("");

  const [verification_flow_3, setVerification_flow_3] = useState("");
  const [etalon_volume_3, setEtalon_volume_3] = useState("");
  const [device_volume_3, setDevice_volume_3] = useState("");
  const [correction_3, setCorrection_3] = useState("");

  const handleVerification_flow_1Change = (param) => {
    dispatch(
      changeProperty({
        value: "verification_flow_1",
        label: param.replace(",", "."),
      })
    );
  };

  const handleEtalon_volume_1Change = (param) => {
    dispatch(
      changeProperty({
        value: "etalon_volume_1",
        label: param.replace(",", "."),
      })
    );
  };

  const handleDevice_volume_1Change = (param) => {
    dispatch(
      changeProperty({
        value: "device_volume_1",
        label: param.replace(",", "."),
      })
    );
  };

  const handleCorrection_1Change = (param) => {
    dispatch(
      changeProperty({
        value: "correction_1",
        label: param.replace(",", "."),
      })
    );
  };

  const handleVerification_flow_2Change = (param) => {
    setVerification_flow_2(param.replace(",", "."));
  };

  const handleEtalon_volume_2Change = (param) => {
    setEtalon_volume_2(param.replace(",", "."));
  };

  const handleDevice_volume_2Change = (param) => {
    setDevice_volume_2(param.replace(",", "."));
  };

  const handleCorrection_2Change = (param) => {
    setCorrection_2(param.replace(",", "."));
  };

  const handleVerification_flow_3Change = (param) => {
    setVerification_flow_3(param.replace(",", "."));
  };

  const handleEtalon_volume_3Change = (param) => {
    setEtalon_volume_3(param.replace(",", "."));
  };

  const handleDevice_volume_3Change = (param) => {
    setDevice_volume_3(param.replace(",", "."));
  };

  const handleCorrection_3Change = (param) => {
    setCorrection_3(param.replace(",", "."));
  };

  const permissible_error_1 = (
    ((parseFloat(device_volume_1) - parseFloat(etalon_volume_1)) /
      parseFloat(etalon_volume_1)) *
      100 -
    parseFloat(correction_1)
  ).toFixed(2);

  const permissible_error_2 = (
    ((parseFloat(device_volume_2) - parseFloat(etalon_volume_2)) /
      parseFloat(etalon_volume_2)) *
      100 -
    parseFloat(correction_2)
  ).toFixed(2);

  const permissible_error_3 = (
    ((parseFloat(device_volume_3) - parseFloat(etalon_volume_3)) /
      parseFloat(etalon_volume_3)) *
      100 -
    parseFloat(correction_3)
  ).toFixed(2);

  let isPermissiblePass_1 = true;
  if (!isNaN(permissible_error_1)) {
    isPermissiblePass_1 =
      permissible_error_1 >= -q_min_limit && permissible_error_1 <= q_min_limit;
  }

  useEffect(() => {
    dispatch(
      changeProperty({ value: "permissiblePass", label: isPermissiblePass_1 })
    );
  }, [isPermissiblePass_1, dispatch]);

  const isPermissiblePass_2 =
    permissible_error_2 >= -q_max_limit && permissible_error_2 <= q_max_limit;

  const isPermissiblePass_3 =
    permissible_error_3 >= -q_max_limit && permissible_error_3 <= q_max_limit;

  useEffect(() => {
    if (isPermissiblePass_2 === false) {
      setVerification_flow_3("");
      setEtalon_volume_3("");
      setDevice_volume_3("");
      setCorrection_3("");
    }
  }, [isPermissiblePass_2]);

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  useEffect(() => {
    const isValues = [
      isPermissiblePass_1,
      isPermissiblePass_2,
      isPermissiblePass_3,
    ];
    dispatch(changeProperty({ value: "indicationPass", label: isValues }));
  }, [isPermissiblePass_1, isPermissiblePass_2, isPermissiblePass_3, dispatch]);

  useEffect(() => {
    if (isPermissiblePass_2) {
      dispatch(
        changeProperty({
          value: "indications",
          label: [
            {
              verification_flow_1: verification_flow_1,
              etalon_volume_1: etalon_volume_1,
              device_volume_1: device_volume_1,
              correction_1: correction_1,
            },
            {
              verification_flow_1: verification_flow_2,
              etalon_volume_1: etalon_volume_2,
              device_volume_1: device_volume_2,
              correction_1: correction_2,
            },
            {
              verification_flow_1: verification_flow_3,
              etalon_volume_1: etalon_volume_3,
              device_volume_1: device_volume_3,
              correction_1: correction_3,
            },
          ],
        })
      );
    } else {
      dispatch(
        changeProperty({
          value: "indications",
          label: [
            {
              verification_flow_1: verification_flow_1,
              etalon_volume_1: etalon_volume_1,
              device_volume_1: device_volume_1,
              correction_1: correction_1,
            },
            {
              verification_flow_1: verification_flow_2,
              etalon_volume_1: etalon_volume_2,
              device_volume_1: device_volume_2,
              correction_1: correction_2,
            },
          ],
        })
      );
    }
  }, [
    verification_flow_1,
    verification_flow_2,
    verification_flow_3,
    etalon_volume_1,
    etalon_volume_2,
    etalon_volume_3,
    device_volume_1,
    device_volume_2,
    device_volume_3,
    correction_1,
    correction_2,
    correction_3,
    isPermissiblePass_2,
    dispatch,
  ]);

  return (
    <React.Fragment>
      <h2 style={{ marginBottom: "15px" }}>
        Определение относительной погрешности
      </h2>
      <React.Fragment>
        <React.Fragment>
          <p style={{ marginBottom: "20px" }}>
            Значение Qmin: <span>{q_min}</span> м³/ч
          </p>

          <div className="flexContainerWithGap">
            <div className="numberBox" style={{ marginBottom: "15px" }}>
              <p style={{ fontSize: "16px", fontWeight: "500" }}>1</p>
            </div>
            <div
              className="width24Point5Percent"
              style={{ marginBottom: "25px" }}
            >
              <Input
                label={"Поверочный расход, м³/ч"}
                placeholder={"0.0000"}
                value={verification_flow_1}
                actions={{
                  change: handleVerification_flow_1Change,
                }}
              />
            </div>

            <div
              className="width24Point5Percent"
              style={{ marginBottom: "25px" }}
            >
              <Input
                label={"Объем, по показаниям эталона, м³"}
                placeholder={"0.0000"}
                value={etalon_volume_1}
                actions={{
                  change: handleEtalon_volume_1Change,
                }}
              />
            </div>

            <div
              className="width24Point5Percent"
              style={{ marginBottom: "25px" }}
            >
              <Input
                label={"Объем прибора, м³"}
                placeholder={"0.0000"}
                value={device_volume_1}
                actions={{
                  change: handleDevice_volume_1Change,
                }}
              />
            </div>

            <div
              className="width24Point5Percent"
              style={{ marginBottom: "25px" }}
            >
              <Input
                label={"Поправка, %"}
                placeholder={"0.0"}
                value={correction_1}
                actions={{
                  change: handleCorrection_1Change,
                }}
              />
            </div>
          </div>

          {correction_1 && (
            <div
              className={`indications_box-gas_bottom ${
                isPermissiblePass_1
                  ? "indications_box-gas_bottom_true"
                  : "indications_box-gas_bottom_false"
              }`}
              style={{ marginBottom: "30px" }}
            >
              <p
                className={`indications_box-gas_bottom ${
                  isPermissiblePass_1 ? "text_true" : "text_false"
                }`}
                style={{ marginBottom: "5px" }}
              >
                Относительная погрешность счетчика:{" "}
                <span style={{ margin: "0 2px" }}>{permissible_error_1}</span> %
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  isPermissiblePass_1 ? "text_true" : "text_false"
                }`}
              >
                Пределы допускаемой погрешности счётчика:{" "}
                <span style={{ margin: "0 2px" }}>±{q_min_limit} </span>%
              </p>
            </div>
          )}
        </React.Fragment>

        <React.Fragment>
          <p style={{ marginBottom: "20px" }}>
            Значение 1.1 * Qt: <span>{q_t * 1.1}</span> м³/ч
          </p>

          <div className="flexContainerWithGap">
            <div className="numberBox" style={{ marginBottom: "15px" }}>
              <p style={{ fontSize: "16px", fontWeight: "500" }}>2</p>
            </div>
            <div
              className="width24Point5Percent"
              style={{ marginBottom: "25px" }}
            >
              <Input
                label={"Поверочный расход, м³/ч"}
                placeholder={"0.0000"}
                value={verification_flow_2}
                actions={{
                  change: handleVerification_flow_2Change,
                }}
              />
            </div>

            <div
              className="width24Point5Percent"
              style={{ marginBottom: "25px" }}
            >
              <Input
                label={"Объем, по показаниям эталона, м³"}
                placeholder={"0.0000"}
                value={etalon_volume_2}
                actions={{
                  change: handleEtalon_volume_2Change,
                }}
              />
            </div>

            <div
              className="width24Point5Percent"
              style={{ marginBottom: "25px" }}
            >
              <Input
                label={"Объем прибора, м³"}
                placeholder={"0.0000"}
                value={device_volume_2}
                actions={{
                  change: handleDevice_volume_2Change,
                }}
              />
            </div>

            <div
              className="width24Point5Percent"
              style={{ marginBottom: "25px" }}
            >
              <Input
                label={"Поправка, %"}
                placeholder={"0.0"}
                value={correction_2}
                actions={{
                  change: handleCorrection_2Change,
                }}
              />
            </div>
          </div>

          {correction_2 && (
            <div
              className={`indications_box-gas_bottom ${
                isPermissiblePass_2
                  ? "indications_box-gas_bottom_true"
                  : "indications_box-gas_bottom_false"
              }`}
              style={{ marginBottom: "30px" }}
            >
              <p
                className={`indications_box-gas_bottom ${
                  isPermissiblePass_2 ? "text_true" : "text_false"
                }`}
                style={{ marginBottom: "5px" }}
              >
                Относительная погрешность счетчика:{" "}
                <span style={{ margin: "0 2px" }}>{permissible_error_2}</span> %
              </p>
              <p
                className={`indications_box-gas_bottom ${
                  isPermissiblePass_2 ? "text_true" : "text_false"
                }`}
              >
                Пределы допускаемой погрешности счётчика:{" "}
                <span style={{ margin: "0 2px" }}>±{q_max_limit} </span>%
              </p>
            </div>
          )}
        </React.Fragment>

        {isPermissiblePass_2 && (
          <React.Fragment>
            <p style={{ marginBottom: "20px" }}>
              Значение Qmax: <span>{q_max}</span> м³/ч
            </p>

            <div className="flexContainerWithGap">
              <div className="numberBox" style={{ marginBottom: "15px" }}>
                <p style={{ fontSize: "16px", fontWeight: "500" }}>3</p>
              </div>
              <div className="width24Point5Percent">
                <Input
                  label={"Поверочный расход, м³/ч"}
                  placeholder={"0.0000"}
                  value={verification_flow_3}
                  actions={{
                    change: handleVerification_flow_3Change,
                  }}
                />
              </div>

              <div className="width24Point5Percent">
                <Input
                  label={"Объем, по показаниям эталона, м³"}
                  placeholder={"0.0000"}
                  value={etalon_volume_3}
                  actions={{
                    change: handleEtalon_volume_3Change,
                  }}
                />
              </div>

              <div className="width24Point5Percent">
                <Input
                  label={"Объем прибора, м³"}
                  placeholder={"0.0000"}
                  value={device_volume_3}
                  actions={{
                    change: handleDevice_volume_3Change,
                  }}
                />
              </div>

              <div className="width24Point5Percent">
                <Input
                  label={"Поправка, %"}
                  placeholder={"0.0"}
                  value={correction_3}
                  actions={{
                    change: handleCorrection_3Change,
                  }}
                />
              </div>
            </div>

            {correction_3 && (
              <div
                className={`indications_box-gas_bottom ${
                  isPermissiblePass_3
                    ? "indications_box-gas_bottom_true"
                    : "indications_box-gas_bottom_false"
                }`}
                style={{ marginTop: "20px" }}
              >
                <p
                  className={`indications_box-gas_bottom ${
                    isPermissiblePass_3 ? "text_true" : "text_false"
                  }`}
                  style={{ marginBottom: "5px" }}
                >
                  Относительная погрешность счетчика:{" "}
                  <span style={{ margin: "0 2px" }}>{permissible_error_3}</span>{" "}
                  %
                </p>
                <p
                  className={`indications_box-gas_bottom ${
                    isPermissiblePass_3 ? "text_true" : "text_false"
                  }`}
                >
                  Пределы допускаемой погрешности счётчика:{" "}
                  <span style={{ margin: "0 2px" }}>±{q_max_limit} </span>%
                </p>
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default IndicationsShort;
