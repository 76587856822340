import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Collapse from "@mui/material/Collapse";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import CardTariff from "../../components/Companies/Tariff/CardTariff";
import Trial from "../../components/Companies/Tariff/Trial";
import FullAccess from "../../components/Companies/Tariff/FullAccess";
import Individual from "../../components/Companies/Tariff/Individual";

const Tariffs = () => {
  const dispatch = useDispatch();
  const [activeTariff, setActiveTariff] = useState("");

  const tariffSelector = useSelector(
    (state) => state.companiesReducer.tariffSelector
  );

  const statusSelector = useSelector(
    (state) => state.companiesReducer.statusSelector
  );

  false && console.log("statusSelector", statusSelector);

  const handleDetailsClick = (tariff) => {
    setActiveTariff(activeTariff === tariff ? null : tariff);
  };

  useEffect(() => {
    switch (tariffSelector) {
      case 1:
        setActiveTariff("trial");
        break;
      case 2:
        setActiveTariff("fullAccess");
        break;
      case 3:
        setActiveTariff("individual");
        break;
      default:
        setActiveTariff(null);
    }
  }, [tariffSelector]);

  return (
    <React.Fragment>
      <div
        className="flexContainerWithGap"
        style={{ marginTop: "26px", gap: "16px", justifyContent: "center" }}
      >
        <CardTariff
          title={"«Пробный период»"}
          chip={statusSelector === "trial" ? "Активирован" : "Завершен"}
          icon={
            <svg
              width="71"
              height="71"
              viewBox="0 0 71 71"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.0019 5.86523C20.6985 5.87723 14.3327 6.09734 10.2941 10.1359C6.25554 14.1745 6.03543 20.5403 6.02344 32.8437H20.3545C19.3309 31.5651 18.566 30.0568 18.1489 28.3885C16.579 22.1089 22.2671 16.4208 28.5467 17.9907C30.215 18.4078 31.7233 19.1727 33.0019 20.1963V5.86523Z"
                fill="#0084E2"
              />
              <path
                d="M6.02344 37.2187C6.03543 49.5221 6.25554 55.888 10.2941 59.9265C14.3327 63.9651 20.6985 64.1852 33.0019 64.1972V41.2252C30.728 45.7671 26.0312 48.8854 20.6061 48.8854C19.398 48.8854 18.4186 47.906 18.4186 46.6979C18.4186 45.4898 19.398 44.5104 20.6061 44.5104C25.0884 44.5104 28.8441 41.3993 29.8316 37.2187H6.02344Z"
                fill="#0084E2"
              />
              <path
                d="M37.3769 64.1972C49.6803 64.1852 56.0462 63.9651 60.0847 59.9265C64.1233 55.888 64.3434 49.5221 64.3554 37.2187H40.5472C41.5348 41.3993 45.2904 44.5104 49.7727 44.5104C50.9809 44.5104 51.9602 45.4898 51.9602 46.6979C51.9602 47.906 50.9809 48.8854 49.7727 48.8854C44.3477 48.8854 39.6509 45.7671 37.3769 41.2252V64.1972Z"
                fill="#0084E2"
              />
              <path
                d="M64.3554 32.8437C64.3434 20.5403 64.1233 14.1745 60.0847 10.1359C56.0462 6.09734 49.6803 5.87723 37.3769 5.86523V20.1963C38.6556 19.1727 40.1638 18.4078 41.8321 17.9907C48.1117 16.4208 53.7998 22.1089 52.2299 28.3885C51.8128 30.0568 51.048 31.5651 50.0243 32.8437H64.3554Z"
                fill="#0084E2"
              />
              <path
                d="M27.4857 22.2351C30.7276 23.0456 33.0019 25.9585 33.0019 29.3002V32.8437H29.4584C26.1167 32.8437 23.2038 30.5694 22.3933 27.3274C21.6244 24.252 24.4102 21.4662 27.4857 22.2351Z"
                fill="#0084E2"
              />
              <path
                d="M37.3769 29.3002V32.8437H40.9204C44.2622 32.8437 47.175 30.5694 47.9855 27.3274C48.7544 24.252 45.9686 21.4662 42.8932 22.2351C39.6512 23.0456 37.3769 25.9585 37.3769 29.3002Z"
                fill="#0084E2"
              />
            </svg>
          }
          price={"1000,00"}
          iconFirstTariff={
            <CheckCircleIcon sx={{ color: "#22c58b", fontSize: "24px" }} />
          }
          iconSecondTariff={
            <CancelIcon sx={{ color: "#ff5963", fontSize: "24px" }} />
          }
          iconThirdTariff={
            <CancelIcon sx={{ color: "#ff5963", fontSize: "24px" }} />
          }
          changeTariff={handleDetailsClick}
          tariffName={"trial"}
          isOpen={activeTariff === "trial"}
          isTariff={tariffSelector === 1}
        />

        <CardTariff
          title={"«Полный доступ»"}
          chip={
            statusSelector === "active"
              ? "Активирован"
              : statusSelector === "frozen"
              ? "Заморожен"
              : "Не активирован"
          }
          icon={
            <svg
              width="71"
              height="71"
              viewBox="0 0 71 71"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.8757 23.3652C19.8757 14.9084 26.7313 8.05273 35.1881 8.05273C42.3202 8.05273 48.3184 12.9306 50.0184 19.5355C50.3195 20.7055 51.5121 21.4098 52.6821 21.1087C53.8521 20.8076 54.5564 19.615 54.2553 18.445C52.0699 9.9541 44.3647 3.67773 35.1881 3.67773C24.315 3.67773 15.5007 12.4921 15.5007 23.3652V29.3578C12.2493 29.6007 10.132 30.2137 8.5843 31.7614C6.02148 34.3242 6.02148 38.449 6.02148 46.6986C6.02148 54.9481 6.02148 59.0729 8.5843 61.6357C11.1471 64.1986 15.2719 64.1986 23.5215 64.1986H46.8548C55.1044 64.1986 59.2292 64.1986 61.792 61.6357C64.3548 59.0729 64.3548 54.9481 64.3548 46.6986C64.3548 38.449 64.3548 34.3242 61.792 31.7614C59.2292 29.1986 55.1044 29.1986 46.8548 29.1986H23.5215C22.2046 29.1986 20.9928 29.1986 19.8757 29.209V23.3652ZM23.5215 49.6152C25.1323 49.6152 26.4382 48.3094 26.4382 46.6986C26.4382 45.0877 25.1323 43.7819 23.5215 43.7819C21.9107 43.7819 20.6048 45.0877 20.6048 46.6986C20.6048 48.3094 21.9107 49.6152 23.5215 49.6152ZM35.1881 49.6152C36.799 49.6152 38.1048 48.3094 38.1048 46.6986C38.1048 45.0877 36.799 43.7819 35.1881 43.7819C33.5773 43.7819 32.2715 45.0877 32.2715 46.6986C32.2715 48.3094 33.5773 49.6152 35.1881 49.6152ZM49.7715 46.6986C49.7715 48.3094 48.4656 49.6152 46.8548 49.6152C45.244 49.6152 43.9381 48.3094 43.9381 46.6986C43.9381 45.0877 45.244 43.7819 46.8548 43.7819C48.4656 43.7819 49.7715 45.0877 49.7715 46.6986Z"
                fill="#0084E2"
              />
            </svg>
          }
          price={"0,00"}
          iconFirstTariff={
            <CancelIcon sx={{ color: "#ff5963", fontSize: "24px" }} />
          }
          iconSecondTariff={
            <CancelIcon sx={{ color: "#ff5963", fontSize: "24px" }} />
          }
          iconThirdTariff={
            <CheckCircleIcon sx={{ color: "#22c58b", fontSize: "24px" }} />
          }
          changeTariff={handleDetailsClick}
          tariffName={"fullAccess"}
          isOpen={activeTariff === "fullAccess"}
          isTariff={tariffSelector === 2}
        />

        <CardTariff
          title={"«Индивидуальный»"}
          chip={"Не активирован"}
          icon={
            <svg
              width="72"
              height="72"
              viewBox="0 0 72 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M60.6541 48.1487L62.7413 36.0794C63.2626 33.0649 60.9444 30.3078 57.8885 30.3078H42.5611C41.042 30.3078 39.8852 28.9442 40.1311 27.4434L42.0915 15.4783C42.41 13.5344 42.319 11.5454 41.8244 9.63884C41.4147 8.05943 40.1962 6.79122 38.5936 6.2764L38.1647 6.13862C37.1962 5.82748 36.1391 5.89989 35.2259 6.33991C34.2208 6.82424 33.4855 7.70767 33.213 8.75833L31.8057 14.1835C31.3579 15.9096 30.7057 17.5758 29.8647 19.1501C28.6359 21.4504 26.7362 23.2914 24.7614 24.9931L20.5051 28.6608C19.305 29.6949 18.6748 31.2436 18.8113 32.8229L21.214 60.6096C21.4344 63.1583 23.5653 65.1149 26.1206 65.1149H39.8728C50.1716 65.1149 58.9609 57.9392 60.6541 48.1487Z"
                fill="#0084E2"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.4688 28.0919C10.6559 28.0407 11.6726 28.9337 11.775 30.1175L14.6493 63.3589C14.8339 65.4942 13.152 67.3345 11.0043 67.3345C8.98134 67.3345 7.3457 65.6934 7.3457 63.6743V30.3086C7.3457 29.1205 8.28172 28.1432 9.4688 28.0919Z"
                fill="#0084E2"
              />
            </svg>
          }
          price={"0,00"}
          iconFirstTariff={
            <CancelIcon sx={{ color: "#ff5963", fontSize: "24px" }} />
          }
          iconSecondTariff={
            <CheckCircleIcon sx={{ color: "#22c58b", fontSize: "24px" }} />
          }
          iconThirdTariff={
            <CheckCircleIcon sx={{ color: "#22c58b", fontSize: "24px" }} />
          }
          changeTariff={handleDetailsClick}
          tariffName={"individual"}
          isOpen={activeTariff === "individual"}
          isTariff={tariffSelector === 3}
        />
      </div>

      <Collapse in={activeTariff === "trial"}>
        <Trial activeTariff={activeTariff} />
      </Collapse>

      <Collapse in={activeTariff === "fullAccess"}>
        <FullAccess activeTariff={activeTariff} />
      </Collapse>

      <Collapse in={activeTariff === "individual"}>
        <Individual activeTariff={activeTariff} />
      </Collapse>
    </React.Fragment>
  );
};

export default Tariffs;
