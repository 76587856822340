// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import * as React from 'react'
import ReactDOM from 'react-dom'

import { useSelector, useDispatch } from 'react-redux'
import { setOpen } from '../../../store/slices/comps/bottomContainer'

import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CloseIcon from '@mui/icons-material/Close'

const BottomSideContainer = ({ children, blockTitle }) => {
  const dispatch = useDispatch()

  const open = useSelector(state => state.bottomContainerReducer.open)

  const toggleDrawer = 
    (open) => 
    (event) => {
      if ( event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }
      
      dispatch(setOpen(open))
  }

  const list = (childrenElement) => (
    <Box
      sx={{ width: "100%", padding: '20px' }}
      role="presentation"
    > 
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <h4 style={{ display: 'block', marginBottom: '13px', fontSize: '18.8px' }}>{ blockTitle }</h4>
        <span
          onClick={() => dispatch(setOpen(false))} 
          style={{  
            marginTop: '-8px', 
            cursor: 'pointer' 
          }}
        >
          <CloseIcon />
        </span>
      </div>
      { childrenElement }
    </Box>
  )

  return ReactDOM.createPortal(
    <div>
      <React.Fragment>
        <Drawer
          anchor={"top"}
          open={open}
          onClose={toggleDrawer(false)}
        >
          { list(children) }
        </Drawer>
      </React.Fragment>
    </div>,
    document.body
  )
}


export default BottomSideContainer