import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const ExitIcon = (props) => (
  <SvgIcon
    {...props}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1578_6443)">
      <path
        d="M0 3.66645C0 2.13433 1.24566 0.888672 2.77778 0.888672H9.72222V6.44423C9.72222 7.21246 10.3429 7.83312 11.1111 7.83312H16.6667V13.3887H9.375C8.79774 13.3887 8.33333 13.8531 8.33333 14.4303C8.33333 15.0076 8.79774 15.472 9.375 15.472H16.6667V20.3331C16.6667 21.8652 15.421 23.1109 13.8889 23.1109H2.77778C1.24566 23.1109 0 21.8652 0 20.3331V3.66645ZM16.6667 15.472V13.3887H21.4453L19.7526 11.696C19.3446 11.288 19.3446 10.6283 19.7526 10.2246C20.1606 9.82096 20.8203 9.81662 21.224 10.2246L24.6962 13.6968C25.1042 14.1048 25.1042 14.7645 24.6962 15.1682L21.224 18.6404C20.816 19.0484 20.1563 19.0484 19.7526 18.6404C19.349 18.2324 19.3446 17.5727 19.7526 17.1691L21.4453 15.4763L16.6667 15.472ZM16.6667 6.44423H11.1111V0.888672L16.6667 6.44423Z"
        fill="#4B5962"
      />
    </g>
    <defs>
      <clipPath id="clip0_1578_6443">
        <rect
          width="25"
          height="22.2222"
          fill="white"
          transform="translate(0 0.888672)"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default ExitIcon;
