// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox"
import AddBoxIcon from "@mui/icons-material/AddBox"
import Checkbox from "@mui/material/Checkbox"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Button from "@mui/material/Button"
import CachedIcon from "@mui/icons-material/Cached"

import Table from "../../LocalElements/Table/Table"
import Input from "../../LocalElements/Input/Input"

import R from "../../../services/request.service"

import {
  setOpen,
  setTitle,
  setChildren,
  setBD,
  showBA,
  resetState,
} from "../../../store/slices/comps/modal"

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/comps/snackbar"

import { refreshCompanies } from "../../../store/slices/controlers/updater"

const FullAccess = (props) => {
  const { activeTariff } = props
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const companyId = useSelector((state) => state.companiesReducer.companyId)
  const tariffs = useSelector((state) => state.companiesReducer.tariffs)
  const statusSelector = useSelector((state) => state.companiesReducer.statusSelector)

  const [isDisabledBtn, setIsDisabledBtn] = useState(false)
  const [expandedTariff, setExpandedTariff] = useState(false)
  const [expandedDescription, setExpandedDescription] = useState(false)

  const [servicesDataMain, setServicesDataMain] = useState([])
  const [servicesDataExtra, setServicesDataExtra] = useState([])
  const [totalPricePostPayment, setTotalPricePostPayment] = useState(0)
  const [totalPricePrePayment, setTotalPricePrePayment] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)

  const [billingCountPostPayment, setBillingCountPostPayment] = useState({})
  const [billingCountPrePayment, setBillingCountPrePayment] = useState({})
  const [sameBillingUnits, setSameBillingUnits] = useState("")

  const [resetTriggerPostPay, setResetTriggerPostPay] = useState(false)
  const [resetTriggerPrePay, setResetTriggerPrePay] = useState(false)

  const [selectedServices, setSelectedServices] = useState({})
  const [selectedServicesExtra, setSelectedServicesExtra] = useState({})
  const [disabledServices, setDisabledServices] = useState({})

  const [tariffIdsPostPaymentForStore, setTariffIdsPostPaymentForStore] =
    useState([])
  const [tariffIdsPrePaymentForStore, setTariffIdsPrePaymentForStore] =
    useState([])

  const [combinedTariffIds, setCombinedTariffIds] = useState([])

  let translatedStatus

  switch (statusSelector) {
    case "active":
      translatedStatus = "Активный"
      break
    case "trial":
      translatedStatus = "Пробный период"
      break
    case "frozen":
      translatedStatus = "Замороженный"
      break
    case "limited":
      translatedStatus = "Ограниченный"
      break
    case "deleted":
      translatedStatus = "Удаленный"
      break
    default:
      translatedStatus = statusSelector
      break
  }

  useEffect(() => {
    const uniqueIds = [
      ...new Set(
        Object.values(selectedServices)
          .filter((service) => service.isRequired)
          .map((service) => service.tariffId)
      ),
    ]
    setTariffIdsPostPaymentForStore(uniqueIds)
  }, [selectedServices])

  useEffect(() => {
    const uniqueIds = [
      ...new Set(
        Object.values(selectedServicesExtra)
          .filter((service) => service.isRequired)
          .map((service) => service.tariffId)
      ),
    ]
    setTariffIdsPrePaymentForStore(uniqueIds)
  }, [selectedServicesExtra])

  useEffect(() => {
    const combinedIds = [
      ...tariffIdsPrePaymentForStore,
      ...tariffIdsPostPaymentForStore,
    ]
    setCombinedTariffIds(combinedIds)
  }, [tariffIdsPostPaymentForStore, tariffIdsPrePaymentForStore])

  const handleAccordionTariffChange = () => {
    setExpandedTariff((prev) => !prev)
  }

  const handleAccordionDescriptionChange = () => {
    setExpandedDescription((prev) => !prev)
  }

  const handleCountBillingPostPaymentChange = (value, id, rowBilling) => {
    const numericValue = parseInt(value.replace(/\D/g, ""))
    setBillingCountPostPayment((prev) => ({
      ...prev,
      [id]: numericValue,
    }))

    if (rowBilling === "Протокол") {
      setSameBillingUnits(value)
    }
  }

  const handleCountBillingPrePaymentChange = (value, id) => {
    const numericValue = parseInt(value.replace(/\D/g, ""))
    setBillingCountPrePayment((prev) => ({
      ...prev,
      [id]: numericValue,
    }))
  }

  const resetDataAllBillingCount = () => {
    setSameBillingUnits("")
    setResetTriggerPostPay((prev) => !prev)
    setResetTriggerPrePay((prev) => !prev)
  }

  const handleCheckboxChange = (event, itemId) => {
    const isChecked = event.target.checked

    setSelectedServices((prev) => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        isRequired: isChecked,
      },
    }))
  }

  const colDataPostPayment = [
    {
      field: "service",
      headerName: "Услуга",
      flex: 1.5,
    },
    {
      field: "description",
      headerName: "Описание",
      flex: 1.5,
    },
    {
      field: "billing",
      headerName: "Биллинг-единица",
      flex: 1,

      renderCell: (params) => {
        const { value } = params

        const billingText =
          value === "Протокол"
            ? `${value} <span style="color: #0084e2">*</span>`
            : value
        return <div dangerouslySetInnerHTML={{ __html: billingText }} />
      },
    },
    {
      field: "count",
      headerName: "Количество единиц",
      flex: 1,
      renderCell: (params) => {
        const { row } = params
        return (
          <Input
            placeholder={"Неизвестно"}
            className={"inputInTable"}
            value={
              row.billing === "Протокол"
                ? sameBillingUnits || ""
                : billingCountPostPayment[row.id] || ""
            }
            actions={{
              change: (value) => {
                handleCountBillingPostPaymentChange(value, row.id, row.billing)
              },
            }}
          />
        )
      },
    },
    {
      field: "prise",
      headerName: "Стоимость, ₽ (за единицу)",
      flex: 0.6,
      renderCell: (params) => {
        const { row } = params
        const countBillingValue =
          row.billing === "Протокол"
            ? sameBillingUnits
            : billingCountPostPayment[row.id]

        let priceToShow = row.prise

        if (row.threshold !== null && row.threshold !== undefined) {
          switch (true) {
            case countBillingValue >= 501 && countBillingValue <= 1000:
              priceToShow = "13.50"
              break
            case countBillingValue >= 1001 && countBillingValue <= 1500:
              priceToShow = "12.00"
              break
            case countBillingValue >= 1501 && countBillingValue <= 2000:
              priceToShow = "10.50"
              break
            case countBillingValue >= 2001 && countBillingValue <= 3000:
              priceToShow = "9.00"
              break
            case countBillingValue >= 3001 && countBillingValue <= 5000:
              priceToShow = "7.50"
              break
            case countBillingValue >= 5001 && countBillingValue <= 7000:
              priceToShow = "5.25"
              break
            case countBillingValue >= 7001:
              priceToShow = "4.00"
              break
            default:
              priceToShow = row.prise
          }
        }

        return (
          <div>
            {priceToShow}{" "}
            {row.threshold !== null && row.threshold !== undefined && (
              <span style={{ color: "#0084e2" }}> **</span>
            )}
          </div>
        )
      },
    },
    {
      field: "priseForService",
      headerName: "Стоимость услуги, ₽(за расчетный период)",
      flex: 0.84,
      renderCell: (params) => {
        const { row } = params

        const countBillingValue =
          row.billing === "Протокол"
            ? sameBillingUnits
            : billingCountPostPayment[row.id]
        let priceToShow = row.prise

        if (row.threshold !== null && row.threshold !== undefined) {
          switch (true) {
            case countBillingValue >= 501 && countBillingValue <= 1000:
              priceToShow = "13.50"
              break
            case countBillingValue >= 1001 && countBillingValue <= 1500:
              priceToShow = "12.00"
              break
            case countBillingValue >= 1501 && countBillingValue <= 2000:
              priceToShow = "10.50"
              break
            case countBillingValue >= 2001 && countBillingValue <= 3000:
              priceToShow = "9.00"
              break
            case countBillingValue >= 3001 && countBillingValue <= 5000:
              priceToShow = "7.50"
              break
            case countBillingValue >= 5001 && countBillingValue <= 7000:
              priceToShow = "5.25"
              break
            case countBillingValue >= 7001:
              priceToShow = "4.00"
              break
            default:
              priceToShow = row.prise
          }
        }

        let servicePrice = row.priseForService

        if (countBillingValue) {
          servicePrice = (countBillingValue * parseFloat(priceToShow)).toFixed(
            2
          )
        }

        return servicePrice
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Включить",
      flex: 0.5,
      getActions: (item) => {
        const localItem = item

        return [
          <div
            style={{
              display: "flex",
              flexFlow: "row",
            }}
          >
            <Checkbox
              checked={selectedServices[item.id]?.isRequired}
              onChange={(event) => handleCheckboxChange(event, item.id)}
              disabled={disabledServices[localItem.id]}
            />
          </div>,
        ]
      },
    },
  ]

  const colDataPrePayment = [
    {
      field: "service",
      headerName: "Услуга",
      flex: 1.5,
    },
    {
      field: "description",
      headerName: "Описание",
      flex: 1.5,
    },
    {
      field: "billing",
      headerName: "Биллинг-единица",
      flex: 0.8,
    },
    {
      field: "count",
      headerName: "Количество единиц",
      flex: 0.8,
      renderCell: (params) => {
        const { row } = params
        return (
          <Input
            placeholder={"Неизвестно"}
            className={"inputInTable"}
            value={billingCountPrePayment[row.id] || ""}
            actions={{
              change: (value) => {
                handleCountBillingPrePaymentChange(value, row.id)
              },
            }}
          />
        )
      },
    },
    {
      field: "prise",
      headerName: "Стоимость, ₽ (за единицу)",
      flex: 0.6,
    },
    {
      field: "priseForService",
      headerName: "Стоимость услуги, ₽(за расчетный период)",
      flex: 0.8,
      renderCell: (params) => {
        const { row } = params

        const countBillingValue = billingCountPrePayment[row.id]

        let servicePrice = row.priseForService

        if (countBillingValue) {
          servicePrice = (countBillingValue * parseFloat(row.prise)).toFixed(2)
        }

        return servicePrice
      },
    },
  ]

  useEffect(() => {
    if ( activeTariff === "fullAccess" ) {
      ( async () => {
        const { data: subscriptionService } = await R.subscriptionService(`/2`)

        let ratesArray = subscriptionService.data.rates
        ratesArray.sort((a, b) => a.id - b.id)

        const filteredMainArray = ratesArray.filter(
          (item) => !item.is_hidden && ![18, 19, 20, 21].includes(item.id)
        )
        setServicesDataMain(filteredMainArray)

        const filteredExtraArray = ratesArray.filter(
          (item) => !item.is_hidden && [18, 19, 20, 21].includes(item.id)
        )
        setServicesDataExtra(filteredExtraArray)

        const itemsData = {}
        const itemsDataExtra = {}
        ratesArray.forEach((item) => {
          if (![18, 19, 20, 21].includes(item.id)) {
            itemsData[item.id] = {
              id: item.id,
              isRequired: item.tariff.is_required,
              price: item.price,
              isHidden: item.is_hidden,
              threshold: item.threshold,
              billing: item.units,
              tariffId: item.tariff_id,
            }
          } else {
            itemsDataExtra[item.id] = {
              id: item.id,
              isRequired: item.tariff.is_required,
              price: item.price,
              isHidden: item.is_hidden,
              threshold: item.threshold,
              billing: item.units,
              tariffId: item.tariff_id,
            }
          }
        })

        setSelectedServices(itemsData)
        setSelectedServicesExtra(itemsDataExtra)

        const initialDisabledServices = {}
        ratesArray.forEach((item) => {
          initialDisabledServices[item.id] = item.tariff.is_required
        })
        setDisabledServices(initialDisabledServices)

        if (tariffs && tariffs.length > 0) {
          const updatedSelectedServices = { ...itemsData }
          Object.keys(updatedSelectedServices).forEach((itemId) => {
            const tariffId = updatedSelectedServices[itemId].tariffId
            if (tariffs.find((tariff) => tariff.tariffId === tariffId)) {
              updatedSelectedServices[itemId].isRequired = true
            }
          })
          setSelectedServices(updatedSelectedServices)
        }
      })()
    }
  }, [activeTariff, tariffs])

  useEffect(() => {
    if ( activeTariff === "fullAccess" ) {
      ( async () => {
        const { data: postPay } = await R.getBillingPostPay(`/${companyId}`)

        const data = postPay.data

        const idCount = {}

        for (const key in data) {
          const item = data[key]
          idCount[item.id] = item.count
        }

        setBillingCountPostPayment(idCount)
      })()
    }
  }, [companyId, activeTariff, resetTriggerPostPay])

  useEffect(() => {
    if (activeTariff === "fullAccess") {
      (async () => {
        const { data: prePay } = await R.getBillingPrePay(`/${companyId}`)

        const data = prePay.data

        const idCount = {}

        for (const key in data) {
          const item = data[key]
          idCount[item.id] = item.count
        }

        setBillingCountPrePayment(idCount)
      })()
    }
  }, [companyId, activeTariff, resetTriggerPrePay])

  useEffect(() => {
    let totalPrice = 0

    Object.keys(selectedServices).forEach((itemId) => {
      const row = selectedServices[itemId]

      if (row.isRequired && !row.isHidden) {
        const countBillingValue =
          row.billing === "Протокол"
            ? sameBillingUnits
            : billingCountPostPayment[row.id]

        let priceToShow = parseFloat(row.price)

        if (row.threshold !== null && row.threshold !== undefined) {
          switch (true) {
            case countBillingValue >= 501 && countBillingValue <= 1000:
              priceToShow = 13.5
              break
            case countBillingValue >= 1001 && countBillingValue <= 1500:
              priceToShow = 12.0
              break
            case countBillingValue >= 1501 && countBillingValue <= 2000:
              priceToShow = 10.5
              break
            case countBillingValue >= 2001 && countBillingValue <= 3000:
              priceToShow = 9.0
              break
            case countBillingValue >= 3001 && countBillingValue <= 5000:
              priceToShow = 7.5
              break
            case countBillingValue >= 5001 && countBillingValue <= 7000:
              priceToShow = 5.25
              break
            case countBillingValue >= 7001:
              priceToShow = 4.0
              break
            default:
              priceToShow = parseFloat(row.price)
          }
        }

        let servicePrice = 0

        if (countBillingValue) {
          servicePrice = (countBillingValue * priceToShow).toFixed(2)
        }

        totalPrice += parseFloat(servicePrice)
      }
    })

    setTotalPricePostPayment(totalPrice.toFixed(2))
  }, [billingCountPostPayment, sameBillingUnits, selectedServices])

  useEffect(() => {
    let totalPrice = 0

    Object.keys(selectedServicesExtra).forEach((itemId) => {
      const row = selectedServicesExtra[itemId]

      if (!row.isHidden) {
        const countBillingValue = billingCountPrePayment[row.id]

        let servicePrice = 0

        if (countBillingValue) {
          servicePrice = (countBillingValue * parseFloat(row.price)).toFixed(2)
        }

        totalPrice += parseFloat(servicePrice)
      }
    })

    setTotalPricePrePayment(totalPrice.toFixed(2))
  }, [billingCountPrePayment, selectedServicesExtra])

  useEffect(() => {
    const sum =
      parseFloat(totalPricePostPayment) + parseFloat(totalPricePrePayment)
    setTotalPrice(sum.toFixed(2))
  }, [totalPricePostPayment, totalPricePrePayment])

  const handleSave = async () => {
    setIsDisabledBtn(true)

    const data = {
      tariffs: combinedTariffIds,
    }

    let response
    if (statusSelector === "trial") {
      response = await R.tariffChangeActivate(data, companyId, "activate")
    } else {
      response = await R.tariffChange(data, companyId)
    }

    const { status, data: addTariffsResponse } = response

    if (status === 200) {
      dispatch(setMessage("Подписка 'Полный доступ' активирована"))
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(refreshCompanies())
      navigate("/metriva/companies")
    } else if (status === 422) {
      dispatch(setMessage("Ошибка валидации"))
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      setIsDisabledBtn(false)
    } else {
      dispatch(setMessage("Ошибка обращения к api"))
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      setIsDisabledBtn(false)
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetState())
    }
  }, [])

  return (
    <React.Fragment>
      <div className="box">
        <h2 style={{ marginBottom: "25px" }}>Тариф «Полный доступ»</h2>

        <Accordion
          expanded={expandedDescription}
          onChange={handleAccordionDescriptionChange}
          style={{
            backgroundColor: "#fffff",
            border: "1px solid #d7ddea",
            boxShadow: "none",
            borderRadius: "12px",
            padding: "14px 0",
            "&::before": {
              display: "none",
            },
            marginBottom: "20px",
          }}
        >
          <AccordionSummary
            expandIcon={null}
            aria-controls="panel1-content"
            id="panel1-header"
            style={{
              fontSize: "17px",
              fontWeight: 600,
              paddingTop: "0px",
            }}
          >
            { expandedDescription ? (
              <IndeterminateCheckBoxIcon
                fontSize="medium"
                sx={{
                  color: "#0084e2",
                  marginRight: "10px",
                  marginTop: "-1px",
                }}
              />
            ) : (
              <AddBoxIcon
                fontSize="medium"
                sx={{
                  color: "#0084e2",
                  marginRight: "10px",
                  marginTop: "-1px",
                }}
              />
            )}
            Краткое описание
          </AccordionSummary>
          <AccordionDetails
            style={{
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            Тариф «Полный доступ» предоставляет клиентам все возможности
            системы, включая синхронизацию с ФГИСами и пользовательскими
            системами. Пользователи могут без ограничений применять все
            инструменты и интерфейсы, интегрируя систему с реальными данными для
            полноценной работы. Рекомендуем этот тариф для максимальной
            эффективности и полной интеграции в рабочие процессы клиентов.
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expandedTariff}
          onChange={handleAccordionTariffChange}
          style={{
            backgroundColor: "#fffff",
            border: "1px solid #d7ddea",
            boxShadow: "none",
            borderRadius: "12px",
            padding: "14px 0",
            "&::before": {
              display: "none",
            },
            marginBottom: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={null}
            aria-controls="panel1-content"
            id="panel1-header"
            style={{
              fontSize: "17px",
              fontWeight: 600,
              paddingTop: "0px",
            }}
          >
            { expandedTariff ? (
              <IndeterminateCheckBoxIcon
                fontSize="medium"
                sx={{
                  color: "#0084e2",
                  marginRight: "10px",
                  marginTop: "-1px",
                }}
              />
            ) : (
              <AddBoxIcon
                fontSize="medium"
                sx={{
                  color: "#0084e2",
                  marginRight: "10px",
                  marginTop: "-1px",
                }}
              />
            )}
            Управление тарифом
          </AccordionSummary>
          <AccordionDetails
            style={{
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            <div
              style={{
                marginBottom: "15px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <h4 style={{ marginRight: "20px" }}>Расчет услуг (Постоплата)</h4>

              <Button
                variant="text"
                startIcon={<CachedIcon />}
                onClick={resetDataAllBillingCount}
                sx={{ mt: 1, mr: 1 }}
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "8px",
                  margin: 0,
                  borderRadius: "12px",
                  width: "190px",
                }}
              >
                Обновить данные
              </Button>
            </div>
            <Table
              css={{
                marginTop: "12px",
                backgroundColor: "#ffffff",
                border: "1px solid #d7ddea",
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "1.2",
                  wordWrap: "break-word",
                },
                "& .MuiDataGrid-cell:hover": {
                  cursor: "default",
                },
                "& .MuiDataGrid-cell": {
                  whiteSpace: "normal",
                  wordWrap: "break-word",

                  height: "auto",
                  lineHeight: "1.5",
                  padding: "8px",
                  boxSizing: "border-box",
                },
              }}
              rowData={servicesDataMain.map((item) => ({
                id: item.id,
                service: item?.title,
                description: item?.description,
                billing: item?.units,
                count: "",
                prise: item?.price,
                priseForService: item?.price,
                threshold: item?.threshold,
              }))}
              colData={colDataPostPayment}
              hideFooter={true}
              hasCheckboxSelection={false}
              getRowHeight={() => "auto"}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 22,
                  },
                },
              }}
            />

            <div style={{ marginTop: "16px" }}>
              <p style={{ paddingLeft: "10px" }}>
                <span style={{ color: "#0084e2" }}>*</span> - тарифицируются
                только протоколы отправленные во ФГИС Аршин и ФСА
              </p>
              <p style={{ marginBottom: "18px", paddingLeft: "10px" }}>
                <span style={{ color: "#0084e2" }}>**</span> - стоимость может
                меняться в зависимости от числа поверок{" "}
                <span
                  style={{
                    color: "#0084e2",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(setOpen(true))
                    dispatch(
                      setTitle(
                        "Стоимость может меняться в зависимости от числа поверок"
                      )
                    )

                    dispatch(
                      setChildren(
                        <div>
                          <p style={{ marginBottom: "8px" }}>
                            До 500 поверок в месяц - 15,00 ₽/шт.
                          </p>
                          <p style={{ marginBottom: "8px" }}>
                            От 501 до 1000 поверок в месяц - 13,50 ₽/шт.
                          </p>
                          <p style={{ marginBottom: "8px" }}>
                            От 1001 до 1500 поверок в месяц - 12,00 ₽/шт.
                          </p>
                          <p style={{ marginBottom: "8px" }}>
                            От 1501 до 2000 поверок в месяц - 10,50 ₽/шт.
                          </p>
                          <p style={{ marginBottom: "8px" }}>
                            От 2001 до 3000 поверок в месяц - 9,00 ₽/шт.
                          </p>
                          <p style={{ marginBottom: "8px" }}>
                            От 3001 до 5000 поверок в месяц - 7,50 ₽/шт.
                          </p>
                          <p style={{ marginBottom: "8px" }}>
                            От 5001 до 7000 поверок в месяц - 5,25 ₽/шт.
                          </p>
                          <p>Более 7001 поверок в месяц - 4,00 ₽/шт.</p>
                        </div>
                      )
                    )
                    dispatch(setBD("Cкрыть"))
                    dispatch(showBA(false))
                  }}
                >
                  см. подробнее
                </span>
              </p>
            </div>

            <h4 style={{ marginBottom: "15px" }}>
              Расчет услуг по лицензии (Предоплата)
            </h4>

            <Table
              css={{
                marginTop: "12px",
                backgroundColor: "#ffffff",
                border: "1px solid #d7ddea",
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "1.2",
                  wordWrap: "break-word",
                },
                "& .MuiDataGrid-cell:hover": {
                  cursor: "default",
                },
                "& .MuiDataGrid-cell": {
                  whiteSpace: "normal",
                  wordWrap: "break-word",

                  height: "auto",
                  lineHeight: "1.5",
                  padding: "8px",
                  boxSizing: "border-box",
                },
              }}
              colData={colDataPrePayment}
              rowData={servicesDataExtra.map((item) => ({
                id: item.id,
                service: item?.title,
                description: item?.description,
                billing: item?.units,
                count: "",
                prise: item?.price,
                priseForService: item?.price,
              }))}
              hideFooter={true}
              hasCheckboxSelection={false}
              getRowHeight={() => "auto"}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />

            <div
              className="flexContainerWithGap"
              style={{ margin: "16px 0 10px", paddingLeft: "10px" }}
            >
              <p>Действие тарифа:</p>
              <h4>{translatedStatus}</h4>
            </div>
            <div
              className="flexContainerWithGap"
              style={{ margin: "6px 0 10px", paddingLeft: "10px" }}
            >
              <p>Постоплата, ₽:</p>
              <h4>{totalPricePostPayment}</h4>
            </div>

            <div
              className="flexContainerWithGap"
              style={{ margin: "6px 0 10px", paddingLeft: "10px" }}
            >
              <p>Предоплата, ₽:</p>
              <h4>{totalPricePrePayment}</h4>
            </div>

            <div
              className="flexContainerWithGap"
              style={{ paddingLeft: "10px" }}
            >
              <p>Общая стоимость, ₽:</p>
              <h4>{totalPrice}</h4>
            </div>

            <div style={{ marginTop: "20px" }}>
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={isDisabledBtn}
                sx={{ mt: 1, mr: 1 }}
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "11px 20px 11px",
                  margin: 0,
                  borderRadius: "12px",
                  width: "100%",
                }}
              >
                { tariffs.length > 0
                  ? "Сохранить изменения"
                  : "Активировать тариф"}
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </React.Fragment>
  )
}

export default FullAccess
