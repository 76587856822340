import { createSlice } from "@reduxjs/toolkit";
import { createMethodology } from "../../store.interface";

const initialState = createMethodology;

const gost815683Reducer = createSlice({
  name: "gost815683Reducer",
  initialState,
  reducers: {
    changeProperty: (state, action) => {
      state[action.payload.value] = action.payload.label;
    },
    setDefaultState: (state) => {
      state = initialState;
    },
  },
});

export const { changeProperty, setDefaultState } = gost815683Reducer.actions;
export default gost815683Reducer.reducer;
