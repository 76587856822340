// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import Collapse from "@mui/material/Collapse"

import R from "../../services/request.service"

import Cases from "../../components/Methodologies/views/ProtocolCases/ProtocolCases"
import TypeMethod from "../../components/Methodologies/views/TypeMethod/TypeMethod"
import WaterMi1592Copy from "../../components/Methodologies/Water/Mi1592-2015/CopyProtocol"

import {
  changeCase,
  resetCase,
  resetMeasurementId,
  resetCompanyId,
  resetMethodId,
} from "../../store/slices/views/protocols"

import css from "../../components/Methodologies/styles.css"
import useWindowDimensions from "../../hooks/useWindowDimensions"
const { BoxContainer } = css

const Copy = () => {
  const { protocol_id } = useParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const width = useWindowDimensions()
  
  const isToggle = useSelector((state) => state.toggleReducer.isToggle)
  const caseId = useSelector((state) => state.protocolReducer.case.caseId)
  const pointId = useSelector((state) => state.protocolReducer.case.pointId)
  
  const [ data, setData ] = useState([])

  useEffect(() => {
    ( async () => {
      const { data } = await R.getProtocol(protocol_id)
      setData(data.data)

      dispatch(
        changeCase({
          value: "caseId",
          label: data.data?.originalCase?.id,
        })
      )
      dispatch(
        changeCase({
          value: "verificationType",
          label: data.data?.originalCase?.verificationType?.id,
        })
      )

      dispatch(
        changeCase({
          value: "pointId",
          label: data.data?.point?.id,
        })
      )

      const initialPoint = data.data.originalCase.points.map((item) => ({
        id: item.id,
        name: item.name,
        isOpticalSensor: item.is_optical_sensor,
      }))

      dispatch(
        changeCase({
          value: "pointMethod",
          label: initialPoint,
        })
      )
    })()
  }, [ protocol_id, dispatch ])

  const navigateToProtocol = () => {
    navigate("/metriva/protocols")
  }

  useEffect(() => {
    return () => {
      dispatch(resetCase())
      dispatch(resetMeasurementId())
      dispatch(resetCompanyId())
      dispatch(resetMethodId())
    }
  }, [])

  return (
    <main className={isToggle ? "main_open" : "main"}>
      <div className="path_to_back" onClick={navigateToProtocol}>
        <i className="fas fa-arrow-left icon_back" style={{ width: '30px', height: '30px', marginRight: '6px' }} />
        <p>Все протоколы</p>
      </div>

      { data && width > 1050 && data.id && <Cases data={data} /> }

      <Collapse in={!!caseId}>
        <BoxContainer margin={"5px 0px 0px"}>
          <TypeMethod />
        </BoxContainer>
      </Collapse>

      {( pointId === 1 || pointId === 3 ) && <WaterMi1592Copy />}
    </main>
  )
}

export default Copy
