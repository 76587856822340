import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  measurement_id: "",
  searchQuery: "",
  metersList: [],
  selectedMeters: [],
  meta: null | undefined,
  isLoading: true,
  filterParams: {
    methods: [],
    titles: [],
    customers: [],

    selectedMethods: [],
    selectedTitles: "",
    selectedCustomers: "",
    isLinkSI: null,
    isPDF: null
  },
  createMeterWater: {
    editIndex: null,
    meterIndex: null,
    checkMethod: "",
    numberRegistry: "",
    number: "",
    nameSi: "",
    typeSi: "",
    country: "",
    cityFactory: "",
    manufacturerFactory: "",
    mpiText: "",
    hot: null,
    cold: null,
    diameter: [],
    qLimitMinCold: "",
    qLimitMaxCold: "",
    qLimitMinHot: "",
    qLimitMaxHot: "",
    fgisLink: "",
    pdf: null,
    imgs: [],
    options: [],
    mods: [],
    mod: {
      title: "",
      diameter: "",
      koeff: "",
      water: null,
      vPositionQmin: "",
      vPositionQt: "",
      vPositionQmax: "",
      hPositionQmin: "",
      hPositionQt: "",
      hPositionQmax: "",
      additionals: []
    }
  },
  createMeterGas: {
    numberRegistry: "",
    number: "",
    nameSi: "",
    manufacturerFactory: "",
    mpiText: "",
    mpiValue: "",
  },
  selectedMeter: {
    manufacturer: "",
    city: "",
    country: "",
    numberRegistry: "",
    typeSi: "",
    number: "",
    nameSi: "",
    image: [],
    pdf: null,
    link: "",
    selectedModification: 0,
    mpiCold: "",
    mpiHot: "",
    qminLimitCold: "",
    qmaxLimitCold: "",
    qminLimitHot: "",
    qmaxLimitHot: "",
    variants: [],
  },
}

export const meterReducer = createSlice({
  name: "meterReducer",
  initialState,
  reducers: {
    setMeasurementId: (state, action) => {
      state.measurement_id = action.payload
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload
    },
    setSelectedMeters: (state, action) => {
      state.selectedMeters = action.payload
    },
    setList: (state, action) => {
      state.metersList = action.payload
    },
    setMeta: (state, action) => {
      state.meta = action.payload
    },
    changeFilterParams: (state, action) => {
      state.filterParams[action.payload.value] = action.payload.label
    },
    changeFilterMethods: (state, action) => {
      // state.filterParams.selectedMethods.push(action.payload)
      state.filterParams.selectedMethods = action.payload
    },
    changeFilterTitles: (state, action) => {
      // state.filterParams.titles.push(action.payload)
      state.filterParams.selectedTitles = action.payload
    },
    changeFilterCustomers: (state, action) => {
      // state.filterParams.customers.push(action.payload)
      state.filterParams.selectedCustomers = action.payload
    },
    changeMeterWater: (state, action) => {
      state.createMeterWater[action.payload.value] = action.payload.label
    },
    changeMeterWaterMod: (state, action) => {
      state.createMeterWater.mod[action.payload.value] = action.payload.label
    },
    changeMeterWaterMods: (state, action) => {
      state.createMeterWater.mods.push(action.payload)
    },
    changeMeterWaterOptions: (state, action) => {
      state.createMeterWater.options.push(action.payload)
    },
    changeMeterWaterInitial: (state, action) => {
      state.createMeterWater = action.payload
    },
    clearMeterWaterMods: (state, action) => {
      state.createMeterWater.mods = []
    },
    clearMeterWaterOptions: (state, action) => {
      state.createMeterWater.options = []
    },
    changeMeterGas: (state, action) => {
      state.createMeterGas[action.payload.value] = action.payload.label
    },
    setSelectedMeter: (state, action) => {
      state.selectedMeter[action.payload.value] = action.payload.label
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
})

export const {
  setMeasurementId,
  setSearchQuery,
  setSelectedMeters,
  setList,
  setMeta,
  changeFilterParams,
  changeFilterMethods,
  changeFilterTitles,
  changeFilterCustomers,
  changeMeterWater,
  changeMeterWaterMod,
  changeMeterWaterMods,
  changeMeterWaterOptions,
  changeMeterWaterInitial,
  clearMeterWaterMods,
  clearMeterWaterOptions,
  changeMeterGas,
  setSelectedMeter,
  setIsLoading,
} = meterReducer.actions
export default meterReducer.reducer
