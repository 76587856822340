// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState } from "react"
import useUpdate from "../hooks/useDataUpdater"
import { useSelector } from "react-redux"

const Updater = () => {
  const [
    updateCompanies,
    updateCases,
    updateVerifications,
    updateTickets,
    updateConditions,
    updateComplect,
    updateEtalon,
    updateMeters,
    updateUsers,
    updateProtocols,
    updateHeaderData,
  ] = useUpdate()

  const [currentPageProtocols] = useState(1)

  const companyId = useSelector((state) => state.companyIdReducer.companyId)
  const token = useSelector((state) => state.authReducer.authData.token)

  const measurement_id = useSelector((state) => state.meterReducer.measurement_id)
  const search_query = useSelector((state) => state.meterReducer.searchQuery)
  const search_query_users = useSelector((state) => state.usersReducer.searchQuery)
  const search_query_etalon = useSelector((state) => state.etalonReducer.searchQuery)
  const search_query_cases = useSelector((state) => state.casesReducer.searchQuery)
  const search_query_companies = useSelector((state) => state.companiesReducer.searchQuery)
  const companiesPing = useSelector((state) => state.updaterReducer.companiesPing)
  const casesPing = useSelector((state) => state.updaterReducer.casesPing)
  const verificationsPing = useSelector((state) => state.updaterReducer.verificationsPing)
  const ticketsPing = useSelector((state) => state.updaterReducer.ticketsPing)
  const conditionsPing = useSelector((state) => state.updaterReducer.conditionsPing)
  const complectsPing = useSelector((state) => state.updaterReducer.complectsPing)
  const dateFromJournal = useSelector((state) => state.journalReducer.dateFrom)
  const dateUntilJournal = useSelector((state) => state.journalReducer.dateUntil)
  const etalonsPing = useSelector((state) => state.updaterReducer.etalonsPing)
  const metersPing = useSelector((state) => state.updaterReducer.metersPing)
  const usersPing = useSelector((state) => state.updaterReducer.usersPing)
  const protocolsPing = useSelector((state) => state.updaterReducer.protocolsPing)
  const headerPing = useSelector((state) => state.updaterReducer.headerPing)
  const protocolSearchQuery = useSelector((state) => state.protocolReducer.searchQuery)
  const measurementIdFilter = useSelector((state) => state.protocolReducer.measurementIdFilter)
  const userId = useSelector((state) => state.protocolReducer.userId)
  const statusId = useSelector((state) => state.protocolReducer.statusId)

  const approvedTransfer = useSelector((state) => state.protocolReducer.approvedTransfer)
  const approvedArshin = useSelector((state) => state.protocolReducer.approvedArshin)
  const approvedFsa = useSelector((state) => state.protocolReducer.approvedFsa)
  const dateFrom = useSelector((state) => state.protocolReducer.dateFrom)
  const dateTo = useSelector((state) => state.protocolReducer.dateTo)

  useEffect(() => {
    token && updateCompanies(search_query_companies)
  }, [companiesPing, search_query_companies])
  useEffect(() => {
    token && updateCases(search_query_cases, companyId)
  }, [companyId, casesPing, search_query_cases])
  useEffect(() => {
    token && updateComplect(companyId, dateFromJournal, dateUntilJournal)
  }, [companyId, complectsPing, dateFromJournal, dateUntilJournal])
  useEffect(() => {
    token && updateEtalon(search_query_etalon, companyId)
  }, [companyId, etalonsPing, search_query_etalon])
  useEffect(() => {
    token && updateMeters(measurement_id, search_query)
  }, [metersPing, measurement_id, search_query])
  useEffect(() => {
    token && updateUsers(search_query_users, companyId)
  }, [usersPing, companyId, search_query_users])
  useEffect(() => {
    token &&
      updateProtocols(
        companyId,
        protocolSearchQuery,
        measurementIdFilter,
        userId,
        statusId,
        approvedTransfer,
        approvedArshin,
        approvedFsa,
        dateFrom,
        dateTo
      )
  }, [
    protocolsPing,
    companyId,
    protocolSearchQuery,
    measurementIdFilter,
    userId,
    statusId,
    approvedTransfer,
    approvedArshin,
    approvedFsa,
    dateFrom,
    dateTo,
  ])

  useEffect(() => {
    token && updateHeaderData()
  }, [headerPing])
  useEffect(() => {
    token && updateVerifications(companyId)
  }, [companyId, verificationsPing])
  useEffect(() => {
    token && updateTickets(companyId)
  }, [companyId, ticketsPing])
  useEffect(() => {
    token && updateConditions(companyId)
  }, [companyId, conditionsPing])

  useEffect(() => {
    false && console.log("updater is ready")
  }, [])
  return <React.Fragment></React.Fragment>
}

export default Updater
