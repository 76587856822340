import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const Replace = (props) => (
  <SvgIcon
    {...props}
    width="24"
    height="23"
    viewBox="0 0 24 23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.088 0.5C6.73713 0.5 2.33833 4.5889 1.90944 9.80225H0.837228C0.498012 9.80225 0.192322 10.0069 0.0631257 10.3206C-0.0660707 10.6342 0.00676425 10.9948 0.247566 11.2338L2.12269 13.0942C2.4491 13.4181 2.9756 13.4181 3.30201 13.0942L5.17713 11.2338C5.41794 10.9948 5.49077 10.6342 5.36158 10.3206C5.23238 10.0069 4.92669 9.80225 4.58747 9.80225H3.59082C4.01468 5.52508 7.6518 2.1744 12.088 2.1744C15.1795 2.1744 17.8857 3.80243 19.3847 6.24074C19.6268 6.63463 20.1424 6.75764 20.5363 6.51549C20.9302 6.27333 21.0532 5.75771 20.8111 5.36382C19.018 2.44708 15.7811 0.5 12.088 0.5Z"
      fill="#899298"
    />
    <path
      d="M21.869 9.67192C21.5429 9.34957 21.0181 9.34957 20.692 9.67192L18.8097 11.5324C18.5682 11.771 18.4948 12.1319 18.6238 12.4459C18.7528 12.76 19.0587 12.965 19.3982 12.965H20.4018C19.9763 17.2398 16.3265 20.5929 11.869 20.5929C8.763 20.5929 6.04557 18.9634 4.54094 16.5253C4.29811 16.1318 3.78229 16.0097 3.38881 16.2525C2.99533 16.4953 2.8732 17.0112 3.11603 17.4047C4.91613 20.3216 8.16446 22.2673 11.869 22.2673C17.2359 22.2673 21.6528 18.1809 22.0833 12.965H23.1628C23.5023 12.965 23.8082 12.76 23.9372 12.4459C24.0662 12.1319 23.9928 11.771 23.7513 11.5324L21.869 9.67192Z"
      fill="#899298"
    />
  </SvgIcon>
);

export default Replace;
