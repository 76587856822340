// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";

import D from "../../store/localStorage/dispatcher";
import R from "../../services/request.service";

import SelectedCompany from "../../components/Users/SelectedCompany";
import DataUser from "../../components/Users/DataUser";
import SelectedRole from "../../components/Users/SelectedRole";
import SelectedRegions from "../../components/Users/SelectedRegions";
import SelectedCases from "../../components/Users/SelectedCases";
import SelectedUsers from "../../components/Users/SelectedUsers";
import { changeData, resetCreateData } from "../../store/slices/views/users";
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../store/slices/comps/snackbar";
import { refreshUsers, refreshHeader } from "../../store/slices/controlers/updater";

import Feedback from "../../components/LocalElements/Feedback/Feedback";
import { setShow } from "../../store/slices/comps/feedback";

const EditUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { users_id } = useParams();

  const isToggle = useSelector((state) => state.toggleReducer.isToggle);
  const show = useSelector((state) => state.feedbackReducer.show);

  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole");
  const userRole = useSelector((state) => state.headerReducer.role);

  const [isDisabledBtn, setIsDisabledBtn] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const companyId = useSelector(
    (state) => state.usersReducer.createData.companyId
  );
  const regionIds = useSelector(
    (state) => state.usersReducer.createData.regionIds
  );
  const rolesId = useSelector((state) => state.usersReducer.createData.rolesId);
  const casesIds = useSelector(
    (state) => state.usersReducer.createData.casesIds
  );
  const userId = useSelector((state) => state.usersReducer.createData.userId);
  const usersId = useSelector((state) => state.usersReducer.createData.usersId);
  const isTrusted = useSelector(
    (state) => state.usersReducer.createData.isTrusted
  );
  const name = useSelector((state) => state.usersReducer.createData.name);
  const surname = useSelector((state) => state.usersReducer.createData.surname);
  const patr = useSelector((state) => state.usersReducer.createData.patr);
  const login = useSelector((state) => state.usersReducer.createData.login);
  const password = useSelector(
    (state) => state.usersReducer.createData.password
  );
  const email = useSelector((state) => state.usersReducer.createData.email);

  const handleTrustedChange = (event) => {
    const newValue = event.target.checked;
    dispatch(
      changeData({
        value: "isTrusted",
        label: newValue,
      })
    );
  };

  useEffect(() => {
    (async () => {
      dispatch(setShow(true));
      try {
        const { data: usersData } = await R.getUsersItem(users_id);
        const data = usersData.data;

        dispatch(
          changeData({
            value: "surname",
            label: data.surname,
          })
        );
        dispatch(
          changeData({
            value: "name",
            label: data.name,
          })
        );
        dispatch(
          changeData({
            value: "patr",
            label: data.patr,
          })
        );
        dispatch(
          changeData({
            value: "email",
            label: data.email,
          })
        );
        dispatch(
          changeData({
            value: "login",
            label: data.login,
          })
        );

        const initialRegionIds = data?.regions?.map((region) => region.id);
        const initialUsersIds = data?.metrologists?.map(
          (metrologists) => metrologists.id
        );
        const initialCases = data?.cases?.map((caseItem) => caseItem.id);

        dispatch(
          changeData({
            value: "companyId",
            label: data.company.id,
          })
        );
        dispatch(
          changeData({
            value: "regionIds",
            label: initialRegionIds,
          })
        );
        dispatch(
          changeData({
            value: "casesIds",
            label: initialCases,
          })
        );
        dispatch(
          changeData({
            value: "usersId",
            label: initialUsersIds,
          })
        );
        dispatch(
          changeData({
            value: "userId",
            label: data.brigadier_id,
          })
        );
        dispatch(
          changeData({
            value: "rolesId",
            label: data.role,
          })
        );
        dispatch(
          changeData({
            value: "isTrusted",
            label: data.is_trusted,
          })
        );
      } catch (error) {
        console.error(error);
      }
      dispatch(setShow(false));
    })();
  }, [users_id, dispatch]);

  const handleSave = async () => {
    setIsDisabledBtn(true);

    const data = {
      name: name,
      surname: surname,
      patr: patr,
      login: login,
      email: email,
      region_ids: regionIds,
      case_ids: casesIds,
      role: rolesId,
      brigadier_id: userId,
      metrologist_ids: usersId,
    };

    if (userRole === "super" && companyId) {
      data.company_id = companyId;
    }

    if (rolesId === "metrologist") {
      data.is_trusted = isTrusted;
    }

    if (password.trim() !== "") {
      data.password = password;
    }

    const { status, data: usersAddData } = await R.addUserPut(users_id, data);

    if (status === 200) {
      dispatch(refreshUsers());
      dispatch(refreshHeader());
      dispatch(setMessage("Пользователь успешно отредактирован"));
      dispatch(setType("success"));
      dispatch(setOpenAlert(true));
      navigate("/metriva/users");
    } else {
      if (usersAddData.errors) {
        const errors = usersAddData.errors;
        setValidationErrors(errors);
      }
      dispatch(setType("error"));
      dispatch(setOpenAlert(true));
      dispatch(setMessage("Пользователь не отредактирован - ошибка валидации"));
      setIsDisabledBtn(false);
    }
  };

  const navigateToProtocols = () => {
    navigate("/metriva/users");
  };

  useEffect(() => {
    return () => {
      dispatch(resetCreateData());
    };
  }, []);

  return (
    <main className={isToggle ? "main_open" : "main"}>
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <div className="path_to_back" onClick={navigateToProtocols}>
            <i
              className="fas fa-arrow-left icon_back"
              style={{ width: "30px", height: "30px", marginRight: "6px" }}
            />
            <p>Все пользователи</p>
          </div>

          <div className="box">
            <h2 style={{ marginBottom: "20px" }}>Личные данные</h2>
            <DataUser validationErrors={validationErrors} />
            {rolesId !== "super" && (
              <SelectedRegions validationErrors={validationErrors} />
            )}
          </div>

          <div className="box">
            <h2 style={{ marginBottom: "20px" }}>Рабочие данные</h2>
            <div className="flexContainerWithGap">
              <SelectedRole />

              {userRole === "super" && rolesId !== "super" && (
                <React.Fragment>
                  <SelectedCompany />
                  {validationErrors["company_id"] && (
                    <div className="error">
                      {validationErrors["company_id"]}
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>

            <div className="flexContainerWithGap" style={{ marginTop: "20px" }}>
              <div className="width32Percent">
                <Collapse
                  in={rolesId === "brigadier" || rolesId === "metrologist"}
                >
                  <SelectedUsers />
                </Collapse>
              </div>
              <div className="width32Percent">
                <Collapse
                  in={rolesId === "brigadier" || rolesId === "metrologist"}
                >
                  <SelectedCases />
                </Collapse>
              </div>
            </div>

            {rolesId === "metrologist" && (
              <div
                style={{
                  marginTop: "15px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <p>Опытный Поверитель :</p>
                <Checkbox checked={isTrusted} onChange={handleTrustedChange} />
              </div>
            )}
          </div>

          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isDisabledBtn}
              sx={{ mt: 1, mr: 1 }}
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: "11px 20px 11px",
                margin: 0,
                borderRadius: "12px",
                width: "100%",
              }}
            >
              Редактировать пользователя
            </Button>
          </div>
        </React.Fragment>
      )}
    </main>
  );
};

export default EditUsers;
