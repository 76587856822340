import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const DownLoad = (props) => (
  <SvgIcon {...props} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 14.75C3.91421 14.75 4.25 15.0858 4.25 15.5C4.25 16.9354 4.25159 17.9365 4.35315 18.6919C4.4518 19.4257 4.63225 19.8142 4.90901 20.091C5.18577 20.3678 5.57435 20.5482 6.30812 20.6469C7.06347 20.7484 8.06459 20.75 9.5 20.75H15.5C16.9354 20.75 17.9365 20.7484 18.6919 20.6469C19.4257 20.5482 19.8142 20.3678 20.091 20.091C20.3678 19.8142 20.5482 19.4257 20.6469 18.6919C20.7484 17.9365 20.75 16.9354 20.75 15.5C20.75 15.0858 21.0858 14.75 21.5 14.75C21.9142 14.75 22.25 15.0858 22.25 15.5V15.5549C22.25 16.9225 22.25 18.0248 22.1335 18.8918C22.0125 19.7919 21.7536 20.5497 21.1517 21.1516C20.5497 21.7536 19.7919 22.0125 18.8918 22.1335C18.0248 22.25 16.9225 22.25 15.5549 22.25H9.44513C8.07754 22.25 6.97522 22.25 6.10825 22.1335C5.20814 22.0125 4.45027 21.7536 3.84835 21.1517C3.24643 20.5497 2.98754 19.7919 2.86652 18.8918C2.74996 18.0248 2.74998 16.9225 2.75 15.5549C2.75 15.5366 2.75 15.5183 2.75 15.5C2.75 15.0858 3.08579 14.75 3.5 14.75Z"
      fill="#4B5962"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 2.75C12.7106 2.75 12.9114 2.83852 13.0535 2.99392L17.0535 7.36892C17.333 7.67462 17.3118 8.14902 17.0061 8.42852C16.7004 8.70802 16.226 8.68678 15.9465 8.38108L13.25 5.4318V16.5C13.25 16.9142 12.9142 17.25 12.5 17.25C12.0858 17.25 11.75 16.9142 11.75 16.5V5.4318L9.05353 8.38108C8.77403 8.68678 8.29963 8.70802 7.99393 8.42852C7.68823 8.14902 7.66698 7.67462 7.94648 7.36892L11.9465 2.99392C12.0886 2.83852 12.2894 2.75 12.5 2.75Z"
      fill="#4B5962"
    />
  </SvgIcon>
);

export default DownLoad;
