import React, { useState, useEffect } from "react"
import Selector from "../LocalElements/Selector/reactSelect/Selector"
import { useDispatch, useSelector } from "react-redux"

import { changeData } from "../../store/slices/views/users"
import R from "../../services/request.service"

import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

const SelectedCases = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const companyId = useSelector(
    (state) => state.usersReducer.createData.companyId
  )

  const casesIds = useSelector(
    (state) => state.usersReducer.createData.casesIds
  )

  const options = data
    ? data.map((item) => ({
        value: item.id,
        label: item.inner_name,
      }))
    : []

  const getValue = (selectedOptions) => {
    const selectedIds = selectedOptions.map((options) => options.value)
    dispatch(changeData({ value: "casesIds", label: selectedIds }))
  }

  useEffect(() => {
    ( async () => {
      const { data: listCases } = await R.getCasesItem(
        `?company_id=${companyId}`
      )
      const data = listCases.data
      setData(data)
    })()
  }, [companyId])

  return (
    <React.Fragment>
      <span
        style={{
          marginBottom: "9px",
          display: "flex",
          alignItems: "center",
        }}
      >
        Комплект СП
        { !options.filter((option) => casesIds.includes(option.value)) ? (
          <ErrorIcon
            sx={{
              color: "#FFD43B",
              marginLeft: "8px",
              marginTop: "-2px",
            }}
          />
        ) : (
          <CheckCircleIcon
            sx={{ color: "#63E6BE", marginLeft: "8px", marginTop: "-2px" }}
          />
        )}
      </span>
      <Selector
        placeholder={"Выберите комплекты СП"}
        options={options}
        value={options.filter((option) => casesIds.includes(option.value))}
        onChange={getValue}
        isMulti={true}
        isSearchable={false}
        noOptionsMessage={() => "Нет доступных комплектов"}
      />
    </React.Fragment>
  )
}

export default SelectedCases
