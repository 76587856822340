import { createSlice } from "@reduxjs/toolkit"
import { createMethodology } from "../../store.interface"

const initialState = createMethodology;

const exampleMethodologyReducer = createSlice({
  name: "exampleMethodologyReducer",
  initialState,
  reducers: {
    changeProperty: (state, action) => {
      state[action.payload.value] = action.payload.label;
    },
    setDefaultState: (state) => {
      state = initialState;
    },
  },
});

export const { changeProperty, setDefaultState } =
  exampleMethodologyReducer.actions;
export default exampleMethodologyReducer.reducer;
