// ----------------------------------------------------------------
/* eslint-disable array-callback-return */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import DatePicker from "react-datepicker";
import { startOfDay, addDays, isAfter } from "date-fns";
import moment from "moment";

import EditShowCompany from "./EditShowCompanies";
import Tariffs from "./Tarrifs";

import BasicTabs from "../../components/LocalElements/TabsMenu/TabsMenu";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import R from "../../services/request.service";

import {
  setViewVariant,
  setTariff,
  setCompanyId,
  setTariffs,
  setStatus,
} from "../../store/slices/views/companies";
import { setMessage, setOpen } from "../../store/slices/comps/snackbar";

const ShowCompany = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isToggle = useSelector((state) => state.toggleReducer.isToggle);
  const { company_id } = useParams();
  const { width } = useWindowDimensions();

  const [companyData, setCompanyData] = useState([]);

  const viewVariant = useSelector(
    (state) => state.companiesReducer.viewVariant
  );

  const navigateToProtocols = () => {
    navigate("/metriva/companies");
  };

  useEffect(() => {
    (async () => {
      const { data: companiesItemData } = await R.getCompaniesItem(company_id);
      const data = companiesItemData.data;
      setCompanyData(data);

      dispatch(setTariff(data.subscription_id));
      dispatch(setCompanyId(data.id));

      const initialTariffs = data.tariffs.map((item) => ({
        tariffId: item.id,
      }));

      dispatch(setTariffs(initialTariffs));

      dispatch(setStatus(data.status));
    })();
  }, [company_id, dispatch]);

  const changeViewVariant = (param) => dispatch(setViewVariant(param));

  return (
    <React.Fragment>
      <main className={isToggle ? "main_open" : "main"}>
        <div className="path_to_back" onClick={navigateToProtocols}>
          <span
            style={{ width: "30px", height: "30px", marginRight: "6px" }}
            className="fas fa-arrow-left icon_back"
          />
          <p>Все компании</p>
        </div>
        <React.Fragment>
          <div className="path_to_edit" style={{ marginBottom: "0px" }}>
            <h4>Компания: "{companyData && companyData.name}"</h4>
          </div>

          <BasicTabs
            callback={changeViewVariant}
            viewVariant={viewVariant}
            tabs={
              width > 1050 &&
              process.env.REACT_APP_API_URL === "https://app-develop.metriva.ru"
                ? [
                    { label: "Общие данные" },
                    { label: "Финансовый блок" },
                    { label: "Информация о тарифах" },
                  ]
                : width <= 1050 &&
                  process.env.REACT_APP_API_URL ===
                    "https://app-develop.metriva.ru"
                ? [{ label: "Общие данные" }]
                : width > 1050 &&
                  process.env.REACT_APP_API_URL !==
                    "https://app-develop.metriva.ru"
                ? [{ label: "Общие данные" }, { label: "Финансовый блок" }]
                : width <= 1050 &&
                  process.env.REACT_APP_API_URL !==
                    "https://app-develop.metriva.ru"
                ? [{ label: "Общие данные" }]
                : null
            }
          />

          {viewVariant === 0 && <EditShowCompany />}

          {viewVariant === 1 && (
            <div className="box" style={{ paddingTop: "19px" }}>
              <h2 style={{ marginBottom: "15px" }}>Финансовая информация</h2>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "13px",
                }}
              >
                <span>Название компании</span>
                <span style={{ fontWeight: 600 }}>
                  {companyData && companyData.name}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "13px",
                }}
              >
                <span>Дата активации аккаунта</span>
                <span style={{ fontWeight: 600 }}>
                  {companyData.activation_date
                    ? moment(companyData.activation_date).format("DD-MM-YYYY")
                    : "Аккаунт не активирован"}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "14px",
                }}
              >
                <span>Начало текущего расчетного периода</span>
                <span style={{ fontWeight: 600 }}>
                  {companyData.postpayment_from
                    ? companyData.postpayment_from
                    : "Расчетный период отсутствует"}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: "14px",
                }}
              >
                <span>Дата окончания текущей лицензии</span>
                <span style={{ fontWeight: 600 }}>
                  {companyData.licensed_to
                    ? moment(companyData.licensed_to).format("DD-MM-YYYY")
                    : "Лицензия не активирована"}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span>Действующие тарифы</span>
                {companyData.tarrifs ? (
                  <span>
                    <span style={{ cursor: "pointer", color: "#0084E2" }}>
                      За лицензии
                    </span>
                    <span> / </span>
                    <span style={{ cursor: "pointer", color: "#0084E2" }}>
                      Оплата за услуги
                    </span>
                    <span> / </span>
                    <span style={{ cursor: "pointer", color: "#0084E2" }}>
                      Дополнительные услуги
                    </span>
                  </span>
                ) : (
                  <span style={{ cursor: "pointer", color: "#0084E2" }}>
                    Нет активных тарифов
                  </span>
                )}
              </div>
              <hr
                style={{
                  display: "block",
                  position: "relative",
                  width: "100%",
                  height: "1px",
                  backgroundColor: "#d7ddea",
                  opacity: 0.6,
                  marginTop: "15px",
                  marginBottom: "10px",
                  border: "none",
                }}
              />
              <div
                className="show_protocol_meter"
                style={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span>Оплачено за лицензии по предоплате</span>
                <span style={{ fontWeight: 600 }}>
                  {moment(companyData.licensed_to).format("DD-MM-YYYY") ??
                    "Лицензия не активирована"}
                </span>
              </div>
              <div
                className="show_protocol_meter"
                style={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "flex-end",
                  width: "100%",
                  marginTop: "30px",
                  marginBottom: "26px",
                }}
              >
                <div className="date_picker" style={{ marginRight: "12px" }}>
                  <span
                    className="far fa-calendar-alt"
                    style={{
                      color: "#0084e2",
                      marginRight: "8px",
                    }}
                  />
                  <DatePicker
                    className="date_picker_input"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Начало периода"
                    maxDate={new Date()}
                    filterDate={(date) =>
                      isAfter(date, addDays(startOfDay(new Date()), -40))
                    }
                    selected={null}
                    onChange={(date) => {}}
                    disabled={companyData.tarrifs ? false : true}
                  />
                </div>
                <div className="date_picker">
                  <span
                    className="far fa-calendar-alt"
                    style={{
                      color: "#0084e2",
                      marginRight: "8px",
                    }}
                  />
                  <DatePicker
                    className="date_picker_input"
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Окончание периода"
                    maxDate={new Date()}
                    filterDate={(date) =>
                      isAfter(date, addDays(startOfDay(new Date()), -40))
                    }
                    selected={null}
                    onChange={(date) => {}}
                    disabled={companyData.tarrifs ? false : true}
                  />
                </div>
              </div>
              {companyData.tarrifs ? (
                <section>
                  <div
                    className="show_protocol_meter"
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      height: "48px",
                      padding: "8px 16px 11px",
                      borderRadius: "6px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        textAlign: "left",
                        width: "60%",
                      }}
                    >
                      Услуги за период с 12 октября 2023 по 05 января 2024
                    </span>
                    <span
                      style={{
                        fontWeight: 600,
                        textAlign: "right",
                        width: "20%",
                      }}
                    >
                      {"Количество"}
                    </span>
                    <span
                      style={{
                        fontWeight: 600,
                        textAlign: "right",
                        width: "20%",
                      }}
                    >
                      {"Стоимость"}
                    </span>
                  </div>
                  <div
                    className="show_protocol_meter"
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      backgroundColor: "#F8F9FC",
                      height: "48px",
                      padding: "8px 16px 11px",
                      borderRadius: "6px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 400,
                        textAlign: "left",
                        width: "60%",
                      }}
                    >
                      Передача данных в АРШИН и ФСА (от 501 до 1000)
                    </span>
                    <span
                      style={{
                        fontWeight: 300,
                        textAlign: "right",
                        width: "20%",
                      }}
                    >
                      {"100"}
                    </span>
                    <span
                      style={{
                        fontWeight: 500,
                        textAlign: "right",
                        width: "20%",
                      }}
                    >
                      {"22000"}
                    </span>
                  </div>
                  <div
                    className="show_protocol_meter"
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      height: "48px",
                      padding: "8px 16px 11px",
                      borderRadius: "6px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 400,
                        textAlign: "left",
                        width: "60%",
                      }}
                    >
                      Хранение протокола в облаке
                    </span>
                    <span
                      style={{
                        fontWeight: 300,
                        textAlign: "right",
                        width: "20%",
                      }}
                    >
                      {"100"}
                    </span>
                    <span
                      style={{
                        fontWeight: 500,
                        textAlign: "right",
                        width: "20%",
                      }}
                    >
                      {"22000"}
                    </span>
                  </div>
                  <div
                    className="show_protocol_meter"
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      backgroundColor: "#F8F9FC",
                      height: "48px",
                      padding: "8px 16px 11px",
                      borderRadius: "6px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 400,
                        textAlign: "left",
                        width: "60%",
                      }}
                    >
                      Формирование протокола из системы
                    </span>
                    <span
                      style={{
                        fontWeight: 300,
                        textAlign: "right",
                        width: "20%",
                      }}
                    >
                      {"100"}
                    </span>
                    <span
                      style={{
                        fontWeight: 500,
                        textAlign: "right",
                        width: "20%",
                      }}
                    >
                      {"22000"}
                    </span>
                  </div>
                  <div
                    className="show_protocol_meter"
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      height: "48px",
                      padding: "8px 16px 11px",
                      borderRadius: "6px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 400,
                        textAlign: "left",
                        width: "60%",
                      }}
                    >
                      Подключение учетной записи Метролога/Руководителя
                    </span>
                    <span
                      style={{
                        fontWeight: 300,
                        textAlign: "right",
                        width: "20%",
                      }}
                    >
                      {"100"}
                    </span>
                    <span
                      style={{
                        fontWeight: 500,
                        textAlign: "right",
                        width: "20%",
                      }}
                    >
                      {"22000"}
                    </span>
                  </div>
                  <div
                    className="show_protocol_meter"
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      backgroundColor: "#F8F9FC",
                      height: "48px",
                      padding: "8px 16px 11px",
                      borderRadius: "6px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        textAlign: "left",
                        width: "60%",
                      }}
                    >
                      Итого к оплате за расчётный период
                    </span>
                    <span
                      style={{
                        fontWeight: 300,
                        textAlign: "right",
                        width: "20%",
                      }}
                    >
                      {""}
                    </span>
                    <span
                      style={{
                        fontWeight: 500,
                        textAlign: "right",
                        width: "20%",
                      }}
                    >
                      {"22000"}
                    </span>
                  </div>
                </section>
              ) : (
                <React.Fragment>
                  <div
                    className="show_protocol_meter"
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      borderRadius: "6px",
                      marginBottom: "6px",
                    }}
                  >
                    <Skeleton animation="wave" width={"100%"} height={50} />
                  </div>
                  <div
                    className="show_protocol_meter"
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      borderRadius: "6px",
                      marginBottom: "6px",
                    }}
                  >
                    <Skeleton animation="wave" width={"100%"} height={50} />
                  </div>
                  <div
                    className="show_protocol_meter"
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      borderRadius: "6px",
                      marginBottom: "6px",
                    }}
                  >
                    <Skeleton animation="wave" width={"100%"} height={50} />
                  </div>
                  <div
                    className="show_protocol_meter"
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      borderRadius: "6px",
                    }}
                  >
                    <Skeleton animation="wave" width={"100%"} height={50} />
                  </div>
                </React.Fragment>
              )}
            </div>
          )}

          {viewVariant === 2 && <Tariffs />}
        </React.Fragment>
      </main>
    </React.Fragment>
  );
};

export default ShowCompany;
