// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import DatePicker from "../../LocalElements/DatePicker/DatePicker"
import Input from "../../LocalElements/Input/InputMUI"
import SelectComplect from "../Select/SelectComplect"
import SelectCompany from "../Select/SelectCompany"
import D from "../../../store/localStorage/dispatcher"
import R from "../../../services/request.service"

import {
  changeComplect,
  resetCreateComplect,
} from "../../../store/slices/views/journals"
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/comps/snackbar"
import { refreshComplects } from "../../../store/slices/controlers/updater"

const AddJournalComplect = () => {
  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector((state) => state.headerReducer.role)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)

  const [validationErrors, setValidationErrors] = useState({})
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const [companyId, setCompanyId] = useState(null)

  const casesId = useSelector((state) => state.journalReducer.createComplect.casesId)
  const issuer = useSelector((state) => state.journalReducer.createComplect.issuer)

  const handleIssuerChange = (param) => {
    const value = param.replace(/[^\D]/g, "")
    dispatch(changeComplect({ value: "issuer", label: value }))
  }

  const receiver = useSelector((state) => state.journalReducer.createComplect.receiver)

  const handleReceiverChange = (param) => {
    const value = param.replace(/[^\D]/g, "")
    dispatch(changeComplect({ value: "receiver", label: value }))
  }

  const dateJournal = useSelector((state) => state.journalReducer.createComplect.dateJournal)

  false && console.log("dateJournal", dateJournal)

  const handleDateChange = (date) => {
    if (date) {
      dispatch(
        changeComplect({
          value: "dateJournal",
          label: date.format("YYYY-MM-DD HH:mm:ss"),
        })
      )
    }
  }

  const isSueMark = useSelector((state) => state.journalReducer.createComplect.isSueMark)
  const isReturnMark = useSelector((state) => state.journalReducer.createComplect.isReturnMark)

  const handleIssueMarkChange = (event) => {
    const isChecked = event.target.checked
    dispatch(
      changeComplect({
        value: "isSueMark",
        label: event.target.checked,
      })
    )

    if (!isChecked) {
      dispatch(
        changeComplect({
          value: "isReturnMark",
          label: false,
        })
      )
    }
  }

  const handleReturnMarkChange = (event) => {
    dispatch(
      changeComplect({
        value: "isReturnMark",
        label: event.target.checked,
      })
    )
  }

  const navigateToProtocols = () => {
    navigate("/metriva/journals")
  }

  const handleSave = async () => {
    setIsDisabledBtn(true)
    const data = {
      case_id: casesId,
      issue_at: dateJournal,
      issue_mark: isSueMark,
      return_mark: isReturnMark,
      issuer_name: issuer,
      receiver_name: receiver,
    }

    if (userRole === "super") {
      data.company_id = companyId
    }

    const { status, data: complectAddData } = await R.addComplect(data)

    if (status === 201) {
      dispatch(setMessage("Эталон успешно создан"))
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(resetCreateComplect())
      dispatch(refreshComplects())
      navigate("/metriva/journals")
    } else {
      if (complectAddData.errors) {
        const errors = complectAddData.errors
        setValidationErrors(errors)
      }
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Эталон не добавлен - ошибка валидации"))
      setIsDisabledBtn(false)
    }
  }

  return (
    <main className={isToggle ? "main_open" : "main"}>
      <div className="path_to_back" onClick={navigateToProtocols}>
        <i className="fas fa-arrow-left icon_back" style={{ width: '30px', height: '30px', marginRight: '6px' }} />
        <p>Все журналы</p>
      </div>

      <div className="path_to_edit">
        <h4>Журнал выдачи средств поверки</h4>
      </div>

      <div className="box">
        {userRole === "super" && (
          <div className="width32Percent" style={{ marginBottom: "15px" }}>
            <h2 style={{ marginBottom: "15px" }}>Компания</h2>
            <SelectCompany companyId={companyId} setCompanyId={setCompanyId} />
            {validationErrors["company_id"] && (
              <div className="error">{validationErrors["company_id"]}</div>
            )}
          </div>
        )}

        <h2 style={{ marginBottom: "15px" }}>Информация о журнале</h2>
        <div className="flexContainerWithGap" style={{ marginBottom: "15px" }}>
          <div className="width32Percent">
            <SelectComplect
              companyId={companyId}
              validationErrors={validationErrors}
            />
          </div>

          <div className="width32Percent">
            <Input
              label={"Выдавший (должность, Ф.И.О.)"}
              type={"text"}
              placeholder={"Введите должность и ФИО выдавшего"}
              value={issuer || ""}
              actions={{
                change: handleIssuerChange,
              }}
            />
            {!issuer && validationErrors["issuer_name"] && (
              <div className="error">{validationErrors["issuer_name"]}</div>
            )}
          </div>

          <div className="width32Percent">
            <Input
              label={"Принявший (должность, Ф.И.О.)"}
              type={"text"}
              placeholder={"Введите должность и ФИО принявшего"}
              value={receiver || ""}
              actions={{
                change: handleReceiverChange,
              }}
            />
            {!receiver && validationErrors["receiver_name"] && (
              <div className="error">{validationErrors["receiver_name"]}</div>
            )}
          </div>
        </div>

        <div className="flexContainerWithGap" style={{ marginBottom: "15px" }}>
          <div className="width32Percent">
            <p
              style={{
                marginBottom: "9px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Дата выдачи{" "}
              {!dateJournal ? (
                <ErrorIcon
                  sx={{
                    color: "#FFD43B",
                    marginLeft: "8px",
                    marginTop: "-2px",
                  }}
                />
              ) : (
                <CheckCircleIcon
                  sx={{
                    color: "#63E6BE",
                    marginLeft: "8px",
                    marginTop: "-2px",
                  }}
                />
              )}
            </p>
            <DatePicker
              selectedDate={dateJournal}
              dateChange={handleDateChange}
            />
          </div>
        </div>

        <div className="flexContainerWithGap">
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <p>Отметка выдачи :</p>
            <Checkbox checked={isSueMark} onChange={handleIssueMarkChange} />
          </div>

          {isSueMark === true && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <p>Отметка возврата :</p>
              <Checkbox
                checked={isReturnMark}
                onChange={handleReturnMarkChange}
              />
            </div>
          )}
        </div>
      </div>

      <div style={{ marginTop: "10px" }}>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={isDisabledBtn}
          sx={{ mt: 1, mr: 1 }}
          style={{
            textTransform: "none",
            fontSize: "15px",
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: "11px 20px 11px",
            margin: 0,
            borderRadius: "12px",
            width: "100%",
          }}
        >
          Сохранить запись
        </Button>
      </div>
    </main>
  )
}

export default AddJournalComplect
