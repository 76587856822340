import React, { useState, useEffect } from "react"
import api from "../../../services/axios-config.service"
import Selector from "../../LocalElements/Selector/reactSelect/Selector"
import { useDispatch, useSelector } from "react-redux"

import { changeComplect } from "../../../store/slices/views/journals"

const FilterUsers = ({ validationErrors, companyId }) => {
  const token = useSelector(state => state.authReducer.authData.token)
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const isClearable = true
  const isSearchable = false

  const casesId = useSelector(
    (state) => state.journalReducer.createComplect.casesId
  )

  const options = data
    ? data.map((data) => ({
        value: data.id,
        label: data.inner_name,
      }))
    : []

  const getValue = (selectedOption) => {
    dispatch(
      changeComplect({
        value: "casesId",
        label: selectedOption ? selectedOption.value : null,
      })
    )
  }

  useEffect(() => {
    ( async () => {
      try {
        let apiUrl = `${process.env.REACT_APP_API_URL}/api/cases/list`
        if (companyId) {
          apiUrl += `?company_id=${companyId}`
        }
        const headers = {
          Authorization: `Bearer ${token}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        }
        const response = await api.get(apiUrl, { headers })
        const cases = response.data.data
        setData(cases)
      } catch (error) {
        console.error(error)
      }
    })()
  }, [ token, companyId ])

  return (
    <React.Fragment>
      <Selector
        placeholder={"Выберите комплект CП"}
        options={options}
        value={options.find((option) => option.value === casesId)}
        onChange={getValue}
        isClearable={isClearable}
        isSearchable={isSearchable}
        noOptionsMessage={() => "Нет доступных комплектов СИ"}
      />

      { validationErrors["case_id"] && (
        <div className="error">{validationErrors["case_id"]}</div>
      )}
    </React.Fragment>
  )
}

export default FilterUsers
