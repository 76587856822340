import React, { useState, useEffect } from "react"
import api from "../../services/axios-config.service"
import Selector from "../LocalElements/Selector/reactSelect/Selector"
import { useDispatch, useSelector } from "react-redux"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import { changeEtalon } from "../../store/slices/views/etalon"

const SelectType = () => {
  const token = useSelector(state => state.authReducer.authData.token)
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const isClearable = true
  const isSearchable = false

  const methodId = useSelector((state) => state.etalonReducer.createData.methodId)
  const etalonId = useSelector((state) => state.etalonReducer.createData.etalonId)

  const options = data
    ? data.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = (selectedOption) => {
    dispatch(
      changeEtalon({
        value: "etalonId",
        label: selectedOption ? selectedOption.value : null,
      })
    )
  }

  useEffect(() => {
    if ( methodId ) {
      ( async () => {
        try {
          const apiUrl = `${process.env.REACT_APP_API_URL}/api/devices?method_id=${methodId}`
          const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          }
          const response = await api.get(apiUrl, { headers })
          const etalon = response.data.data
          setData(etalon)
        } catch (error) {
          console.error(error)
        }
      })()
    }
  }, [ token, methodId ])

  return (
    <React.Fragment>
      <p
        style={{
          marginBottom: "9px",
          display: "flex",
          alignItems: "center",
        }}
      >
        Вариант эталона{" "}

        { !etalonId ? (
          <ErrorIcon
            sx={{
              color: "#FFD43B",
              marginLeft: "8px",
              marginTop: "-2px",
            }}
          />
        ) : (
          <CheckCircleIcon
            sx={{ color: "#63E6BE", marginLeft: "8px", marginTop: "-2px" }}
          />
        )}
      </p>
      <Selector
        placeholder={"Выберите эталон из списка"}
        options={options}
        value={options.find((option) => option.value === etalonId)}
        onChange={getValue}
        isClearable={isClearable}
        isSearchable={isSearchable}
        noOptionsMessage={() => "Сначала выберите методику поверки"}
      />
    </React.Fragment>
  )
}

export default SelectType
