import React from "react";
import Button from "@mui/material/Button";

const CardTariff = (props) => {
  const {
    title,
    chip,
    icon,
    price,
    iconFirstTariff,
    iconSecondTariff,
    iconThirdTariff,
    changeTariff,
    tariffName,
    isOpen,
    isTariff,
  } = props;

  return (
    <React.Fragment>
      <div
        className="cardContainer"
        style={{ background: isTariff ? "#E5F3FC" : "#ffffff" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          {isTariff && (
            <div className="tariffLabel">
              <p
                style={{
                  color: "#FFFFFF",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                Текущий тариф
              </p>
            </div>
          )}
          <div
            style={{
              width: "257px",
              height: "131px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <i>{icon}</i>
            <h4 style={{ fontWeight: "bold", fontSize: "24px" }}>{title}</h4>
          </div>
        </div>

        <div
          style={{
            height: "148px",
            marginBottom: "26px",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              color: isTariff ? "#0084E2" : "#899298",
              marginBottom: "24px",
            }}
          >
            {chip}
          </p>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "12px" }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <i>{iconFirstTariff}</i>
              <p>Льготный период</p>
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <i>{iconSecondTariff}</i>
              <p>Индивидуальный тарифный план</p>
            </div>

            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <i>{iconThirdTariff}</i>
              <p>Персональный набор функций</p>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "26px",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
              fontSize: "26px",
              color: isTariff ? "#132532" : "#899298",
            }}
          >
            {price} ₽
          </p>
        </div>

        <Button
          variant="contained"
          sx={{ mt: 1, mr: 1 }}
          style={{
            textTransform: "none",
            fontSize: "16px",
            fontFamily: '"Wix Madefor Display", sans-serif',
            padding: "11px 20px 11px",
            margin: 0,
            borderRadius: "12px",
            width: "100%",
          }}
          onClick={() => changeTariff(tariffName)}
          endIcon={
            isOpen ? (
              <i>
                <svg
                  width="16"
                  height="13"
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_342_942)">
                    <path
                      d="M10.6895 0.308362C10.3103 0.308362 9.96563 0.446237 9.68989 0.721977L1.073 9.68354C0.555987 10.235 0.555979 11.0967 1.10746 11.6482C1.65894 12.1652 2.52065 12.1652 3.07213 11.6137L10.6895 3.68618L18.3068 11.6137C18.8238 12.1652 19.72 12.1652 20.2714 11.6482C20.8229 11.1312 20.8229 10.235 20.3059 9.68354L11.689 0.721977C11.4133 0.446237 11.0686 0.308362 10.6895 0.308362Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_342_942">
                      <rect
                        width="20"
                        height="12"
                        fill="white"
                        transform="matrix(1 0 0 -1 0.689453 12.0317)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </i>
            ) : (
              <i>
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 21 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6895 11.7551C10.3103 11.7551 9.96563 11.6172 9.68989 11.3415L1.073 2.37994C0.555987 1.82846 0.555979 0.966777 1.10746 0.415296C1.65894 -0.101717 2.52065 -0.101715 3.07213 0.449766L10.6895 8.3773L18.3068 0.449766C18.8238 -0.101715 19.72 -0.101717 20.2714 0.415296C20.8229 0.932309 20.8229 1.82846 20.3059 2.37994L11.689 11.3415C11.4133 11.6172 11.0686 11.7551 10.6895 11.7551Z"
                    fill="white"
                  />
                </svg>
              </i>
            )
          }
        >
          Подробнее
        </Button>
      </div>
    </React.Fragment>
  );
};

export default CardTariff;
