import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import Selector from "../LocalElements/Selector/reactSelect/Selector"
import { changeData } from "../../store/slices/views/cases"
import R from "../../services/request.service"

const TypeMethod = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState(null)

  const methodId = useSelector((state) => state.casesReducer.createData.methodId)
  const typeMethodId = useSelector((state) => state.casesReducer.createData.typeMethodId)

  const options = data
    ? data.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = (selectedOption) => {
    if (selectedOption) {
      dispatch(
        changeData({
          value: "typeMethodId",
          label: selectedOption.value,
        })
      )

      dispatch(
        changeData({
          value: "methodPoints",
          label: [],
        })
      )
      dispatch(changeData({ value: "deviceIds", label: [] }))
    } else {
      dispatch(
        changeData({
          value: "typeMethodId",
          label: "",
        })
      )

      dispatch(
        changeData({
          value: "methodPoints",
          label: [],
        })
      )
      dispatch(changeData({ value: "deviceIds", label: [] }))
    }
  }

  useEffect(() => {
    if (methodId) {
      (async () => {
        const { data: listMethod } = await R.getTypeItem(
          `?method_id=${methodId}`
        )
        const verificationType = listMethod.data
        setData(verificationType)
      })()
    }
  }, [methodId])

  return (
    <React.Fragment>
      <p
        style={{
          marginBottom: "9px",
          display: "flex",
          alignItems: "center",
        }}
      >
        Тип поверки{" "}
        {!typeMethodId ? (
          <ErrorIcon
            sx={{
              color: "#FFD43B",
              marginLeft: "8px",
              marginTop: "-2px",
            }}
          />
        ) : (
          <CheckCircleIcon
            sx={{ color: "#63E6BE", marginLeft: "8px", marginTop: "-2px" }}
          />
        )}
      </p>
      <Selector
        placeholder={"Выберите тип поверки из списка"}
        isClearable={true}
        isSearchable={true}
        options={options}
        onChange={getValue}
        value={
          typeMethodId
            ? options.find((option) => option.value === typeMethodId)
            : null
        }
        noOptionsMessage={() => "Сначала выберите методику поверки"}
      />
    </React.Fragment>
  )
}

export default TypeMethod
