/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import moment from "moment"

import R from "../../services/request.service"
import Feedback from "../../components/LocalElements/Feedback/Feedback"
import useWindowDimensions from "../../hooks/useWindowDimensions"

import { setShow } from "../../store/slices/comps/feedback"

import Fab from '@mui/material/Fab'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

const ShowCases = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { cases_id } = useParams()
  const { width } = useWindowDimensions()

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)
  const show = useSelector((state) => state.feedbackReducer.show)

  const [data, setData] = useState([])
  const userRole = useSelector((state) => state.headerReducer.role)

  useEffect(() => {
    ( async () => {
      dispatch(setShow(true))
      try {
        const { data: caseData } = await R.getCasesShow(cases_id)
        const data = caseData.data

        setData(data)
      } catch (error) {
        console.error(error)
      }
      dispatch(setShow(false))
    })()
  }, [])

  const navigateToProtocols = () => {
    navigate("/metriva/cases")
  }

  const capitalizeFirstLetter = (string) => {
    if ( typeof string !== "string" ) return ""
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  return (
    <main className={isToggle ? "main_open" : "main"}>
      { show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          { width <= 1050 && <Fab 
            color="primary" 
            aria-label="add"
            onClick={navigateToProtocols}
            style={{ 
              position: "absolute",
              top: "100%",
              marginTop: "-78.8px"
            }}
          >
            <ArrowBackIosNewIcon
              style={{ marginRight: '3px' }}
            />
          </Fab> }
          { width > 1050 && <div className="path_to_back" onClick={navigateToProtocols}>
            <i
              className="fas fa-arrow-left icon_back"
              style={{ width: "30px", height: "30px", marginRight: "6px" }}
            />
            <p>Все Комплекты СП</p>
          </div> }

          <div
            className="path_to_edit"
            style={{ justifyContent: "space-between" }}
          >
            <h2
              style={ width > 1050 ? {} : { width: '100%', textAlign: 'center' }}
            >
              Комплект СП № {data?.inner_name}
            </h2>
            {(( userRole === "super" || userRole === "admin" ) && width > 1050 ) && (
              <span
                className="fas fa-pencil-alt icon_back"
                onClick={() => navigate(`/metriva/cases/${cases_id}/edit`)}
              />
            )}
          </div>

          <div className="box">
            <h2 style={{ marginBottom: "15px" }}>
              { width > 1050 ? 'Внутренние данные комплекта' : 'Внутренние данные' }
            </h2>

            <div
              className="flexContainerWithGap"
              style={{ marginBottom: "15px" }}
            >
              <p>Внутренний номер СП :</p>
              <h4>{data?.inner_name}</h4>
            </div>

            <div
              className="flexContainerWithGap"
              style={{ marginBottom: "15px" }}
            >
              <p>Поверитель :</p>
              <h4>{data?.arshin_name}</h4>
            </div>

            <div className="flexContainerWithGap">
              <p>Снилс :</p>
              <h4>{data?.snils}</h4>
            </div>
          </div>

          <div className="box">
            <h2 style={{ marginBottom: "15px" }}>
              { width > 1050 ? 'Область измерения и методика поверки' : 'Область измерения' }
            </h2>

            <div
              className="flexContainerWithGap"
              style={{ marginBottom: "15px" }}
            >
              <p>Область измерения :</p>
              <h4>{data?.measurement?.name}</h4>
            </div>

            <div
              className="flexContainerWithGap"
              style={{ marginBottom: "15px" }}
            >
              <p>Методика поверки :</p>
              <h4>{data?.method?.name}</h4>
            </div>

            <div
              className="flexContainerWithGap"
              style={{ marginBottom: "15px" }}
            >
              <p>Тип поверки :</p>
              <h4>{ capitalizeFirstLetter(data?.verificationType?.name) }</h4>
            </div>

            <div className="flexContainerWithGap">
              <p>Пункты методики :</p>
              <h4>{ data?.points?.map((item) => item.name).join(", ") }</h4>
            </div>
          </div>

          <div className="box">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "26px" }}
            >
              { data?.kits?.map((item) => (
                <div key={item.device_id}>
                  <h2 style={{ marginBottom: "16px" }}>{item.device.name}</h2>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      padding: "20px",
                      borderRadius: "12px",
                      backgroundColor: "rgb(248, 249, 252)",
                    }}
                  >
                    <div className="flexContainerWithGap">
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Тип эталона :
                      </span>
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                        }}
                      >
                        {item.device_sign}
                      </span>
                    </div>
                    <div className="flexContainerWithGap">
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Заводской номер :
                      </span>
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                        }}
                      >
                        { item.device_manufacturer_number }
                      </span>
                    </div>
                    <div className="flexContainerWithGap">
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Регистрационный номер :{" "}
                      </span>
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                        }}
                      >
                        { item.reg_number }
                      </span>
                    </div>
                    <div className="flexContainerWithGap">
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Свидетельство о поверке :
                      </span>
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                          lineHeight: "24px",
                        }}
                      >
                        { item.device_description }
                      </span>
                    </div>
                    <div className="flexContainerWithGap">
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Место проведения поверки :
                      </span>
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                        }}
                      >
                        { item.verification_place || "Неизвестно" }
                      </span>
                    </div>
                    <div className="flexContainerWithGap">
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Ссылка :
                      </span>
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        { item.device_link }
                      </span>
                    </div>
                    <div className="flexContainerWithGap">
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Отправляется в Аршин:
                      </span>
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                        }}
                      >
                        { item.is_send_to_arshin ? "Отправляется" : "Не отправляется" }
                      </span>
                    </div>
                    <div className="flexContainerWithGap">
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Дата поверки:
                      </span>
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        { moment(item.verified_at).format("DD-MM-YYYY") }
                      </span>
                    </div>
                    <div className="flexContainerWithGap">
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        Поверка действительна до:
                      </span>  
                      <span
                        style={{
                          color: "#123532",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        { moment(item.expired_at).format("DD-MM-YYYY") }
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </main>
  )
}

export default ShowCases
