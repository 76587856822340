import React, { useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import D from "../store/localStorage/dispatcher"
import { refreshHeader, refreshCompanies, refreshCases, refreshEtalons, refreshUsers } from "../store/slices/controlers/updater"
import { setIsToggle } from "../store/slices/controlers/toggle"

import useWindowDimensions from "../hooks/useWindowDimensions"

import protocols from "../img/menu/protocols.svg"
import magazines from "../img/menu/magazines.svg"
import users from "../img/menu/users.svg"
import meters from "../img/menu/meters.svg"
import etalons from "../img/menu/etalons.svg"
import cases from "../img/menu/cases.svg"
import companies from "../img/menu/companies.svg"

const SideBar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { width } = useWindowDimensions()

  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector((state) => state.headerReducer.role)

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050)

  const navigateToPage = (path) => {
    navigate(`/metriva/${path}`)
  }

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1050)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleMenuClick = () => {
    if (isMobile) {
      dispatch(setIsToggle(false))
    }
  }

  return (
    <React.Fragment>
      { width > 1050 && (
        <div
          style={{
            width: '130px',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            gap: '36px',
            paddingTop: '12px'
          }}
        >
          <div
            className={`sidebar-menu ${
              location.pathname.endsWith("protocol") ? "active" : ""
            }`}
            onClick={() => {
              navigateToPage("protocol")
              dispatch(refreshHeader())
              handleMenuClick()
            }}
          >
            <img
              alt={""}
              src={magazines}
              style={{ height: "20px" }}
            />
            <h5>Новый протокол</h5>
          </div>

          <div
            className={`sidebar-menu ${
              location.pathname.startsWith("/metriva/protocols") ? "active" : ""
            }`}
            onClick={() => {
              navigateToPage("protocols")
              dispatch(refreshHeader())
              handleMenuClick()
            }}
          >
            <img
              alt={""}
              src={protocols}
              style={{ height: "20px" }}
            />
            <h5>Протоколы</h5>
          </div>

          {( userRole === "super" ) && (
            <div
              className={`sidebar-menu ${
                location.pathname.startsWith("/metriva/journals")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                navigateToPage("journals")
                dispatch(refreshHeader())
                handleMenuClick()
              }}
            >
              <img
                alt={""}
                src={magazines}
                style={{ height: "20px" }}
              />
              <h5>Журналы</h5>
            </div>
          )}

          { userRole !== "metrologist" && (
            <div
              className={`sidebar-menu ${
                location.pathname.startsWith("/metriva/users") ? "active" : ""
              }`}
              onClick={() => {
                dispatch(refreshUsers())
                dispatch(refreshHeader())
                handleMenuClick()

                navigateToPage("users")
              }}
            >
              <img
                alt={""}
                src={users}
                style={{ height: "24px" }}
              />
              <h5>Пользователи</h5>
            </div>
          )}

          <div
            className={`sidebar-menu ${
              location.pathname.startsWith("/metriva/meters") ? "active" : ""
            }`}
            onClick={() => {
              navigateToPage("meters")
              dispatch(refreshHeader())
              handleMenuClick()
            }}
          >
            <img
              alt={""}
              src={meters}
              style={{ height: "24px" }}
            />
            <h5>База приборов</h5>
          </div>

          {( userRole === "super" || userRole === "admin" ) && (
            <div
              className={`sidebar-menu ${
                location.pathname.startsWith("/metriva/etalon") ? "active" : ""
              }`}
              onClick={() => {
                dispatch(refreshEtalons())
                dispatch(refreshHeader())
                handleMenuClick()

                navigateToPage("etalon")
              }}
            >
              <img
                alt={""}
                src={etalons}
                style={{ height: "24px" }}
              />
              <h5>Эталоны</h5>
            </div>
          )}

          <div
            className={`sidebar-menu ${
              location.pathname.startsWith("/metriva/cases") ? "active" : ""
            }`}
            onClick={() => {
              dispatch(refreshCases())
              dispatch(refreshHeader())
              handleMenuClick()

              navigateToPage("cases")
            }}
          >
            <img
              alt={""}
              src={cases}
              style={{ height: "24px" }}
            />
            <h5>Комплекты СП</h5>
          </div>

           { userRole === "super" && (
            <div
              className={`sidebar-menu ${
                location.pathname.startsWith("/metriva/companies")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                dispatch(refreshCompanies())
                dispatch(refreshHeader())
                handleMenuClick()

                navigateToPage("companies")
              }}
            >
              <img
                alt={""}
                src={companies}
                style={{ height: "24px" }}
              />
              <h5>Компании</h5>
            </div>
          )}

          { userRole === "admin" && (
            <div
              className={`sidebar-menu ${
                location.pathname.startsWith("/metriva/company") ? "active" : ""
              }`}
              onClick={() => {

                navigateToPage("company")
                dispatch(refreshHeader())
                handleMenuClick()
              }}
            >
              <img
                alt={""}
                src={companies}
                style={{ height: "24px" }}
              />
              <h5>Компания</h5>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default SideBar
