/* eslint-disable no-unused-expressions */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import AsyncSelector from "../../../LocalElements/Selector/reactSelect/Selector.async.customer"

import R from "../../../../services/request.service"
import Input from "../../../LocalElements/Input/InputMUI"

import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import useWindowDimensions from "../../../../hooks/useWindowDimensions"

const Customer = (props) => {
  const {
    valueName,
    changeName,
    valueNameType,
    changeNameType,
    valueFullName,
    changeFullName,
    valueAddress,
    changeAddress,
    valuePhone,
    changePhone,
  } = props

  const [tariffIds, setTariffIds] = useState([])
  const [regionValue, setRegionValue] = useState([])

  const { width } = useWindowDimensions()

  useEffect(() => {
    ( async () => {
      const { data } = await R.getUserRegions()

      const region = data.regions.map((regionId) => regionId.id)
      setRegionValue(region)

      const tariffIds = data.tariffs.map((tariff) => tariff.id)
      if (tariffIds.length === 0) {
        setTariffIds(null)
      } else {
        setTariffIds(tariffIds)
      }
    })()
  }, [])

  return (
    <React.Fragment>
      <h2 className="M__Customer__title" style={{ marginBottom: "14px" }}>
        Информация о заказчике
      </h2>

      <div
        className="flexContainerWithGap M__Customer__container"
        style={{
          alignItems: "flex-start",
          width: "100%",
          marginTop: width > 1050 ? "15px" : "14px",
        }}
      >
        <div className="width50Percent M__Customer__container__row">
          <p
            style={{
              marginBottom: "9px",
              display: "flex",
              alignItems: "center",
            }}
          >
            Владелец СИ в системе Аршин
          </p>
          <Input
            type={"text"}
            placeholder={"Выберите тип лица или введите ФИО"}
            value={valueName}
            actions={{
              change: changeName,
            }}
          />
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={valueNameType}
            onChange={changeNameType}
            style={{
              flexDirection: "row",
              fontFamily: "'Wix Madefor Display', sans-serif",
              color: "#123532",
              fontSize: "16px",
              fontWeight: "400",
              marginTop: "8px",
              paddingLeft: "5px",
            }}
          >
            <FormControlLabel
              value="Физическое лицо"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "22px !important",
                    },
                  }}
                />
              }
              label="Физическое лицо"
              sx={{
                "& .MuiTypography-root": {
                  fontFamily: "'Wix Madefor Display', sans-serif !important",
                  fontSize: "15px !important",
                  fontWeight: 400,
                  color: "#132532",
                },
              }}
            />
            <FormControlLabel
              value="Юридическое лицо"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "22px !important",
                    },
                  }}
                />
              }
              label="Юридическое лицо"
              sx={{
                "& .MuiTypography-root": {
                  fontFamily: "'Wix Madefor Display', sans-serif !important",
                  fontSize: "15px !important",
                  fontWeight: 400,
                  color: "#132532",
                },
              }}
            />
          </RadioGroup>
        </div>
        <div className="width50Percent M__Customer__container__row">
          <p
            style={{
              marginBottom: "9px",
              display: "flex",
              alignItems: "center",
            }}
          >
            Адрес проведения поверки
            {!!valueAddress ? (
              <CheckCircleIcon
                sx={{ color: "#63E6BE", marginLeft: "8px", marginTop: "-2px" }}
              />
            ) : (
              <ErrorIcon
                sx={{
                  color: "#FFD43B",
                  marginLeft: "8px",
                  marginTop: "-1px",
                }}
              />
            )}
          </p>

          <AsyncSelector
            callback={changeAddress}
            optionKey={"suggestions"}
            options={async (param) =>
              R.addresses(
                param,
                regionValue.map((regionId) => ({ kladr_id: regionId }))
              )
            }
            value={valueAddress}
          />
        </div>
      </div>

      { tariffIds && tariffIds.includes(6) && (
        <div
          className="flexContainerWithGap"
          style={{ marginTop: "20px", marginBottom: "0px", width: "100%" }}
        >
          <div className="width50Percent M__Customer__container__row">
            <Input
              type={"text"}
              placeholder={"Введите ФИО"}
              label={"ФИО заказчика (необязательно)"}
              notRequired={true}
              value={valueFullName}
              actions={{
                change: changeFullName,
              }}
            />
          </div>

          <div
            className="
              width50Percent 
              M__Customer__container__row 
              M__Customer__container__rowEnd"
          >
            <Input
              type={"text"}
              placeholder={"+7(XXX)XXX-XX-XX"}
              label={"Телефона заказчика (необязательно)"}
              notRequired={true}
              value={valuePhone}
              actions={{
                change: changePhone,
              }}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Customer
