import React from "react";
import { Skeleton } from "@mui/material";

const CustomSkeleton = () => {
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexFlow: "column",
        }}
      >
        <Skeleton
          sx={{ backgroundColor: "#fff" }}
          animation="wave"
          width={"100%"}
          height={66}
        />
        <Skeleton
          sx={{ marginTop: "-16px" }}
          animation="wave"
          width={"100%"}
          height={66}
        />
        <Skeleton
          sx={{ backgroundColor: "#fff", marginTop: "-16px" }}
          animation="wave"
          width={"100%"}
          height={66}
        />
        <Skeleton
          sx={{ marginTop: "-16px" }}
          animation="wave"
          width={"100%"}
          height={66}
        />
        <Skeleton
          sx={{ backgroundColor: "#fff", marginTop: "-16px" }}
          animation="wave"
          width={"100%"}
          height={66}
        />
        <Skeleton
          sx={{ marginTop: "-16px" }}
          animation="wave"
          width={"100%"}
          height={66}
        />
      </div>
    </React.Fragment>
  );
};

export default CustomSkeleton;
