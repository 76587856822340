// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Input from "../../../../LocalElements/Input/InputMUI";
import { changeProperty } from "../state";

const IndicationsFull = () => {
  const dispatch = useDispatch();

  const q_min = useSelector((state) => state.gost83242002Reducer.q_min);
  const q_t = useSelector((state) => state.gost83242002Reducer.q_t);
  const q_max = useSelector((state) => state.gost83242002Reducer.q_max);
  const q_min_limit = useSelector(
    (state) => state.gost83242002Reducer.q_min_limit
  );
  const q_max_limit = useSelector(
    (state) => state.gost83242002Reducer.q_max_limit
  );

  const verification_flow_1 = useSelector(
    (state) => state.gost83242002Reducer.verification_flow_1
  );
  const etalon_volume_1 = useSelector(
    (state) => state.gost83242002Reducer.etalon_volume_1
  );
  const device_volume_1 = useSelector(
    (state) => state.gost83242002Reducer.device_volume_1
  );
  const correction_1 = useSelector(
    (state) => state.gost83242002Reducer.correction_1
  );

  const [verification_flow_2, setVerification_flow_2] = useState("");
  const [etalon_volume_2, setEtalon_volume_2] = useState("");
  const [device_volume_2, setDevice_volume_2] = useState("");
  const [correction_2, setCorrection_2] = useState("");

  const [verification_flow_3, setVerification_flow_3] = useState("");
  const [etalon_volume_3, setEtalon_volume_3] = useState("");
  const [device_volume_3, setDevice_volume_3] = useState("");
  const [correction_3, setCorrection_3] = useState("");

  const handleVerification_flow_1Change = (param) => {
    dispatch(
      changeProperty({
        value: "verification_flow_1",
        label: param.replace(",", "."),
      })
    );
  };

  const handleEtalon_volume_1Change = (param) => {
    dispatch(
      changeProperty({
        value: "etalon_volume_1",
        label: param.replace(",", "."),
      })
    );
  };

  const handleDevice_volume_1Change = (param) => {
    dispatch(
      changeProperty({
        value: "device_volume_1",
        label: param.replace(",", "."),
      })
    );
  };

  const handleCorrection_1Change = (param) => {
    dispatch(
      changeProperty({
        value: "correction_1",
        label: param.replace(",", "."),
      })
    );
  };

  const handleVerification_flow_2Change = (param) => {
    setVerification_flow_2(param.replace(",", "."));
  };

  const handleEtalon_volume_2Change = (param) => {
    setEtalon_volume_2(param.replace(",", "."));
  };

  const handleDevice_volume_2Change = (param) => {
    setDevice_volume_2(param.replace(",", "."));
  };

  const handleCorrection_2Change = (param) => {
    setCorrection_2(param.replace(",", "."));
  };

  const handleVerification_flow_3Change = (param) => {
    setVerification_flow_3(param.replace(",", "."));
  };

  const handleEtalon_volume_3Change = (param) => {
    setEtalon_volume_3(param.replace(",", "."));
  };

  const handleDevice_volume_3Change = (param) => {
    setDevice_volume_3(param.replace(",", "."));
  };

  const handleCorrection_3Change = (param) => {
    setCorrection_3(param.replace(",", "."));
  };

  const permissible_error_1 = (
    ((parseFloat(device_volume_1) - parseFloat(etalon_volume_1)) /
      parseFloat(etalon_volume_1)) *
      100 -
    parseFloat(correction_1)
  ).toFixed(2);

  const permissible_error_2 = (
    ((parseFloat(device_volume_2) - parseFloat(etalon_volume_2)) /
      parseFloat(etalon_volume_2)) *
      100 -
    parseFloat(correction_2)
  ).toFixed(2);

  const permissible_error_3 = (
    ((parseFloat(device_volume_3) - parseFloat(etalon_volume_3)) /
      parseFloat(etalon_volume_3)) *
      100 -
    parseFloat(correction_3)
  ).toFixed(2);

  let isPermissiblePass_1 = true;
  if (!isNaN(permissible_error_1)) {
    isPermissiblePass_1 =
      permissible_error_1 >= -q_min_limit && permissible_error_1 <= q_min_limit;
  }

  useEffect(() => {
    dispatch(
      changeProperty({ value: "permissiblePass", label: isPermissiblePass_1 })
    );
  }, [isPermissiblePass_1, dispatch]);

  const permissible_error_q_min = (
    (parseFloat(permissible_error_1) +
      parseFloat(permissible_error_2) +
      parseFloat(permissible_error_3)) /
    3
  ).toFixed(2);

  const isPermissiblePassQmin =
    permissible_error_q_min >= -q_min_limit &&
    permissible_error_q_min <= q_min_limit;

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const [verification_flow_4, setVerification_flow_4] = useState("");
  const [etalon_volume_4, setEtalon_volume_4] = useState("");
  const [device_volume_4, setDevice_volume_4] = useState("");
  const [correction_4, setCorrection_4] = useState("");

  const [verification_flow_5, setVerification_flow_5] = useState("");
  const [etalon_volume_5, setEtalon_volume_5] = useState("");
  const [device_volume_5, setDevice_volume_5] = useState("");
  const [correction_5, setCorrection_5] = useState("");

  const [verification_flow_6, setVerification_flow_6] = useState("");
  const [etalon_volume_6, setEtalon_volume_6] = useState("");
  const [device_volume_6, setDevice_volume_6] = useState("");
  const [correction_6, setCorrection_6] = useState("");

  const handleVerification_flow_4Change = (param) => {
    setVerification_flow_4(param.replace(",", "."));
  };

  const handleEtalon_volume_4Change = (param) => {
    setEtalon_volume_4(param.replace(",", "."));
  };

  const handleDevice_volume_4Change = (param) => {
    setDevice_volume_4(param.replace(",", "."));
  };

  const handleCorrection_4Change = (param) => {
    setCorrection_4(param.replace(",", "."));
  };

  const handleVerification_flow_5Change = (param) => {
    setVerification_flow_5(param.replace(",", "."));
  };

  const handleEtalon_volume_5Change = (param) => {
    setEtalon_volume_5(param.replace(",", "."));
  };

  const handleDevice_volume_5Change = (param) => {
    setDevice_volume_5(param.replace(",", "."));
  };

  const handleCorrection_5Change = (param) => {
    setCorrection_5(param.replace(",", "."));
  };

  const handleVerification_flow_6Change = (param) => {
    setVerification_flow_6(param.replace(",", "."));
  };

  const handleEtalon_volume_6Change = (param) => {
    setEtalon_volume_6(param.replace(",", "."));
  };

  const handleDevice_volume_6Change = (param) => {
    setDevice_volume_6(param.replace(",", "."));
  };

  const handleCorrection_6Change = (param) => {
    setCorrection_6(param.replace(",", "."));
  };

  const permissible_error_4 = (
    ((parseFloat(device_volume_4) - parseFloat(etalon_volume_4)) /
      parseFloat(etalon_volume_4)) *
      100 -
    parseFloat(correction_4)
  ).toFixed(2);

  const permissible_error_5 = (
    ((parseFloat(device_volume_5) - parseFloat(etalon_volume_5)) /
      parseFloat(etalon_volume_5)) *
      100 -
    parseFloat(correction_5)
  ).toFixed(2);

  const permissible_error_6 = (
    ((parseFloat(device_volume_6) - parseFloat(etalon_volume_6)) /
      parseFloat(etalon_volume_6)) *
      100 -
    parseFloat(correction_6)
  ).toFixed(2);

  const permissible_error_q_t = (
    (parseFloat(permissible_error_4) +
      parseFloat(permissible_error_5) +
      parseFloat(permissible_error_6)) /
    3
  ).toFixed(2);

  const isPermissiblePassQt =
    permissible_error_q_t >= -q_max_limit &&
    permissible_error_q_t <= q_max_limit;

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const [verification_flow_7, setVerification_flow_7] = useState("");
  const [etalon_volume_7, setEtalon_volume_7] = useState("");
  const [device_volume_7, setDevice_volume_7] = useState("");
  const [correction_7, setCorrection_7] = useState("");

  const [verification_flow_8, setVerification_flow_8] = useState("");
  const [etalon_volume_8, setEtalon_volume_8] = useState("");
  const [device_volume_8, setDevice_volume_8] = useState("");
  const [correction_8, setCorrection_8] = useState("");

  const [verification_flow_9, setVerification_flow_9] = useState("");
  const [etalon_volume_9, setEtalon_volume_9] = useState("");
  const [device_volume_9, setDevice_volume_9] = useState("");
  const [correction_9, setCorrection_9] = useState("");

  const handleVerification_flow_7Change = (param) => {
    setVerification_flow_7(param.replace(",", "."));
  };

  const handleEtalon_volume_7Change = (param) => {
    setEtalon_volume_7(param.replace(",", "."));
  };

  const handleDevice_volume_7Change = (param) => {
    setDevice_volume_7(param.replace(",", "."));
  };

  const handleCorrection_7Change = (param) => {
    setCorrection_7(param.replace(",", "."));
  };

  const handleVerification_flow_8Change = (param) => {
    setVerification_flow_8(param.replace(",", "."));
  };

  const handleEtalon_volume_8Change = (param) => {
    setEtalon_volume_8(param.replace(",", "."));
  };

  const handleDevice_volume_8Change = (param) => {
    setDevice_volume_8(param.replace(",", "."));
  };

  const handleCorrection_8Change = (param) => {
    setCorrection_8(param.replace(",", "."));
  };

  const handleVerification_flow_9Change = (param) => {
    setVerification_flow_9(param.replace(",", "."));
  };

  const handleEtalon_volume_9Change = (param) => {
    setEtalon_volume_9(param.replace(",", "."));
  };

  const handleDevice_volume_9Change = (param) => {
    setDevice_volume_9(param.replace(",", "."));
  };

  const handleCorrection_9Change = (param) => {
    setCorrection_9(param.replace(",", "."));
  };

  const permissible_error_7 = (
    ((parseFloat(device_volume_7) - parseFloat(etalon_volume_7)) /
      parseFloat(etalon_volume_7)) *
      100 -
    parseFloat(correction_7)
  ).toFixed(2);

  const permissible_error_8 = (
    ((parseFloat(device_volume_8) - parseFloat(etalon_volume_8)) /
      parseFloat(etalon_volume_8)) *
      100 -
    parseFloat(correction_8)
  ).toFixed(2);

  const permissible_error_9 = (
    ((parseFloat(device_volume_9) - parseFloat(etalon_volume_9)) /
      parseFloat(etalon_volume_9)) *
      100 -
    parseFloat(correction_9)
  ).toFixed(2);

  const permissible_error_q_max = (
    (parseFloat(permissible_error_7) +
      parseFloat(permissible_error_8) +
      parseFloat(permissible_error_9)) /
    3
  ).toFixed(2);

  const isPermissiblePassQmax =
    permissible_error_q_max >= -q_max_limit &&
    permissible_error_q_max <= q_max_limit;

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  useEffect(() => {
    const isValues = [
      isPermissiblePassQmin,
      isPermissiblePassQt,
      isPermissiblePassQmax,
    ];
    dispatch(changeProperty({ value: "indicationPass", label: isValues }));
  }, [
    isPermissiblePassQmin,
    isPermissiblePassQt,
    isPermissiblePassQmax,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(
      changeProperty({
        value: "indications",
        label: [
          {
            verification_flow_1: verification_flow_1,
            etalon_volume_1: etalon_volume_1,
            device_volume_1: device_volume_1,
            correction_1: correction_1,
            verification_flow_2: verification_flow_2,
            etalon_volume_2: etalon_volume_2,
            device_volume_2: device_volume_2,
            correction_2: correction_2,
            verification_flow_3: verification_flow_3,
            etalon_volume_3: etalon_volume_3,
            device_volume_3: device_volume_3,
            correction_3: correction_3,
          },
          {
            verification_flow_1: verification_flow_4,
            etalon_volume_1: etalon_volume_4,
            device_volume_1: device_volume_4,
            correction_1: correction_4,
            verification_flow_2: verification_flow_5,
            etalon_volume_2: etalon_volume_5,
            device_volume_2: device_volume_5,
            correction_2: correction_5,
            verification_flow_3: verification_flow_6,
            etalon_volume_3: etalon_volume_6,
            device_volume_3: device_volume_6,
            correction_3: correction_6,
          },
          {
            verification_flow_1: verification_flow_7,
            etalon_volume_1: etalon_volume_7,
            device_volume_1: device_volume_7,
            correction_1: correction_7,
            verification_flow_2: verification_flow_8,
            etalon_volume_2: etalon_volume_8,
            device_volume_2: device_volume_8,
            correction_2: correction_8,
            verification_flow_3: verification_flow_9,
            etalon_volume_3: etalon_volume_9,
            device_volume_3: device_volume_9,
            correction_3: correction_9,
          },
        ],
      })
    );
  }, [
    verification_flow_1,
    verification_flow_2,
    verification_flow_3,
    verification_flow_4,
    verification_flow_5,
    verification_flow_6,
    verification_flow_7,
    verification_flow_8,
    verification_flow_9,
    etalon_volume_1,
    etalon_volume_2,
    etalon_volume_3,
    etalon_volume_4,
    etalon_volume_5,
    etalon_volume_6,
    etalon_volume_7,
    etalon_volume_8,
    etalon_volume_9,
    device_volume_1,
    device_volume_2,
    device_volume_3,
    device_volume_4,
    device_volume_5,
    device_volume_6,
    device_volume_7,
    device_volume_8,
    device_volume_9,
    correction_1,
    correction_2,
    correction_3,
    correction_4,
    correction_5,
    correction_6,
    correction_7,
    correction_8,
    correction_9,
    dispatch,
  ]);

  return (
    <React.Fragment>
      <h2 style={{ marginBottom: "15px" }}>
        Определение относительной погрешности
      </h2>
      <React.Fragment>
        <p style={{ marginBottom: "20px" }}>
          Значение Qmin: <span>{q_min}</span> м³/ч
        </p>

        <div className="flexContainerWithGap">
          <div className="numberBox" style={{ marginBottom: "15px" }}>
            <p style={{ fontSize: "16px", fontWeight: "500" }}>1</p>
          </div>
          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поверочный расход, м³/ч"}
              placeholder={"0.0000"}
              value={verification_flow_1}
              actions={{
                change: handleVerification_flow_1Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем, по показаниям эталона, м³"}
              placeholder={"0.0000"}
              value={etalon_volume_1}
              actions={{
                change: handleEtalon_volume_1Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем прибора, м³"}
              placeholder={"0.0000"}
              value={device_volume_1}
              actions={{
                change: handleDevice_volume_1Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поправка, %"}
              placeholder={"0.0"}
              value={correction_1}
              actions={{
                change: handleCorrection_1Change,
              }}
            />
          </div>
        </div>

        <div className="flexContainerWithGap">
          <div className="numberBox" style={{ marginBottom: "15px" }}>
            <p style={{ fontSize: "16px", fontWeight: "500" }}>2</p>
          </div>
          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поверочный расход, м³/ч"}
              placeholder={"0.0000"}
              value={verification_flow_2}
              actions={{
                change: handleVerification_flow_2Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем, по показаниям эталона, м³"}
              placeholder={"0.0000"}
              value={etalon_volume_2}
              actions={{
                change: handleEtalon_volume_2Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем прибора, м³"}
              placeholder={"0.0000"}
              value={device_volume_2}
              actions={{
                change: handleDevice_volume_2Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поправка, %"}
              placeholder={"0.0"}
              value={correction_2}
              actions={{
                change: handleCorrection_2Change,
              }}
            />
          </div>
        </div>

        <div className="flexContainerWithGap">
          <div className="numberBox" style={{ marginBottom: "15px" }}>
            <p style={{ fontSize: "16px", fontWeight: "500" }}>3</p>
          </div>
          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поверочный расход, м³/ч"}
              placeholder={"0.0000"}
              value={verification_flow_3}
              actions={{
                change: handleVerification_flow_3Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем, по показаниям эталона, м³"}
              placeholder={"0.0000"}
              value={etalon_volume_3}
              actions={{
                change: handleEtalon_volume_3Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем прибора, м³"}
              placeholder={"0.0000"}
              value={device_volume_3}
              actions={{
                change: handleDevice_volume_3Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поправка, %"}
              placeholder={"0.0"}
              value={correction_3}
              actions={{
                change: handleCorrection_3Change,
              }}
            />
          </div>
        </div>

        {correction_1 && correction_2 && correction_3 && (
          <div
            className={`indications_box-gas_bottom ${
              isPermissiblePassQmin
                ? "indications_box-gas_bottom_true"
                : "indications_box-gas_bottom_false"
            }`}
            style={{ marginBottom: "20px" }}
          >
            <p
              className={`indications_box-gas_bottom ${
                isPermissiblePassQmin ? "text_true" : "text_false"
              }`}
              style={{ marginBottom: "5px" }}
            >
              Среднее арифметическое значение погрешности:{" "}
              <span style={{ margin: "0 2px" }}>{permissible_error_q_min}</span>{" "}
              %
            </p>
            <p
              className={`indications_box-gas_bottom ${
                isPermissiblePassQmin ? "text_true" : "text_false"
              }`}
            >
              Пределы допускаемой погрешности счётчика:{" "}
              <span style={{ margin: "0 2px" }}>±{q_min_limit} </span>%
            </p>
          </div>
        )}
      </React.Fragment>

      <React.Fragment>
        <p style={{ marginBottom: "20px" }}>
          Значение 1.1 * Qt: <span>{q_t * 1.1}</span> м³/ч
        </p>

        <div className="flexContainerWithGap">
          <div className="numberBox" style={{ marginBottom: "15px" }}>
            <p style={{ fontSize: "16px", fontWeight: "500" }}>4</p>
          </div>
          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поверочный расход, м³/ч"}
              placeholder={"0.0000"}
              value={verification_flow_4}
              actions={{
                change: handleVerification_flow_4Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем, по показаниям эталона, м³"}
              placeholder={"0.0000"}
              value={etalon_volume_4}
              actions={{
                change: handleEtalon_volume_4Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем прибора, м³"}
              placeholder={"0.0000"}
              value={device_volume_4}
              actions={{
                change: handleDevice_volume_4Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поправка, %"}
              placeholder={"0.0"}
              value={correction_4}
              actions={{
                change: handleCorrection_4Change,
              }}
            />
          </div>
        </div>

        <div className="flexContainerWithGap">
          <div className="numberBox" style={{ marginBottom: "15px" }}>
            <p style={{ fontSize: "16px", fontWeight: "500" }}>5</p>
          </div>
          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поверочный расход, м³/ч"}
              placeholder={"0.0000"}
              value={verification_flow_5}
              actions={{
                change: handleVerification_flow_5Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем, по показаниям эталона, м³"}
              placeholder={"0.0000"}
              value={etalon_volume_5}
              actions={{
                change: handleEtalon_volume_5Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем прибора, м³"}
              placeholder={"0.0000"}
              value={device_volume_5}
              actions={{
                change: handleDevice_volume_5Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поправка, %"}
              placeholder={"0.0"}
              value={correction_5}
              actions={{
                change: handleCorrection_5Change,
              }}
            />
          </div>
        </div>

        <div className="flexContainerWithGap">
          <div className="numberBox" style={{ marginBottom: "15px" }}>
            <p style={{ fontSize: "16px", fontWeight: "500" }}>6</p>
          </div>
          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поверочный расход, м³/ч"}
              placeholder={"0.0000"}
              value={verification_flow_6}
              actions={{
                change: handleVerification_flow_6Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем, по показаниям эталона, м³"}
              placeholder={"0.0000"}
              value={etalon_volume_6}
              actions={{
                change: handleEtalon_volume_6Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем прибора, м³"}
              placeholder={"0.0000"}
              value={device_volume_6}
              actions={{
                change: handleDevice_volume_6Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поправка, %"}
              placeholder={"0.0"}
              value={correction_6}
              actions={{
                change: handleCorrection_6Change,
              }}
            />
          </div>
        </div>

        {correction_4 && correction_5 && correction_6 && (
          <div
            className={`indications_box-gas_bottom ${
              isPermissiblePassQt
                ? "indications_box-gas_bottom_true"
                : "indications_box-gas_bottom_false"
            }`}
            style={{ marginBottom: "20px" }}
          >
            <p
              className={`indications_box-gas_bottom ${
                isPermissiblePassQt ? "text_true" : "text_false"
              }`}
              style={{ marginBottom: "5px" }}
            >
              Среднее арифметическое значение погрешности:{" "}
              <span style={{ margin: "0 2px" }}>{permissible_error_q_t}</span> %
            </p>
            <p
              className={`indications_box-gas_bottom ${
                isPermissiblePassQt ? "text_true" : "text_false"
              }`}
            >
              Пределы допускаемой погрешности счётчика:{" "}
              <span style={{ margin: "0 2px" }}>±{q_max_limit} </span>%
            </p>
          </div>
        )}
      </React.Fragment>

      <React.Fragment>
        <p style={{ marginBottom: "20px" }}>
          Значение Qmax: <span>{q_max}</span> м³/ч
        </p>

        <div className="flexContainerWithGap">
          <div className="numberBox" style={{ marginBottom: "15px" }}>
            <p style={{ fontSize: "16px", fontWeight: "500" }}>7</p>
          </div>
          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поверочный расход, м³/ч"}
              placeholder={"0.0000"}
              value={verification_flow_7}
              actions={{
                change: handleVerification_flow_7Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем, по показаниям эталона, м³"}
              placeholder={"0.0000"}
              value={etalon_volume_7}
              actions={{
                change: handleEtalon_volume_7Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем прибора, м³"}
              placeholder={"0.0000"}
              value={device_volume_7}
              actions={{
                change: handleDevice_volume_7Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поправка, %"}
              placeholder={"0.0"}
              value={correction_7}
              actions={{
                change: handleCorrection_7Change,
              }}
            />
          </div>
        </div>

        <div className="flexContainerWithGap">
          <div className="numberBox" style={{ marginBottom: "15px" }}>
            <p style={{ fontSize: "16px", fontWeight: "500" }}>8</p>
          </div>
          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поверочный расход, м³/ч"}
              placeholder={"0.0000"}
              value={verification_flow_8}
              actions={{
                change: handleVerification_flow_8Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем, по показаниям эталона, м³"}
              placeholder={"0.0000"}
              value={etalon_volume_8}
              actions={{
                change: handleEtalon_volume_8Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Объем прибора, м³"}
              placeholder={"0.0000"}
              value={device_volume_8}
              actions={{
                change: handleDevice_volume_8Change,
              }}
            />
          </div>

          <div
            className="width24Point5Percent"
            style={{ marginBottom: "25px" }}
          >
            <Input
              label={"Поправка, %"}
              placeholder={"0.0"}
              value={correction_8}
              actions={{
                change: handleCorrection_8Change,
              }}
            />
          </div>
        </div>

        <div className="flexContainerWithGap">
          <div className="numberBox" style={{ marginBottom: "15px" }}>
            <p style={{ fontSize: "16px", fontWeight: "500" }}>9</p>
          </div>
          <div className="width24Point5Percent" style={{ marginTop: "25px" }}>
            <Input
              label={"Поверочный расход, м³/ч"}
              placeholder={"0.0000"}
              value={verification_flow_9}
              actions={{
                change: handleVerification_flow_9Change,
              }}
            />
          </div>

          <div className="width24Point5Percent">
            <Input
              label={"Объем, по показаниям эталона, м³"}
              placeholder={"0.0000"}
              value={etalon_volume_9}
              actions={{
                change: handleEtalon_volume_9Change,
              }}
            />
          </div>

          <div className="width24Point5Percent">
            <Input
              label={"Объем прибора, м³"}
              placeholder={"0.0000"}
              value={device_volume_9}
              actions={{
                change: handleDevice_volume_9Change,
              }}
            />
          </div>

          <div className="width24Point5Percent">
            <Input
              label={"Поправка, %"}
              placeholder={"0.0"}
              value={correction_9}
              actions={{
                change: handleCorrection_9Change,
              }}
            />
          </div>
        </div>

        {correction_7 && correction_8 && correction_9 && (
          <div
            className={`indications_box-gas_bottom ${
              isPermissiblePassQmax
                ? "indications_box-gas_bottom_true"
                : "indications_box-gas_bottom_false"
            }`}
            style={{ marginTop: "20px" }}
          >
            <p
              className={`indications_box-gas_bottom ${
                isPermissiblePassQmax ? "text_true" : "text_false"
              }`}
              style={{ marginBottom: "5px" }}
            >
              Среднее арифметическое значение погрешности:{" "}
              <span style={{ margin: "0 2px" }}>{permissible_error_q_max}</span>{" "}
              %
            </p>
            <p
              className={`indications_box-gas_bottom ${
                isPermissiblePassQmax ? "text_true" : "text_false"
              }`}
            >
              Пределы допускаемой погрешности счётчика:{" "}
              <span style={{ margin: "0 2px" }}>±{q_max_limit} </span>%
            </p>
          </div>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default IndicationsFull;
