import React from "react"
import api from "./axios-config.service"
import { saveAs } from "file-saver"
import Cookies from "js-cookie"

import SaveAltIcon from '@mui/icons-material/SaveAlt'

const PdfDownloadButton = ({ 
  measurementId, 
  meterId, 
  viewType = 'default', 
  title = "undefined",
  isMask = false 
}) => {
  const handlePdfDownload = async () => {
    try {
      const token = Cookies.get("accessToken")
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/meters/${measurementId}/${meterId}/pdf`
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/pdf",
      }

      const response = await api.get(apiUrl, {
        headers,
        responseType: "blob",
      })

      false && console.log(response.status)

      const fileName = `${title}.pdf`

      saveAs(response.data, fileName)
    } catch (error) { console.error(error) }
  }

  return (
    <React.Fragment>
      { viewType === 'default' ? <i
          className="far fa-file-pdf icon"
          style={{ 
            fontSize: isMask ? "inherit" : "16px", 
            opacity: isMask ? 0 : 1 }}
          onClick={handlePdfDownload}
        /> : <SaveAltIcon 
          style={{ 
            opacity: isMask ? 0 : 1 
          }} 
          onClick={handlePdfDownload} 
        /> 
      }
    </React.Fragment>
  )
}

export default PdfDownloadButton
