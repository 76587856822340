import React, { useState, useEffect } from "react"
import api from "../../services/axios-config.service"
import Selector from "../LocalElements/Selector/reactSelect/Selector"
import { useDispatch, useSelector } from "react-redux"

import { changeEtalon } from "../../store/slices/views/etalon"

const SelectCompany = () => {
  const token = useSelector(state => state.authReducer.authData.token)
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const isClearable = true
  const isSearchable = false

  const companyId = useSelector(
    (state) => state.etalonReducer.createData.companyId
  )

  const options = data
    ? data.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = (selectedOption) => {
    dispatch(
      changeEtalon({
        value: "companyId",
        label: selectedOption ? selectedOption.value : null,
      })
    )
  }

  useEffect(() => {
    ( async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/companies/list`
        const headers = {
          Authorization: `Bearer ${token}`,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        }
        const response = await api.get(apiUrl, { headers })
        const etalon = response.data.data
        setData(etalon)
      } catch (error) {
        console.error(error)
      }
    })()
  }, [token])

  return (
    <React.Fragment>
      <Selector
        placeholder={"Выберите компанию"}
        options={options}
        value={options.find((option) => option.value === companyId)}
        onChange={getValue}
        isClearable={isClearable}
        isSearchable={isSearchable}
      />
    </React.Fragment>
  )
}

export default SelectCompany
