import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { settingsFileViewer } from "../../../store/slices/comps/modal"
import { styled } from "@mui/material/styles"
import IconButton from '@mui/material/IconButton'
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Button from "@mui/material/Button"

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import iconPDF from '../../../img/iconPDF.svg'

import { Document, Page, pdfjs } from 'react-pdf'

const FileViewerComponent = () => {

  pdfjs.GlobalWorkerOptions.workerSrc = `
    //cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js
  `

  const [ totalPages, setTotalPages ] = useState(1)
  const [ pageNumber, setPageNumber ] = useState(1)
  const [ scale, setScale ] = useState(1)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages)
  }
 
  const previousPage = () => {
    pageNumber !== 1 && setPageNumber(prevPageNumber => prevPageNumber - 1)
  }
 
  const nextPage = () => {
    pageNumber < totalPages && setPageNumber(prevPageNumber => prevPageNumber + 1)
  }

  const dispatch = useDispatch()

  const open = useSelector((state) => state.modalReducer.fileViewerMode.open)
  const pdf = useSelector(state => state.meterReducer.selectedMeter.pdf)

  const BootstrapDialog = styled(
    Dialog
  )(() => ({
    '& .MuiPaper-root': {
      width: '100vw',
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100vh',
      margin: 0,
      backgroundColor: 'transparent',
      borderRadius: '0px'
    },
    '& .MuiDialogTitle-root': {
      backgroundColor: 'white',
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '16px'
    },
    '& .MuiDialogActions-root': {
      backgroundColor: 'rgba(19, 37, 50, 0.3)',
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    '& .MuiDialogContent-root': {
      position: 'relative',
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: 0
    }
  }))

  return (
    <React.Fragment>
      <BootstrapDialog
        open={open}
        keepMounted
        onClose={() => dispatch(settingsFileViewer({ value: 'open', label: false }))}
        aria-describedby="alert-dialog-slide-description"
        style={{
          width: '100vw'
        }}
      >
        <DialogTitle
          style={{
            textTransform: "none",
            fontSize: "18px",
            fontFamily: '"Wix Madefor Display", sans-serif',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              position: 'relative',
              boxSizing: 'border-box',
              width: '320px',
              minHeight: '30px',
              borderRadius: '8px',
              marginLeft: '8.5px',
              gap: '19px',
              cursor: 'pointer'
            }}
          >
            <img
              alt={""}
              src={iconPDF}
              style={{
                width: '28px',
              }}
            />
            { pdf && <div
              style={{
                display: 'flex',
                flexFlow: 'column'
              }}
            >
              <span style={{ fontSize: '15px', fontWeight: 500, marginBottom: '0px', letterSpacing: '0.6px' }}>
                { pdf.name }
              </span>
              <span style={{ fontSize: '14px', color: '#899196' }}>
                Размер файла - { (Number(pdf.size) / 1024).toFixed(0) } KB
              </span>
            </div> }
          </div>
          <span
            style={{
              display: 'block',
              position: 'absolute',
              width: '200px',
              textAlign: 'center',
              left: '50%',
              marginLeft: '-100px',
              fontSize: '16px',
              fontWeight: 600
            }}
          >
            Страница { pageNumber } из { totalPages }
          </span>
          <Button
            variant="contained"
            disabled
            onClick={() => {}}
            style={{
              textTransform: 'none',
              fontSize: '15px',
              fontFamily: '"Wix Madefor Display", sans-serif',
              padding: '10px 33px 12px',
              borderRadius: '16px'
            }}
          > 
            Скачать документ
          </Button>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'block',
              position: 'relative',
              width: '100%',
              height: 'calc(100vh - 200px)',
              overflow: 'hidden',
              borderRadius: '4px'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'center',
                position: 'relative',
                width: 'calc(100% + 30px)',
                paddingRight: '30px',
                height: '100%',
                overflowY: 'scroll',
              }}
            >
              { pdf && <Document
                file={pdf.path}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={"Формирование PDF визуализации.."}
              >
                <Page 
                  pageNumber={pageNumber}
                  scale={scale} 
                />
              </Document> }
            </div>
          </div>
          <IconButton
            onClick={() => {
              setScale(1)
              setPageNumber(1) 
              dispatch(settingsFileViewer({ value: 'open', label: false }))
            }}
            aria-label="add"
            style={{
              backgroundColor: 'white',
              width: '44px',
              height: '44px',
              display: 'block',
              position: 'absolute',
              left: '100%',
              marginLeft: '-68px',
              top: 0,
              marginTop: '24px'
            }}
          >
            <CloseIcon 
              sx={{ 
                color: 'black',
                marginBottom: '-2px' 
              }} 
            />
          </IconButton>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
              width: '300px'
            }}
          >
            <IconButton
              disabled={pageNumber === 1}
              onClick={previousPage}
              style={{
                border: '0.6px solid white',
                boxSizing: 'border-box',
                width: '40px',
                height: '40px',
                opacity: pageNumber === 1 ? 0.6 : 1
              }}
            >
              <ArrowBackIosIcon
                sx={{ 
                  display: 'block',
                  position: 'absolute',
                  color: 'white',
                  marginLeft: '6.6px'
                }} 
              />
            </IconButton>
            <IconButton
              disabled={scale > 1 ? false : true}
              onClick={() => {
                setScale(prev => {
                  let newValue = prev - 0.2
                  if ( newValue > 0.99 ) return newValue
                  else return prev
                })
              }}
              style={{
                border: '0.6px solid white',
                width: '40px',
                height: '40px',
                opacity: scale === 1 ? 0.6 : 1
              }}
            >
              <RemoveIcon sx={{ color: 'white' }} />
            </IconButton>
            <span style={{ color: 'white', fontWeight: '200', fontSize: '15px' }}>
              { (scale * 100).toFixed(0) }%
            </span>
            <IconButton
              disabled={scale === 2 ? true : false}
              onClick={() => {
                setScale(prev => {
                  let newValue = prev + 0.2
                  if ( newValue <= 2 ) return newValue
                  else return prev
                })
              }}
              style={{
                border: '0.6px solid white',
                width: '40px',
                height: '40px',
                opacity: scale === 2 ? 0.6 : 1
              }}
              
            >
              <AddIcon sx={{ color: 'white' }} />
            </IconButton>
            <IconButton
              disabled={pageNumber === totalPages}
              onClick={nextPage}
              style={{
                border: '0.6px solid white',
                boxSizing: 'border-box',
                width: '40px',
                height: '40px',
                opacity: pageNumber === totalPages ? 0.6 : 1
              }}
            >
              <ArrowForwardIosIcon 
                sx={{ 
                  display: 'block',
                  position: 'absolute',
                  color: 'white',
                  marginLeft: '5px'
                }} 
              />
            </IconButton>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  )
}

export default FileViewerComponent
