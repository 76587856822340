// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react/jsx-no-target-blank */
// ----------------------------------------------------------------
import React, { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  setOpen,
  setTitle,
  setChildren,
  setBD,
  showBA,
  settingsFileViewer,
} from "../../store/slices/comps/modal"
import Selector from "../../components/LocalElements/Selector/reactSelect/Selector"
import { setSelectedMeter, setMeasurementId } from "../../store/slices/views/meters"
import useWindowDimensions from "../../hooks/useWindowDimensions"

import iconPDF from "../../img/iconPDF.svg"
import emptyMeter from "../../img/emptyMeter.jpg"

import D from "../../store/localStorage/dispatcher"
import R from "../../services/request.service"

import Fab from '@mui/material/Fab'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'

const MeterShow = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)

  const { meters_id } = useParams()
  const { width } = useWindowDimensions()

  const slider = useRef()
  const headliner = useRef()

  const measurement_id = useSelector((state) => state.meterReducer.measurement_id)
  const manufacturer = useSelector((state) => state.meterReducer.selectedMeter.manufacturer)
  const city = useSelector((state) => state.meterReducer.selectedMeter.city)
  const typeSi = useSelector((state) => state.meterReducer.selectedMeter.typeSi)
  const country = useSelector((state) => state.meterReducer.selectedMeter.country)
  const numberRegistry = useSelector((state) => state.meterReducer.selectedMeter.numberRegistry)
  const nameSi = useSelector((state) => state.meterReducer.selectedMeter.nameSi)
  const link = useSelector((state) => state.meterReducer.selectedMeter.link)
  const image = useSelector((state) => state.meterReducer.selectedMeter.image)
  const pdf = useSelector((state) => state.meterReducer.selectedMeter.pdf)
  const coldValue = useSelector((state) => state.meterReducer.selectedMeter.cold)
  const hotValue = useSelector((state) => state.meterReducer.selectedMeter.hot)
  const qminCold = useSelector((state) => state.meterReducer.selectedMeter.q_min_limit_cold)
  const qmaxCold = useSelector((state) => state.meterReducer.selectedMeter.q_max_limit_cold)
  const qminHot = useSelector((state) => state.meterReducer.selectedMeter.q_min_limit_hot)
  const qmaxHot = useSelector((state) => state.meterReducer.selectedMeter.q_max_limit_hot)

  const mpiHot = useSelector((state) => state.meterReducer.selectedMeter.mpiHot)
  const mpiCold = useSelector((state) => state.meterReducer.selectedMeter.mpiCold)
  const qminLimitCold = useSelector((state) => state.meterReducer.selectedMeter.qminLimitCold)
  const qmaxLimitCold = useSelector((state) => state.meterReducer.selectedMeter.qmaxLimitCold)
  const qminLimitHot = useSelector((state) => state.meterReducer.selectedMeter.qminLimitHot)
  const qmaxLimitHot = useSelector((state) => state.meterReducer.selectedMeter.qminLimitHot)

  const variants = useSelector((state) => state.meterReducer.selectedMeter.variants)
  const selectedVariant = useSelector((state) => state.meterReducer.selectedMeter.selectedModification)

  const [ selectedImage, setSelectedImage ] = useState(0)
  const [ scrolling, setScrolling ] = useState(false)

  const [ ping, setPing ] = useState(false)
  const [ additionalValue, setAdditionalValue ] = useState(null)

  const navigateToProtocols = () => {
    navigate("/metriva/meters")
  }

  const showImageFull = (param) => {
    dispatch(setTitle(""))
    dispatch(showBA(false))
    dispatch(setBD("Закрыть окно"))
    dispatch(
      setChildren(
        <img
          alt={""}
          src={param}
          style={{
            display: "block",
            position: "relative",
            width: "550px",
            border: "1px solid #EAEDF3",
            borderRadius: "12px",
            objectFit: "contain",
            cursor: "pointer",
            marginTop: "4px",
          }}
        />
      )
    )
    dispatch(setOpen(true))
  }

  useEffect(() => {
    if ( measurement_id ) {
      D.set("measurement_id", measurement_id)
    }
  }, [ measurement_id ])

  useEffect(() => {
    const savedMeasurementId = D.get("measurement_id")
    if ( savedMeasurementId ) {
      dispatch(setMeasurementId(savedMeasurementId))
    }
  }, [])

  useEffect(() => {

    false && console.log(variants)

  }, [ variants ])

  useEffect(() => {
    if ( nameSi === "" ) {
      ( async () => {
        const { data } = await R.getMeterItem(`${measurement_id}/${meters_id}`)

        console.log(data)

        data.data &&
          dispatch(
            setSelectedMeter({
              value: "manufacturer",
              label: data.data.manufacturer.name,
            })
          )
        data.data &&
          dispatch(
            setSelectedMeter({
              value: "city",
              label: data.data.manufacturer_cities,
            })
          )
        data.data &&
          dispatch(
            setSelectedMeter({
              value: "country",
              label: data.data.manufacturer_countries,
            })
          )
        data.data &&
          dispatch(
            setSelectedMeter({
              value: "numberRegistry",
              label: data.data.fif_number,
            })
          )
        data.data &&
          dispatch(
            setSelectedMeter({
              value: "nameSi",
              label: data.data.name_si,
            })
          )
        data.data &&
          dispatch(
            setSelectedMeter({
              value: "typeSi",
              label: data.data.type_si,
            })
          )
        data.data &&
          dispatch(
            setSelectedMeter({
              value: "link",
              label: data.data.type_link,
            })
          )
        data.data &&
          dispatch(
            setSelectedMeter({
              value: "pdf",
              label: data.data.pdf,
            })
          )
        data.data &&
          dispatch(
            setSelectedMeter({
              value: "image",
              label: data.data.images 
                ? data.data.images 
                : [],
            })
          )
        data.data &&
          dispatch(
            setSelectedMeter({
              value: "variants",
              label: data.data.modifications,
            })
          )

        if ( variants[selectedVariant] ) {
          if ( variants[selectedVariant].additionals.length === 0 && data.data ) {
            dispatch(
              setSelectedMeter({
                value: "cold",
                label: data.data.mpi_cold ? data.data.mpi_cold : null,
              })
            )}
          if ( variants[selectedVariant].additionals.length === 0 && data.data ) {
            dispatch(
              setSelectedMeter({
                value: "hot",
                label: data.data.mpi_hot ? data.data.mpi_hot : null,
              })
            )}
          
          data.data &&
            dispatch(
              setSelectedMeter({
                value: "q_min_limit_cold",
                label: variants[selectedVariant].q_min_limit_cold,
              })
            )
          data.data &&
            dispatch(
              setSelectedMeter({
                value: "q_max_limit_cold",
                label: variants[selectedVariant].q_max_limit_cold,
              })
            )
          data.data &&
            dispatch(
              setSelectedMeter({
                value: "q_min_limit_hot",
                label: variants[selectedVariant].q_min_limit_hot,
              })
            )
          data.data &&
            dispatch(
              setSelectedMeter({
                value: "q_max_limit_hot",
                label: variants[selectedVariant].q_max_limit_hot,
              })
            )
        }
      })()
    }
  }, [ measurement_id, meters_id, nameSi ])

  useEffect(() => {
      ( async () => {
        const { data } = await R.getMeterItem(`${measurement_id}/${meters_id}`)

        if ( variants[selectedVariant] ) {
          if ( variants[selectedVariant].additionals.length === 0 && data.data ) {
            dispatch(
              setSelectedMeter({
                value: "cold",
                label: data.data.mpi_cold ? data.data.mpi_cold : null,
              })
            )}
          if ( variants[selectedVariant].additionals.length === 0 && data.data ) {
            dispatch(
              setSelectedMeter({
                value: "hot",
                label: data.data.mpi_hot ? data.data.mpi_hot : null,
              })
            )}

          if ( variants[selectedVariant].additionals.length !== 0 && data.data ) {
            dispatch(
              setSelectedMeter({
                value: "cold",
                label: null,
              })
            )}
          if ( variants[selectedVariant].additionals.length !== 0 && data.data ) {
            dispatch(
              setSelectedMeter({
                value: "hot",
                label: null,
              })
            )}

          data.data &&
            dispatch(
              setSelectedMeter({
                value: "q_min_limit_cold",
                label: variants[selectedVariant].q_min_limit_cold,
              })
            )
          data.data &&
            dispatch(
              setSelectedMeter({
                value: "q_max_limit_cold",
                label: variants[selectedVariant].q_max_limit_cold,
              })
            )
          data.data &&
            dispatch(
              setSelectedMeter({
                value: "q_min_limit_hot",
                label: variants[selectedVariant].q_min_limit_hot,
              })
            )
          data.data &&
            dispatch(
              setSelectedMeter({
                value: "q_max_limit_hot",
                label: variants[selectedVariant].q_max_limit_hot,
              })
            )
        }
      })()
  }, [ ping ])

  useEffect(() => {

    headliner.current.scrollIntoView({ behavior: 'smooth' })

  }, [ scrolling ])

  useEffect(() => {
    return () => {
      dispatch(settingsFileViewer({ value: "open", label: false }))
    }
  }, [])

  const cold = coldValue || "-"
  let formattedCold = "Отсутствует"

  if (cold && !isNaN(cold)) {
    const numberValue = parseFloat(cold) % 12 === 0 
    ? parseFloat(cold) / 12 
    : Math.floor(parseFloat(cold) / 12) === 1 
    ? `${Math.floor(parseFloat(cold) / 12)} лет ${parseFloat(cold) % 12} месяцев`
    : ( Math.floor(parseFloat(cold) / 12) === 2 || Math.floor(parseFloat(cold) / 12) === 3 || Math.floor(parseFloat(cold) / 12) === 4 )
    ? `${Math.floor(parseFloat(cold) / 12)} года ${parseFloat(cold) % 12} месяцев`
    : `${Math.floor(parseFloat(cold) / 12)} лет ${parseFloat(cold) % 12} месяцев`
    
    if ( isNaN(numberValue) ) { 
      formattedCold = numberValue
    } else  {
      formattedCold = +numberValue === 1
      ? numberValue + ' год'
      : ( +numberValue === 2 || +numberValue === 3 || +numberValue === 4 )
      ? numberValue + ' года'
      : numberValue + ' лет'
    }
  }

  const hot = hotValue || "-"
  let formattedHot = "Отсутствует"

  if (hot && !isNaN(hot)) {
    const numberValue = parseFloat(hot) % 12 === 0 
    ? parseFloat(hot) / 12 
    : Math.floor(parseFloat(hot) / 12) === 1 
    ? `${Math.floor(parseFloat(hot) / 12)} лет ${parseFloat(hot) % 12} месяцев`
    : ( Math.floor(parseFloat(hot) / 12) === 2 || Math.floor(parseFloat(hot) / 12) === 3 || Math.floor(parseFloat(hot) / 12) === 4 )
    ? `${Math.floor(parseFloat(hot) / 12)} года ${parseFloat(hot) % 12} месяцев`
    : `${Math.floor(parseFloat(hot) / 12)} лет ${parseFloat(hot) % 12} месяцев`
    
    if ( isNaN(numberValue) ) { 
      formattedHot = numberValue
    } else  {
      formattedHot = +numberValue === 1
      ? numberValue + ' год'
      : ( +numberValue === 2 || +numberValue === 3 || +numberValue === 4 )
      ? numberValue + ' года'
      : numberValue + ' лет'
    }

    
  }

  const qMinVertical = variants[selectedVariant] ? variants[selectedVariant]["q_min_vertical"] : null
  let formattedQMinVertical = "-"

  if (qMinVertical && !isNaN(qMinVertical)) {
    const numberValue = parseFloat(qMinVertical)
    formattedQMinVertical = numberValue.toFixed(4).replace(/\.?0+$/, "")
  }

  const qMinHorizontal = variants[selectedVariant] ? variants[selectedVariant]["q_min_horizontal"] : null
  let formattedQMinHorizontal = "-"

  if (qMinHorizontal && !isNaN(qMinHorizontal)) {
    const numberValue = parseFloat(qMinHorizontal)
    formattedQMinHorizontal = numberValue.toFixed(4).replace(/\.?0+$/, "")
  }

  const qTVertical = variants[selectedVariant] ? variants[selectedVariant]["q_t_vertical"] : null
  let formattedQTVertical = "-"

  if (qTVertical && !isNaN(qTVertical)) {
    const numberValue = parseFloat(qTVertical)
    formattedQTVertical = numberValue.toFixed(4).replace(/\.?0+$/, "")
  }

  const qTHorizontal = variants[selectedVariant] ? variants[selectedVariant]["q_t_horizontal"] : null
  let formattedQTHorizontal = "-"

  if (qTHorizontal && !isNaN(qTHorizontal)) {
    const numberValue = parseFloat(qTHorizontal)
    formattedQTHorizontal = numberValue.toFixed(4).replace(/\.?0+$/, "")
  }

  const qMaxVertical = variants[selectedVariant] ? variants[selectedVariant]["q_max_vertical"] : null
  let formattedQMaxVertical = "-"

  if (qMaxVertical && !isNaN(qMaxVertical)) {
    const numberValue = parseFloat(qMaxVertical)
    formattedQMaxVertical = numberValue.toFixed(4).replace(/\.?0+$/, "")
  }

  const qMaxHorizontal = variants[selectedVariant] ? variants[selectedVariant]["q_max_horizontal"] : null
  let formattedQMaxHorizontal = "-"

  if (qMaxHorizontal && !isNaN(qMaxHorizontal)) {
    const numberValue = parseFloat(qMaxHorizontal)
    formattedQMaxHorizontal = numberValue.toFixed(4).replace(/\.?0+$/, "")
  }

  return (
    <main 
      className={isToggle ? "main_open" : "main"}
      style={ width <= 1050 ? { paddingTop: 0 } : {}}
    >
      <React.Fragment>
        { false ? null : (
          <React.Fragment>
            { width <= 1050 && <Fab 
              color="primary" 
              aria-label="add"
              onClick={navigateToProtocols}
              style={{ 
                position: "absolute",
                top: "100%",
                marginTop: "-78.8px"
              }}
            >
              <ArrowBackIosNewIcon
                style={{ marginRight: '3px' }}
              />
            </Fab> }
            { width > 1050 && <div 
              className="path_to_back" 
              onClick={navigateToProtocols}
            >
              <span
                style={{ width: "30px", height: "30px", marginRight: "6px" }}
                className="fas fa-arrow-left icon_back"
              />
              <p>Все приборы</p>
            </div> }
            <div 
              className="path_to_edit" 
              style={
                width <= 1050 
                  ? { flexWrap: 'wrap', gap: '13px' } 
                  : { marginBottom: "0px" }
              }
            >
              <h2
                ref={headliner}
                style={ width > 1050 ? {
                  lineHeight: "30px",
                  width: "calc(100% - 400px)",
                } : {
                  lineHeight: "30px",
                  width: "100%",
                  textAlign: "center",
                  paddingTop: "28px"
                }}
              >
                { manufacturer }
              </h2>
              { width <= 1050 && <div style={{ width: "100%", boxSizing: "border-box" }}>
                { typeof image === "object" &&
                  image[selectedImage] === undefined && (
                    <img
                      alt={""}
                      src={emptyMeter}
                      style={{
                        display: "block",
                        position: "relative",
                        width: "60%",
                        border: "1px solid #EAEDF3",
                        borderRadius: "50%",
                        objectFit: "contain",
                        cursor: "pointer",
                        margin: "0 auto"
                      }}
                      onClick={() => {
                        showImageFull(image[selectedImage].path)
                      }}
                    />
                  )}
                { typeof image === "object" &&
                  image[selectedImage] === undefined && (
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "row",
                        width: "100%",
                        justifyContent: "center",
                        position: "relative",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexFlow: "row",
                          gap: "10px",
                          position: "relative",
                        }}
                      >
                        <span
                          ref={slider}
                          className="imageSliderPoint__slider"
                          style={{
                            display: "block",
                            position: "relative",
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            backgroundColor: "#0084e2",
                            left: 0,
                            filter: "grayscale(1)",
                            opacity: "0.6",
                          }}
                        />
                      </div>
                    </div>
                  )}

                { typeof image === "object" &&
                  image[selectedImage] !== undefined && (
                    <img
                      alt={""}
                      src={image[selectedImage].path}
                      style={{
                        display: "block",
                        position: "relative",
                        width: "100%",
                        border: "1px solid #EAEDF3",
                        borderRadius: "50%",
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        showImageFull(image[selectedImage].path)
                      }}
                    />
                  )}
                { typeof image === "object" &&
                  image[selectedImage] !== undefined && (
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "row",
                        width: "100%",
                        justifyContent: "center",
                        position: "relative",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexFlow: "row",
                          gap: "10px",
                          position: "relative",
                        }}
                      >
                        <span
                          ref={slider}
                          className="imageSliderPoint__slider"
                          style={{
                            display: "block",
                            position: "absolute",
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            backgroundColor: "#0084e2",
                            left:
                              selectedImage !== 0
                                ? selectedImage * 20 + selectedImage * 10 + "px"
                                : 0,
                          }}
                        />
                        { typeof image === "object" &&
                          image &&
                          image.map(($$, index) => {
                            return (
                              <span
                                onClick={() => setSelectedImage(index)}
                                className="imageSliderPoint"
                                style={{
                                  display: "block",
                                  position: "relative",
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  border: "1px solid #0084e2",
                                }}
                              />
                            )
                          })}
                      </div>
                    </div>
                  )}
              </div> }
              <div style={{ width: width > 1050 ? "400px" : "100%" }}>
                <Selector
                  isDisabled={false}
                  placeholder={"Выберите модификацию прибора"}
                  defaultValue={ variants[selectedVariant] ? {
                    value: 0,
                    label: variants[selectedVariant].name
                  } : null}
                  options={variants.map((variant, index) => {
                    return {
                      value: index,
                      label: variant.name
                    }
                  })}
                  onChange={(inputValue) => {
                    setPing(!ping)
                    setAdditionalValue(null)

                    inputValue
                      ? dispatch(
                          setSelectedMeter({
                            value: "selectedModification",
                            label: inputValue.value,
                          })
                        )
                      : dispatch(
                          setSelectedMeter({
                            value: "selectedModification",
                            label: 0,
                          })
                        )
                  }}
                  isClearable={true}
                  isSearchable={true}
                  noOptionsMessage={() => "Нет доступных комплектов СИ"}
                />
              </div>
            </div>
            <div
              className="box"
              style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "space-between",
              }}
            >
              <h2
                style={{
                  display: "block",
                  position: "relative",
                  width: "100%",
                  marginBottom: "18px",
                }}
              >
                Общие данные о приборе
              </h2>
              <div 
                style={ width > 1050 ?
                  { width: "70%", boxSizing: "border-box" } :
                  { width: "100%", boxSizing: "border-box" }
                }
              >
                <div
                  className="show_protocol_meter_box"
                  style={ width > 1050 ? {
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "22px",
                    paddingRight: "100px",
                  } : {
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "22px",
                    paddingRight: "0px",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      width: width > 1050 ? "40%" : "100%",
                      lineHeight: "25px",
                    }}
                  >
                    Номер в реестре ФИФ :
                  </span>
                  <h4
                    style={{
                      letterSpacing: "0.6px",
                      width: width > 1050 ? "60%" : "100%",
                      lineHeight: "25px",
                      fontWeight: 500,
                      marginTop: width > 1050 ? 0 : "8px"
                    }}
                  >
                    {numberRegistry}
                  </h4>
                </div>
                <div
                  className="show_protocol_meter_box"
                  style={ width > 1050 ? {
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "22px",
                    paddingRight: "100px",
                  } : {
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "22px",
                    paddingRight: "0px",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      width: width > 1050 ? "40%" : "100%",
                      lineHeight: "25px",
                    }}
                  >
                    Наименование счетчика :
                  </span>
                  <h4 
                    style={{ 
                      width: width > 1050 ? "60%" : "100%", 
                      lineHeight: "25px",
                      fontWeight: 500,
                      marginTop: width > 1050 ? 0 : "8px"
                    }}
                  >
                    {nameSi}
                  </h4>
                </div>

                <div
                  className="show_protocol_meter_box"
                  style={ width > 1050 ? {
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "22px",
                    paddingRight: "100px",
                  } : {
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "22px",
                    paddingRight: "0px",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      width: width > 1050 ? "40%" : "100%",
                      lineHeight: "25px",
                    }}
                  >
                    Модификация :
                  </span>
                  <h4 
                    style={{ 
                      width: width > 1050 ? "60%" : "100%", 
                      lineHeight: "25px",
                      fontWeight: 500,
                      marginTop: width > 1050 ? 0 : "8px"
                    }}
                  >
                    {typeSi}
                  </h4>
                </div>

                <div
                  className="show_protocol_meter_box"
                  style={ width > 1050 ? {
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "22px",
                    paddingRight: "100px",
                  } : {
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "22px",
                    paddingRight: "0px",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      width: width > 1050 ? "40%" : "100%",
                      lineHeight: "25px",
                    }}
                  >
                    Страна производства :
                  </span>
                  <h4 
                    style={{ 
                      width: width > 1050 ? "60%" : "100%", 
                      lineHeight: "25px",
                      fontWeight: 500,
                      marginTop: width > 1050 ? 0 : "8px"
                    }}
                  >
                    {country}
                  </h4>
                </div>
                <div
                  className="show_protocol_meter_box"
                  style={ width > 1050 ? {
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "22px",
                    paddingRight: "100px",
                  } : {
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "22px",
                    paddingRight: "0px",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      width: width > 1050 ? "40%" : "100%",
                      lineHeight: "25px",
                    }}
                  >
                    Город производства :
                  </span>
                  <h4 
                    style={{ 
                      width: width > 1050 ? "60%" : "100%", 
                      lineHeight: "25px",
                      fontWeight: 500,
                      marginTop: width > 1050 ? 0 : "8px"
                    }}
                  >
                    {city || "-"}
                  </h4>
                </div>
                <div
                  className="show_protocol_meter_box"
                  style={ width > 1050 ? {
                    display: "flex",
                    flexFlow: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "22px",
                    paddingRight: "100px",
                  } : {
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <span
                    style={{
                      display: "block",
                      width: width > 1050 ? "40%" : "100%",
                      lineHeight: "25px",
                    }}
                  >
                    Завод производитель :
                  </span>
                  <h4 
                    style={{ 
                      width: width > 1050 ? "60%" : "100%", 
                      lineHeight: "25px",
                      fontWeight: 500,
                      marginTop: width > 1050 ? 0 : "8px"
                    }}
                  >
                    {manufacturer}
                  </h4>
                </div>
              </div>
              { width > 1050 && <div style={{ width: "26.6%", boxSizing: "border-box" }}>
                { typeof image === "object" &&
                  image[selectedImage] === undefined && (
                    <img
                      alt={""}
                      src={emptyMeter}
                      style={{
                        display: "block",
                        position: "relative",
                        width: "100%",
                        border: "1px solid #EAEDF3",
                        borderRadius: "12px",
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        showImageFull(image[selectedImage].path)
                      }}
                    />
                  )}
                { typeof image === "object" &&
                  image[selectedImage] === undefined && (
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "row",
                        width: "100%",
                        justifyContent: "center",
                        position: "relative",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexFlow: "row",
                          gap: "10px",
                          position: "relative",
                        }}
                      >
                        <span
                          ref={slider}
                          className="imageSliderPoint__slider"
                          style={{
                            display: "block",
                            position: "relative",
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            backgroundColor: "#0084e2",
                            left: 0,
                            filter: "grayscale(1)",
                            opacity: "0.6",
                          }}
                        />
                      </div>
                    </div>
                  )}

                { typeof image === "object" &&
                  image[selectedImage] !== undefined && (
                    <img
                      alt={""}
                      src={image[selectedImage].path}
                      style={{
                        display: "block",
                        position: "relative",
                        width: "100%",
                        border: "1px solid #EAEDF3",
                        borderRadius: "12px",
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        showImageFull(image[selectedImage].path)
                      }}
                    />
                  )}
                { typeof image === "object" &&
                  image[selectedImage] !== undefined && (
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "row",
                        width: "100%",
                        justifyContent: "center",
                        position: "relative",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexFlow: "row",
                          gap: "10px",
                          position: "relative",
                        }}
                      >
                        <span
                          ref={slider}
                          className="imageSliderPoint__slider"
                          style={{
                            display: "block",
                            position: "absolute",
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            backgroundColor: "#0084e2",
                            left:
                              selectedImage !== 0
                                ? selectedImage * 20 + selectedImage * 10 + "px"
                                : 0,
                          }}
                        />
                        { typeof image === "object" &&
                          image &&
                          image.map(($$, index) => {
                            return (
                              <span
                                onClick={() => setSelectedImage(index)}
                                className="imageSliderPoint"
                                style={{
                                  display: "block",
                                  position: "relative",
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  border: "1px solid #0084e2",
                                }}
                              />
                            )
                          })}
                      </div>
                    </div>
                  )}
              </div> }
            </div>
            <div
              className="box"
              style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              {( variants[selectedVariant] && variants[selectedVariant].additionals ) && <div
                style={ width > 1050 ? {
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: "100%",
                  marginTop: "22px",
                  width: "400px",
                  marginLeft: "-420px"
                } : {
                  display: "block",
                  position: "relative",
                  width: "100%",
                  marginBottom: "20px"
                }}
              >
                <Selector
                  placeholder={ variants[selectedVariant].additionals.length > 0
                    ? "Есть дополнительные условия"
                    : "Дополнительных условий нет"
                  }
                  options={variants[selectedVariant].additionals.map((additional, index) => {
                    return {
                      value: {
                        mpiCold: additional.mpi_cold,
                        mpiHot: additional.mpi_hot
                      },
                      label: additional.text
                    }
                  })}
                  value={additionalValue}
                  onChange={(inputValue) => {
                    setAdditionalValue(inputValue)

                    dispatch(
                      setSelectedMeter({
                        value: "cold",
                        label: inputValue.value.mpiCold,
                      })
                    )
                    dispatch(
                      setSelectedMeter({
                        value: "hot",
                        label: inputValue.value.mpiHot,
                      })
                    )
                  }}
                  isDisabled={ 
                    variants[selectedVariant].additionals.length > 0 ? false : true 
                  }
                  isClearable={true}
                  isSearchable={true}
                  noOptionsMessage={() => ""}
                />
              </div> }
              <div
                className="show_protocol_meter_box"
                style={ width > 1050 ? {
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "10px",
                  paddingRight: "100px",
                  gap: "16px",
                } : {
                  display: "flex",
                  flexFlow: "row wrap",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "16px",
                  paddingRight: "0px",
                  gap: "8px",
                }}
              >
                <span
                  style={ width > 1050 ? {
                    display: "block",
                    lineHeight: "23px",
                    fontWeight: 500,
                  } : {
                    width: "100%",
                    display: "block",
                    lineHeight: "23px",
                    fontWeight: 500,
                  }}
                >
                  { width > 1050 
                    ? "Межповерочный интервал - холодная вода :"
                    : "МПИ - холодная вода :"
                  }
                </span>
                <h4
                  style={ width > 1050 ? {
                    letterSpacing: "0.6px",
                    lineHeight: "23px",
                    fontWeight: 200,
                  } : {
                    letterSpacing: "0.6px",
                    lineHeight: "23px",
                    fontWeight: 200,
                    width: "100%",
                  }}
                >
                  {formattedCold}
                </h4>
              </div>
              <div
                className="show_protocol_meter_box"
                style={ width > 1050 ? {
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "0px",
                  paddingRight: "100px",
                  gap: "16px",
                } : {
                  display: "flex",
                  flexFlow: "row wrap",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "0px",
                  paddingRight: "0px",
                  gap: "8px",
                }}
              >
                <span
                  style={ width > 1050 ? {
                    display: "block",
                    lineHeight: "23px",
                    fontWeight: 500,
                  } : {
                    width: "100%",
                    display: "block",
                    lineHeight: "23px",
                    fontWeight: 500,
                  }}
                >
                  { width > 1050 
                    ? "Межповерочный интервал - горячая вода :"
                    : "МПИ - горячая вода :"
                  }
                </span>
                <h4
                  style={ width > 1050 ? {
                    letterSpacing: "0.6px",
                    lineHeight: "23px",
                    fontWeight: 200,
                  } : {
                    letterSpacing: "0.6px",
                    lineHeight: "23px",
                    fontWeight: 200,
                    width: "100%",
                  }}
                >
                  {formattedHot}
                </h4>
              </div>
            </div>
            <div
              className="box"
              style={{
                display: "flex",
                flexFlow: "column",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <div
                style={ width > 1050 ? {
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  borderRadius: "8px",
                  backgroundColor: "rgba(248, 249, 252, 1)",
                  padding: "14px",
                } : {
                  display: "flex",
                  flexFlow: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  borderRadius: "8px",
                  backgroundColor: "transparent",
                  padding: "14px",
                }}
              >
                <div style={{ width: width > 1050 ? "33.33333%" : "100%" }}>
                  <div
                    style={ width > 1050 ? {
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginBottom: "8px",
                    } : {
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginBottom: "8px",
                      backgroundColor: "rgba(248, 249, 252, 1)",
                      padding: "8px 0px 10px",
                      borderRadius: "4px"
                    }}
                  >
                    <span style={{ textAlign: "center", fontWeight: 600 }}>
                      Qmin
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>
                      <i style={{ fontStyle: "normal", fontWeight: 600 }}>A</i>{" "}
                      - вертикальная
                    </span>
                    <span>
                      <i style={{ fontStyle: "normal", fontWeight: 600 }}>B</i>{" "}
                      - горизонтальная
                    </span>
                  </div>
                  { width <= 1050 && <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginTop: "13px"
                    }}
                  >
                    <span>{formattedQMinVertical}</span>
                    <span>{formattedQMinHorizontal}</span>
                  </div> }
                </div>
                <div style={{ width: width > 1050 ? "33.33333%" : "100%" }}>
                  <div
                    style={ width > 1050 ? {
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginBottom: "8px",
                    } : {
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginBottom: "8px",
                      backgroundColor: "rgba(248, 249, 252, 1)",
                      padding: "8px 0px 10px",
                      borderRadius: "4px",
                      marginTop: "13px",
                    }}
                  >
                    <span style={{ textAlign: "center", fontWeight: 600 }}>
                      Qt
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>
                      <i style={{ fontStyle: "normal", fontWeight: 600 }}>A</i>{" "}
                      - вертикальная
                    </span>
                    <span>
                      <i style={{ fontStyle: "normal", fontWeight: 600 }}>B</i>{" "}
                      - горизонтальная
                    </span>
                  </div>
                  { width <= 1050 && <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginTop: "13px"
                    }}
                  >
                    <span>{formattedQTVertical}</span>
                    <span>{formattedQTHorizontal}</span>
                  </div> }
                </div>
                <div style={{ width: width > 1050 ? "33.33333%" : "100%" }}>
                  <div
                    style={ width > 1050 ? {
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginBottom: "8px",
                    } : {
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginBottom: "8px",
                      backgroundColor: "rgba(248, 249, 252, 1)",
                      padding: "8px 0px 10px",
                      borderRadius: "4px",
                      marginTop: "13px",
                    }}
                  >
                    <span style={{ textAlign: "center", fontWeight: 600 }}>
                      Qmax
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>
                      <i style={{ fontStyle: "normal", fontWeight: 600 }}>A</i>{" "}
                      - вертикальная
                    </span>
                    <span>
                      <i style={{ fontStyle: "normal", fontWeight: 600 }}>B</i>{" "}
                      - горизонтальная
                    </span>
                  </div>
                  { width <= 1050 && <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginTop: "13px"
                    }}
                  >
                    <span>{formattedQMaxVertical}</span>
                    <span>{formattedQMaxHorizontal}</span>
                  </div> }
                </div>
              </div>
              { width > 1050 && <div
                style={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  borderRadius: "8px",
                  padding: "14px",
                }}
              >
                <div style={{ width: "33.33333%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>{formattedQMinVertical}</span>
                    <span>{formattedQMinHorizontal}</span>
                  </div>
                </div>
                <div style={{ width: "33.33333%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>{formattedQTVertical}</span>
                    <span>{formattedQTHorizontal}</span>
                  </div>
                </div>
                <div style={{ width: "33.33333%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>{formattedQMaxVertical}</span>
                    <span>{formattedQMaxHorizontal}</span>
                  </div>
                </div>
              </div> }
            </div>
            <div
              className="box"
              style={{
                display: "flex",
                flexFlow: "column",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <div
                style={ width > 1050 ? {
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  borderRadius: "8px",
                  backgroundColor: "rgba(248, 249, 252, 1)",
                  padding: "14px",
                } : {
                  display: "flex",
                  flexFlow: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  borderRadius: "8px",
                  backgroundColor: "transparent",
                  padding: "14px",
                }}
              >
                <div style={{ width: width > 1050 ? "25%" : "100%" }}>
                  <div
                    style={ width > 1050 ? {
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginBottom: "8px",
                    } : {
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginBottom: "8px",
                      backgroundColor: "rgba(248, 249, 252, 1)",
                      padding: "8px 0px 10px",
                      borderRadius: "4px",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        lineHeight: "22px",
                      }}
                    >
                      Погрешность от Qmin
                      <br /> до Qt, % ХВС
                    </span>
                  </div>
                </div>
                { width <= 1050 && <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginTop: "6px"
                    }}
                  >
                    <span>
                      { qminCold || "-" }
                    </span>
                  </div>
                </div> }
                <div style={{ width: width > 1050 ? "25%" : "100%" }}>
                  <div
                    style={ width > 1050 ? {
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginBottom: "8px",
                    } : {
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginBottom: "8px",
                      backgroundColor: "rgba(248, 249, 252, 1)",
                      padding: "8px 0px 10px",
                      borderRadius: "4px",
                      marginTop: "17px",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        lineHeight: "22px",
                      }}
                    >
                      Погрешность от Qt
                      <br /> до Qmax, % ХВС
                    </span>
                  </div>
                </div>
                { width <= 1050 && <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginTop: "6px"
                    }}
                  >
                    <span>
                      { qmaxCold || "-" }
                    </span>
                  </div>
                </div> }
                <div style={{ width: width > 1050 ? "25%" : "100%" }}>
                  <div
                    style={ width > 1050 ? {
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginBottom: "8px",
                    } : {
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginBottom: "8px",
                      backgroundColor: "rgba(248, 249, 252, 1)",
                      padding: "8px 0px 10px",
                      borderRadius: "4px",
                      marginTop: "17px",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        lineHeight: "22px",
                      }}
                    >
                      Погрешность от Qmin
                      <br /> до Qt, % ГВС
                    </span>
                  </div>
                </div>
                { width <= 1050 && <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginTop: "6px"
                    }}
                  >
                    <span>
                      { qminHot || "-" }
                    </span>
                  </div>
                </div> }
                <div style={{ width: width > 1050 ? "25%" : "100%" }}>
                  <div
                    style={ width > 1050 ? {
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginBottom: "8px",
                    } : {
                      width: "100%",
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginBottom: "8px",
                      backgroundColor: "rgba(248, 249, 252, 1)",
                      padding: "8px 0px 10px",
                      borderRadius: "4px",
                      marginTop: "17px",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        lineHeight: "22px",
                      }}
                    >
                      Погрешность от Qt
                      <br /> до Qmax, % ГВС
                    </span>
                  </div>
                </div>
                { width <= 1050 && <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                      marginTop: "6px"
                    }}
                  >
                    <span>
                      { qmaxHot || "-" }
                    </span>
                  </div>
                </div> }
              </div>
              { width > 1050 && <div
                style={{
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  borderRadius: "8px",
                  padding: "14px",
                }}
              >
                <div style={{ width: "25%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>
                      { qminCold || "-" }
                    </span>
                  </div>
                </div>
                <div style={{ width: "25%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>
                      { qmaxCold || "-" }
                    </span>
                  </div>
                </div>
                <div style={{ width: "25%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>
                      { qminHot || "-" }
                    </span>
                  </div>
                </div>
                <div style={{ width: "25%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>
                      { qmaxHot || "-" }
                    </span>
                  </div>
                </div>
              </div> }
            </div>
            <div
              className="box"
              style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "space-between",
              }}
            >
              <div
                className="show_protocol_meter_box"
                style={ width > 1050 ? {
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "10px",
                  paddingRight: "100px",
                  gap: "16px",
                } : {
                  display: "flex",
                  flexFlow: "row wrap",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "16px",
                  paddingRight: "0px",
                  gap: "8px",
                }}
              >
                <span
                  style={ width > 1050 ? {
                    display: "block",
                    lineHeight: "23px",
                    fontWeight: 500,
                  } : {
                    width: "100%",
                    display: "block",
                    lineHeight: "23px",
                    fontWeight: 500,
                  }}
                >
                  Коэффициент преобразования :
                </span>
                <h4
                  style={ width > 1050 ? {
                    display: "block",
                    lineHeight: "23px",
                    fontWeight: 200,
                  } : {
                    width: "100%",
                    display: "block",
                    lineHeight: "23px",
                    fontWeight: 200,
                  }}
                >
                  {( variants[selectedVariant] && variants[selectedVariant]["conversion_factor"] ) 
                    ? variants[selectedVariant]["conversion_factor"] 
                    : "-" 
                  }
                </h4>
              </div>
              <div
                className="show_protocol_meter_box"
                style={ width > 1050 ? {
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "0px",
                  paddingRight: "100px",
                  gap: "16px",
                } : {
                  display: "flex",
                  flexFlow: "row wrap",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "0px",
                  paddingRight: "0px",
                  gap: "8px",
                }}
              >
                <span
                  style={ width > 1050 ? {
                    display: "block",
                    lineHeight: "23px",
                    fontWeight: 500,
                  } : {
                    width: "100%",
                    display: "block",
                    lineHeight: "23px",
                    fontWeight: 500,
                  }}
                >
                  Утвержденные типы средств измерений :
                </span>
                <h4
                  style={ width > 1050 ? {
                    letterSpacing: "0.6px",
                    lineHeight: "23px",
                    fontWeight: 200,
                  } : {
                    letterSpacing: "0.6px",
                    lineHeight: "23px",
                    fontWeight: 200,
                    width: "100%",
                    overflow: "hidden"
                  }}
                >
                  <a
                    href={link}
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      borderBottom: "1px solid #000",
                      fontWeight: "bold",
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                  >
                    {link}
                  </a>
                </h4>
              </div>
            </div>
            
            { width <= 1050 && (
              <span
                style={{
                  display: "block",
                  position: "relative",
                  opacity: 0.55,
                  width: "100%",
                  textAlign: "center",
                  margin: "30px 0px 15px"
                }}
                onClick={() => setScrolling(prev => !prev)}
              >
                Вернуться наверх
              </span>
            )}

            { width > 1050 && <div className="box">
              <h2 style={{ marginBottom: "18px" }}>Описание типа прибора</h2>
              { pdf ? (
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row",
                    position: "relative",
                    boxSizing: "border-box",
                    border: "1px solid #EAEDF3",
                    width: "320px",
                    minHeight: "30px",
                    padding: "13px 19.6px",
                    borderRadius: "8px",
                    gap: "19px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    dispatch(settingsFileViewer({ value: "open", label: true }))
                  }
                >
                  <img
                    alt={""}
                    src={iconPDF}
                    style={{
                      width: "32px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "column",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: 500,
                        marginBottom: "4px",
                        letterSpacing: "0.6px",
                      }}
                    >
                      {pdf.name}
                    </span>
                    <span style={{ fontSize: "14px", color: "#899196" }}>
                      Размер файла - {(Number(pdf.size) / 1024).toFixed(0)} KB
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexFlow: "row",
                    position: "relative",
                    boxSizing: "border-box",
                    border: "1px solid #EAEDF3",
                    width: "320px",
                    minHeight: "30px",
                    padding: "13px 19.6px",
                    borderRadius: "8px",
                    gap: "19px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    alt={""}
                    src={iconPDF}
                    style={{
                      width: "32px",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "column",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: 500,
                        marginBottom: "4px",
                        letterSpacing: "0.6px",
                      }}
                    >
                      Файл отсутствует
                    </span>
                    <span style={{ fontSize: "14px", color: "#899196" }}>
                      Размер файла - 0 KB
                    </span>
                  </div>
                </div>
              )}
            </div> }
          </React.Fragment>
        )}
      </React.Fragment>
    </main>
  )
}

export default MeterShow
