import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import Selector from "../LocalElements/Selector/reactSelect/Selector"
import R from "../../services/request.service"
import { changeEtalon } from "../../store/slices/views/etalon"

const SelectMeasurement = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  const isClearable = true
  const isSearchable = false

  const idMeasurement = useSelector(
    (state) => state.etalonReducer.createData.idMeasurement
  )

  const options = data
    ? data.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const getValue = (selectedOption) => {
    const newMeasurementId = selectedOption ? selectedOption.value : null
    dispatch(changeEtalon({ value: "idMeasurement", label: newMeasurementId }))
  }

  useEffect(() => {
    (async () => {
      const { data: listMeasurement } = await R.getMeasurementItem()
      const measurement = listMeasurement.measurements
      setData(measurement)
    })()
  }, [])

  return (
    <React.Fragment>
      <p
        style={{
          marginBottom: "9px",
          display: "flex",
          alignItems: "center",
        }}
      >
        Область измерения{" "}
        {!idMeasurement ? (
          <ErrorIcon
            sx={{
              color: "#FFD43B",
              marginLeft: "8px",
              marginTop: "-2px",
            }}
          />
        ) : (
          <CheckCircleIcon
            sx={{ color: "#63E6BE", marginLeft: "8px", marginTop: "-2px" }}
          />
        )}
      </p>
      <Selector
        placeholder={"Выберите область измерения"}
        options={options}
        value={options.find((option) => option.value === idMeasurement)}
        onChange={getValue}
        isClearable={isClearable}
        isSearchable={isSearchable}
        noOptionsMessage={() => "Нет доступных областей измерения"}
      />
    </React.Fragment>
  )
}

export default SelectMeasurement
