import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Selector from "../LocalElements/Selector/reactSelect/Selector";

import R from "../../services/request.service";

const FilterMeasurements = ({ setMeasurementId }) => {
  const [data, setData] = useState([]);

  const measurementIdFilter = useSelector(
    (state) => state.protocolReducer.measurementIdFilter
  );

  const options = data
    ? data.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : [];

  const getValue = (selectedOption) => {
    setMeasurementId(selectedOption ? selectedOption.value : "");
  };

  useEffect(() => {
    (async () => {
      const { data: listMeasurement } = await R.getMeasurementItem();
      const measurement = listMeasurement.measurements;
      setData(measurement);
    })();
  }, []);

  return (
    <React.Fragment>
      <Selector
        placeholder={"Выберите область измерения"}
        options={options}
        onChange={getValue}
        isClearable={true}
        isSearchable={true}
        value={
          measurementIdFilter
            ? options.find((option) => option.value === measurementIdFilter)
            : null
        }
        noOptionsMessage={() => "Нет доступных областей измерения"}
      />
    </React.Fragment>
  );
};

export default FilterMeasurements;
