// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import Button from "@mui/material/Button"
import Tooltip from "@mui/material/Tooltip"

import FilterCompany from "../../components/LocalElements/FilterCompany/FilterCompany"
import SearchQuery from "../../components/LocalElements/Search/SearchQuery"
import D from "../../store/localStorage/dispatcher"
import R from "../../services/request.service"
import Table from "../../components/LocalElements/Table/Table"
import Skeleton from "../../components/LocalElements/Skeleton/Skeleton"
import { setSearchQuery as setSearchQueryCases } from "../../store/slices/views/cases"
import { refreshCases } from "../../store/slices/controlers/updater"

import useWindowDimensions from "../../hooks/useWindowDimensions"

import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
} from "../../store/slices/comps/modal"
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../store/slices/comps/snackbar"
import { setName } from "../../store/slices/controlers/pageName"

const Cases = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)
  const { width } = useWindowDimensions()

  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector((state) => state.headerReducer.role)

  const isLoading = useSelector((state) => state.casesReducer.isLoading)
  const data = useSelector((state) => state.casesReducer.casesList)

  const casesSearchQuery = useSelector(
    (state) => state.casesReducer.searchQuery
  )

  const changeSearchQuery = (param) => {
    dispatch(setSearchQueryCases(param))
  }

  const [, setCompanyId] = useState(null)

  const handleCasesClick = () => {
    navigate("/metriva/cases/create")
  }

  const handleRowClick = (param) => {
    navigate(`/metriva/cases/${param.row.id}`)
  }

  const handleEditCase = (caseId) => {
    navigate(`/metriva/cases/${caseId}/edit`)
  }

  const handleDelete = async (caseId) => {
    const { status } = await R.deleteCase(caseId)
    if (status === 200) {
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Эталон удален"))
      dispatch(setOpen(false))
      dispatch(refreshCases())
    } else {
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Ошибка обращения к API."))
    }
  }

  let colData = [
    { field: "innerNumber", headerName: "Внутренний номер СП", width: 300 },
    { field: "master", headerName: "Поверитель", width: 300 },
    { field: "measurement", headerName: "Область измерения", width: 240 },
    { field: "methods", headerName: "Методика поверки", width: 240 },
    { field: "typeMethods", headerName: "Тип поверки", width: 240 },
  ]

  if ( userRole === "super" ) {
    colData.splice(0, 0, {
      field: "company",
      headerName: "Компания",
      width: 300,
    })
  }

  if ( userRole === "super" || userRole === "admin" ) {
    colData.splice(6, 0, {
      field: "actions",
      type: "actions",
      headerName: "Действия",
      width: 200,
      getActions: (item) => {
        const localItem = item

        return [
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-around",
              gap: "26px",
            }}
          >
            <Tooltip title="Редактировать Комплект СП" placement="top-end">
              <span
                onClick={() => handleEditCase(localItem.id)}
                style={{
                  width: "5%",
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-around",
                  marginRight: "5px",
                }}
              >
                <span
                  className="fas fa-pencil-alt icon"
                  style={{
                    fontSize: "15px",
                  }}
                />
              </span>
            </Tooltip>

            <Tooltip title="Удалить Комплект СП" placement="top-end">
              <span
                onClick={() => {
                  dispatch(setOpen(true))
                  dispatch(
                    setTitle("Вы действительно хотите удалить комплект СП?")
                  )
                  dispatch(
                    setChildren("Отменить действие будет уже невозможно.")
                  )
                  dispatch(setConfirmAction(() => handleDelete(localItem.id)))
                }}
                style={{
                  width: "5%",
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-around",
                }}
              >
                <span
                  className="fas fa-trash icon"
                  style={{
                    fontSize: "15px",
                  }}
                />
              </span>
            </Tooltip>
          </div>,
        ]
      },
    })
  }

  useEffect(() => { dispatch(setName("Средства проверки")) }, []) 

  return (
    <main className={isToggle ? "main_open" : "main"}>
      <div className="header_box" style={width > 1050 ? {} : { gap: "12px" }}>
        <div
          className="flexContainerWithGap"
          style={width > 1050 ? {} : { margin: 0 }}
        >
          {userRole === "super" && (
            <div className="selector">
              <FilterCompany setCompanyId={setCompanyId} />
            </div>
          )}
          {(userRole === "admin" || userRole === "super") && width > 1050 && (
            <div className="btn">
              <Button
                variant="contained"
                onClick={handleCasesClick}
                sx={{ mt: 1, mr: 1 }}
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "0px 20px",
                  height: "55px",
                  margin: 0,
                  borderRadius: "12px",
                  width: "100%",
                }}
              >
                Добавить комплект
              </Button>
            </div>
          )}
        </div>

        <div style={width > 1050 ? { width: "300px" } : { width: "100%" }}>
          <SearchQuery
            searchQuery={casesSearchQuery}
            setSearchQuery={changeSearchQuery}
          />
        </div>
      </div>

      { isLoading || !!!data ? (
        <Skeleton />
      ) : (
        <Table
          css={{
            marginTop: "12px",
            backgroundColor: "#ffffff",
            border: "1px solid #d7ddea",
            "& .MuiDataGrid-cell:hover": {
              cursor: "pointer",
            },
          }}
          colData={colData}
          rowData={data.map((item) => ({
            id: item.id ? item.id : "",
            innerNumber: item.inner_name ? item.inner_name : "",
            master: item.arshin_name ? item.arshin_name : "",
            measurement: item.measurement ? item.measurement.name : "",
            company: item.company?.name ? item.company?.name : "",
            methods: item.method?.name ? item.method?.name : "",
            typeMethods: item.verificationType
              ? item.verificationType?.name
              : "",
          }))}
          rowCallback={handleRowClick}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      )}
    </main>
  )
}

export default Cases
