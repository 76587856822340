import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  show: false,
}

export const feedbackReducer = createSlice({
  name: 'feedbackReducer',
  initialState,
  reducers: {
    setShow: (state, action) => {
      state.show = action.payload
    }
  }
})

export const { setShow } = feedbackReducer.actions
export default feedbackReducer.reducer