import React from "react"
import api from "./axios-config.service"
import { saveAs } from "file-saver"
import moment from "moment"
import Cookies from "js-cookie"

import SaveAltIcon from '@mui/icons-material/SaveAlt'

const PdfDownloadButton = ({ protocolId, viewType = 'default' }) => {
  const handlePdfDownload = async () => {
    try {
      const token = Cookies.get("accessToken")
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/protocols/${protocolId}/pdf`
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/pdf",
      }

      const response = await api.get(apiUrl, {
        headers,
        responseType: "blob",
      })

      const protocolCreatedAt = moment().format("YYYY-MM-DD")
      const fileName = `Protocol_${protocolId}_${protocolCreatedAt}.pdf`

      saveAs(response.data, fileName)
    } catch (error) { console.error(error) }
  }

  return (
    <React.Fragment>
      { viewType === 'default' ? <i
          className="far fa-file-pdf icon"
          style={{ fontSize: "16px" }}
          onClick={handlePdfDownload}
        /> : <SaveAltIcon onClick={handlePdfDownload} /> 
      }
    </React.Fragment>
  )
}

export default PdfDownloadButton
