import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setOpen } from "../../../store/slices/comps/modal";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalComponent = () => {
  const dispatch = useDispatch();

  const open = useSelector((state) => state.modalReducer.open);
  const title = useSelector((state) => state.modalReducer.title);
  const children = useSelector((state) => state.modalReducer.children);
  const accept = useSelector((state) => state.modalReducer.buttonAccept);
  const dismiss = useSelector((state) => state.modalReducer.buttonDismiss);
  const showAccept = useSelector(
    (state) => state.modalReducer.showButtonAccept
  );
  const showDismiss = useSelector(
    (state) => state.modalReducer.showButtonDismiss
  );

  const confirmAction = useSelector(
    (state) => state.modalReducer.confirmAction
  );

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => dispatch(setOpen(false))}
        aria-describedby="alert-dialog-slide-description"
      >
        {title && (
          <DialogTitle
            style={{
              textTransform: "none",
              fontSize: "18px",
              fontFamily: '"Wix Madefor Display", sans-serif',
            }}
          >
            {title}
          </DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {showDismiss && (
            <Button
              onClick={() => dispatch(setOpen(false))}
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: '"Wix Madefor Display", sans-serif',
              }}
            >
              {dismiss ?? "Отменить"}
            </Button>
          )}
          {showAccept && (
            <Button
              onClick={() => {
                if (confirmAction) {
                  confirmAction();
                }
              }}
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: '"Wix Madefor Display", sans-serif',
              }}
            >
              {accept ?? "Принять"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ModalComponent;
