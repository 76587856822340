import React, { useState } from "react"
import api from "../../../services/axios-config.service"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import D from "../../../store/localStorage/dispatcher"
import { changeMeterGas } from "../../../store/slices/views/meters"
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/comps/snackbar"
import Input from "../../../components/LocalElements/Input/Input"

const AddMetersGas = () => {
  const token = useSelector(state => state.authReducer.authData.token)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isButton, setIsButton] = useState(true)
  const [validationErrors, setValidationErrors] = useState({})
  const idMeasurement = parseInt(D.get("measurement_id"), 10)

  const numberRegistry = useSelector(
    (state) => state.meterReducer.createMeterGas.numberRegistry
  )

  const handleNumberRegistryChange = (param) => {
    dispatch(changeMeterGas({ value: "numberRegistry", label: param }))
  }

  const number = useSelector(
    (state) => state.meterReducer.createMeterGas.number
  )

  const handleNumberChange = (param) => {
    dispatch(changeMeterGas({ value: "number", label: param }))
  }

  const nameSi = useSelector(
    (state) => state.meterReducer.createMeterGas.nameSi
  )

  const handleNameSiChange = (param) => {
    dispatch(changeMeterGas({ value: "nameSi", label: param }))
  }

  const manufacturerFactory = useSelector(
    (state) => state.meterReducer.createMeterGas.manufacturerFactory
  )

  const handleManufactureFactoryChange = (param) => {
    dispatch(changeMeterGas({ value: "manufacturerFactory", label: param }))
  }

  const mpiText = useSelector(
    (state) => state.meterReducer.createMeterGas.mpiText
  )

  const handleMpiTextChange = (param) => {
    dispatch(changeMeterGas({ value: "mpiText", label: param }))
  }

  const mpiValue = useSelector(
    (state) => state.meterReducer.createMeterGas.mpiValue
  )

  const handleMpiValueChange = (param) => {
    dispatch(changeMeterGas({ value: "mpiValue", label: param }))
  }

  const handleSaveMeters = async () => {
    setValidationErrors({})
    if (!isButton) {
      return
    }

    setIsButton(false)
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/meters/${idMeasurement}`
      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      }

      const data = {
        manufacturer: {
          name: manufacturerFactory,
        },
        number: number,
        number_registry: numberRegistry,
        name_si: nameSi,
        mpi_text: mpiText,
        mpi_value: mpiValue,
      }

      const response = await api.post(apiUrl, data, { headers })
      if ( response.data && response.data.message ) {
        dispatch(setMessage(response.data.message))
      }
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      navigate("/metriva/meters")
    } catch (error) {
      setIsButton(true)
      if (error.response && error.response.data && error.response.data.errors) {
        const serverErrors = error.response.data.errors
        setValidationErrors(serverErrors)
        dispatch(setType("error"))
        dispatch(setOpenAlert(true))
        dispatch(
          setMessage("Ошибка валидации. Убедитесь, что вы заполнили все поля!")
        )
        console.error(error)
      }
    }
  }

  const navigateToProtocols = () => {
    navigate("/metriva/meters")
  }

  return (
    <main className="main">
      <div className="cases__form_add">
        <div className="path_to_back" onClick={navigateToProtocols}>
          <span className="fas fa-arrow-left icon_back"></span>
          <p>База приборов</p>
        </div>

        <div className="path_to_edit">
          <h4>Новый прибор</h4>
          <span
            className="fas fa-times icon_back"
            onClick={navigateToProtocols}
          ></span>
        </div>

        <div className="box">
          <h2>Данные прибора</h2>

          <div
            className="mechanical_characteristics_box"
            style={{ marginBottom: "30px" }}
          >
            <div className="mechanical_characteristics">
              <p>№ Госреестра (Поиск)</p>
              <Input
                className={"input"}
                type={"text"}
                placeholder={"XXXXX-XX"}
                value={numberRegistry || ""}
                actions={{
                  change: handleNumberRegistryChange,
                }}
              />
              { validationErrors["number_registry"] && (
                <div className="error">
                  {validationErrors["number_registry"]}
                </div>
              )}
            </div>

            <div className="mechanical_characteristics">
              <p>№ Госреестра (Аршин)</p>
              <Input
                className={"input"}
                type={"text"}
                placeholder={"XXXXX-XX"}
                value={number || ""}
                actions={{
                  change: handleNumberChange,
                }}
              />
              { validationErrors["number"] && (
                <div className="error">{validationErrors["number"]}</div>
              )}
            </div>

            <div className="mechanical_characteristics">
              <p>Завод производитель</p>
              <Input
                className={"input"}
                type={"text"}
                placeholder={"ПО 'Точмаш'"}
                value={manufacturerFactory || ""}
                actions={{
                  change: handleManufactureFactoryChange,
                }}
              />
              { validationErrors["manufacturer.name"] && (
                <div className="error">
                  { validationErrors["manufacturer.name"] }
                </div>
              )}
            </div>
          </div>

          <h2>Интервал поверки</h2>

          <div className="mechanical_characteristics_box_min_max">
            <div className="mechanical_characteristics">
              <p>Наименование</p>
              <Input
                className={"input"}
                type={"text"}
                placeholder={"Счетчики ..."}
                value={nameSi || ""}
                actions={{
                  change: handleNameSiChange,
                }}
              />
              { validationErrors["name_si"] && (
                <div className="error">{validationErrors["name_si"]}</div>
              )}
            </div>

            <div className="mechanical_characteristics_max">
              <p>МПИ</p>
              <Input
                className={"input"}
                type={"text"}
                placeholder={"1 год"}
                value={mpiText || ""}
                actions={{
                  change: handleMpiTextChange,
                }}
              />
              { validationErrors["mpi_text"] && (
                <div className="error">{validationErrors["mpi_text"]}</div>
              )}
            </div>

            <div className="mechanical_characteristics_max">
              <p>Межповерочный интервал</p>
              <Input
                className={"input"}
                type={"text"}
                placeholder={"1"}
                value={mpiValue || ""}
                actions={{
                  change: handleMpiValueChange,
                }}
              />
              { validationErrors["mpi_value"] && (
                <div className="error">{validationErrors["mpi_value"]}</div>
              )}
            </div>
          </div>
        </div>

        <button
          className={`save_button ${!isButton ? "disabled" : ""}`}
          onClick={handleSaveMeters}
          disabled={!isButton}
        >
          Сохранить
        </button>
      </div>
    </main>
  )
}

export default AddMetersGas
