import React from "react"
import { useDispatch, useSelector } from "react-redux"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import Selector from "../../../../LocalElements/Selector/reactSelect/Selector"
import Input from "../../../../LocalElements/Input/InputMUI"
import { changeProperty } from "../state"

const DescriptionType = () => {
  const dispatch = useDispatch()
  const typeSize = useSelector((state) => state.gost83242002Reducer.typeSize)

  const meterTypeData = [
    "G1",
    "G1.6",
    "G2.5",
    "G4",
    "G6",
    "G10",
    "G16",
    "G25",
    "G40",
  ]
  const options = meterTypeData
    ? meterTypeData.map((item) => ({
        value: item,
        label: item,
      }))
    : []

  const getValue = (selectedOption) => {
    dispatch(
      changeProperty({
        value: "typeSize",
        label: selectedOption ? selectedOption.value : null,
      })
    )
  }

  const q_min = useSelector((state) => state.gost83242002Reducer.q_min)
  const q_t = useSelector((state) => state.gost83242002Reducer.q_t)
  const q_max = useSelector((state) => state.gost83242002Reducer.q_max)
  const q_min_limit = useSelector(
    (state) => state.gost83242002Reducer.q_min_limit
  )
  const q_max_limit = useSelector(
    (state) => state.gost83242002Reducer.q_max_limit
  )

  const q_min_pressure_loss = useSelector(
    (state) => state.gost83242002Reducer.q_min_pressure_loss
  )
  const q_max_pressure_loss = useSelector(
    (state) => state.gost83242002Reducer.q_max_pressure_loss
  )

  const handleQMinChange = (param) => {
    dispatch(
      changeProperty({ value: "q_min", label: param.replace(",", ".") })
    )
  }

  const handleQtChange = (param) => {
    dispatch(changeProperty({ value: "q_t", label: param.replace(",", ".") }))
  }

  const handleQMaxChange = (param) => {
    dispatch(
      changeProperty({ value: "q_max", label: param.replace(",", ".") })
    )
  }

  const handleQMinLimitChange = (param) => {
    dispatch(
      changeProperty({ value: "q_min_limit", label: param.replace(",", ".") })
    )
  }

  const handleQMaxLimitChange = (param) => {
    dispatch(
      changeProperty({ value: "q_max_limit", label: param.replace(",", ".") })
    )
  }

  const handleQMinPressureChange = (param) => {
    dispatch(
      changeProperty({
        value: "q_min_pressure_loss",
        label: param.replace(",", "."),
      })
    )
  }

  const handleQMaxPressureChange = (param) => {
    dispatch(
      changeProperty({
        value: "q_max_pressure_loss",
        label: param.replace(",", "."),
      })
    )
  }

  return (
    <React.Fragment>
      <h2 style={{ marginBottom: "15px" }}>
        Метрологические и технические характеристики (по описанию типа)
      </h2>

      <div className="width32Percent" style={{ marginBottom: "25px" }}>
        <p
          style={{
            marginBottom: "9px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Типоразмер счетчика{" "}
          {!typeSize && (
            <ErrorIcon
              sx={{
                color: "#FFD43B",
                marginLeft: "8px",
                marginTop: "-2px",
              }}
            />
          )}
          {typeSize && (
            <CheckCircleIcon
              sx={{ color: "#63E6BE", marginLeft: "8px", marginTop: "-2px" }}
            />
          )}
        </p>
        <Selector
          placeholder={"Выберите типоразмер счетчика"}
          options={options}
          onChange={getValue}
          value={options.find((option) => option.value === typeSize)}
          isClearable={true}
          isSearchable={true}
        />
      </div>

      <div className="flexContainerWithGap">
        <div className="width32Percent" style={{ marginBottom: "25px" }}>
          <Input
            label={"Qmin (описание типа), м³/ч"}
            placeholder={"0.000"}
            value={q_min}
            actions={{
              change: handleQMinChange,
            }}
          />
        </div>

        <div className="width32Percent" style={{ marginBottom: "25px" }}>
          <Input
            label={"Qt (описание типа), м³/ч"}
            placeholder={"0.000"}
            value={q_t}
            actions={{
              change: handleQtChange,
            }}
          />
        </div>

        <div className="width32Percent" style={{ marginBottom: "25px" }}>
          <Input
            label={"Qmax (описание типа), м³/ч"}
            placeholder={"0.000"}
            value={q_max}
            actions={{
              change: handleQMaxChange,
            }}
          />
        </div>
      </div>

      <div className="flexContainerWithGap">
        <div className="width50Percent" style={{ marginBottom: "25px" }}>
          <Input
            label={"Предел допускаемой погрешности от Qmin до Qt"}
            placeholder={"0"}
            value={q_min_limit}
            actions={{
              change: handleQMinLimitChange,
            }}
          />
        </div>

        <div className="width50Percent" style={{ marginBottom: "25px" }}>
          <Input
            label={"Предел допускаемой погрешности от Qt до Qmax"}
            placeholder={"0"}
            value={q_max_limit}
            actions={{
              change: handleQMaxLimitChange,
            }}
          />
        </div>
      </div>

      <div className="flexContainerWithGap">
        <div className="width50Percent">
          <Input
            label={"Потеря давления при расходе Qmin, Па."}
            placeholder={"0.0"}
            value={q_min_pressure_loss}
            actions={{
              change: handleQMinPressureChange,
            }}
          />
        </div>

        <div className="width50Percent">
          <Input
            label={"Потеря давления при расходе Qmax, Па."}
            placeholder={"0.0"}
            value={q_max_pressure_loss}
            actions={{
              change: handleQMaxPressureChange,
            }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default DescriptionType
