class DispatchLocalStorage {
  set(key, value) {
    localStorage.setItem(key, value)
  }
  get(key) { 
    return localStorage.getItem(key) 
  }
  rm(key) {
    localStorage.removeItem(key);
  }
  cls() {
    localStorage.clear()
  }
}

const D = new DispatchLocalStorage()
export default D