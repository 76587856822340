// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import Input from "../../../../LocalElements/Input/InputMUI"

import { changeProperty } from "../state"
import useWindowDimensions from "../../../../../hooks/useWindowDimensions"

import css from "../style.css"

const {
  Container,
  IndicationBox,
  IndicationBoxLastElement,
  CountIndicationBox,
  QActualBox,
  InputQActualElement,
  ConversionFactor,
} = css

const OpticalSensor = () => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const opticalSensorValuesEdit = useSelector(
    (state) => state.mi15922015Reducer.opticalSensorValuesEdit
  )

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const q_min = useSelector((state) => state.mi15922015Reducer.q_min)
  const q_t = useSelector((state) => state.mi15922015Reducer.q_t)
  const q_actual = useSelector((state) => state.mi15922015Reducer.q_actual)
  const q_min_limit = useSelector(
    (state) => state.mi15922015Reducer.q_min_limit
  )
  const q_max_limit = useSelector(
    (state) => state.mi15922015Reducer.q_max_limit
  )
  const conversion_factor = useSelector(
    (state) => state.mi15922015Reducer.conversion_factor
  )

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleQ_ActualChange = (param) => {
    const value = param.replace(",", ".")
    dispatch(changeProperty({ value: "q_actual", label: value }))
  }

  const handleConversionFactorChange = (param) => {
    const value = param.replace(",", ".")
    dispatch(
      changeProperty({
        value: "conversion_factor",
        label: value,
      })
    )
  }

  const q_i_1 = useSelector((state) => state.mi15922015Reducer.q_i_1)
  const q_i_2 = useSelector((state) => state.mi15922015Reducer.q_i_2)
  const q_i_3 = useSelector((state) => state.mi15922015Reducer.q_i_3)

  const v_y_i_1 = useSelector((state) => state.mi15922015Reducer.v_y_i_1)
  const v_y_i_2 = useSelector((state) => state.mi15922015Reducer.v_y_i_2)
  const v_y_i_3 = useSelector((state) => state.mi15922015Reducer.v_y_i_3)

  const impulses_1 = useSelector((state) => state.mi15922015Reducer.impulses_1)
  const impulses_2 = useSelector((state) => state.mi15922015Reducer.impulses_2)
  const impulses_3 = useSelector((state) => state.mi15922015Reducer.impulses_3)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  const handleQ_i_1Change = (param) => {
    const value = param.replace(",", ".")
    dispatch(changeProperty({ value: "q_i_1", label: value }))
  }
  const handleQ_i_2Change = (param) => {
    const value = param.replace(",", ".")
    dispatch(changeProperty({ value: "q_i_2", label: value }))
  }
  const handleQ_i_3Change = (param) => {
    const value = param.replace(",", ".")
    dispatch(changeProperty({ value: "q_i_3", label: value }))
  }

  const handleImpulses_1Change = (param) => {
    const value = param.replace(",", ".")
    dispatch(changeProperty({ value: "impulses_1", label: value }))
  }
  const handleImpulses_2Change = (param) => {
    const value = param.replace(",", ".")
    dispatch(changeProperty({ value: "impulses_2", label: value }))
  }
  const handleImpulses_3Change = (param) => {
    const value = param.replace(",", ".")
    dispatch(changeProperty({ value: "impulses_3", label: value }))
  }

  const handleV_y_i_1Change = (param) => {
    const value = param.replace(",", ".")
    dispatch(changeProperty({ value: "v_y_i_1", label: value }))
  }
  const handleV_y_i_2Change = (param) => {
    const value = param.replace(",", ".")
    dispatch(changeProperty({ value: "v_y_i_2", label: value }))
  }
  const handleV_y_i_3Change = (param) => {
    const value = param.replace(",", ".")
    dispatch(changeProperty({ value: "v_y_i_3", label: value }))
  }

  const v_u_i_1 = parseFloat(conversion_factor) * parseFloat(impulses_1)
  const v_u_i_2 = parseFloat(conversion_factor) * parseFloat(impulses_2)
  const v_u_i_3 = parseFloat(conversion_factor) * parseFloat(impulses_3)

  const permissible_error_1 = (
    ((parseFloat(v_u_i_1) - v_y_i_1) / v_y_i_1) *
    100
  ).toFixed(2)
  const permissible_error_2 = (
    ((parseFloat(v_u_i_2) - v_y_i_2) / v_y_i_2) *
    100
  ).toFixed(2)
  const permissible_error_3 = (
    ((parseFloat(v_u_i_3) - v_y_i_3) / v_y_i_3) *
    100
  ).toFixed(2)

  const indicationPass_1 =
    permissible_error_1 >= -parseFloat(q_min_limit) &&
    permissible_error_1 <= parseFloat(q_min_limit)

  const indicationPass_2 =
    permissible_error_2 >= -parseFloat(q_max_limit) &&
    permissible_error_2 <= parseFloat(q_max_limit)

  const indicationPass_3 =
    permissible_error_3 >= -parseFloat(q_max_limit) &&
    permissible_error_3 <= parseFloat(q_max_limit)

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------

  useEffect(() => {
    if ( opticalSensorValuesEdit.length > 0 ) {
      const initialValues_1 = opticalSensorValuesEdit[0]
      dispatch(changeProperty({ value: "impulses_1", label: initialValues_1.impulses }))
      dispatch(changeProperty({ value: "q_i_1", label: initialValues_1.q_i }))
      dispatch(changeProperty({ value: "v_y_i_1", label: initialValues_1.v_y_i }))
      dispatch(
        changeProperty({
          value: "conversion_factor",
          label: initialValues_1.conversion_factor,
        })
      )

      const initialValues_2 = opticalSensorValuesEdit[1]
      dispatch(changeProperty({ value: "impulses_2", label: initialValues_2.impulses }))
      dispatch(changeProperty({ value: "q_i_2", label: initialValues_2.q_i }))
      dispatch(changeProperty({ value: "v_y_i_2", label: initialValues_2.v_y_i }))

      const initialValues_3 = opticalSensorValuesEdit[2]
      dispatch(changeProperty({ value: "impulses_3", label: initialValues_3.impulses }))
      dispatch(changeProperty({ value: "q_i_3", label: initialValues_3.q_i }))
      dispatch(changeProperty({ value: "v_y_i_3", label: initialValues_3.v_y_i }))
    }
  }, [opticalSensorValuesEdit, dispatch])

  useEffect(() => {
    dispatch(
      changeProperty({
        value: "indicationsPass",
        label: [indicationPass_1, indicationPass_2, indicationPass_3],
      })
    )
  }, [indicationPass_1, indicationPass_2, indicationPass_3, dispatch])

  useEffect(() => {
    dispatch(
      changeProperty({
        value: "indications",
        label: [
          {
            id: opticalSensorValuesEdit[0]
              ? opticalSensorValuesEdit[0].id
              : null,
            conversion_factor: conversion_factor,
            q_i: q_i_1,
            impulses: impulses_1,
            v_y_i: v_y_i_1,
            v_u_i: v_u_i_1,
            permissible_error: permissible_error_1,
          },
          {
            id: opticalSensorValuesEdit[1]
              ? opticalSensorValuesEdit[1].id
              : null,
            conversion_factor: conversion_factor,
            q_i: q_i_2,
            impulses: impulses_2,
            v_y_i: v_y_i_2,
            v_u_i: v_u_i_2,
            permissible_error: permissible_error_2,
          },
          {
            id: opticalSensorValuesEdit[2]
              ? opticalSensorValuesEdit[2].id
              : null,
            conversion_factor: conversion_factor,
            q_i: q_i_3,
            impulses: impulses_3,
            v_y_i: v_y_i_3,
            v_u_i: v_u_i_3,
            permissible_error: permissible_error_3,
          },
        ],
      })
    )
  }, [
    conversion_factor,
    q_i_1,
    impulses_1,
    v_y_i_1,
    v_u_i_1,
    permissible_error_1,
    q_i_2,
    impulses_2,
    v_y_i_2,
    v_u_i_2,
    permissible_error_2,
    q_i_3,
    impulses_3,
    v_y_i_3,
    v_u_i_3,
    permissible_error_3,
    dispatch,
  ])

  return (
    <React.Fragment>
      <div className="flexContainerWithGap" style={ width > 1050 ? {} : { margin: 0 }}>
        <h2 className="width50Percent" style={{ marginBottom: "18px" }}>
          Определение относительной погрешности
        </h2>
      </div>

      <ConversionFactor margin={"0 0 22px"}>
        <Input
          isDisabled={false}
          type={"text"}
          placeholder={"0.00000814"}
          label={"Коэффициент преобразования"}
          value={conversion_factor}
          actions={{
            change: handleConversionFactorChange,
          }}
        />
      </ConversionFactor>

      <h4 style={{ marginBottom: "26px" }}>
        { q_min
          ? `Введенное значение Qmin : ${q_min}, м³/ч`
          : "Введите Qmin (описание типа)"}
      </h4>

      <React.Fragment>
        <Container margin={ width > 1050 ? "0px 0px 22px" : "0px 0px 7px" }>
          <CountIndicationBox>
            <span style={{ fontSize: "14px", fontWeight: "500" }}>1</span>
          </CountIndicationBox>

          <IndicationBox>
            <Input
              isDisabled={false}
              type={"text"}
              placeholder={"0.6830"}
              label={"Расход во время измерения, м³/ч"}
              value={q_i_1}
              actions={{
                change: handleQ_i_1Change,
              }}
            />
          </IndicationBox>

          <IndicationBox>
            <Input
              isDisabled={false}
              type={"text"}
              placeholder={"2000"}
              label={"Количество импульсов, имп."}
              value={impulses_1}
              actions={{
                change: handleImpulses_1Change,
              }}
            />
          </IndicationBox>

          <IndicationBox>
            <Input
              isDisabled={true}
              type={"text"}
              placeholder={"0.024665"}
              label={"Объём воды, м³"}
              value={v_u_i_1 ? v_u_i_1.toFixed(4) : "Автозаполнение"}
            />
          </IndicationBox>

          <IndicationBoxLastElement>
            <Input
              isDisabled={false}
              type={"text"}
              placeholder={"0.055233"}
              label={"Объем, по показаниям эт., м³"}
              value={v_y_i_1}
              actions={{
                change: handleV_y_i_1Change,
              }}
            />
          </IndicationBoxLastElement>
        </Container>

        { q_i_1 && impulses_1 && v_y_i_1 && (
          <div
            className={`indications_box-gas_bottom ${
              indicationPass_1
                ? "indications_box-gas_bottom_true"
                : "indications_box-gas_bottom_false"
            }`}
            style={{ marginBottom: "22px", marginTop: "16px" }}
          >
            <p
              className={`indications_box-gas_bottom ${
                indicationPass_1 ? "text_true" : "text_false"
              }`}
              style={{ margin: "0 2px" }}
            >
              { width > 1050 ? 'Относительная погрешность счетчика:' : 'Относительная погрешность:' }
              <span style={{ margin: "0 4px" }}>{permissible_error_1} %</span>
            </p>
            <p
              className={`indications_box-gas_bottom ${
                indicationPass_1 ? "text_true" : "text_false"
              }`}
              style={{ margin: "0 2px" }}
            >
              { width > 1050 ? 'Пределы допускаемой погрешности счётчика:' : 'Пределы погрешности:' }
              <span style={{ margin: "0 4px" }}>±{q_min_limit} %</span>
            </p>
          </div>
        )}
      </React.Fragment>

      <React.Fragment>
        <h4 style={{ marginBottom: "26px" }}>
          { q_t
            ? `Введенное значение 1.1 * Qt : ${(1.1 * q_t).toFixed(3)}, м³/ч`
            : "Введите Qt (описание типа)"}
        </h4>

        <React.Fragment>
          <Container margin={ width > 1050 ? "0px 0px 22px" : "0px 0px 8px" }>
            <CountIndicationBox>
              <span style={{ fontSize: "14px", fontWeight: "500" }}>2</span>
            </CountIndicationBox>

            <IndicationBox>
              <Input
                isDisabled={false}
                type={"text"}
                placeholder={"0.6830"}
                label={"Расход во время измерения, м³/ч"}
                value={q_i_2}
                actions={{
                  change: handleQ_i_2Change,
                }}
              />
            </IndicationBox>

            <IndicationBox>
              <Input
                isDisabled={false}
                type={"text"}
                placeholder={"2000"}
                label={"Количество импульсов, имп."}
                value={impulses_2}
                actions={{
                  change: handleImpulses_2Change,
                }}
              />
            </IndicationBox>

            <IndicationBox>
              <Input
                isDisabled={true}
                type={"text"}
                placeholder={"0.024665"}
                label={"Объём воды, м³"}
                value={v_u_i_2 ? v_u_i_2.toFixed(4) : "Автозаполнение"}
              />
            </IndicationBox>

            <IndicationBoxLastElement>
              <Input
                isDisabled={false}
                type={"text"}
                placeholder={"0.055233"}
                label={"Объем, по показаниям эт., м³"}
                value={v_y_i_2}
                actions={{
                  change: handleV_y_i_2Change,
                }}
              />
            </IndicationBoxLastElement>
          </Container>

          { q_i_2 && impulses_2 && v_y_i_2 && (
            <div className="testimony__box-items-bottom">
              <div
                className={`indications_box-gas_bottom ${
                  indicationPass_2
                    ? "indications_box-gas_bottom_true"
                    : "indications_box-gas_bottom_false"
                }`}
                style={{ marginBottom: "22px", marginTop: "16px" }}
              >
                <p
                  className={`indications_box-gas_bottom ${
                    indicationPass_2 ? "text_true" : "text_false"
                  }`}
                  style={{ margin: "0 2px" }}
                >
                  { width > 1050 ? 'Относительная погрешность счетчика:' : 'Относительная погрешность:' }
                  <span style={{ margin: "0 4px" }}>
                    {permissible_error_2} %
                  </span>
                </p>
                <p
                  className={`indications_box-gas_bottom ${
                    indicationPass_2 ? "text_true" : "text_false"
                  }`}
                  style={{ margin: "0 2px" }}
                >
                  { width > 1050 ? 'Пределы допускаемой погрешности счётчика:' : 'Пределы погрешности:' }
                  <span style={{ margin: "0 4px" }}>±{q_max_limit} %</span>
                </p>
              </div>
            </div>
          )}
        </React.Fragment>
      </React.Fragment>

      <React.Fragment>
        <QActualBox margin={"0 0 23px"}>
          <h4>Введите значение Qmax (фактический) :</h4>
          <InputQActualElement>
            <Input
              type={"text"}
              placeholder={"Введите фактическое значение Qmax"}
              value={q_actual}
              isDisabled={false}
              actions={{
                change: handleQ_ActualChange,
              }}
              className={"backgroundColorWhite"}
            />
          </InputQActualElement>
        </QActualBox>

        <React.Fragment>
          <Container margin={"0px 0px 0px"}>
            <CountIndicationBox>
              <span style={{ fontSize: "14px", fontWeight: "500" }}>3</span>
            </CountIndicationBox>
            <IndicationBox>
              <Input
                isDisabled={false}
                type={"text"}
                placeholder={"0.6830"}
                label={"Расход во время измерения, м³/ч"}
                value={q_i_3}
                actions={{
                  change: handleQ_i_3Change,
                }}
              />
            </IndicationBox>

            <IndicationBox>
              <Input
                isDisabled={false}
                type={"text"}
                placeholder={"3000"}
                label={"Количество импульсов, имп."}
                value={impulses_3}
                actions={{
                  change: handleImpulses_3Change,
                }}
              />
            </IndicationBox>

            <IndicationBox>
              <Input
                isDisabled={true}
                type={"text"}
                placeholder={"0.024665"}
                label={"Объём воды, м³"}
                value={v_u_i_3 ? v_u_i_3.toFixed(4) : "Автозаполнение"}
              />
            </IndicationBox>

            <IndicationBoxLastElement>
              <Input
                isDisabled={false}
                type={"text"}
                placeholder={"0.055233"}
                label={"Объем, по показаниям эт., м³"}
                value={v_y_i_3}
                actions={{
                  change: handleV_y_i_3Change,
                }}
              />
            </IndicationBoxLastElement>
          </Container>

          { q_i_3 && impulses_3 && v_y_i_3 && (
            <div
              className={`indications_box-gas_bottom ${
                indicationPass_3
                  ? "indications_box-gas_bottom_true"
                  : "indications_box-gas_bottom_false"
              }`}
              style={ width > 1050 ? { marginTop: "22px" } : { marginTop: "16px" }}
            >
              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_3 ? "text_true" : "text_false"
                }`}
                style={{ margin: "0 2px" }}
              >
                { width > 1050 ? 'Относительная погрешность счетчика:' : 'Относительная погрешность:' }
                <span style={{ margin: "0 4px" }}>{permissible_error_3} %</span>
              </p>

              <p
                className={`indications_box-gas_bottom ${
                  indicationPass_3 ? "text_true" : "text_false"
                }`}
                style={{ margin: "0 2px" }}
              >
                { width > 1050 ? 'Пределы допускаемой погрешности счётчика:' : 'Пределы погрешности:' }
                <span style={{ margin: "0 4px" }}>±{q_max_limit} %</span>
              </p>
            </div>
          )}
        </React.Fragment>
      </React.Fragment>
    </React.Fragment>
  )
}

export default OpticalSensor
