// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import PropTypes from "prop-types";

import MeterData from "../../views/MeterData/MeterWater/MeterData";
import Customer from "../../views/Customer/Customer";
import Accepted from "../../views/Accepted/Accepted";
import Conditions from "./ProtocolComponents/Conditions";
import Indications from "./ProtocolComponents/Indications";
import TotalAccepted from "../../views/TotalAccepted/TotalAccepted";
import ProtocolDate from "../../views/ProtocolDate/ProtocolDate";
import ImageUpload from "../../views/ImagesUploading/ImagesUploading";

import { changeProperty } from "./state";

import Stepper from "../../../LocalElements/MobileStepper/Stepper";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

import css from "../../styles.css";

const { PageContainer, BoxContainer } = css;

const AddProtocol = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const [isDisabledBtn] = useState(false);

  const customerName = useSelector(
    (state) => state.gost815683Reducer.customerName
  );
  const customerNameType = useSelector(
    (state) => state.gost815683Reducer.customerNameType
  );
  const customerAddress = useSelector(
    (state) => state.gost815683Reducer.customerAddress
  );
  const customerFullName = useSelector(
    (state) => state.gost815683Reducer.customerFullName
  );
  const customerPhoneNumber = useSelector(
    (state) => state.gost815683Reducer.customerPhoneNumber
  );

  const meterFactoryNumber = useSelector(
    (state) => state.gost815683Reducer.meterFactoryNumber
  );
  const meterYear = useSelector((state) => state.gost815683Reducer.meterYear);

  const isMechanicalAccepted = useSelector(
    (state) => state.gost815683Reducer.isMechanicalAccepted
  );
  const isTightnessAccepted = useSelector(
    (state) => state.gost815683Reducer.isTightnessAccepted
  );
  const isMarkPassport = useSelector(
    (state) => state.gost815683Reducer.isMarkPassport
  );
  const isMarkSi = useSelector((state) => state.gost815683Reducer.isMarkSi);

  const verificationDate = useSelector(
    (state) => state.gost815683Reducer.verificationDate
  );
  const nextVerificationDate = useSelector(
    (state) => state.gost815683Reducer.nextVerificationDate
  );

  const agreementNumber = useSelector(
    (state) => state.gost815683Reducer.agreementNumber
  );
  const isTotalAccepted = useSelector(
    (state) => state.gost815683Reducer.isTotalAccepted
  );
  const indicationPass = useSelector(
    (state) => state.gost815683Reducer.indicationsPass
  );

  const handleMeterFactoryNumberChange = (param) => {
    dispatch(
      changeProperty({
        value: "meterFactoryNumber",
        label: param,
      })
    );
  };

  const getValueYear = (selectedOption) => {
    dispatch(
      changeProperty({
        value: "meterYear",
        label: selectedOption ? selectedOption.value : null,
      })
    );
  };

  const capitalizeFirstLetter = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
  };
  const changeName = (param) => {
    const newValue = capitalizeFirstLetter(param);
    dispatch(changeProperty({ value: "customerName", label: newValue }));
  };
  const changeNameType = (event) => {
    dispatch(
      changeProperty({ value: "customerNameType", label: event.target.value })
    );
    dispatch(
      changeProperty({ value: "customerName", label: event.target.value })
    );
  };
  const changeAddress = (selectedOption) => {
    dispatch(
      changeProperty({
        value: "customerAddress",
        label: selectedOption ? selectedOption.value : null,
      })
    );
  };
  const changeFullName = (param) => {
    const newValue = capitalizeFirstLetter(param);
    dispatch(changeProperty({ value: "customerFullName", label: newValue }));
  };

  const formatPhoneNumber = (input) => {
    const phoneNumber = input.replace(/\D/g, "");
    let formattedPhoneNumber = "";

    if (phoneNumber.length >= 1) {
      formattedPhoneNumber = `+7`;
    }
    if (phoneNumber.length > 1) {
      formattedPhoneNumber += ` (${phoneNumber.slice(1, 4)}`;
    }
    if (phoneNumber.length > 4) {
      formattedPhoneNumber += `) ${phoneNumber.slice(4, 7)}`;
    }
    if (phoneNumber.length > 7) {
      formattedPhoneNumber += `-${phoneNumber.slice(7, 9)}`;
    }
    if (phoneNumber.length > 9) {
      formattedPhoneNumber += `-${phoneNumber.slice(9, 11)}`;
    }

    dispatch(
      changeProperty({
        value: "customerPhoneNumber",
        label: formattedPhoneNumber,
      })
    );
  };
  const changePhone = (param) => {
    formatPhoneNumber(param);
  };

  const changeMechanical = (event) => {
    const newValue = event.target.value === "true";
    dispatch(
      changeProperty({
        value: "isMechanicalAccepted",
        label: newValue,
      })
    );
  };

  const changeTightness = (event) => {
    const newValue = event.target.value === "true";
    dispatch(
      changeProperty({
        value: "isTightnessAccepted",
        label: newValue,
      })
    );
  };

  const handleMarkPassport = (event) => {
    dispatch(
      changeProperty({
        value: "isMarkPassport",
        label: event.target.checked,
      })
    );
  };

  const handleMarkSi = (event) => {
    dispatch(
      changeProperty({
        value: "isMarkSi",
        label: event.target.checked,
      })
    );
  };

  const handleDateChange = (date) => {
    if (date) {
      dispatch(
        changeProperty({
          value: "verificationDate",
          label: date.format("YYYY-MM-DD HH:mm:ss"),
        })
      );
    }
  };

  const handleAgreementChange = (param) => {
    dispatch(changeProperty({ value: "agreementNumber", label: param }));
  };

  useEffect(() => {
    const totalAccepted =
      isMechanicalAccepted &&
      isTightnessAccepted &&
      !indicationPass.includes(false);

    dispatch(
      changeProperty({ value: "isTotalAccepted", label: totalAccepted })
    );
  }, [isMechanicalAccepted, isTightnessAccepted, indicationPass, dispatch]);

  const handleSaveProtocol = async () => {};

  return (
    <PageContainer>
      {width <= 1050 && (
        <Stepper
          steps={[
            {
              description: (
                <BoxContainer margin={"14px 0px 14px"}>
                  <Customer
                    valueName={customerName}
                    changeName={changeName}
                    valueNameType={customerNameType}
                    changeNameType={changeNameType}
                    valueAddress={customerAddress}
                    changeAddress={changeAddress}
                    valueFullName={customerFullName}
                    changeFullName={changeFullName}
                    valuePhone={customerPhoneNumber}
                    changePhone={changePhone}
                  />
                </BoxContainer>
              ),
            },
            {
              description: (
                <BoxContainer margin={"14px 0px 14px"}>
                  <MeterData
                    meterFactoryNumber={meterFactoryNumber}
                    handleMeterFactoryNumberChange={
                      handleMeterFactoryNumberChange
                    }
                    meterYear={meterYear}
                    getValueYear={getValueYear}
                  />
                </BoxContainer>
              ),
            },
            {
              description: (
                <BoxContainer margin={"14px 0px 14px"}>
                  <React.Fragment></React.Fragment>
                </BoxContainer>
              ),
            },

            {
              description: (
                <React.Fragment>
                  <BoxContainer margin={"14px 0px 14px"}>
                    <Accepted
                      title={"Внешний осмотр"}
                      description={
                        "Раздел 2 «Методика поверки «Pr»: есть возможность визуально считывать показания счётчика, не нарушена целостность счётного механизм счетчика, отсутствуют механические повреждения на корпусе счётчика, комплектность счётчика соответствует описанию типа, знак утверждения типа нанесён на счётчик в установленном виде"
                      }
                      value={isMechanicalAccepted}
                      onChange={changeMechanical}
                      label={
                        "Соответствие пункту 2.7.1 Методики поверки МИ 1592-2015"
                      }
                    />
                  </BoxContainer>
                </React.Fragment>
              ),
            },
            {
              description: (
                <React.Fragment>
                  <BoxContainer margin={"14px 0px 14px"}>
                    <Accepted
                      title={"Опробование"}
                      description={
                        "Раздел 2 «Методика поверки «Pr»: после выдержки в течение 5 минут в местах соединений и на корпусах счётчиков и эталона не наблюдается каплевыделений и течи воды"
                      }
                      value={isTightnessAccepted}
                      onChange={changeTightness}
                      label={
                        "Соответствие пункту 2.7.2 Методики поверки МИ 1592-2015"
                      }
                    />
                  </BoxContainer>
                </React.Fragment>
              ),
            },
            {
              description: (
                <BoxContainer margin={"14px 0px 14px"}>
                  <Conditions />
                </BoxContainer>
              ),
            },
            {
              description: (
                <BoxContainer margin={"14px 0px 14px"}>
                  <React.Fragment></React.Fragment>
                </BoxContainer>
              ),
            },
            {
              description: (
                <BoxContainer
                  margin={"14px 0px 14px"}
                  style={{
                    padding: 0,
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                >
                  <TotalAccepted value={isTotalAccepted} />
                </BoxContainer>
              ),
            },
            {
              description: (
                <BoxContainer margin={"14px 0px 14px"}>
                  <ProtocolDate
                    isTotalAccepted={isTotalAccepted}
                    valuePassport={isMarkPassport}
                    onChangePassport={handleMarkPassport}
                    valueSi={isMarkSi}
                    onChangeSi={handleMarkSi}
                    currentDate={verificationDate}
                    changeCurrentDate={handleDateChange}
                    nextDate={nextVerificationDate}
                    valueAgreement={agreementNumber}
                    changeAgreement={handleAgreementChange}
                  />
                </BoxContainer>
              ),
            },
            {
              description: (
                <BoxContainer margin={"14px 0px 14px"}>
                  <ImageUpload />
                </BoxContainer>
              ),
            },
          ]}
        />
      )}
      {width > 1050 && (
        <React.Fragment>
          <BoxContainer margin={"14px 0px 14px"}>
            <Customer
              valueName={customerName}
              changeName={changeName}
              valueNameType={customerNameType}
              changeNameType={changeNameType}
              valueAddress={customerAddress}
              changeAddress={changeAddress}
              valueFullName={customerFullName}
              changeFullName={changeFullName}
              valuePhone={customerPhoneNumber}
              changePhone={changePhone}
            />
          </BoxContainer>

          <BoxContainer>
            <MeterData
              dispatchers={{
                selectMeterDispatcher: () => {},
                selectModDispatcher: () => {},

                meterIdDispatcher: () => {},
                meterTypeDispatcher: () => {},
                meterYearDispatcher: () => {},
                meterNumberDispatcher: () => {},
                meterFifNumberDispatcher: () => {},

                meterDiameterDispatcher: () => {},
                meterLocationDispatcher: () => {},
                meterColdDispatcher: () => {},
                meterHotDispatcher: () => {},

                conversionFactorDispatcher: () => {},

                qminDispatcher: () => {},
                qtDispatcher: () => {},
                qmaxDispatcher: () => {},

                qminLimitDispatcher: () => {},
                qmaxLimitDispatcher: () => {},
              }}
            />
          </BoxContainer>

          <BoxContainer></BoxContainer>

          <BoxContainer>
            <Accepted
              title={"Внешний осмотр"}
              description={
                "Для счётчиков находящихся в эксплуатации: соответствие комплектности требованиям нормативно-технической документации на счетчик конкретного типа состояние лакокрасочного покрытия четкость изображения надписей на маркировочной табличке, а также цифр и отметок на циферблате и роликах отсчетного устройства отсутствие пятен, трещин и сколов, влияющих на считывание показаний на циферблате отсутствие загрязнений на стекле, закрывающем циферблат и отсчетное устройство наличие стрелки, указывающей направление потока воды."
              }
              label={"Соответствие пункту 3.1. Методики поверки ГОСТ 8.156-83"}
              value={isMechanicalAccepted}
              onChange={changeMechanical}
            />
          </BoxContainer>

          <BoxContainer>
            <Accepted
              title={"Опробование"}
              description={
                "После выдержки в течение 15 мин в местах соединений и корпусе не наблюдается отпотеваний, каплепадений или течи воды. Падение давления по манометру не допускается."
              }
              label={"Соответствие пункту 3.2. Методики поверки ГОСТ 8.156-83"}
              value={isTightnessAccepted}
              onChange={changeTightness}
            />
          </BoxContainer>

          <BoxContainer>
            <Conditions />
          </BoxContainer>

          <BoxContainer>
            <Indications />
          </BoxContainer>

          <BoxContainer
            style={{
              backgroundColor: "transparent",
              padding: "0px",
              border: "none",
            }}
          >
            <TotalAccepted value={isTotalAccepted} />
          </BoxContainer>

          <BoxContainer>
            <ProtocolDate
              isTotalAccepted={isTotalAccepted}
              valuePassport={isMarkPassport}
              onChangePassport={handleMarkPassport}
              valueSi={isMarkSi}
              onChangeSi={handleMarkSi}
              currentDate={verificationDate}
              changeCurrentDate={handleDateChange}
              nextDate={nextVerificationDate}
              valueAgreement={agreementNumber}
              changeAgreement={handleAgreementChange}
            />
          </BoxContainer>
          <BoxContainer margin={"0px 0px 14px"}>
            <ImageUpload />
          </BoxContainer>
        </React.Fragment>
      )}

      <Button
        variant="contained"
        onClick={handleSaveProtocol}
        disabled={isDisabledBtn}
        sx={{ mt: 1, mr: 1 }}
        style={{
          textTransform: "none",
          fontSize: "15px",
          fontFamily: '"Wix Madefor Display", sans-serif',
          padding: "11px 20px 11px",
          margin: 0,
          borderRadius: "12px",
          width: "100%",
        }}
      >
        Сохранить протокол
      </Button>
    </PageContainer>
  );
};

// Methodology.propTypes = {
//   exampleParameter: PropTypes.string | PropTypes.number,
// }

export default AddProtocol;
