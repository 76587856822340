import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import D from "../../../store/localStorage/dispatcher";
import R from "../../../services/request.service";
import Table from "../../LocalElements/Table/Table";
import Skeleton from "../../LocalElements/Skeleton/Skeleton";
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
} from "../../../store/slices/comps/modal";

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../../store/slices/comps/snackbar";

import { refreshTickets } from "../../../store/slices/controlers/updater";

const Tab = () => {
  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole");
  const userRole = useSelector((state) => state.headerReducer.role);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector(
    (state) => state.journalReducer.isLoadingTickets
  );
  const data = useSelector((state) => state.journalReducer.dataTickets);

  const handleEditClick = (param) => {
    navigate(`/metriva/journals/${param.row.id}/edit_tickets`);
  };

  const handleDelete = async (journalId) => {
    const { status } = await R.deleteTickets(journalId);
    if (status === 200) {
      dispatch(refreshTickets());
      dispatch(setType("success"));
      dispatch(setOpenAlert(true));
      dispatch(setMessage("Запись удалена"));
      dispatch(setOpen(false));
    } else {
      dispatch(setType("error"));
      dispatch(setOpenAlert(true));
      dispatch(setMessage("Ошибка обращения к API."));
    }
  };

  let colData = [
    {
      field: "protocolId",
      headerName: "№ протокола",
      width: 160,
    },
    {
      field: "meterNumber",
      headerName: "№ госреестра",
      width: 160,
    },
    {
      field: "nameSi",
      headerName: "Наименование",
      width: 160,
    },
    {
      field: "meterFactoryNumber",
      headerName: "Заводской номер",
      width: 180,
    },
    {
      field: "customerName",
      headerName: "Владелец СИ",
      width: 220,
    },
    {
      field: "arshinName",
      headerName: "Поверитель",
      width: 220,
    },
    {
      field: "verificationDate",
      headerName: "Дата поверки",
      width: 170,
    },
    {
      field: "arshinId",
      headerName: "№ свид-ва / извещения",
      width: 220,
    },
    {
      field: "desc",
      headerName: "Примечание",
      width: 210,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Удалить",
      width: 130,
      getActions: (item) => {
        const localItem = item;

        return [
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-around",
              gap: "14px",
            }}
          >
            <span
              onClick={() => {
                dispatch(setOpen(true));
                dispatch(setTitle("Вы действительно хотите удалить запись?"));
                dispatch(
                  setChildren("Отменить действие будет уже невозможно.")
                );
                dispatch(setConfirmAction(() => handleDelete(localItem.id)));
              }}
              style={{
                width: "5%",
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-around",
              }}
            >
              <span
                className="fas fa-trash icon"
                style={{
                  fontSize: "15px",
                }}
              />
            </span>
          </div>,
        ];
      },
    },
  ];

  if (userRole === "super") {
    colData.splice(9, 0, {
      field: "company",
      headerName: "Компания",
      width: 210,
    });
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <Skeleton />
      ) : (
        <Table
          css={{
            marginTop: "12px",
            backgroundColor: "#ffffff",
            border: "1px solid #d7ddea",
            "& .MuiDataGrid-cell:hover": {
              cursor: "pointer",
            },
          }}
          colData={colData}
          rowData={data.map((item) => ({
            id: item.id,
            meterNumber: item.meter_number,
            customerName: item.customer_arshin_name,
            nameSi: item.name_si,
            verificationDate: moment(item.verification_date).format(
              "YYYY-MM-DD"
            ),
            meterFactoryNumber: item.meter_factory_number,
            arshinName: item.verifier_arshin_name,
            arshinId: item.arshin_id,
            protocolId: item.protocol_id,
            desc: item.desc ?? "Нет данных",
            company: item.company?.name,
          }))}
          rowCallback={handleEditClick}
          hasCheckboxSelection={false}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Tab;
