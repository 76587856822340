import React, { useState, useEffect } from "react"
import api from "../../services/axios-config.service"
import D from "../../store/localStorage/dispatcher"
import Selector from "../LocalElements/Selector/reactSelect/Selector"
import { useDispatch, useSelector } from "react-redux"

import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

import { changeData } from "../../store/slices/views/users"

const SelectedCompany = () => {
  const dispatch = useDispatch()

  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector(state => state.headerReducer.role)
  const token = useSelector(state => state.authReducer.authData.token)
  
  const [data, setData] = useState(null)
  const isClearable = true
  const isSearchable = false

  const options = data
    ? data.map((data) => ({
        value: data.id,
        label: data.name,
      }))
    : []

  const companyId = useSelector(
    (state) => state.usersReducer.createData.companyId
  )

  const getValue = (selectedOption) => {
    const newCompanyId = selectedOption ? selectedOption.value : null
    dispatch(
      changeData({
        value: "companyId",
        label: newCompanyId,
      })
    )
  }

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        if ( userRole === "super" ) {
          const apiUrl = `${process.env.REACT_APP_API_URL}/api/companies/list`
          const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          }
          const response = await api.get(apiUrl, { headers })
          const companyData = response.data.data
          setData(companyData)
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchUsersData()
  }, [token, userRole])

  return (
    <div className="width50Percent">
      <span
        style={{
          marginBottom: "9px",
          display: "flex",
          alignItems: "center",
        }}
      >
        Выберите компанию
        { !companyId ? (
          <ErrorIcon
            sx={{
              color: "#FFD43B",
              marginLeft: "8px",
              marginTop: "-2px",
            }}
          />
        ) : (
          <CheckCircleIcon
            sx={{ color: "#63E6BE", marginLeft: "8px", marginTop: "-2px" }}
          />
        )}
      </span>
      <Selector
        placeholder={"Выберите компанию"}
        options={options}
        onChange={getValue}
        value={options.find((option) => option.value === companyId)}
        isClearable={isClearable}
        isSearchable={isSearchable}
        noOptionsMessage={() => "Нет доступных компаний"}
      />
    </div>
  )
}

export default SelectedCompany
