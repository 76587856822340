// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React from "react"
import { 
  GridToolbarContainer,
  GridToolbarColumnsButton } from "@mui/x-data-grid"

const TableMenu = (props) => {
  const { children } = props

  return (
    <React.Fragment>
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton/> */}
      </GridToolbarContainer>
    </React.Fragment>
  )
}

export default TableMenu