import React from "react"
import AccordionComponent from "../../../LocalElements/Accordion/Accordion"

const Cases = ({ data }) => {
  return (
    <React.Fragment>
      <AccordionComponent
        style={{ backgroundColor: "white" }}
        title={`Комплект СП - ${data?.archivedCase?.inner_name}`}
        titleSize={"17px"}
        backgroundColor={"white"}
        border={"1px solid #d7ddea"}
        data={[
          [
            {
              fontWeight: 600,
              textAlign: "left",
              width: "300px",
              title: "Методика поверки : " + data?.method?.name,
            },
          ],
          [
            {
              fontWeight: 600,
              textAlign: "left",
              width: "30%",
              title: "Название и тип устройства",
            },
            {
              fontWeight: 600,
              textAlign: "left",
              width: "30%",
              title: "Заводской номер / Регистрационный номер",
            },
            {
              fontWeight: 600,
              textAlign: "left",
              width: "40%",
              title: "Описание и ссылка",
            },
          ],
        ].concat(
          data.archivedCase?.archivedKits?.map((kit) => {
            return [
              {
                fontWeight: 400,
                textAlign: "left",
                width: "30%",
                title: (
                  <React.Fragment>
                    <span style={{ display: 'block', width: '100%', lineHeight: '22px', fontWeight: 500 }}>{kit.device.name}</span>
                    <span style={{ display: 'block', width: '100%', lineHeight: '22px' }}>{kit.device_sign}</span>
                  </React.Fragment>
                ),
              },
              {
                fontWeight: 400,
                textAlign: "left",
                width: "30%",
                title: (
                  <React.Fragment>
                    <span style={{ display: 'block', width: '100%', letterSpacing: '0.6px', lineHeight: '22px', fontWeight: 500 }}>{kit.device_manufacturer_number}</span>
                    <span style={{ display: 'block', width: '100%', letterSpacing: '0.6px', lineHeight: '22px' }}>{kit.reg_number}</span>
                  </React.Fragment>
                ),
              },
              {
                fontWeight: 400,
                textAlign: "left",
                width: "40%",
                title: (
                  <React.Fragment>
                    <span style={{ display: 'block', width: '100%', lineHeight: '22px', fontWeight: 500 }}>{kit.device_description}</span>
                    <span style={{ display: 'block', width: '100%', lineHeight: '22px' }}>{kit.device_link}</span>
                  </React.Fragment>
                ),
              },
            ]
          })
        )}
      />
    </React.Fragment>
  )
}

export default Cases
