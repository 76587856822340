import React from "react"

const TotalAccepted = (props) => {
  const { value } = props

  return (
    <React.Fragment>
      { value ? (
        <div
          className="total-accepted__true"
          style={{ border: "1px solid #1976d2" }}
        >
          <h2 className="text_true " style={{ marginBottom: "10px" }}>
            Заключение поверки
          </h2>
          <p className="text_true">
            На основании результатов поверки, средство измерения признано
            пригодным к использованию.
          </p>
        </div>
      ) : (
        <div
          className="total-accepted__false"
          style={{ border: "1px solid red" }}
        >
          <h2 className="text_false" style={{ marginBottom: "10px" }}>
            Заключение поверки
          </h2>
          <p className="text_false" style={{ marginBottom: "10px" }}>
            На основании результатов поверки, средство измерения признано
            непригодным к использованию.
          </p>
          <p
            className="text_false"
            style={{ opacity: "0.8", fontWeight: "400" }}
          >
            *Статус заключения зависит от результатов внешнего осмотра,
            опробования, определения относительной погрешности.
          </p>
        </div>
      )}
    </React.Fragment>
  )
}

export default TotalAccepted
