// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Button, Tooltip, Collapse } from "@mui/material";

import PdfIcon from "../../img/icon/PdfIcon";
import ArchiveIcon from "../../img/icon/ArchiveIcon";
import ExitIcon from "../../img/icon/ExitIcon";
import RestoreIcon from "../../img/icon/RestoreIcon";
import FilterIcon from "../../img/icon/FilterIcon";
import Csv from "../../services/protocol-csv-excel-download.service";
import RightSideContainer from "../../components/LocalElements/RightSideContainer/RightSideContainer";
import FilterMeasurement from "../../components/FilterProtocol/FilterMeasurement";
import FilterUsers from "../../components/FilterProtocol/FilterUsers";
import Approved from "../../components/FilterProtocol/Approved";
import DatePicker from "../../components/LocalElements/DatePicker/DatePicker";
import SelectorCompany from "../../components/LocalElements/FilterCompany/FilterCompany";
import BasicTabs from "../../components/LocalElements/TabsMenu/TabsMenu";
import SearchQuery from "../../components/LocalElements/Search/SearchQuery";
import Table from "../../components/LocalElements/Table/Table";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { setState } from "../../components/Methodologies/UniversalShow/state";
import R from "../../services/request.service";

import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  showBD,
  resetState,
} from "../../store/slices/comps/modal";
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../store/slices/comps/snackbar";
import {
  setMeasurementIdFilter,
  setUserId,
  setStatusId,
  setApprovedTransfer,
  setApprovedArshin,
  setApprovedFsa,
  setDateFrom,
  setDateTo,
  setSelectedProtocols,
  setViewVariantMain,
  setSearchQuery as setSearchQueryProtocol,
} from "../../store/slices/views/protocols";
import { setName } from "../../store/slices/controlers/pageName";

import { refreshProtocols } from "../../store/slices/controlers/updater";
import { setOpen as setOpenRightContainer } from "../../store/slices/comps/rightContainer";

const AllProtocols = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const userRole = useSelector((state) => state.headerReducer.role);
  const protocolsData = useSelector(
    (state) => state.protocolReducer.protocolsList
  );
  const isTrusted = useSelector((state) => state.headerReducer.isTrusted);
  const viewVariant = useSelector(
    (state) => state.protocolReducer.viewVariantMain
  );
  const selectedProtocols = useSelector(
    (state) => state.protocolReducer.selectedProtocols
  );
  const protocolSearchQuery = useSelector(
    (state) => state.protocolReducer.searchQuery
  );
  const measurementIdFilter = useSelector(
    (state) => state.protocolReducer.measurementIdFilter
  );
  const userId = useSelector((state) => state.protocolReducer.userId);
  const statusProtocol = useSelector((state) => state.protocolReducer.statusId);
  const approvedTransfer = useSelector(
    (state) => state.protocolReducer.approvedTransfer
  );
  const approvedArshin = useSelector(
    (state) => state.protocolReducer.approvedArshin
  );
  const approvedFsa = useSelector((state) => state.protocolReducer.approvedFsa);
  const dateFrom = useSelector((state) => state.protocolReducer.dateFrom);
  const dateTo = useSelector((state) => state.protocolReducer.dateTo);

  const resetActions = [
    setMeasurementIdFilter,
    setUserId,
    setStatusId,
    setApprovedTransfer,
    setApprovedArshin,
    setApprovedFsa,
    setDateFrom,
    setDateTo,
  ];

  const count = useMemo(() => {
    let filtersCount = 0;

    if (measurementIdFilter) filtersCount++;
    if (userId) filtersCount++;
    if (statusProtocol) filtersCount++;
    if (approvedTransfer) filtersCount++;
    if (approvedArshin) filtersCount++;
    if (approvedFsa) filtersCount++;
    if (dateFrom) filtersCount++;
    if (dateTo) filtersCount++;

    if (
      !measurementIdFilter &&
      !userId &&
      !statusProtocol &&
      !approvedTransfer &&
      !approvedArshin &&
      !approvedFsa &&
      !dateFrom &&
      !dateTo
    )
      filtersCount = 0;
  }, [
    measurementIdFilter,
    userId,
    statusProtocol,
    approvedTransfer,
    approvedArshin,
    approvedFsa,
    dateFrom,
    dateTo,
  ]);

  const countArchive = useMemo(() => {
    let filtersCount = 0;

    if (measurementIdFilter) filtersCount++;
    if (userId) filtersCount++;
    if (dateFrom) filtersCount++;
    if (dateTo) filtersCount++;

    if (!measurementIdFilter && !userId && !dateFrom && !dateTo)
      filtersCount = 0;

    return filtersCount;
  }, [measurementIdFilter, userId, dateFrom, dateTo]);

  const handleVerifiedAtChange = (date) => {
    if (date) {
      dispatch(setDateFrom(date.format("YYYY-MM-DD")));
    } else {
      dispatch(setDateFrom(null));
    }
  };

  const handleExpiredAtChange = (date) => {
    if (date) {
      dispatch(setDateTo(date.format("YYYY-MM-DD")));
    } else {
      dispatch(setDateTo(null));
    }
  };

  const changeMeasurementId = (param) => {
    dispatch(setMeasurementIdFilter(param));
  };

  const changeUserId = (param) => {
    dispatch(setUserId(param));
  };
  const changeApprovedStatus = (event) => {
    dispatch(setStatusId(event.target.value));
  };

  const changeApprovedTransfer = (event) => {
    dispatch(setApprovedTransfer(event.target.value));
  };

  const changeApprovedArshin = (event) => {
    dispatch(setApprovedArshin(event.target.value));
  };
  const changeApprovedFsa = (event) => {
    dispatch(setApprovedFsa(event.target.value));
  };

  useEffect(() => {
    if (approvedTransfer === "no") {
      dispatch(setApprovedArshin(""));
    }

    if (approvedTransfer === "no" || approvedArshin === "not-published") {
      dispatch(setApprovedFsa(""));
    }
  }, [approvedTransfer, approvedArshin, dispatch]);

  const handleResetFilter = () => {
    resetActions.forEach((action) => dispatch(action("")));
  };

  const changeViewVariant = (param) => {
    handleResetFilter();
    dispatch(setViewVariantMain(param));
  };

  const filteredProtocolsData = protocolsData.filter((item) => {
    if (viewVariant === 0) {
      return !item.archived_at;
    } else if (viewVariant === 1) {
      return item.archived_at;
    }
    return true;
  });

  const noRowsText =
    viewVariant === 0
      ? "Протоколы не созданы или перемещены в архив"
      : "Архивные протоколы не найдены";

  const changeSearchQuery = (param) => {
    dispatch(setSearchQueryProtocol(param));
  };

  const handleShowClick = (param) => {
    width > 1050 && navigate(`/metriva/protocols/${param.row.id}`);
  };
  const handleEditClick = (protocolId) => {
    navigate(`/metriva/protocols/${protocolId}/edit`);
  };
  const handleCopyClick = (protocolId) => {
    navigate(`/metriva/protocols/${protocolId}/copy`);
  };

  const handleDelete = async (protocolId) => {
    const { status } = await R.deleteProtocol(protocolId);
    if (status === 200) {
      dispatch(refreshProtocols());

      dispatch(setType("success"));
      dispatch(setOpenAlert(true));
      dispatch(setMessage("Протокол удален"));
      dispatch(setOpen(false));
    } else {
      dispatch(setType("error"));
      dispatch(setOpenAlert(true));
      dispatch(setMessage("Ошибка обращения к API."));
    }
  };

  const handleCheckProtocol = async () => {
    const data = {
      ids: selectedProtocols,
    };

    const { status, data: protocolCheckData } = await R.protocolCheck(data);

    if (status === 200) {
      dispatch(
        setMessage("Протоколы успешно одобрены для передачи в Аршин и ФСА")
      );
      dispatch(setType("success"));
      dispatch(setOpenAlert(true));
      dispatch(refreshProtocols());
      dispatch(resetState());
    } else {
      dispatch(setType("error"));
      dispatch(setOpenAlert(true));
      dispatch(setMessage("Статус 500. Ошибка обращения к api."));
    }
  };

  const handleClickOnArchiveBtn = () => {
    const filteredBySelected = filteredProtocolsData.filter((item) =>
      selectedProtocols.includes(item.id)
    );

    const allThreeConditionsMet = filteredBySelected.every(
      (item) =>
        item.status === "sent" &&
        item.status_arshin === "published" &&
        item.status_fsa === "published"
    );

    const someConditionsNotMet = filteredBySelected.some(
      (item) =>
        item.status !== "sent" ||
        item.status_arshin !== "published" ||
        item.status_fsa !== "published"
    );

    const validProtocols = filteredBySelected.filter(
      (item) =>
        item.status === "sent" &&
        item.status_arshin === "published" &&
        item.status_fsa === "published"
    );

    const updatedProtocols = validProtocols.map((item) => item.id);

    let title;
    let message;
    let confirmAction;

    if (allThreeConditionsMet) {
      title = "Архивация протокола";
      message =
        "Вы действительно хотите перенести выбранный(е) протокол(ы) в архив?\nАрхивная версия будет доступна только в режиме просмотра.";
      confirmAction = () => handleArchiveProtocol(updatedProtocols);
    } else if (someConditionsNotMet && validProtocols.length > 0) {
      title = "Архивация приостановлена";
      message =
        "Внимание! Архивация протоколов временно недоступна т.к. среди выбранных протоколов есть те, которые не были переданы во ФГИС «Аршин» и ФСА.\nВы можете продолжить, исключив данные протоколы из списка архивируемых, либо возобновить процесс позднее.";
      confirmAction = () => handleArchiveProtocol(updatedProtocols);
    } else {
      title = "Архивация приостановлена";
      message =
        "Внимание! Архивация протоколов временно недоступна т.к. среди выбранных протоколов есть те, которые не были переданы во ФГИС «Аршин» и ФСА.";
      confirmAction = () => dispatch(setOpen(false));
    }

    dispatch(setOpen(true));
    dispatch(setTitle(title));
    dispatch(setChildren(message));
    dispatch(setBA(validProtocols.length > 0 ? "Архивировать" : "Закрыть"));
    dispatch(setConfirmAction(confirmAction));
    if (validProtocols.length > 0) {
      dispatch(showBD(true));
    } else {
      dispatch(showBD(false));
    }
  };

  const handleArchiveProtocol = async (updatedProtocols) => {
    const data = {
      ids: updatedProtocols,
    };

    const { status, data: protocolArchiveData } = await R.protocolArchive(data);

    if (status === 200) {
      dispatch(setMessage("Протоколы успешно архивированы."));
      dispatch(setType("success"));
      dispatch(setOpenAlert(true));
      dispatch(refreshProtocols());
      dispatch(resetState());
    } else {
      dispatch(setType("error"));
      dispatch(setOpenAlert(true));
      dispatch(setMessage("Статус 500. Ошибка обращения к api."));
    }
  };

  const handleUnArchiveProtocol = async () => {
    const data = {
      ids: selectedProtocols,
    };

    const { status, data: protocolUnArchiveData } = await R.protocolUnArchive(
      data
    );

    if (status === 200) {
      dispatch(setMessage("Протоколы успешно восстановлены из архива."));
      dispatch(setType("success"));
      dispatch(setOpenAlert(true));
      dispatch(refreshProtocols());
      dispatch(resetState());
    } else {
      dispatch(setType("error"));
      dispatch(setOpenAlert(true));
      dispatch(setMessage("Статус 500. Ошибка обращения к api."));
    }
  };

  const handlePdf = async () => {
    const data = {
      ids: selectedProtocols,
    };

    try {
      const { status, data: fileData } = await R.protocolPdf(data);

      if (status === 200) {
        let blob;
        let fileName;

        if (selectedProtocols.length === 1) {
          blob = new Blob([fileData], { type: "application/pdf" });
          fileName = `protocol_${selectedProtocols[0]}.pdf`;
        } else {
          blob = new Blob([fileData], { type: "application/zip" });
          fileName = `protocols_${selectedProtocols.join("-")}.zip`;
        }

        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(url);

        dispatch(setMessage("Протоколы успешно скачаны."));
        dispatch(setType("success"));
        dispatch(setOpenAlert(true));
        dispatch(refreshProtocols());

        dispatch(resetState());
      } else {
        dispatch(setType("error"));
        dispatch(setOpenAlert(true));
        dispatch(setMessage("Ошибка при скачивании архива."));
      }
    } catch (error) {
      dispatch(setType("error"));
      dispatch(setOpenAlert(true));
      dispatch(setMessage("Ошибка при обращении к API."));
    }
  };

  useEffect(() => {
    dispatch(setState({}));
  }, []);
  useEffect(() => {
    dispatch(setName("Созданные протоколы"));
  }, []);

  useEffect(() => {
    false && false && console.log(protocolsData);
  }, [protocolsData]);

  return (
    <main className="main">
      {userRole === "super" && (
        <div
          style={{
            width: width > 1050 ? "605px" : "100%",
          }}
        >
          <SelectorCompany />
        </div>
      )}

      {(userRole === "super" || userRole === "admin") && (
        <div style={{ margin: "10px 0 12px" }}>
          <BasicTabs
            viewVariant={viewVariant}
            callback={changeViewVariant}
            tabs={[{ label: "Актуальные" }, { label: "Архивные" }]}
          />
        </div>
      )}

      {viewVariant === 0 ? (
        <React.Fragment>
          <RightSideContainer blockTitle={"Фильтры"}>
            <div className="right-side-container" style={{ gap: 0 }}>
              <div className="right-side-container-block" style={{ marginBottom: "10px" }}>
                <span className="label-filter">Область измерения</span>
                <div style={{ width: "100%" }}>
                  <FilterMeasurement
                    measurementId={measurementIdFilter}
                    setMeasurementId={changeMeasurementId}
                  />
                </div>
              </div>

              {userRole !== "metrologist" && (
                <div className="right-side-container-block" style={{ marginBottom: "10px" }}>
                  <span className="label-filter">Поверители</span>
                  <div style={{ width: "100%" }}>
                    <FilterUsers userId={userId} setUserId={changeUserId} />
                  </div>
                </div>
              )}

              <div className="right-side-container-block" style={{ marginBottom: "10px" }}>
                <span className="label-filter">Заключение поверки</span>
                <Approved
                  approved={statusProtocol}
                  changeApproved={changeApprovedStatus}
                  labelTrue={"Пригоден"}
                  labelFalse={"Непригоден"}
                  valueTrue={true}
                  valueFalse={false}
                />
              </div>

              <div className="right-side-container-block" style={{ marginBottom: "10px" }}>
                <span className="label-filter">Одобрен к передаче</span>
                <Approved
                  approved={approvedTransfer}
                  changeApproved={changeApprovedTransfer}
                  labelTrue={"Да"}
                  labelFalse={"Нет"}
                  valueTrue={"yes"}
                  valueFalse={"no"}
                />
              </div>

              <Collapse in={approvedTransfer === "yes"}>
                <div className="right-side-container-block" style={{ marginBottom: "10px" }}>
                  <span className="label-filter">Передан в Аршин</span>
                  <Approved
                    approved={approvedArshin}
                    changeApproved={changeApprovedArshin}
                    labelTrue={"Да"}
                    labelFalse={"Нет"}
                    valueTrue={"published"}
                    valueFalse={"not-published"}
                  />
                </div>
              </Collapse>
              <Collapse
                in={
                  approvedTransfer === "yes" && approvedArshin === "published"
                }
              >
                <div className="right-side-container-block" style={{ marginBottom: "10px" }}>
                  <span className="label-filter">Передан в ФСА</span>
                  <Approved
                    approved={approvedFsa}
                    changeApproved={changeApprovedFsa}
                    labelTrue={"Да"}
                    labelFalse={"Нет"}
                    valueTrue={"published"}
                    valueFalse={"not-published"}
                  />
                </div>
              </Collapse>

              <div className="right-side-container-block" style={{ marginBottom: "10px" }}>
                <span className="label-filter">Дата поверки</span>
                <DatePicker
                  selectedDate={dateFrom}
                  dateChange={handleVerifiedAtChange}
                />

                <DatePicker
                  selectedDate={dateTo}
                  dateChange={handleExpiredAtChange}
                />
              </div>
              <Button
                variant="outlined"
                onClick={handleResetFilter}
                style={{
                  width: "100%",
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "11px 20px 11px",
                  borderRadius: "12px",
                }}
              >
                Сбросить все значения
              </Button>
            </div>
          </RightSideContainer>
          <div
            className="header_box"
            style={width > 1050 ? { position: "relative" } : {}}
          >
            <div style={{ width: width > 1050 ? "82%" : "100%" }}>
              <SearchQuery
                searchQuery={protocolSearchQuery}
                setSearchQuery={changeSearchQuery}
              />
            </div>

            {width > 1050 && (
              <React.Fragment>
                {userRole !== "metrologist" && <Csv />}
              </React.Fragment>
            )}

            <div
              style={{
                position: "relative",
                width: width > 1050 ? "220px" : "100%",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(setOpenRightContainer(true));
                }}
                startIcon={<FilterIcon />}
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "11px 20px 11px",
                  borderRadius: "12px",
                  width: "100%",
                  height: "54.4px",
                }}
              >
                Фильтры
              </Button>
              <span
                style={{
                  display: "block",
                  position: "absolute",
                  width: "22px",
                  height: "22px",
                  borderRadius: "50%",
                  backgroundColor: "#1976d2",
                  color: "white",
                  fontSize: "11px",
                  lineHeight: "21px",
                  textAlign: "center",
                  top: 0,
                  left: "100%",
                  marginLeft: "-18px",
                  marginTop: "-4px",
                  transition: "all 300ms",
                  opacity: count > 0 ? 1 : 0,
                }}
              >
                {count}
              </span>
            </div>
          </div>

          <Collapse in={selectedProtocols && selectedProtocols.length > 0}>
            <div
              className="flexContainerWithGap"
              style={
                width > 1050
                  ? { marginTop: "11px", gap: "0px" }
                  : { marginTop: "12px", gap: "0px", marginBottom: "0px" }
              }
            >
              <Button
                variant="text"
                startIcon={<PdfIcon />}
                onClick={handlePdf}
                style={{
                  width: width > 1050 ? "" : "100%",
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "11px 20px 11px",
                  borderRadius: "12px",
                  color: "#123532",
                }}
              >
                Скачать PDF
              </Button>
              {userRole !== "metrologist" && (
                <Button
                  variant="text"
                  startIcon={<ExitIcon />}
                  onClick={handleCheckProtocol}
                  style={{
                    width: width > 1050 ? "" : "100%",
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: "11px 20px 11px",
                    borderRadius: "12px",
                    color: "#123532",
                  }}
                >
                  Передать в Аршин и ФСА
                </Button>
              )}
              {(userRole === "super" || userRole === "admin") && (
                <Button
                  variant="text"
                  startIcon={<ArchiveIcon />}
                  onClick={handleClickOnArchiveBtn}
                  style={{
                    width: width > 1050 ? "" : "100%",
                    textTransform: "none",
                    fontSize: "15px",
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: "11px 20px 11px",
                    borderRadius: "12px",
                    color: "#123532",
                  }}
                >
                  Архивировать
                </Button>
              )}
            </div>
          </Collapse>

          <Table
            css={{
              marginTop: "12px",
              backgroundColor: "#ffffff",
              border: "1px solid #d7ddea",
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "1.2",
                wordWrap: "break-word",
              },
              "& .MuiDataGrid-cell:hover": {
                cursor: "default",
              },
              "& .MuiDataGrid-cell": {
                whiteSpace: "normal",
                wordWrap: "break-word",

                height: "auto",
                lineHeight: "1.5",
                padding: "8px",
                boxSizing: "border-box",
              },
            }}
            colData={[
              {
                field: "numId",
                headerName: width > 1050 ? "Номер" : "№",
                width: 100,
              },
              {
                field: "verificationDate",
                headerName: "Дата поверки",
                width: 200,
              },
              {
                field: "totalAccepted",
                headerName: "Заключение",
                width: 200,
              },
              userRole === "metrologist"
                ? {
                    field: "measurement",
                    headerName: "Область измерения",
                    width: 300,
                  }
                : {
                    field: "createdBy",
                    headerName: (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Поверитель
                        <Tooltip
                          title={
                            "Фактический поверитель - пользователь который создал протокол в системе"
                          }
                          placement="top"
                          style={{ marginLeft: "7px" }}
                        >
                          <i
                            className="fas fa-question-circle question"
                            style={{ marginLeft: "7px", cursor: "pointer" }}
                          />
                        </Tooltip>
                      </div>
                    ),
                    width: 300,
                  },
              {
                field: "statusArshin",
                headerName: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Передача в Аршин
                    <Tooltip
                      title={
                        isTrusted
                          ? "Для начала передачи: выберите необходимый(е) протокол(ы) в общем списке и нажмите кнопку “Передать в Аршин и ФСА” или предоставьте разрешение в процессе создания/редактирования протокола"
                          : "Дождитесь когда передача протокола(ов) во ФГИС «Аршин» будет одобрена руководителем или администратором"
                      }
                      placement="top"
                      style={{ marginLeft: "7px" }}
                    >
                      <i
                        className="fas fa-question-circle question"
                        style={{ marginLeft: "7px", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                ),
                width: 240,
                renderCell: (params) => {
                  const { status, statusArshin, statusFsa } = params.row;

                  if (status !== "sent" && statusArshin === "not-sent") {
                    return <p style={{ color: "#E74953" }}>Не начата</p>;
                  } else if (status === "sent" && statusArshin === "not-sent") {
                    return <p style={{ color: "#E57004" }}>В процессе</p>;
                  } else if (statusArshin === "published") {
                    return <p style={{ color: "#00A37C" }}>Завершена</p>;
                  }
                },
              },
              {
                field: "statusFsa",
                headerName: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Передача в ФСА
                    <Tooltip
                      title="Передача начнётся автоматически после получения данных о номере свидетельства/извещения из ФГИС «Аршин»"
                      placement="top"
                      style={{ marginLeft: "7px" }}
                    >
                      <i
                        className="fas fa-question-circle question"
                        style={{ marginLeft: "7px", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                ),
                width: 200,
                renderCell: (params) => {
                  const { statusArshin, statusFsa } = params.row;

                  if (statusArshin === "not-sent" && statusFsa === "not-sent") {
                    return <p style={{ color: "#E74953" }}>Не начата</p>;
                  } else if (
                    statusArshin === "published" &&
                    statusFsa === "not-sent"
                  ) {
                    return <p style={{ color: "#E57004" }}>В процессе</p>;
                  } else if (statusFsa === "published") {
                    return <p style={{ color: "#00A37C" }}>Завершена</p>;
                  }
                },
              },
              {
                field: "arshinId",
                headerName: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    № свидетельства или извещения
                    <Tooltip
                      title={"Приходит из ФГИС «Аршин»"}
                      placement="top"
                      style={{ marginLeft: "7px" }}
                    >
                      <i
                        className="fas fa-question-circle question"
                        style={{ marginLeft: "7px", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                ),
                width: 200,
              },
              {
                field: "actions",
                type: "actions",
                headerName: "Действия",
                width: 200,
                getActions: (item) => {
                  const localItem = item;

                  return [
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "row",
                        justifyContent: "space-between",
                        gap: "30px",
                      }}
                    >
                      {localItem.row.originalCase !==
                      "Эталоны комплекта были изменены" ? (
                        <Tooltip
                          title="Копировать протокол"
                          placement="top-end"
                        >
                          <span
                            onClick={() => handleCopyClick(localItem.id)}
                            className="action-container-block"
                          >
                            <span
                              className="fas fa-copy icon"
                              style={{
                                fontSize: "15px",
                                marginTop: "1px",
                              }}
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="Копирование протокола недоступно, используемый комплект СП был удалён или изменён"
                          placement="top-start"
                        >
                          <span className="action-container-block-disabled">
                            <span
                              className="fas fa-copy icon"
                              style={{
                                fontSize: "15px",
                                color: "#ccc",
                                marginTop: "1px",
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}
                      {localItem.row.status !== "sent" ? (
                        <Tooltip
                          title="Редактировать протокол"
                          placement="top-end"
                        >
                          <span
                            onClick={() => handleEditClick(localItem.id)}
                            className="action-container-block"
                          >
                            <span
                              className="fas fa-pencil-alt icon"
                              style={{
                                fontSize: "15px",
                                marginTop: "1px",
                              }}
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="Редактирование недоступно, протокол был передан во ФГИС «Аршин»"
                          placement="top-end"
                        >
                          <span className="action-container-block-disabled">
                            <span
                              className="fas fa-pencil-alt icon"
                              style={{
                                fontSize: "15px",
                                color: "#ccc",
                                marginTop: "1px",
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}
                      {userRole === "super" || userRole === "admin" ? (
                        localItem.row.status !== "sent" ? (
                          <Tooltip title="Удалить протокол" placement="top-end">
                            <span
                              onClick={() => {
                                dispatch(setOpen(true));
                                dispatch(
                                  setTitle(
                                    "Вы действительно хотите удалить протокол?"
                                  )
                                );
                                dispatch(
                                  setChildren(
                                    "Отменить действие будет уже невозможно."
                                  )
                                );
                                dispatch(setBA("Удалить"));
                                dispatch(
                                  setConfirmAction(() =>
                                    handleDelete(localItem.id)
                                  )
                                );
                              }}
                              style={{
                                width: "5%",
                                display: "flex",
                                flexFlow: "row",
                                justifyContent: "space-around",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                className="fas fa-trash icon"
                                style={{
                                  fontSize: "15px",
                                }}
                              />
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="Удаление недоступно, протокол был передан во ФГИС «Аршин»"
                            placement="top-end"
                          >
                            <span className="action-container-block-disabled">
                              <span
                                className="fas fa-trash icon"
                                style={{
                                  fontSize: "15px",
                                  color: "#ccc",
                                }}
                              />
                            </span>
                          </Tooltip>
                        )
                      ) : null}
                    </div>,
                  ];
                },
              },
            ]}
            rowData={filteredProtocolsData.map((item) => ({
              id: item.id,
              numId: item.id,
              verificationDate: moment(item.verification_date.full).format(
                "DD-MM-YYYY"
              ),
              measurement: item.measurement?.name,
              createdBy: `${item.createdBy?.surname || ""} ${
                item.createdBy?.name || ""
              }`,
              totalAccepted: item.total_accepted ? "Пригоден" : "Непригоден",
              status: item.status,
              arshinId: item.arshin_id ? item.arshin_id : "номера нет",
              statusArshin: item.status_arshin,
              statusFsa: item.status_fsa,
              originalCase: item.originalCase
                ? item.originalCase
                : "Эталоны комплекта были изменены",
            }))}
            rowCallback={handleShowClick}
            rowSelectionCallback={(array) => {
              dispatch(setSelectedProtocols(array));
            }}
            noRowsText={noRowsText}
            hasCheckboxSelection={true}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <RightSideContainer blockTitle={"Фильтры"}>
            <div className="right-side-container">
              <div className="right-side-container-block">
                <span className="label-filter">Область измерения</span>
                <div style={{ width: "100%" }}>
                  <FilterMeasurement
                    measurementId={measurementIdFilter}
                    setMeasurementId={changeMeasurementId}
                  />
                </div>
              </div>

              {userRole !== "metrologist" && (
                <div className="right-side-container-block">
                  <span className="label-filter">Поверители</span>
                  <div style={{ width: "100%" }}>
                    <FilterUsers userId={userId} setUserId={changeUserId} />
                  </div>
                </div>
              )}

              <div className="right-side-container-block">
                <span className="label-filter">Заключение поверки</span>
                <Approved
                  approved={statusProtocol}
                  changeApproved={changeApprovedStatus}
                  labelTrue={"Пригоден"}
                  labelFalse={"Непригоден"}
                  valueTrue={true}
                  valueFalse={false}
                />
              </div>

              <div className="right-side-container-block">
                <span className="label-filter">Дата поверки</span>
                <DatePicker
                  selectedDate={dateFrom}
                  dateChange={handleVerifiedAtChange}
                />

                <DatePicker
                  selectedDate={dateTo}
                  dateChange={handleExpiredAtChange}
                />
              </div>
              <Button
                variant="outlined"
                onClick={handleResetFilter}
                style={{
                  width: "100%",
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "11px 20px 11px",
                  borderRadius: "12px",
                  marginTop: "16px",
                }}
              >
                Сбросить все значения
              </Button>
            </div>
          </RightSideContainer>

          <div className="header_box" style={{ position: "relative" }}>
            <div style={{ width: width > 1050 ? "82%" : "100%" }}>
              <SearchQuery
                searchQuery={protocolSearchQuery}
                setSearchQuery={changeSearchQuery}
              />
            </div>

            {width > 1050 && (
              <React.Fragment>
                {userRole !== "metrologist" && <Csv />}
              </React.Fragment>
            )}

            <div
              style={{
                position: "relative",
                width: width > 1050 ? "220px" : "100%",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(setOpenRightContainer(true));
                }}
                startIcon={<FilterIcon />}
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "11px 20px 11px",
                  borderRadius: "12px",
                  width: "100%",
                }}
              >
                Фильтры
              </Button>
              <span
                style={{
                  display: "block",
                  position: "absolute",
                  width: "22px",
                  height: "22px",
                  borderRadius: "50%",
                  backgroundColor: "#1976d2",
                  color: "white",
                  fontSize: "11px",
                  lineHeight: "21px",
                  textAlign: "center",
                  top: 0,
                  left: "100%",
                  marginLeft: "-18px",
                  marginTop: "-4px",
                  transition: "all 300ms",
                  opacity: countArchive > 0 ? 1 : 0,
                }}
              >
                {countArchive}
              </span>
            </div>
          </div>

          <Collapse in={selectedProtocols && selectedProtocols.length > 0}>
            <div
              className="flexContainerWithGap"
              style={{ marginTop: "11px", gap: "0px" }}
            >
              <Button
                variant="text"
                startIcon={<PdfIcon />}
                onClick={handlePdf}
                style={{
                  width: width > 1050 ? "" : "100%",
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "11px 20px 11px",
                  borderRadius: "12px",
                  color: "#123532",
                }}
              >
                Скачать PDF
              </Button>

              <React.Fragment>
                {(userRole === "super" || userRole === "admin") && (
                  <Button
                    variant="text"
                    startIcon={<RestoreIcon />}
                    onClick={() => {
                      dispatch(setOpen(true));

                      dispatch(
                        setTitle(
                          "Вы действительно хотите востановить выбранные протоколы?"
                        )
                      );
                      dispatch(
                        setChildren(
                          "После восстановления, выбранные протоколы будут перенесены во вкладку 'Актуальные'."
                        )
                      );
                      dispatch(setBA("Восстановить"));
                      dispatch(
                        setConfirmAction(() => handleUnArchiveProtocol())
                      );
                    }}
                    style={{
                      width: width > 1050 ? "" : "100%",
                      textTransform: "none",
                      fontSize: "15px",
                      fontFamily: '"Wix Madefor Display", sans-serif',
                      padding: "11px 20px 11px",
                      borderRadius: "12px",
                      color: "#123532",
                    }}
                  >
                    Восстановить
                  </Button>
                )}
              </React.Fragment>
            </div>
          </Collapse>

          <Table
            css={{
              marginTop: "12px",
              backgroundColor: "#ffffff",
              border: "1px solid #d7ddea",
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "1.2",
                wordWrap: "break-word",
              },
              "& .MuiDataGrid-cell:hover": {
                cursor: "default",
              },
              "& .MuiDataGrid-cell": {
                whiteSpace: "normal",
                wordWrap: "break-word",

                height: "auto",
                lineHeight: "1.5",
                padding: "8px",
                boxSizing: "border-box",
              },
            }}
            colData={[
              {
                field: "numId",
                headerName: width > 1050 ? "Номер" : "№",
                width: 100,
              },
              {
                field: "verificationDate",
                headerName: "Дата поверки",
                width: 200,
              },
              {
                field: "totalAccepted",
                headerName: "Заключение",
                width: 300,
              },
              userRole === "metrologist"
                ? {
                    field: "measurement",
                    headerName: "Область измерения",
                    width: 300,
                  }
                : {
                    field: "createdBy",
                    headerName: (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        Поверитель
                        <Tooltip
                          title={
                            "Фактический поверитель - пользователь который создал протокол в системе"
                          }
                          placement="top"
                          style={{ marginLeft: "7px" }}
                        >
                          <i
                            className="fas fa-question-circle question"
                            style={{ marginLeft: "7px", cursor: "pointer" }}
                          />
                        </Tooltip>
                      </div>
                    ),
                    width: 300,
                  },

              {
                field: "arshinId",
                headerName: (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    № свидетельства или извещения
                    <Tooltip
                      title={"Приходит из ФГИС «Аршин»"}
                      placement="top"
                      style={{ marginLeft: "7px" }}
                    >
                      <i
                        className="fas fa-question-circle question"
                        style={{ marginLeft: "7px", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                ),
                width: 300,
              },
            ]}
            rowData={filteredProtocolsData.map((item) => ({
              id: item.id,
              numId: item.id,
              verificationDate: moment(item.verification_date.full).format(
                "DD-MM-YYYY"
              ),
              measurement: item.measurement?.name,
              createdBy: `${item.createdBy?.surname || ""} ${
                item.createdBy?.name || ""
              }`,
              totalAccepted: item.total_accepted ? "Пригоден" : "Непригоден",
              arshinId: item.arshin_id ? item.arshin_id : "номера нет",
            }))}
            rowCallback={handleShowClick}
            rowSelectionCallback={(array) => {
              dispatch(setSelectedProtocols(array));
            }}
            noRowsText={noRowsText}
            hasCheckboxSelection={width > 1050 ? true : false}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
          />
        </React.Fragment>
      )}
    </main>
  );
};

export default AllProtocols;
