// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import FilterCompany from "../../components/LocalElements/FilterCompany/FilterCompany"
import SearchQuery from "../../components/LocalElements/Search/SearchQuery"
import Table from "../../components/LocalElements/Table/Table"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Tooltip from "@mui/material/Tooltip"

import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
} from "../../store/slices/comps/modal"

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../store/slices/comps/snackbar"
import { setSearchQuery as setSearchQueryUsers } from "../../store/slices/views/users"
import { refreshUsers } from "../../store/slices/controlers/updater"
import { setName } from "../../store/slices/controlers/pageName"

import R from "../../services/request.service"
import D from "../../store/localStorage/dispatcher"

import Skeleton from "../../components/LocalElements/Skeleton/Skeleton"

const Users = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isToggle = useSelector((state) => state.toggleReducer.isToggle)

  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole")
  const userRole = useSelector((state) => state.headerReducer.role)

  const isLoading = useSelector((state) => state.usersReducer.isLoading)

  const usersList = useSelector((state) => state.usersReducer.usersList)
  const usersSearchQuery = useSelector(
    (state) => state.usersReducer.searchQuery
  )

  const changeSearchQuery = (param) => {
    dispatch(setSearchQueryUsers(param))
  }

  const handleIsTrustedChange = async (userId, isChecked) => {
    await R.changeUserTrust(userId, { isTrusted: isChecked })
    dispatch(refreshUsers())
  }

  const handleCheckboxBan = async (userId, isChecked) => {
    await R.changeUserBan(userId, { status: isChecked })
    dispatch(refreshUsers())
  }

  const handleUsersClick = () => {
    navigate("/metriva/users/create")
  }

  const handleEditClick = (userId) => {
    navigate(`/metriva/users/${userId}/edit`)
  }

  const handleRowShowClick = (params) => {
    navigate(`/metriva/users/${params.row.id}`)
  }

  const handleDelete = async (userId) => {
    const { status } = await R.deleteUser(userId)
    if (status === 200) {
      dispatch(setType("success"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Пользователь удален"))
      dispatch(setOpen(false))
      dispatch(refreshUsers())
    } else {
      dispatch(setType("error"))
      dispatch(setOpenAlert(true))
      dispatch(setMessage("Ошибка обращения к API."))
    }
  }

  let colData = [
    { field: "name", headerName: "Имя в системе", width: 300 },
    { field: "job", headerName: "Должность", width: 200 },
    { field: "login", headerName: "Логин", width: 200 },
    { field: "mail", headerName: "Email", width: 300 },
    {
      field: "lastActive",
      headerName: "Последняя активность",
      width: 300,
    },
    {
      field: "isTrusted",
      headerName: (
        <Tooltip
          title="Наличие возможности отправлять протоколы во ФГИС без дополнительной проверки"
          placement="top"
        >
          <span>Опытный</span>
        </Tooltip>
      ),
      width: 140,
      renderCell: (params) => {
        const handleIsTrustedClick = async (event) => {
          event.stopPropagation()
          const userId = params.row.id
          const isChecked = event.target.checked
          await handleIsTrustedChange(userId, isChecked)
        }

        if ( params.row.job !== "Поверитель" ) {
          return <Checkbox checked={params.value} disabled />
        } else {
          return (
            <Checkbox
              checked={params.value}
              onClick={handleIsTrustedClick}
              userId={params.row.id}
              isChecked={params.value}
            />
          )
        }
      },
    },
  ]

  if ( userRole === "super" ) {
    colData.splice(6, 0, {
      field: "status",
      headerName: "Забанить",
      width: 140,
      renderCell: (params) => {
        const handleStatusClick = async (event) => {
          event.stopPropagation()
          const userId = params.row.id
          const isChecked = event.target.checked
          await handleCheckboxBan(userId, isChecked)
        }

        return (
          <Checkbox
            checked={params.value === "banned"}
            onClick={handleStatusClick}
            userId={params.row.id}
            isChecked={params.value}
          />
        )
      },
    })
  }

  if ( userRole === "super" ) {
    colData.splice(7, 0, {
      field: "company",
      headerName: "Компания",
      width: 400,
    })
  }

  if ( userRole === "super" || userRole === "admin" ) {
    colData.splice(8, 0, {
      field: "actions",
      type: "actions",
      headerName: "Действия",
      width: 200,
      getActions: (item) => {
        const localItem = item

        return [
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "space-around",
              gap: "22px",
            }}
          >
            <Tooltip title="Редактировать пользователя" placement="top-end">
              <span
                onClick={() => handleEditClick(localItem.id)}
                style={{
                  width: "5%",
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-around",
                  marginRight: "5px",
                }}
              >
                <span
                  className="fas fa-pencil-alt icon"
                  style={{
                    fontSize: "15px",
                  }}
                />
              </span>
            </Tooltip>
            <Tooltip title="Удалить пользователя" placement="top-end">
              <span
                onClick={() => {
                  dispatch(setOpen(true))
                  dispatch(
                    setTitle("Вы действительно хотите удалить пользователя?")
                  )
                  dispatch(
                    setChildren("Отменить действие будет уже невозможно.")
                  )
                  dispatch(setConfirmAction(() => handleDelete(localItem.id)))
                }}
                style={{
                  width: "5%",
                  display: "flex",
                  flexFlow: "row",
                  justifyContent: "space-around",
                }}
              >
                <span
                  className="fas fa-trash icon"
                  style={{
                    fontSize: "15px",
                  }}
                />
              </span>
            </Tooltip>
          </div>,
        ]
      },
    })
  }

  useEffect(() => { dispatch(setName("Пользователи в системе")) }, []) 

  return (
    <main className={isToggle ? "main_open" : "main"}>
      <div className="header_box">
        <div className="flexContainerWithGap">
          { userRole === "super" && (
            <div className="selector">
              <FilterCompany />
            </div>
          )}

          {( userRole === "admin" || userRole === "super" ) && (
            <div className="btn">
              <Button
                variant="contained"
                onClick={handleUsersClick}
                sx={{ mt: 1, mr: 1 }}
                style={{
                  textTransform: "none",
                  fontSize: "15px",
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: "0px 20px",
                  borderRadius: "12px",
                  width: "100%",
                  height: "55px",
                  margin: 0,
                }}
              >
                Добавить пользователя
              </Button>
            </div>
          )}
        </div>
        <div style={{ width: "300px" }}>
          <SearchQuery
            searchQuery={usersSearchQuery}
            setSearchQuery={changeSearchQuery}
          />
        </div>
      </div>

      { isLoading ? (
        <Skeleton />
      ) : (
        <Table
          css={{
            marginTop: "12px",
            backgroundColor: "#ffffff",
            border: "1px solid #d7ddea",
            "& .MuiDataGrid-cell:hover": {
              cursor: "pointer",
            },
          }}
          colData={colData}
          rowData={usersList.map((user) => ({
            id: user.id,
            name: user.surname + " " + user.name,
            job:
              user.role === "metrologist"
                ? "Поверитель"
                : user.role === "brigadier"
                ? "Руководитель"
                : user.role === "admin"
                ? "Админ"
                : user.role === "super"
                ? "Суперадмин"
                : "",
            login: user.login,
            mail: user.email,
            lastActive: user.last_event
              ? moment(user.last_event).format("DD-MM-YYYY, HH:mm")
              : "Нет информации",
            isTrusted: user.is_trusted,
            status: user.status,
            company: user.company?.name,
          }))}
          rowCallback={handleRowShowClick}
          hasCheckboxSelection={false}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      )}
    </main>
  )
}

export default Users
