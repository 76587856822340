import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  open: false
}

export const bottomContainerReducer = createSlice({
  name: 'bottomContainerReducer',
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload
    }
  }
})

export const { setOpen } = bottomContainerReducer.actions
export default bottomContainerReducer.reducer