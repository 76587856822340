// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import R from "../services/request.service";
import { useDispatch } from "react-redux";
import {
  setList,
  setMeta,
  setIsLoading as setIsLoadingCompanies,
} from "../store/slices/views/companies";
import {
  setList as setListCases,
  setMeta as setMetaCases,
  setIsLoading as setIsLoadingCases,
} from "../store/slices/views/cases";
import {
  setList as setListMeters,
  setMeta as setMetaMeters,
  setIsLoading as setIsLoadingMeters,
  changeFilterParams,
} from "../store/slices/views/meters";
import {
  setList as setListUsers,
  setMeta as setMetaUsers,
  setIsLoading as setIsLoadingUser,
} from "../store/slices/views/users";
import {
  setList as setListProtocols,
  setMeta as setMetaProtocols,
  setIsLoading as setIsLoadingProtocol,
} from "../store/slices/views/protocols";

import {
  setDataVerifications,
  setMetaVerifications,
  setIsLoadingVerifications,
  setDataTickets,
  setMetaTickets,
  setIsLoadingTickets,
  setDataConditions,
  setMetaConditions,
  setIsLoadingConditions,
  setDataComplect,
  setMetaComplect,
  setIsLoadingComplect,
} from "../store/slices/views/journals";

import {
  setDataEtalon,
  setMetaEtalon,
  setIsLoading as setIsLoadingEtalon,
} from "../store/slices/views/etalon";

import {
  setLogo,
  setRole,
  setSurName,
  setName,
  setIsTrusted,
} from "../store/slices/controlers/headerData";

const useUpdate = () => {
  const dispatch = useDispatch();

  const updateCompanies = async (searchQuery) => {
    dispatch(setIsLoadingCompanies(true));
    const { status: statusCompanies, data: dataCompanies } =
      await R.getCompanies(`?search=${searchQuery}&limit=100`);
    dispatch(setIsLoadingCompanies(false));
    dispatch(setList(dataCompanies.data));
    dispatch(setMeta(dataCompanies.meta));

    false && false && console.log(dataCompanies);
  };

  const updateCases = async (searchQuery, companyId) => {
    dispatch(setIsLoadingCases(true));
    const { status: statusCases, data: dataCases } = await R.getCases(
      `?search=${searchQuery}&company_id=${companyId}&limit=100`
    );
    dispatch(setIsLoadingCases(false));
    dispatch(setListCases(dataCases.data));
    dispatch(setMetaCases(dataCases.meta));

    false && false && console.log(dataCases);
  };

  const updateVerifications = async (companyId) => {
    dispatch(setIsLoadingVerifications(true));
    const { status: statusVerifications, data: dataVerifications } =
      await R.getVerifications(`?company_id=${companyId}&limit=100`);
    dispatch(setIsLoadingVerifications(false));
    dispatch(setDataVerifications(dataVerifications.data));
    dispatch(setMetaVerifications(dataVerifications.meta));

    false && false && console.log(dataVerifications);
  };

  const updateTickets = async (companyId) => {
    dispatch(setIsLoadingTickets(true));
    const { status: statusTickets, data: dataTickets } = await R.getTickets(
      `?company_id=${companyId}&limit=100`
    );
    dispatch(setIsLoadingTickets(false));
    dispatch(setDataTickets(dataTickets.data));
    dispatch(setMetaTickets(dataTickets.meta));

    false && false && console.log(dataTickets);
  };

  const updateConditions = async (companyId) => {
    dispatch(setIsLoadingConditions(true));
    const { status: statusConditions, data: dataConditions } =
      await R.getConditions(`?company_id=${companyId}&limit=100`);
    dispatch(setIsLoadingConditions(false));
    dispatch(setDataConditions(dataConditions.data));
    dispatch(setMetaConditions(dataConditions.meta));

    false && false && console.log(dataConditions);
  };

  const updateComplect = async (
    companyId,
    verifiedAtFormatted,
    expiredAtFormatted
  ) => {
    dispatch(setIsLoadingComplect(true));
    const { status: statusComplect, data: dataComplect } = await R.getComplect(
      `?company_id=${companyId}&issue_at_start=${verifiedAtFormatted}&issue_at_end=${expiredAtFormatted}&limit=100`
    );
    dispatch(setIsLoadingComplect(false));
    dispatch(setDataComplect(dataComplect.data));
    dispatch(setMetaComplect(dataComplect.meta));

    false && false && console.log(dataComplect);
  };

  const updateEtalon = async (searchQuery, companyId) => {
    dispatch(setIsLoadingEtalon(true));
    const { status: statusEtalon, data: dataEtalon } = await R.getEtalon(
      `?search=${searchQuery}&company_id=${companyId}&limit=100`
    );
    dispatch(setIsLoadingEtalon(false));
    dispatch(setDataEtalon(dataEtalon.data));
    dispatch(setMetaEtalon(dataEtalon.meta));

    false && false && console.log(dataEtalon);
  };

  const updateMeters = async (measurement_id, search_query) => {
    dispatch(setIsLoadingMeters(true));
    const { status: statusMeters, data: dataMeters } =
      await R.getMetersListWithParams(measurement_id, search_query);
    dispatch(setIsLoadingMeters(false));
    dispatch(setListMeters(dataMeters.data));
    dispatch(setMetaMeters(dataMeters.meta));

    dispatch(
      changeFilterParams({
        value: "methods",
        label: [{ value: 1, label: "МИ 1592-2015" }],
      })
    );
    dispatch(
      changeFilterParams({
        value: "titles",
        label: [
          ...new Set(
            dataMeters.data.map((meter) => {
              return meter.name_si;
            })
          ),
        ].map((item) => ({ value: item, label: item })),
      })
    );
    dispatch(
      changeFilterParams({
        value: "customers",
        label: [
          ...new Set(
            dataMeters.data.map((meter) => {
              return meter.manufacturer.name;
            })
          ),
        ].map((item) => ({ value: item, label: item })),
      })
    );

    false && console.log(dataMeters);
  };

  const updateUsers = async (searchQuery, companyId) => {
    dispatch(setIsLoadingUser(true));
    const { status: statusUsers, data: dataUsers } = await R.getUsers(
      `?search=${searchQuery}&company_id=${companyId}&limit=100`
    );
    dispatch(setIsLoadingUser(false));
    dispatch(setListUsers(dataUsers.data));
    dispatch(setMetaUsers(dataUsers.meta));

    false && false && console.log(dataUsers);
  };

  const updateProtocols = async (
    companyId,
    protocolSearchQuery,
    measurementIdFilter,
    userId,
    statusId,
    approvedTransfer,
    approvedArshin,
    approvedFsa,
    dateFrom,
    dateTo
  ) => {
    dispatch(setIsLoadingProtocol(true));

    const formattedDateFrom =
      dateFrom === "Invalid Date" || dateFrom === null ? "" : dateFrom;
    const formattedDateTo =
      dateTo === "Invalid Date" || dateTo === null ? "" : dateTo;

    const { status: statusProtocols, data: dataProtocols } =
      await R.getProtocolsList(
        `?company_id=${companyId}&search=${protocolSearchQuery}&measurement_id=${measurementIdFilter}&created_by=${userId}&total_accepted=${statusId}&status=${approvedTransfer}&status_arshin=${approvedArshin}&status_fsa=${approvedFsa}&verification_date_from=${formattedDateFrom}&verification_date_to=${formattedDateTo}&limit=100`
      );

    dispatch(setIsLoadingProtocol(false));
    dispatch(setListProtocols(dataProtocols.data));
    dispatch(setMetaProtocols(dataProtocols.meta));
  };

  const updateHeaderData = async () => {
    const { status: statusHeaderData, data: headerData } =
      await R.getHeaderData();

    const logoPath = headerData.data.company.logo
      ? headerData.data.company.logo.path
      : null;
    dispatch(setLogo(logoPath));
    dispatch(setRole(headerData.data.role));
    dispatch(setSurName(headerData.data.surname));
    dispatch(setName(headerData.data.name));
    dispatch(setIsTrusted(headerData.data.is_trusted));

    false && false && console.log(headerData);
  };

  return [
    updateCompanies,
    updateCases,
    updateVerifications,
    updateTickets,
    updateConditions,
    updateComplect,
    updateEtalon,
    updateMeters,
    updateUsers,
    updateProtocols,
    updateHeaderData,
  ];
};

export default useUpdate;
