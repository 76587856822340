// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";

import SelectCompany from "../../components/Cases/SelectCompany";
import InnerDataCase from "../../components/Cases/InnerDataCase";
import Measurements from "../../components/Cases/Measurements";
import Methods from "../../components/Cases/Methods";
import TypeMethod from "../../components/Cases/TypeMethod";
import MethodPoint from "../../components/Cases/MethodPoint";
import Kits from "../../components/Cases/Kits";

import D from "../../store/localStorage/dispatcher";
import R from "../../services/request.service";
import Feedback from "../../components/LocalElements/Feedback/Feedback";

import { setShow } from "../../store/slices/comps/feedback";

import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from "../../store/slices/comps/snackbar";
import { changeData, resetCreateData } from "../../store/slices/views/cases";
import { refreshCases } from "../../store/slices/controlers/updater";

const EditCases = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cases_id } = useParams();

  const isToggle = useSelector((state) => state.toggleReducer.isToggle);
  const show = useSelector((state) => state.feedbackReducer.show);

  // eslint-disable-next-line no-unused-vars
  const userRoleDeprecated = D.get("userRole");
  const userRole = useSelector((state) => state.headerReducer.role);

  const [validationErrors, setValidationErrors] = useState({});
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);

  const [data, setData] = useState([]);

  const companyId = useSelector(
    (state) => state.casesReducer.createData.companyId
  );
  const innerName = useSelector(
    (state) => state.casesReducer.createData.innerName
  );
  const arshinName = useSelector(
    (state) => state.casesReducer.createData.arshinName
  );
  const snils = useSelector((state) => state.casesReducer.createData.snils);
  const measurementId = useSelector(
    (state) => state.casesReducer.createData.measurementId
  );
  const methodId = useSelector(
    (state) => state.casesReducer.createData.methodId
  );
  const typeMethodId = useSelector(
    (state) => state.casesReducer.createData.typeMethodId
  );
  const methodPointIds = useSelector(
    (state) => state.casesReducer.createData.methodPoints
  );
  const kitIds = useSelector((state) => state.casesReducer.createData.kitIds);

  useEffect(() => {
    (async () => {
      dispatch(setShow(true));
      try {
        const { data: caseData } = await R.getCasesShow(cases_id);
        const data = caseData.data;

        setData(data);

        dispatch(
          changeData({
            value: "companyId",
            label: data.company.id,
          })
        );
        dispatch(
          changeData({
            value: "innerName",
            label: data.inner_name,
          })
        );
        dispatch(
          changeData({
            value: "arshinName",
            label: data.arshin_name,
          })
        );
        dispatch(
          changeData({
            value: "snils",
            label: data.snils,
          })
        );
        dispatch(
          changeData({
            value: "measurementId",
            label: data.measurement.id,
          })
        );
        dispatch(
          changeData({
            value: "methodId",
            label: data.method.id,
          })
        );
        dispatch(
          changeData({
            value: "typeMethodId",
            label: data.verificationType.id,
          })
        );

        const initialPoints = data.points.map((point) => point.id);
        dispatch(changeData({ value: "methodPoints", label: initialPoints }));

        const initialDevices = data.kits.map((kit) => ({
          id: kit.device.id,
          kit_id: kit.id,
          requiredArshin: kit.is_send_to_arshin,
        }));

        dispatch(changeData({ value: "deviceIds", label: initialDevices }));
      } catch (error) {
        console.error(error);
      }
      dispatch(setShow(false));
    })();
  }, [cases_id]);

  const handleSave = async () => {
    setIsDisabledBtn(true);

    const data = {
      arshin_name: arshinName,
      inner_name: innerName,
      snils: snils,
      method_id: methodId,
      verification_type_id: typeMethodId,
      point_ids: methodPointIds,
      kits: kitIds,
    };

    if (userRole === "super") {
      data.company_id = companyId;
    }

    const { status, data: addCaseResponse } = await R.addCasesPut(
      cases_id,
      data
    );

    if (status === 200) {
      dispatch(setMessage("Комплект СП успешно отредактирован"));
      dispatch(setType("success"));
      dispatch(setOpenAlert(true));
      dispatch(refreshCases());
      navigate("/metriva/cases");
    } else if (status === 422) {
      if (addCaseResponse.errors) {
        const errors = addCaseResponse.errors;
        setValidationErrors(errors);
      }
      dispatch(setMessage(`Комплект СП не отредактирован - ошибка валидации`));
      dispatch(setType("error"));
      dispatch(setOpenAlert(true));
      setIsDisabledBtn(false);
    } else {
      if (addCaseResponse.errors) {
        const errors = addCaseResponse.errors;
        setValidationErrors(errors);
      }
      dispatch(setMessage("Ошибка обращения к api"));
      dispatch(setType("error"));
      dispatch(setOpenAlert(true));
      setIsDisabledBtn(false);
    }
  };

  const navigateToProtocols = () => {
    navigate("/metriva/cases");
  };

  useEffect(() => {
    return () => {
      dispatch(resetCreateData());
    };
  }, []);

  return (
    <main className={isToggle ? "main_open" : "main"}>
      {show ? (
        <Feedback />
      ) : (
        <React.Fragment>
          <div className="path_to_back" onClick={navigateToProtocols}>
            <i
              className="fas fa-arrow-left icon_back"
              style={{ width: "30px", height: "30px", marginRight: "6px" }}
            />
            <p>Все комплекты СП</p>
          </div>

          <div className="box">
            {userRole === "super" && (
              <div className="width32Percent" style={{ marginBottom: "15px" }}>
                <h2 style={{ marginBottom: "15px" }}>Компания</h2>
                <SelectCompany />
                {validationErrors["company_id"] && (
                  <div className="error">*{validationErrors["company_id"]}</div>
                )}
              </div>
            )}

            <h2 style={{ marginBottom: "25px" }}>
              Внутренние данные комплекта СП
            </h2>
            <InnerDataCase validationErrors={validationErrors} />

            <h2 style={{ marginBottom: "11px" }}>
              Область измерения и методика поверки
            </h2>

            <div className="flexContainerWithGap">
              <div className="width32Percent" style={{ marginBottom: "13px" }}>
                <Measurements />
              </div>

              <div className="width32Percent">
                <Collapse in={!!measurementId}>
                  <Methods />
                </Collapse>
              </div>

              <div className="width32Percent">
                <Collapse in={!!measurementId && !!methodId}>
                  <TypeMethod />
                </Collapse>
              </div>
            </div>

            <Collapse in={!!measurementId && !!methodId && !!typeMethodId}>
              <MethodPoint />
            </Collapse>

            <Collapse
              in={
                !!measurementId &&
                !!methodId &&
                !!typeMethodId &&
                methodPointIds.length > 0
              }
            >
              <React.Fragment>
                <h2 style={{ marginBottom: "15px", marginTop: "20px" }}>
                  Эталоны
                </h2>
                <Kits />
              </React.Fragment>
            </Collapse>
          </div>

          <div style={{ marginTop: "10px" }}>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isDisabledBtn}
              sx={{ mt: 1, mr: 1 }}
              style={{
                textTransform: "none",
                fontSize: "15px",
                fontFamily: '"Wix Madefor Display", sans-serif',
                padding: "11px 20px 11px",
                margin: 0,
                borderRadius: "12px",
                width: "100%",
              }}
            >
              Редактировать комплект СП
            </Button>
          </div>
        </React.Fragment>
      )}
    </main>
  );
};

export default EditCases;
