import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isToggle: false,
};

const toggleReducer = createSlice({
  name: "toggleReducer",
  initialState,
  reducers: {
    setIsToggle: (state, action) => {
      state.isToggle = action.payload;
    },
  },
});

export const { setIsToggle } = toggleReducer.actions;
export default toggleReducer.reducer;
