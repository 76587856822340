import React from "react";

const NameData = ({ nameSi, setNameSi, validationErrors }) => {
  return (
    <>
      <div className="meters_box">
        <p>Изготовитель и место производства</p>
        <input
          className="input"
          placeholder="Изготовитель"
          value={nameSi}
          onChange={(e) => setNameSi(e.target.value)}
        />
        {validationErrors["name_si"] && (
          <div className="error">{validationErrors["name_si"]}</div>
        )}
      </div>
    </>
  );
};

export default NameData;
