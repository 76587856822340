import { configureStore } from "@reduxjs/toolkit"
import auth from "./slices/controlers/auth"
import companies from "./slices/views/companies"
import cases from "./slices/views/cases"
import companyId from "./slices/controlers/companyId"
import users from "./slices/views/users"
import meters from "./slices/views/meters"
import protocolReducer from "./slices/views/protocols"
import snackbarReducer from "./slices/comps/snackbar"
import rightContainer from "./slices/comps/rightContainer"
import bottomContainer from "./slices/comps/bottomContainer"
import modalReducer from "./slices/comps/modal"
import journals from "./slices/views/journals"
import etalon from "./slices/views/etalon"
import feedback from "./slices/comps/feedback"
import updater from "./slices/controlers/updater"
import headerData from "./slices/controlers/headerData"
import toggle from "./slices/controlers/toggle"
import pageName from "./slices/controlers/pageName"

// ----------------------------------------------------------------
// стейт для новых методик ниже
// ----------------------------------------------------------------

import exampleMethodologyReducer from "../components/Methodologies/Water/ExampleComponent/state"
import gost83242002Reducer from "../components/Methodologies/Gas/Gost8324-2002/state"
import gost815683Reducer from "../components/Methodologies/Water/Gost8-156-83/state"
import mi15922015Reducer from "../components/Methodologies/Water/Mi1592-2015/state"

import showProtocolReducer from "../components/Methodologies/UniversalShow/state"

export const store = configureStore({
  reducer: {
    authReducer: auth,
    companiesReducer: companies,
    casesReducer: cases,
    companyIdReducer: companyId,
    usersReducer: users,
    protocolReducer: protocolReducer,
    snackbarReducer: snackbarReducer,
    rightContainerReducer: rightContainer,
    bottomContainerReducer: bottomContainer,
    modalReducer: modalReducer,
    meterReducer: meters,
    journalReducer: journals,
    etalonReducer: etalon,
    feedbackReducer: feedback,
    updaterReducer: updater,
    headerReducer: headerData,
    toggleReducer: toggle,
    pageNameReducer: pageName,

    exampleMethodologyReducer: exampleMethodologyReducer,
    gost83242002Reducer: gost83242002Reducer,
    gost815683Reducer: gost815683Reducer,
    mi15922015Reducer: mi15922015Reducer,

    showProtocolReducer: showProtocolReducer
  },
})
